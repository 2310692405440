<template>
  <div class="macroapp--page-common macroapp">
    <transition name="fade" mode="out-in">
      <page-content-skeleton v-if="!loaded"></page-content-skeleton>
      <component v-bind:is="dynamicComponent" v-if="loaded"></component>
    </transition>
    <popup-not-found-error v-if="notFoundPage" v-on:close="goBack()"></popup-not-found-error>
  </div>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import PageList from './PageList'
import PageContentSkeleton from './PageContentSkeleton.vue'
import PageDetails from './PageDetails'
import PageVideo from './PageVideo'
import PageContent from './PageContent'
import MainPageMixin from '../../../../mixins/mainPageMixin'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import PopupNotFoundError from '../../../global/popups/PopupNotFoundError'
import PageRecipes from '@/components/layout/template-1/content/PageRecipes'
import PageDetailRecipe from '@/components/layout/template-1/content/PageDetailRecipe'
import DashboardService from "@/services/DashboardService";

export default {
  name: 'Main',
  extends: BaseComponent,
  mixins: [MainPageMixin],
  components: {
    PopupNotFoundError,
    PageContentSkeleton
  },
  beforeRouteEnter: function (to, from, next) {

    let initData = async () => {
      let service = new DashboardService()
      await service.getPageData()
      await service.getUserData()
    }

    initData().finally(() => {
      next()
    })
  },
  data: function () {
    return {
      pageName: this.$route.params.contentPage.toString(),
      loaded: false,
      components: {
        'content-custom-list': {design1: PageList},
        'content-custom-details': {design1: PageDetails},
        'content-custom-video': {design1: PageVideo},
        'content-custom-content': {design1: PageContent},
        'content-custom-recipes': {design1: PageRecipes},
        'content-custom-recipe-detail': {design1: PageDetailRecipe}

      },
      pageType: null,
      pageCode: null,
      notFoundPage: false
    }
  },
  updated() {
    this.setPageName(this.pageCode)
  },
  mounted() {
    const service = new ContentPageFactoryService()
    service.getPageList().then(data => {
      const link = service.findPageInList(this.pageName, data)
      if (link) {
        this.pageCode = link.code
        this.loadPageData(link.code)
        this.setPageName(link.code)
      } else {
        this.notFoundPage = true
      }
    }).catch(() => {
      this.$router.go(-1)
    })
  },
  computed: {
    page() {
      console.log(this.pageType)
      switch (this.pageType) {

        case 'custom-list':
          return 'content-custom-list'
        case 'custom-video':
          return 'content-custom-video'
        // following custom page type added for the internal page handle
        case 'custom-details':
          return 'content-custom-details'
        case 'custom-recipe':
          return 'content-custom-recipes'
        case 'custom-recipe-detail':
          return 'content-custom-recipe-detail'
        default:
          return 'content-custom-content'
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    loadPageData(pageName) {
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(pageName)
      page.setPageData().then(() => {
        const queryParams = this.$route.query
        if ('recipe' in queryParams) {
          this.pageType = 'custom-recipe-detail'
        } else if ('rootItem' in queryParams && 'subItem' in queryParams) {
          this.pageType = 'custom-details'
        } else {
          this.pageType = page.pageData.type
        }
        this.loaded = true
        this.logEvent('PageView', {slug: '/page/' + this.pageName, title: page.pageData.page.title})
      }).catch((e) => {
        this.notFoundPage = true
      })
    }
  }
}
</script>
