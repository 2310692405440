import MealPlanAPI from './api/meal-plan/MealPlanAPI'
import RecipeAPI from './api/meal-plan/RecipeAPI'
import MealDislikeService from './MealDislikeService'
import LanguageService from './LanguageService'
import {getVisibilityOfCaloriesInNutrition, showNutritionInformationFlag} from '../includes/TemplateSettings'
import NutritionService from './nutrition/NutritionService'

export default class MealPlanService {
  mealPlansList = {}
  mealPlanOptions = {}
  mealPlans = {}
  allMealPlans = {} // includes inactive plans
  recipes = {}
  activeMealPlans = {}

  constructor () {
    if (!MealPlanService.instance) {
      MealPlanService.instance = this
    }
    return MealPlanService.instance
  }

  reset () {
    this.mealPlansList = {}
    this.mealPlanOptions = {}
    this.mealPlans = {}
    this.allMealPlans = {}
    this.recipes = {}
  }

  setMealPlan (id) {
    const api = new MealPlanAPI()
    return new Promise((resolve, reject) => {
      let dislikeService = new MealDislikeService()
      const promise1 = api.get(id)
      const promise2 = dislikeService.setDislikes()
      Promise.all([promise1, promise2]).then((values) => {
        this.mealPlans[id] = values[0].data
        this.setDislikes(id)
        resolve(this.mealPlans[id])
      }).catch((error) => {
        reject(error)
      })
    })
  }

  isMealPlanArchived (id) {
    if (this.mealPlans[id] && this.mealPlans[id].plan.archived) {
      return this.mealPlans[id].plan.archived || false
    }

    return false
  }

  findMealPlanAndDayIDByMealID (id) {
  }

  setDislikes (id) {
    this.mealPlans[id].plan.weeks = this.mealPlans[id].plan.weeks.map(week => {
      week.days = week.days.map(day => {
        day.meals = day.meals.map(meal => {
          let dislikeService = new MealDislikeService()
          meal.disliked = dislikeService.isDislikeMeal(meal.reference_meal_id)
          return meal
        })
        return day
      })
      return week
    })
  }

  getMealPlan (id) {
    return new Promise((resolve, reject) => {
      if (this.mealPlans.hasOwnProperty(id)) {
        this.setDislikes(id)
        resolve(this.mealPlans[id])
      } else {
        this.setMealPlan(id).then((data) => {
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  getMealsDislikedIngredients (id, excludedIngredients, currentMealId, mealSwapStatus) {
    return new Promise((resolve, reject) => {
      if (this.mealPlans.hasOwnProperty(id)) {
        const plan = this.mealPlans[id].plan
        const excludedNames = []
        const inclIng = []
        const mealIds = []
        const refMealIds = []
        let reg = ''

        for (let i = 0; i < excludedIngredients.length; i++) {
          reg += excludedIngredients[i].name + (i < excludedIngredients.length - 1 ? '|' : '')
          excludedNames.push(excludedIngredients[i].name)
        }
        reg = reg.replace(/[.*+?^${}()[\]\\]/g, '\\$&')
        const regex = new RegExp(reg)

        const days = plan.weeks[0].days
        for (let day = 0; day < days.length; day++) {
          const meals = days[day].meals
          for (let meal = 0; meal < meals.length; meal++) {
            const recipes = meals[meal].recipes
            for (let recipe = 0; recipe < recipes.length; recipe++) {
              const ingredients = recipes[recipe].ingredients
              for (let ingredient = 0; ingredient < ingredients.length; ingredient++) {
                const matched = ingredients[ingredient].name.match(regex)
                if (matched) {
                  const index = excludedNames.indexOf(matched[0])
                  if (mealIds.indexOf(meals[meal].id) === -1 && !mealSwapStatus[meals[meal].id + '' + meals[meal].reference_meal_id] && meals[meal].id !== currentMealId && meals[meal].swap_status !== 'swap-requested') {
                    mealIds.push(meals[meal].id)
                    refMealIds.push(meals[meal].reference_meal_id)
                    if (inclIng.indexOf(excludedIngredients[index]) === -1) {
                      inclIng.push(excludedIngredients[index])
                    }
                  }
                  break
                }
              }
            }
          }
        }
        resolve({
          ingredients: inclIng,
          mealIds: mealIds,
          refMealIds: refMealIds
        })
      }
    })
  }

  getMealPanIndex (mealPlanId, dayID) {
    return new Promise((resolve, reject) => {
      if (this.mealPlans.hasOwnProperty(mealPlanId)) {
        resolve(this.mealPlans[mealPlanId].plan.weeks[0].days.findIndex((day) => {
          return day.id === parseInt(dayID)
        }))
      } else {
        this.setMealPlan(mealPlanId).then((data) => {
          resolve(this.mealPlans[mealPlanId].plan.weeks[0].days.findIndex((day) => {
            return day.id === parseInt(dayID)
          }))
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  getMealsByDay (mealPlanId, dayID) {
    return new Promise((resolve, reject) => {
      if (this.mealPlans.hasOwnProperty(mealPlanId)) {
        resolve(this.mealPlans[mealPlanId].plan.weeks[0].days.find((day) => {
          return day.id === parseInt(dayID)
        }))
      } else {
        this.setMealPlan(mealPlanId).then((data) => {
          resolve(this.mealPlans[mealPlanId].plan.weeks[0].days.find((day) => {
            return day.id === parseInt(dayID)
          }))
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  getRecipesByDayAndMeal (mealPlanID, dayID, mealID) {
    return new Promise((resolve, reject) => {
      if (this.mealPlans.hasOwnProperty(mealPlanID)) {
        const day = this.mealPlans[mealPlanID].plan.weeks[0].days.find((day) => {
          return day.id === parseInt(dayID)
        })
        const recipes = day.meals.find((meal) => {
          return meal.id === parseInt(mealID)
        })
        resolve(recipes)
      } else {
        this.setMealPlan(mealPlanID).then((data) => {
          const day = this.mealPlans[mealPlanID].plan.weeks[0].days.find((day) => {
            return day.id === parseInt(dayID)
          })
          const recipes = day.meals.find((meal) => {
            return meal.id === parseInt(mealID)
          })
          resolve(recipes)
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  getRecipe (id, pid) {
    return new Promise((resolve, reject) => {
      if (this.recipes.hasOwnProperty(id)) {
        resolve(this.recipes[id])
      } else {
        this.setRecipe(id, pid).then((data) => {
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  setRecipe (id, pid) {
    const api = new RecipeAPI()
    return new Promise((resolve, reject) => {
      api.get(id, [pid]).then((data) => {
        this.recipes[id] = data.data

        resolve(this.recipes[id])
      })
    })
  }

  swapMeal (mealPlanID, mealID) {
    return new Promise((resolve, reject) => {
      const api = new MealPlanAPI()
      api.substitute(mealPlanID, mealID).then(response => {
        this.updateMealSwapStatusToRequested(mealPlanID, mealID)

        resolve(response)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  updateMealSwapStatusToRequested (mealPlanID, mealID) {
    this.mealPlans[mealPlanID].plan.weeks = this.mealPlans[mealPlanID].plan.weeks.map(week => {
      week.days = week.days.map(day => {
        day.meals = day.meals.map(meal => {
          if (meal.id === mealID) {
            meal.swap_status = 'swap-requested'
          }
          return meal
        })
        return day
      })
      return week
    })
  }

  setMealPlanOptions () {
    const api = new MealPlanAPI()
    const self = this
    return new Promise((resolve, reject) => {
      api.getList().then(function (data) {
        self.mealPlanOptions = data.data
        self.allMealPlans = self.mealPlanOptions.meal_plans
        if (self.allMealPlans) {
          self.mealPlanOptions.meal_plans = self.allMealPlans.filter((mealPlan) => {
            return mealPlan.available && mealPlan.pricing_plan_type !== 'challenge'
          })

          self.activeMealPlans.meal_plans = self.allMealPlans.filter((mealPlan) => {
            return mealPlan.available
          })
        }
        resolve(self.mealPlanOptions)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getActiveMealPlans () {
    return new Promise((resolve, reject) => {
      if (this.activeMealPlans.hasOwnProperty('meal_plans')) {
        resolve(this.activeMealPlans)
      } else {
        this.setMealPlanOptions().then((data) => {
          resolve(this.activeMealPlans)
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  getMealPlanOptions () {
    return new Promise((resolve, reject) => {
      if (this.mealPlanOptions.hasOwnProperty('meal_plans')) {
        resolve(this.mealPlanOptions)
      } else {
        this.setMealPlanOptions().then((data) => {
          let nutrition = new NutritionService()
          nutrition.mapMealPlans(data.meal_plans)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  getAllMealPlans () {
    return new Promise((resolve, reject) => {
      if (this.mealPlanOptions.hasOwnProperty('meal_plans')) {
        resolve(this.allMealPlans)
      } else {
        this.setMealPlanOptions().then(() => {
          resolve(this.allMealPlans)
        }).catch(error => {
          reject(error)
        })
      }
    })
  }

  getChallengeMealPlans () {
    return this.allMealPlans.filter((plan) => {
      return plan.pricing_plan_type === 'challenge'
    })
  }

  setMealPlanList (plans) {
    this.allMealPlans = plans
    this.mealPlanOptions.meal_plans = plans.filter((plan) => {
      return plan.available && plan.pricing_plan_type !== 'challenge'
    })
  }

  getMealPlanIDAndDayIDByMealID (id) {
    let dayID = null
    let mealPlanID = null
    for (const [key, value] of Object.entries(this.mealPlans)) {
      (value.plan.weeks.some(week => {
        return (week.days.some(day => {
          return day.meals.some(meal => {
            if (meal.id === id) {
              dayID = day.id
              mealPlanID = key
            }
          })
        }))
      }))
    }

    return {
      dayID: dayID,
      mealPlanID: mealPlanID
    }
  }

  sendRecipeEmail (formData) {
    let mealPlanName = formData.subject || ''
    if (this.mealPlanOptions.meal_plans && formData.meal_plan_id) {
      let mealPlan = this.mealPlanOptions.meal_plans.find(plan => {
        return plan.id === parseInt(formData.meal_plan_id)
      })
      if (mealPlan) {
        mealPlanName = mealPlan.name
      }
    }

    let lang = new LanguageService()
    const langCode = lang.lang
    formData = {
      ...formData,
      lang: langCode,
      meal_plan_name: mealPlanName,
      show_nutrition_info: this._checkNutritionInfoShow(),
      hide_calories: getVisibilityOfCaloriesInNutrition()
    }
    const api = new MealPlanAPI()
    return api.sendRecipeEmail(formData)
  }

  sendMealChangeRequest (mealId, formData) {
    let payload = {
      'reason': formData.reason,
      'affects_on': formData.span,
      'additional_info': formData.information
    }
    const api = new MealPlanAPI()
    return api.requestChange(mealId, payload)
  }

  sendWeeklySummaryEmail (formData) {
    let lang = new LanguageService()
    const langCode = lang.lang
    formData = {
      ...formData,
      lang: langCode,
      show_nutrition_info: this._checkNutritionInfoShow(),
      hide_calories: getVisibilityOfCaloriesInNutrition()
    }
    const api = new MealPlanAPI()
    return api.sendWeeklySummaryEmail(formData)
  }

  /**
   * check the nutrition info component show or hide based on toning
   * @returns {boolean}
   * @private
   */
  _checkNutritionInfoShow () {
    return !!showNutritionInformationFlag()
  }

  isFavouriteMealAvaiable (payload) {
    const api = new MealPlanAPI()
    return api.checkAvailabilityFavouriteMeal(payload)
  }
}
