import Vue from 'vue'
import Vuex from 'vuex'
import lodash from 'lodash'
import headerModifiers from '../../config/header-modifiers'
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    title: '',
    currentPage: '',
    /** class that append to the macroapp */
    classes: [],
    pageType :'',
    /** page background data */
    background: {
      /** show/hide the background */
      show: false,
      /** background type eg - image, gradient */
      type: '',
      /** background image path */
      image: '',
      /** background classes */
      classes: []
    },
    notificationBar: {
      show: false
    },
    eleActions: {
      backButton: 0,
      addButton: 0,
      editButton: 0,
      closeButton: 0,
      messageButton: 0,
      infoButton: 0
    },
    /** page header options */
    header: {
      /** header show/hide */
      show: false,
      /** header title main and subtitle */
      title: {
        main: '',
        sub: ''
      },
      /**  left icon list 'type1|type2' */
      left: '',
      /** left show/hide */
      leftDisabled: false,
      /**  right icon list 'type1|type2' */
      right: '',
      /**  left icon blink status */
      rightBlink: false,
      /**  left icon show/hide */
      rightDisabled: false,
      /**  help content */
      helpContent: '',
      /**  app header class modifiers */
      modifiers: []
    },
    modifiers: {
      'with-bg-color': 'app-nav--with-bg-color'
    },
    trainer: ''
  },
  mutations: {

    setPageType (state, pageType) {
      state.pageType = pageType || 'secondary'
    },
    /**
     * @param state
     * @param pageClasses array of classes
     */
    addPageClass (state, pageClasses) {
      state.classes = state.classes.concat(pageClasses)
    },
    /**
     * @param state
     * @param title object of { title: '',subtitle: ''}
     */
    setPageTitle (state, title) {
      state.title = title

      this.commit('pageStore/setDocumentTitle')
    },
    setTrainer (state, data) {
      state.trainer = data
      this.commit('pageStore/setDocumentTitle')
    },
    setDocumentTitle (state) {
      let documentTitle = state.title || state.trainer || ''
      if (state.trainer && state.title) {
        documentTitle = documentTitle + ' - ' + state.trainer
      }
      document.title = documentTitle
    },
    setHeader (state, header) {
      state.header = header
    },
    setPageName (state, pageName) {
      state.currentPage = pageName
    },
    unsetPageName (state) {
      state.currentPage = ''
    },
    setBackgroundImage (state, imagePath) {
      state.background = {
        show: true,
        type: 'image',
        image: imagePath,
        classes: []
      }
    },
    setBackground (state, payload) {
      state.background = {
        show: true,
        type: payload.type || '',
        image: payload.image || '',
        classes: payload.classes || []
      }
    },
    resetBackground (state) {
      state.background = {
        show: false,
        type: '',
        image: '',
        classes: []
      }
    },
    backButtonClicked (state) {
      state.eleActions.backButton++
    },
    addButtonClicked (state) {
      state.eleActions.addButton++
    },
    editButtonClicked (state) {
      state.eleActions.editButton++
    },
    closeButtonClicked (state) {
      state.eleActions.closeButton++
    },
    messageButtonClicked (state) {
      state.eleActions.messageButton++
    },
    infoButtonClicked (state) {
      state.eleActions.infoButton++
    },
    /**
     *
     * @param state
     * @param classes -classes to remove
     */
    removeClasses (state, classes) {
      lodash.remove(state.classes, (n) => {
        return state.classes.includes(n)
      })
    },
    setNotificationBar (state, settings) {
      state.notificationBar = settings
    },
    resetNotificationBar (state) {
      state.notificationBar = { show: false }
    },
    /**
     * reset the header object
     * @param state
     */
    resetHeader (state) {
      state.eleActions.backButton = 0
      state.eleActions.addButton = 0
      state.header = {
        show: false,
        title: '',
        subtitle: '',
        left: '',
        leftDisabled: false,
        right: '',
        rightBlink: false,
        rightDisabled: false,
        helpContent: '',
        modifiers: ['']
      }
    }

  },
  actions: {},
  getters: {
    getBackButtonClick (state) {
      return state.eleActions.backButton
    },
    getMessageButtonClick (state) {
      return state.eleActions.messageButton
    },
    getAddButtonClick (state) {
      return state.eleActions.addButton
    },
    getEditButtonClick (state) {
      return state.eleActions.editButton
    },
    getCloseButtonClick (state) {
      return state.eleActions.closeButton
    },
    getInfoButtonClick (state) {
      return state.eleActions.infoButton
    },
    getTrainerBrand (state) {
      return state.trainer
    },
    header (state) {
      return state.header
    },
    notificationBarSettings (state) {
      return state.notificationBar
    },
    navClasses (state) {
      const navClasses = []
      if (Array.isArray(state.header.modifiers)) {
        state.header.modifiers.forEach((modifier) => {
          navClasses.push(headerModifiers[modifier] || modifier)
        })
      } else {
        navClasses.push(headerModifiers[state.header.modifiers] || state.header.modifiers)
      }
      return navClasses
    },
    background (state) {
      return state.background
    },
    pageBackground (state) {
      return state.background
    },
    pageClasses (state) {
      return state.classes
    },
    pageType(state){
      return state.pageType
    }
  }
}
