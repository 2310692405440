<template>


  <div class="pc-dashboard__text pc-section-dashboard-intro-style-1">
    <text-body-extra-small :line-height="'multi'" class="pc-dashboard__text-description" v-html="elements">
    </text-body-extra-small>
  </div>
</template>

<script>
import TextContent from "@/components/root/TextContent.vue";
import CardEmpty from "../CardEmpty.vue";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall.vue";

export default {
  name: 'sectionDashboardIntroStyle1',
  components: {
    TextBodyExtraSmall,
    TextContent,
    CardEmpty
  },
  data: function () {
    return {
      paragraphs: 'style1',
      videos: [],
      images: [],
      buttons: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.extractElements();
    });
  },
  props: {
    elements: {
      default: ''
    }
  },
  watch: {
    elements: function () {
      this.extractElements();
    }
  },
  methods: {
    extractElements() {
      this.$nextTick(() => {
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = this.elements;
        this.paragraphs = Array.from(tempContainer.querySelectorAll('p'))
          .map(p => p.innerText.trim())
          .filter(text => text !== '');
        this.buttons = Array.from(tempContainer.querySelectorAll('button')).map(btn => ({
          text: btn.innerText.trim(),
          id: btn.id || null,
          classes: Array.from(btn.classList)
        }));
        this.videos = Array.from(tempContainer.querySelectorAll('.video-container'))
          .map(div => div.innerText.trim())
          .filter(content => content !== '');
        this.images = Array.from(tempContainer.querySelectorAll('img')).map(img => ({
          src: img.src,
          alt: img.alt || null,
          id: img.id || null,
          classes: Array.from(img.classList)
        }));
      });
    }
  }
}
</script>
