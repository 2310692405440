<template>
  <div class="macroapp macroapp--page-common">
    <component :pageConfig="dynamicComponentConfig" v-bind:is="dynamicComponent"></component>
  </div>
</template>
<script>
import ComingSoon from './ComingSoon'
import PagePhotoJourney from './PagePhotoJourney'
import Chart from './PageMyJourneyMeasumentChart'
import Home from './PageMyJourney'
import MainPageMixin from '../../../../mixins/mainPageMixin'
import FitnessDiaryStore from '../../../../store/modules/FitnessDiaryStore'
import {flagValue} from '../../../../includes/TemplateSettings'
import DashboardService from '../../../../services/DashboardService'

export default {
  name: 'Main',
  mixins: [MainPageMixin],
  components: {
    ComingSoon
  },
  data: function () {
    return {
      components: {
        home: {design1: Home},
        'coming-soon': {design1: ComingSoon},
        'photo-journey': {design1: PagePhotoJourney, design2: PagePhotoJourney},
        chart: {design1: Chart}
      }
    }
  },
  computed: {
    page: function () {
      if (flagValue('show_fitness_diary') === null || flagValue('show_fitness_diary')) {
        switch (this.$route.query.page) {
          case 'photo-journey':
            return 'photo-journey'
          case 'chart':
            return 'chart'
          default:
            return 'home'
        }
      } else {
        return 'coming-soon'
      }
    }
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state.fitnessDiaryStore)) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  created () {
    this.setPageTitle(this.$t('message["fitness-diary.home-title"]'))
  },
  beforeDestroy () {
    this.resetHeader()
    this.resetBackground()
  },
  beforeRouteEnter: function (to, from, next) {
    let dashboardService = new DashboardService()

    Promise.all([dashboardService.getPageData(), dashboardService.getUserConfig()]).finally(() => {
      next()
    })
  },
  beforeRouteLeave (to, from, next) { //added to stop flickering when leaving page from browser nav buttons
    this.showLoading()
    let delay = 500
    setTimeout(() => {
      next()
    }, delay)
  },
  beforeRouteUpdate (to, from, next) { //added to stop flickering when leaving page from browser nav buttons
    this.showLoading()
    let delay = 500
    setTimeout(() => {
      next()
    }, delay)
  }
}

</script>
<style scoped>
</style>
