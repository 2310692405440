<template>
  <div
    class="pc-workout-history-skeleton pc-container-workout-log-history__card gc-card-workout-history"
  >
    <div
      class="pc-workout-history-skeleton__header gc-card-workout-history__header"
    >
      <AnimatedPlaceholder width="100%" height="27px" />
    </div>
    <div class="pc-workout-history-skeleton__container">
      <div
        class="pc-workout-history-skeleton-data-row"
        v-for="index in 3"
        :key="index"
      >
        <div class="pc-workout-history-skeleton--left">
          <AnimatedPlaceholder width="60px" height="27px" />
        </div>
        <div class="pc-workout-history-skeleton--middle">
          <AnimatedPlaceholder width="24px" height="20px" />
        </div>
        <div class="pc-workout-history-skeleton--right">
          <AnimatedPlaceholder width="60px" height="27px" />
        </div>
      </div>
    </div>
    <div class="pc-workout-history-skeleton__bottom">
      <AnimatedPlaceholder width="50%" height="15px" />
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";

// import AnimatedPlaceHolder from "@/components/global/animators/AnimatedPlaceHolder";
export default {
  components: { AnimatedPlaceholder },
};
</script>

<style lang="scss" scoped></style>
