<template>
  <div ref="slickWrapper">
    <slick
      :options="slick.slickOptions"
      @afterChange="handleAfterChange"
      @beforeChange="handleBeforeChange"
      @breakpoint="handleBreakpoint"
      @destroy="handleDestroy"
      @edge="handleEdge"
      @init="handleInit"
      @lazyLoadError="handleLazeLoadError"
      @lazyLoaded="handleLazyLoaded"
      @reInit="handleReInit"
      @setPosition="handleSetPosition"
      @swipe="handleSwipe"
      ref="slick"
      class="gc-carousel-body-fat"
    >
      <div v-for="(item) in slickData"
           :data-max="item.max"
           :data-min="item.min"
           :key="'bf-image-'+item.min"
           class="gc-carousel-body-fat-slick">
        <div class="gc-carousel-body-fat-slick__overlay"></div>
        <div>
          <img
            :src="item.image"
            alt
            class="gc-carousel-body-fat-slick__image"
            style="margin-left: auto; margin-right: auto;"
          />
        </div>
        <div class="gc-carousel-body-fat-slick__description">
          <div v-if="isTextLabelType">
            <text-content :weight="'medium'" :size="'sm2'">
              <span>{{ item.label }}</span>
            </text-content>
          </div>
          <div v-else>
            <text-content
            v-if="item.max != 100"
            :weight="'medium'"
            :size="'sm2'">
            <span>{{item.min}}-{{item.max}}</span>
            <span>%</span>
            </text-content>
            <text-content
              v-else
              :weight="'medium'"
              :size="'sm2'">
              <span>{{item.min}}</span>
              <span>%</span>
              <span>+</span>
            </text-content>
          </div>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import Slick from 'vue-slick'

export default {
  name: 'CarouselBodyFat',
  data: function () {
    return {
      slick: {
        slickOptions: {
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
          autoplay: false,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          variableWidth: true,
          rtl: false,
          focusOnSelect: true
        },
        activeSlide: {
          index: 0,
          min: 0,
          max: 6
        },
        data: []
      }
    }
  },
  components: {
    TextContent,
    Slick
  },
  props: {
    slickData: {
      default: [],
      required: true
    },
    isTextLabelType: {
      type: Boolean,
      default : false
    }
  },
  beforeMount () {
    this.slick.slickOptions.rtl = this.isRTL()
  },
  beforeDestroy() {

    this.$refs.slickWrapper.style.visibility='hidden'
    if(this.$refs.slick){
      this.$refs.slick.$destroy()
    }

  },
  methods: {
    next () {
      this.$refs.slick.next()
    },
    goTo (slideIndex) {
      this.$nextTick(() => {
        this.$refs.slick.goTo(slideIndex)
      })
    },
    prev () {
      this.$refs.slick.prev()
    },
    reInit () {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick()
      })
    },

    // Events listeners
    handleAfterChange (event, slick, currentSlide) {
      this.$emit('handleAfterChange', event, slick, currentSlide)
    },
    handleBeforeChange (event, slick, currentSlide, nextSlide) {
      this.$emit('handleBeforeChange', event, slick, currentSlide, nextSlide)
    },
    handleBreakpoint (event, slick, breakpoint) {
      this.$emit('handleBreakpoint', event, slick, breakpoint)
    },
    handleDestroy (event, slick) {
      this.$emit('handleDestroy', event, slick)
    },
    handleEdge (event, slick, direction) {
      this.$emit('handleEdge', event, slick, direction)
    },
    handleInit (event, slick) {
      this.$emit('handleInit', event, slick)
    },
    handleReInit (event, slick) {
      this.$emit('handleReInit', event, slick)
    },
    handleSetPosition (event, slick) {
      this.$emit('handleSetPosition', event, slick)
    },
    handleSwipe (event, slick, direction) {
      this.$emit('handleSwipe', event, slick, direction)
    },
    handleLazyLoaded (event, slick, image, imageSource) {
      this.$emit('handleLazyLoaded', event, slick, image, imageSource)
    },
    handleLazeLoadError (event, slick, image, imageSource) {
      this.$emit('handleLazeLoadError', event, slick, image, imageSource)
    }
  }
}

</script>
