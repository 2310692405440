<template>
  <div
    v-if="isLoaded && showCard"
    class="pc-dashboard__card-my-journey-with-input"
  >
    <header-simple
      class="pc-dashboard__card-my-journey-with-input-header"
      :header-text="title"
      :icon="'forward'"
      @click="navigate()"
    />
    <div class="pc-dashboard__card-my-journey-with-input-container">
      <div
        class="pc-dashboard__card-my-journey-with-input-btn"
        @click="photoButtonClick()"
      >
        <icon-tick
          v-if="hasTodayUploadAllImages"
          :size="'sm1'"
          class="pc-dashboard__card-my-journey-with-input-btn-tick-icon"
        />
        <icon-add
          v-if="isPaidUserEnabled && !hasTodayUploadAllImages"
          :size="'sm1'"
          class="pc-dashboard__card-my-journey-with-input-btn-add-icon"
        />
        <div
          class="pc-dashboard__card-my-journey-with-input-btn-content-center"
        >
          <icon-photos :size="'sm4'" />
          <text-body-extra-small
            class="pc-dashboard__card-my-journey-with-input-btn-text"
            :weight="'extra-bold'"
            :line-height="'multi'"
          >
            {{ $t('message["fitness-diary.photo"]') }}
          </text-body-extra-small>
        </div>
      </div>

      <div
        class="pc-dashboard__card-my-journey-with-input-btn"
        @click="measurementButtonClick('weight')"
      >
        <icon-tick
          v-if="hasTodayUpdatedWeight"
          :size="'sm1'"
          class="pc-dashboard__card-my-journey-with-input-btn-tick-icon"
        />
        <icon-add
          v-else
          :size="'sm1'"
          class="pc-dashboard__card-my-journey-with-input-btn-add-icon"

        />
        <div
          class="pc-dashboard__card-my-journey-with-input-btn-content-center"
        >
          <icon-scale :size="'sm4'" />
          <text-content
            class="pc-dashboard__card-my-journey-with-input-btn-text"
            :weight="'extra-bold'"
            :line-height="'multi'"
            :size="'sm1'"
          >
            {{ $t('message["progress.weight"]') }}
          </text-content>
        </div>
      </div>
    </div>

    <pop-up-update-fitness-data
      :fitnessData="updatePopup.data"
      :type="updatePopup.type"
      :visible="updatePopup.show"
      v-if="updatePopup.show"
      v-on:close="closeUpdatePopup()"
      v-on:updated="updatedFitnessData(true)"
    ></pop-up-update-fitness-data>
    <pop-up-upload-photos
      :visible="uploadPopup.show"
      v-if="uploadPopup.show"
      v-on:close="dataUpdated()"
      v-on:updated="dataUpdated()"
    ></pop-up-upload-photos>
    <popup-feed-back
      :flag="feedback.eventName"
      :text-place-holder="feedback.textPlaceHolder"
      :title="feedback.title"
      :visible="feedback.popupShow"
      v-if="feedback.popupShow"
      v-on:close="hideFeedbackPopup()"
      v-on:success="hideFeedbackPopup()"
    />
  </div>
</template>
<script>
import FitnessDiaryStore from '../../../../../store/modules/FitnessDiaryStore'
import { flagValue } from '../../../../../includes/TemplateSettings'
import { mapGetters, mapMutations } from 'vuex'
import fitnessDairyPageMixin from '../../../../../mixins/fitnessDairyPageMixin'
import HeaderSimple from '../../../../global/headers/HeaderSimple'
import PopUpUpdateFitnessData from '../../../../global/popups/PopUpUpdateFitnessData'
import PopUpUploadPhotos from '../../../../global/popups/PopUpUploadPhotos'
import IconAdd from '../../../../root/icons/IconAdd'
import TextContent from '../../../../root/TextContent'
import IconScale from '../../../../root/icons/IconScale'
import IconTick from '../../../../root/icons/IconTick'
import DashBoardService from '../../../../../services/DashboardService'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconPhotos from '../../../../root/icons/IconPhotos'

export default {
  name: 'CardMyJourneyWithInputs',
  mixins: [fitnessDairyPageMixin],
  components: {
    IconPhotos,
    HeaderSimple,
    PopUpUpdateFitnessData,
    PopUpUploadPhotos,
    IconAdd,
    TextContent,
    IconScale,
    IconTick,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      isPaidUserEnabled: true,
      fitnessData: [],
      showCard: false,
      isLoaded: false,
      isEditable: false,
      hasTodayUploadAllImages: false,
      hasTodayUpdatedWeight: false,
      updatePopup: {
        show: false,
        type: '',
        data: 0
      },
      uploadPopup: {
        show: false
      },
      title: ''
    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    })
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state['fitnessDiaryStore'])) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  mounted () {
    if (
      flagValue('show_fitness_diary') === null ||
      flagValue('show_fitness_diary')
    ) {
      this.showCard = true
    }

    this.getData()
    this.setFeedbackPopup()
    this.setTitle()
    this.isPaidUser()
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    ...mapMutations({
      showServerErrorPopup: 'showServerErrorPopup',
      hideServerErrorPopup: 'hideServerErrorPopup',
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    isPaidUser () {
      const dashBoardService = new DashBoardService()
      this.isPaidUserEnabled = dashBoardService.getDob() !== null
    },
    setTitle () {
      let dashboard = new DashBoardService()
      if (!(dashboard.isEntriesEditable())) {
        this.title = this.$t('message["fitness-diary.home-title"]')
      } else {
        this.title = this.$t('message["fitness-diary.log-my-journey"]')
      }
    },
    getData () {
      let service = this.service()
      service
        .getDataFromAPI()
        .then(() => {
          this.fitnessData = Object.values(service.getData())
          this.hasTodayUploadAllImages = Object.values(service.images).every(
            (image) => {
              return image.getTodayImage()
            }
          )
          this.updatedFitnessData()
          this.isLoaded = true
        })
        .catch(() => {
          this.isLoaded = false
        })

      let dashBoardService = new DashBoardService()
      dashBoardService.getPageData().then(() => {
        this.isEditable = dashBoardService.isFitnessDiaryEditable()
      })
    },
    measurementButtonClick (type) {
      if (!this.isEditable) {
        this.$router.push({ path: this.$appConfig.appUrlList.progress,
          query: {
            page: 'chart', type: type
          } })
      }
      if (type) {
        let currentData = this.fitnessData.find(data => {
          return data.getType() === type
        })
        if (currentData) {
          this.updatePopup.show = true
          this.updatePopup.type = currentData.getType()
          this.updatePopup.data = currentData
        }
      }
    },
    photoButtonClick () {
      if (!this.isPaidUserEnabled) {
        return
      }
      if (!this.isEditable) {
        this.$router.push({ path: this.$appConfig.appUrlList.progress,
          query: {
            page: 'photo-journey'
          } })
      } else {
        this.uploadPopup.show = true
      }
    },
    dataUpdated () {
      this.uploadPopup.show = false
      this.hasTodayUploadAllImages = Object.values(this.service().images).every(
        (image) => {
          return image.getTodayImage()
        }
      )
    },
    navigate () {
      this.logEvent('Journey.ViewedHome', { context: 'dashboard' }, false)
      this.$router.push({ path: this.$appConfig.appUrlList.progress })
    },
    closeUpdatePopup () {
      this.updatePopup.show = false
    },
    updatedFitnessData (feedback = false) {
      let type = 'weight'
      let currentData = this.fitnessData.find((data) => {
        return data.getType() === type
      })
      if (currentData && currentData.getTodayValue()) {
        this.hasTodayUpdatedWeight = true
      }
      if (feedback) {
        setTimeout(() => {
          this.triggerFeedbackEvent()
        }, 3000)
      }
    }
  }
}
</script>
