<template>
  <popup
    v-if="visible"
    ref="mealSwapModal"
    :visible="visible"
    class="pc-popup-suggested-meal-swap"
    modal-class="pc-popup-suggested-meal-swap"
    :no-close-on-backdrop="noCloseOnBackdrop"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close
        :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }"
        @hide="closePopup()"
      />
    </popup-title>
    <popup-body
      class="pc-popup-suggested-meal-swap__body text-center"
    >
      <popup-head class="text-center">
        <popup-text-heading>{{ title }}</popup-text-heading>
        <div
          v-if="status==='loading'"
          class="text-center"
        >
          <popup-text-heading>{{ $t('message["general.swapping"]') + '...' }}</popup-text-heading>
          <loading-buffer :height="'75'"/>
        </div>
      </popup-head>
      <popup-content>
        <transition name="fade">
          <ContainerMealSwapOptions
            v-if="status==='init'|| status==='initiating'"
            :meal="meal"
            :status="status"
            v-model="selectedMeal"
            @selected-meal-type="setSelectedMealType"
            @loaded ="mealsLoaded"
            @no-results="hasResults= $event"
          />
        </transition>
        <div>
          <icon-complete-filled
            v-if="status==='success'"
            :size="'lg3'"
          />
          <text-body-small  v-if="status==='success'"  style="margin-top: 32px; padding-left: 40px; padding-right: 40px">{{ $t('message["meal-plan.suggested-meal-swap.success"]') }}</text-body-small>

        </div>

        <div v-if="status==='error'">
          <div class="text-center"/>

          <p class="text-center info-modal__subtitle mb-2">
            {{ errorMessage }}
          </p>
        </div>
        <popup-footer v-if="status==='init'" class="pc-popup-suggested-meal-swap__footer">
          <loading-buffer
            v-if="apiLoading"
            :height="'75'"
          />
          <div
            v-if="isFavouritesAvailable && !apiLoading && status !== 'loading' && status !== 'success'"
            class="pc-popup-suggested-meal-swap__favourite-check"
          >
            <input-check-box-squared
              :id="true"
              v-model="isCheckedFavouriteMeals"
            />
            <text-content
              :size="'sm2'"
              class="pc-popup-suggested-meal-swap__favourite-text"
            >
              {{ $t('message["swap.request-try-with-favourite-meal"]') }}
            </text-content>
          </div>
          <button-primary
            :disabled="!selectedMeal"
            v-if="hasResults && !apiLoading"
            :text="swapConfirmButtonText"
            @click="swapMeal()"
          />
        </popup-footer>
      </popup-content>

    </popup-body>
  </popup>
</template>

<script>


import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer.vue'
import Popup from '@/components/global/popups/Popup.vue'
import PopupBody from '@/components/global/popups/popup-sub-components/PopupBody.vue'
import PopupButtonClose from '@/components/global/popups/popup-sub-components/PopupButtonClose.vue'
import PopupContent from '@/components/global/popups/popup-sub-components/PopupContent.vue'
import PopupFooter from '@/components/global/popups/popup-sub-components/PopupFooter.vue'
import PopupHead from '@/components/global/popups/popup-sub-components/PopupHead.vue'
import PopupTextHeading from '@/components/global/popups/popup-sub-components/PopupTextHeading.vue'
import PopupTitle from '@/components/global/popups/popup-sub-components/PopupTitle.vue'
import ButtonPrimary from '@/components/global/buttons/ButtonPrimary.vue'
import IconCompleteFilled from '@/components/root/icons/IconCompleteFilled.vue'
import InputCheckBoxSquared from '@/components/global/inputs/InputCheckBoxSquared.vue'
import TextContent from '@/components/root/TextContent.vue'
import popupAnimationMixin from '@/mixins/popupAnimationMixin'
import mealSwapMixin from '@/mixins/component-mixins/global/mealSwapMixin'
import MealPlanService from '@/services/MealPlanService'
import MealPlanMealService from '@/services/nutrition/MealPlanMealService'
import ProfileService from '@/services/ProfileService'
import DefineInclude from '@/DefineInclude'
import ContainerMealSwapOptions
  from '@/components/layout/template-1/meal-plan/page-components/ContainerMealSwapOptions.vue'
import PopupFloatingFooter
  from '@/components/layout/template-2/global/popups/popup-sub-components/PopupFloatingFooter.vue'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'
import MealFavouritesService from '@/services/nutrition/MealFavouritesService'

export default {
  name: 'PopupSuggestedMealSwap',
  components: {
    TextBodySmall,
    PopupFloatingFooter,
    ContainerMealSwapOptions,
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFooter,
    PopupHead,
    PopupTextHeading,
    PopupTitle,
    ButtonPrimary,
    IconCompleteFilled,
    InputCheckBoxSquared,
    TextContent
  },

  mixins: [popupAnimationMixin, mealSwapMixin],
  props: {

    meal: {
      required: true,
      validator: function (value) {
        return value instanceof MealPlanMealService
      }
    },
    data: {
      default: function () {
        return {}
      },
      type: Object
    },
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      hasResults: false,
      title: this.$i18n.t('message["meal-plan.suggested-meal-swap.title"]'),
      status: 'initiating',
      loading: true,
      confirmButtonText: this.$i18n.t('message["swap.meal-popup-button-text"]'),
      swapConfirmButtonText: this.$i18n.t('message["swap.confirm-button-text"]'),
      successMsg: 'success',
      errorMessage: 'Error',
      ingredientsToSwap: [],
      isCheckedFavouriteMeals: [],
      isFavouritesAvailable: false,
      apiLoading: true,
      selectedMeal: null,
      selectedMealType: '',
    }
  },
  watch: {
    tab: function () {
      this.selectedMeal = null
      this.page = 1
    }
  },
  computed: {
    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'loading'
    },

  },
  created () {
    const service = new MealPlanService()
    service.getMealPlan(this.$route.params.mid).then(response => {
      this.confirmButtonText = response.content['request-meal-substitute']
      this.successMsg = response.content['meal-substitute-requested']
    })

    this.apiLoading = false

  },
  methods: {
    setSelectedMealType(e){
      this.selectedMealType= e
    },
    mealsLoaded () {
      this.status = 'init'
      this.$nextTick(()=>{
        this.$refs.mealSwapModal.setContentHeight()
      })

    },
    async swapMeal () {
      this.status = 'loading'

      this.$nextTick(async () => {
        this.title = ''
        await this.setIngredients()
      })

    },
    async setIngredients () {
      const promises = []
      this.meal.getRecipes().forEach(recipe => {
        promises.push(this.getRepeatIngredientSwap(recipe, this.$route.params.mid))
      })
      let results = await Promise.all(promises)

      results.forEach((repeatIngredientSwap) => {
        this.ingredientsToSwap = this.ingredientsToSwap.concat(repeatIngredientSwap)
      })

      let profileService = new ProfileService()
      await profileService.setProfileData()
      let ingredients = this.ingredientsToSwap
      let excludedIngredients = profileService.getExcludedIngrediants()
      let ingredientsArray = ingredients.map(x => x.id)
      let commonIngredients = ingredientsArray.filter(e => excludedIngredients.indexOf(e) !== -1)
      if (commonIngredients.length > 0) {
        for (let i = 0; i < commonIngredients.length;) {
          const idxObj = ingredients.findIndex(object => {
            return object.id === commonIngredients[i]
          })
          ingredients.splice(idxObj, 1)
          i++
        }
      }
      this.sendSwapRequest(ingredients)
    },
    sendSwapRequest (repeatIngredientSwap) {
      let isFavouriteSelected = false
      if (this.isCheckedFavouriteMeals.length > 0 && this.isCheckedFavouriteMeals[0] === true) {
        isFavouriteSelected = true
      }
      const mealFav = new MealFavouritesService()
      const isAFavourite = mealFav.isFavouriteMeal(this.meal.getReferenceMealId())
      this.meal.swapWithMeal(this.selectedMeal)
        .then(() => {
          this.status = 'success'
          this.title = this.$i18n.t('message["meal-plan.swapped"]')
          this.updateMealSwapStatus([{
            mid: this.data.mealID,
            rmid: this.data.refMealID
          }])
          const recipeseArray = []
          this.meal.getRecipes().forEach(recipe => {
            recipeseArray.push(recipe.getName())
          })

          setTimeout(() => {
            this.logEvent('MealPlan.SwappedMeal', {
              day: this.data.mealTitle,
              recipes: recipeseArray.join(','),
              meal_type: this.meal.getType().name,
              swapped_favourite:   isAFavourite,
              requested_favourite: (this.selectedMealType=== 'favorite')
            })

            this.closePopup(null, () => {
              if (repeatIngredientSwap.length > 0) {
                this.showUpdateDislikesPopup(repeatIngredientSwap, this.data.mealID)
              } else if (this.$route.query.meal) {
                this.$router.push({
                  path:
                    [DefineInclude.appUrlList.mealPlanBase, this.$route.params.mid].join('/'),
                  query: {
                    day: this.$route.query.day
                  }
                })
              }
            })
            this.$emit('meal-swap-success')
          }, 2000)
        }).catch(() => {
        this.status = 'error'
        if (!this.$i18n) {
          return
        }
        this.title = this.$i18n.t('message[\'swap.meal-requested-title-error\']')
        this.errorMessage = this.$i18n.t('message[\'swap.meal-requested-error\']')
      })
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('hide-swap-popup')
        if (callback) {
          callback()
        }
      })
    }

  }
}
</script>
