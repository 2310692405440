<template>
  <div class="gc-popup-sub-text-body-custom">
    <text-content
      :size="'sm1'"
      :weight="'medium'"
      :lineHeight="lineHeight"
      :decoration="decoration"
    >
      <slot></slot>
    </text-content>
  </div>
</template>

<script>
import TextContent from "../../../root/TextContent";

export default {
  name: "PopupTextBody",
  components: {
    TextContent,
  },
  props: {
    /**
     * to decorate  body text
     * expected values - underline, italic
     */
    decoration: {
      default: " ",
    },
    /**
     * to set lineheight of body text
     * expected values(old version): 'single' = 1 | 'multi' = 1.5
     * expected values(new version): 'lh-sm' = 1 | 'lh-md' = 1.3 | 'lh-lg' = 1.5 | 'lh-xl' = 1.6
     */
    lineHeight: {
      default: "lh-lg",
    },
    size: {
      default: "sm1",
    },
  },
};
</script>

<style scoped></style>
