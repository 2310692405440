<template>

  <popup
    :visible="visible"
    @hide="closePopup"
    v-if="visible"
    class="gc-popup-meal-dislike"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <popup-title>
      <popup-button-close :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }" @hide="closePopup"/>
    </popup-title>
    <popup-body>
      <popup-head class="text-center">
        <popup-text-heading v-if="status==='init'">{{ $t('message["popup.add-meal-dislikes-header"]') }}
        </popup-text-heading>
        <div v-if="status==='processing'" class="text-center">
          <popup-text-heading v-if="!swap">{{ $t('message["meal-plan.disliking"]') }}</popup-text-heading>
          <popup-text-heading v-else>{{ $t('message["meal-plan.disliking-and-swapping"]') }}</popup-text-heading>
          <loading-buffer :height="'75'"></loading-buffer>
        </div>
        <div v-if="status==='success'">
          <popup-text-heading v-if="!swap">{{ $t('message["meal-plan.disliked"]') }}</popup-text-heading>
          <popup-text-heading v-else>{{ $t('message["meal-plan.disliked-and-swapped"]') }}</popup-text-heading>
        </div>
      </popup-head>
      <popup-content class="text-center">
        <popup-text-body
          v-if="status==='init'"
          class="text-center info-modal__subtitle mb-2"
        >{{ $t('message["messages.dislike-meal-popup"]') }}
        </popup-text-body>
        <div>
          <p class="app-error-msg">{{ error }}</p>
        </div>
        <icon-complete-filled :size="'lg3'" v-if="status==='success'"/>
        <div v-if="status==='error'">
          <div class="text-center"/>

          <p class="text-center info-modal__subtitle mb-2">{{ errorMessage }}</p>
        </div>
      </popup-content>
      <popup-footer v-if="status==='init'">
        <button-primary class="gc-popup-meal-dislike__button-dislike-and-swap" v-if="!isSwapped&&canSwap"
                        @click="confirmed(true)" :text="swapAndDislikeButtonText"
        ></button-primary>
        <button-secondary class="gc-popup-meal-dislike__button-dislike" @click="confirmed(false)"
                          :text="dislikeButtonText"
        ></button-secondary>
      </popup-footer>
    </popup-body>

  </popup>
</template>
<script>


import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer.vue'
import Popup from '@/components/global/popups/Popup.vue'
import PopupBody from '@/components/global/popups/popup-sub-components/PopupBody.vue'
import PopupButtonClose from '@/components/global/popups/popup-sub-components/PopupButtonClose.vue'
import PopupContent from '@/components/global/popups/popup-sub-components/PopupContent.vue'
import PopupFloatingFooter from '@/components/global/popups/popup-sub-components/PopupFloatingFooter.vue'
import PopupFooter from '@/components/global/popups/popup-sub-components/PopupFooter.vue'
import PopupTextBody from '@/components/global/popups/popup-sub-components/PopupTextBody.vue'
import PopupHead from '@/components/global/popups/popup-sub-components/PopupHead.vue'
import PopupTextHeading from '@/components/global/popups/popup-sub-components/PopupTextHeading.vue'
import PopupTextSubHeading from '@/components/global/popups/popup-sub-components/PopupTextSubHeading.vue'
import PopupTitle from '@/components/global/popups/popup-sub-components/PopupTitle.vue'
import ButtonSecondary from '@/components/global/buttons/ButtonSecondary.vue'
import ButtonPrimary from '@/components/global/buttons/ButtonPrimary.vue'
import IconCompleteFilled from '@/components/root/icons/IconCompleteFilled.vue'
import popupAnimationMixin from '@/mixins/popupAnimationMixin'
import NativeAppMixin from '@/mixins/NativeAppMixin'
import mealSwapMixin from '@/mixins/component-mixins/global/mealSwapMixin'
import MealDislikeService from '@/services/MealDislikeService'
import ProfileService from '@/services/ProfileService'
import DefineInclude from '@/DefineInclude'

export default {
  name: 'PopupAddDislikesV1',
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary,
    IconCompleteFilled
  },
  mixins: [popupAnimationMixin, NativeAppMixin, mealSwapMixin],
  props: {
    visible: {
      default: false
    },
    canSwap: {
      default: true
    },
    meal: {
      default: 'This meal'
    },
    isSwapped: {
      default: false
    },
    mealPlanID: {
      default: 0,
      type: Number
    },
    swapData: {
      default: function () {
        return {}
      },
      type: Object
    }
  },
  data: function () {
    return {
      successImage: DefineInclude.imagesUrl + 'swap-success.svg',
      error: '',
      swap: false,
      showClose: true,
      status: 'init',
      imagesUrl: this.$appConfig.imagesUrl,
      swapAndDislikeButtonText: this.$t('message["popup.add-meal-dislikes-with-swap-button"]'),
      dislikeButtonText: this.$t('message["popup.add-meal-dislikes-button"]'),
      ingredientsToSwap: []
    }
  },
  computed: {
    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'processing'
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    async confirmed (swap) {
      await this.setIngredients(swap)
    },
    async setIngredients (swap) {
      this.status = 'processing'
      const promises = []
      this.meal.getRecipes().forEach(recipe => {
        promises.push(this.getRepeatIngredientSwap(recipe, this.$route.params.mid))
      })
      let results = await Promise.all(promises)

      results.forEach((repeatIngredientSwap) => {
        this.ingredientsToSwap = this.ingredientsToSwap.concat(repeatIngredientSwap)
      })

      let profileService = new ProfileService()
      await profileService.setProfileData()
      let ingredients = this.ingredientsToSwap
      let excludedIngredients = profileService.getExcludedIngrediants()
      let ingredientsArray = ingredients.map(x => x.id)
      let commonIngredients = ingredientsArray.filter(e => excludedIngredients.indexOf(e) !== -1)
      if (commonIngredients.length > 0) {
        for (let i = 0; i < commonIngredients.length;) {
          const idxObj = ingredients.findIndex(object => {
            return object.id === commonIngredients[i]
          })
          ingredients.splice(idxObj, 1)
          i++
        }
      }
      this.sendRequest(swap, ingredients)
    },
    sendRequest (swap, repeatIngredientSwap) {
      this.swap = swap
      this.status = 'processing'
      let dislikeService = new MealDislikeService()
      this.showClose = false

      let promises = [dislikeService.setDislike({ 'meal_id': this.meal.getReferenceMealId() })]
      if (swap) {
        promises.push(this.swapMeal())
      }
      Promise.all(promises).then(() => {
        this.meal.setDisliked(true)
        this.status = 'success'
        this.$emit('success', {
          id: this.meal.getReferenceMealId(),
          isSwapped: swap
        })
        setTimeout(() => {
          this.logEvent('MealPlan.DislikedMeal', { meal: this.meal.getName() })
          if (repeatIngredientSwap.length > 0) {
            this.showUpdateDislikesPopup(repeatIngredientSwap, this.meal.getId())
          }
          this.closePopup()
        }, 1000)
      }).catch((err) => {
        if (err.config && err.config.url.includes('substitute-meal') && err.data && err.data.errors) {
          this.error = this.$i18n.t('message["meal-dislikes.unable-to-swap"]')
        } else {
          this.error = this.$i18n.t('message["general.common-error-message"]')
        }
        this.status = 'init'
        this.showClose = true
      })
    },
    swapMeal () {
      return new Promise((resolve, reject) => {
        let promises = []
        this.meal.getRecipes().forEach(recipe => {
          promises.push(this.getRepeatIngredientSwap(recipe))
        })
        Promise.all(promises).then(repeatIngredientSwap => {
          const allRepeatIngredientSwap = []
          repeatIngredientSwap.forEach(item => {
            allRepeatIngredientSwap.concat(item)
          })
          this.meal.swap().then(() => {
            resolve({ 'repeatIngredientSwap': allRepeatIngredientSwap })
          }).catch(err => {
            reject(err)
          })
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
