<template>
  <page-secondary
    :header="headerOptions"
    :desktop-side-bar="false"
    class="pc-sign-up-complete"
  >
    <header-subscription
      :steps="stepsData"
      :route="'/sign-up'"
    />

    <grid-row :no-gutters="true">
      <grid-col
        md="12"
        lg="4"
      >
        <card-subscription-cover-image
          :title="''"
          :logo="logoImage"
          :image="titleImage"
        />
      </grid-col>
      <grid-col
        md="12"
        lg="8"
        class="pc-sign-up-complete__content-col"
      >
        <page-container class="pc-sign-up-register__container">
          <div class="pc-sign-up-complete__container">
            <div class="pc-sign-up-complete__intro">
              <text-body-regular
                :line-height="'multi'"
                :weight="'extra-bold'"
                class="pc-sign-up-complete__intro-title"
              >
                {{ $t('message["sign-up.tank-you.text"]') }}
              </text-body-regular>
              <text-body-small
                :line-height="'multi'"
                v-html="introDescriptionText"
              />
            </div>
            <page-footer
              v-if="showFooter"
              class="pc-sign-up-complete__footer"
            >
              <button-primary
                :text="$t('message[\'sign-up.tank-you.button\']')"
                @click="goToNextPage()"
              />
            </page-footer>
          </div>
        </page-container>
      </grid-col>
    </grid-row>
  </page-secondary>
</template>

<script>
import CardSubscriptionCoverImage from '../../../global/cards/card-subscription-sub-components/CardSubscriptionCoverImage'
import HeaderSubscription from '../../../global/headers/HeaderSubscription'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../global/pages/PageSecondary'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextBodySmall from '../../../global/typography/TextBodySmall'
import globalSignUpMixin from '../../../../mixins/component-mixins/page/sign-up/globalSignUpMixin'
import PageFooter from '../../../global/pages/PageFixedFooter'
import pageSignUpComplete from '../../../../mixins/component-mixins/page/sign-up/pageSignUpCompleteMixin'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import { thankYouPageLoadedEvent } from '@/helpers/dom/events/customEvents'
import { setMAApplicationData } from '@/helpers/dom/header'
import AppService from '@/services/AppService'
import { injectHTMLToBody } from '@/helpers/dom/body'

export default {
  name: 'PageSignUpComplete',
  components: {
    GridCol,
    GridRow,
    ButtonPrimary,
    PageFooter,
    PageSecondary,
    HeaderSubscription,
    CardSubscriptionCoverImage,
    PageContainer,
    TextBodyRegular,
    TextBodySmall
  },
  mixins: [globalSignUpMixin, pageSignUpComplete],
  data: function () {
    return {
      showFooter: false
    }
  },
  async beforeMount () {
    this.introDescriptionText = this.$i18n.t('message["sign-up.tank-you.description"]')
    this.setImages()
    await this.loadUserData()
    this.subscriptionID = parseInt(this.$route.params.subscription)
    window.addEventListener('beforeunload', this.beforeunload)
    this.$nextTick(() => {
      this.showFooter = true
    })

    this.dispatchDOMContentLoaded()
    thankYouPageLoadedEvent()
    setMAApplicationData('subscription-id',this.subscriptionID)


    const app= new AppService()
    app.getScripts().then(() => {
      if(app.getThankYouPageScript()){
        injectHTMLToBody(app.getThankYouPageScript())
      }

    })
    await this.loadUserData()
    await this.delay(700)
    this.hideLoading()
  },

  mounted () {
    // This code will prevent the user from navigating back using the browser's back button
    history.pushState(null, null, document.URL)
    history.pushState(null, null, document.URL)

    this.stepsData[0].status = 'completed'
    this.stepsData[1].status = 'completed'
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeunload)

  },
  methods: {
    delay (time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    beforeunload (event) {
      event.preventDefault()
      event.returnValue = '' // For older browsers

      // Custom confirmation message
      const confirmationMessage = 'Are you sure you want to leave this page?'

      // Display the confirmation dialog
      return confirmationMessage
    }
  }

}
</script>
