<template>
  <div :class="['pc-header-dashboard-v2', 'pc-dashboard__main-featured-area', isDesktop ? 'pc-dashboard__main-featured-area--desktop' : 'pc-dashboard__main-featured-area--mobile']">
    <nav-bar-header :options="headerOptions">
      <template v-slot:left>
        <div class="pc-dashboard__main-featured-area__logo" v-if="logoImage&&bottomBarState|| logoImage&&isDesktop" >
          <image-base :src="logoImage"/>
        </div>

      </template>
      <template v-slot:center>
        <div class="pc-dashboard__main-featured-area__logo"  v-if="logoImage&&!bottomBarState&&!isDesktop">
          <image-base :src="logoImage"/>
        </div>
      </template>

      <template v-slot:right>
      <div class="app-nav__btn-pwa-wrap">
        <icon-create-shortcut class="app-nav__btn-pwa" v-if="showPwaPopup()" @click="pwaPopup.visible = true" size="md1" />
        <popup-p-w-a-instructions
          :visible="pwaPopup.visible"
          v-bind:key="'pwa-popup'"
          v-if="pwaPopup.visible"
          v-on:close="pwaPopup.visible = false"
        ></popup-p-w-a-instructions>
      </div>
      <div class="pc-dashboard__nav-bar-header-bell-icon"
           :class="{ 'pc-dashboard__nav-bar-header-bell-icon--new-notification': unreadCommunityNotificationsExist }"
           @click="goToNotificationPage"
           v-if="isCommunityEnabled">
        <icon-bell-filled v-if="unreadCommunityNotificationsExist" />
        <icon-bell v-else />
      </div>
      </template>
    </nav-bar-header>
    <page-container class="pc-dashboard__container-featured-image">
      <div :class="['pc-dashboard__featured-image', isDesktop ? 'pc-dashboard__main-featured-area__content' : '']" :style="isDesktop ? heroCardStylesDesktop : heroCardStyles">
        <image-base v-if="!showTabImage && isDesktop" class="pc-dashboard__featured-image-bg--blur" :src="backgroundTabImage"/>
        <image-base v-if="!showTabImage && isDesktop" class="pc-dashboard__featured-image-on-top" :src="backgroundTabImage"/>

      </div>
      <text-heading-5 class="pc-dashboard__main-featured-area__greeting-text" weight="extra-bold" >{{ pageHeader }}</text-heading-5>
    </page-container>
  </div>
</template>

<script>
import NavBarHeader from '../NavBarHeader.vue'
import ImageBase from '../../../../template-2/root/Image.vue'

import HeaderDashboardMixin from "@/mixins/page-mixins/dashboard/headerDashboardMixin";
import PageContainer from "@/components/global/pages/page-sub-components/PageContainer.vue";
import TextBodyMedium from "@/components/global/typography/TextBodyMedium.vue";
import TextHeading5 from "@/components/global/typography/TextHeading5.vue";
import TextHeading3 from "@/components/global/typography/TextHeading3.vue";
import IconBell from "@/components/root/icons/IconBell.vue";
import PopupPWAInstructions from "@/components/global/popups/PopupPWAInstructions.vue";
import IconBellFilled from "@/components/root/icons/IconBellFilled.vue";
import IconCreateShortcut from "@/components/root/icons/IconCreateShortcut.vue";
import {showBottomNavigationBar} from "@/includes/TemplateSettings";

export default {
  name: 'HeaderDashboardStyle2',
  components: {
    IconCreateShortcut, IconBellFilled, PopupPWAInstructions, IconBell,
    TextBodyMedium,
    TextHeading5,
    TextHeading3,
    PageContainer,
    ImageBase,
    NavBarHeader
  },
  mixins: [HeaderDashboardMixin],
  computed:{
    bottomBarState : function (){
      return showBottomNavigationBar()
    }
  },
  beforeMount() {
    document.querySelector('body').classList.add('header-dashboard-v2');
  },
  beforeDestroy() {
    document.querySelector('body').classList.remove('header-dashboard-v2');
  },
}
</script>
