<template>
  <div class="pc-meal-plan-shopping-days-skeleton">
    <div class="pc-meal-plan-shopping-days__heading">
      <AnimatedPlaceholder width="250px" height="24px" />
    </div>
    <div class="pc-meal-plan-shopping-days__sub-heading">
      <AnimatedPlaceholder width="120px" height="20px" />
    </div>
    <div class="pc-meal-plan-shopping-days__button-check-wrapper">
      <grid-row class="pc-meal-plan-shopping-days__button-check-row" no-gutters>
        <grid-col
          class="pc-meal-plan-shopping-days__button-check-col"
          cols="6"
          md="3"
        >
          <div
            class="pc-meal-plan-shopping-days__button-check gc-button-check gc-button-check--selected"
          >
            <div class="gc-button-check__text-container">
              <AnimatedPlaceholder width="30px" height="16px" />
              <AnimatedPlaceholder
                width="20px"
                height="20px"
                borderRadius="20px"
              />
            </div>
          </div>
        </grid-col>
        <grid-col
          class="pc-meal-plan-shopping-days__button-check-col"
          cols="6"
          md="3"
        >
          <div
            class="pc-meal-plan-shopping-days__button-check gc-button-check gc-button-check--selected"
          >
            <div class="gc-button-check__text-container">
              <AnimatedPlaceholder width="30px" height="16px" />
              <AnimatedPlaceholder
                width="20px"
                height="20px"
                borderRadius="20px"
              />
            </div>
          </div>
        </grid-col>
        <grid-col
          class="pc-meal-plan-shopping-days__button-check-col"
          cols="6"
          md="3"
        >
          <div
            class="pc-meal-plan-shopping-days__button-check gc-button-check gc-button-check--selected"
          >
            <div class="gc-button-check__text-container">
              <AnimatedPlaceholder width="30px" height="16px" />
              <AnimatedPlaceholder
                width="20px"
                height="20px"
                borderRadius="20px"
              />
            </div>
          </div>
        </grid-col>
        <grid-col
          class="pc-meal-plan-shopping-days__button-check-col"
          cols="6"
          md="3"
        >
          <div
            class="pc-meal-plan-shopping-days__button-check gc-button-check gc-button-check--selected"
          >
            <div class="gc-button-check__text-container">
              <AnimatedPlaceholder width="30px" height="16px" />
              <AnimatedPlaceholder
                width="20px"
                height="20px"
                borderRadius="20px"
              />
            </div>
          </div>
        </grid-col>
      </grid-row>
      <grid-row class="pc-meal-plan-shopping-days__button-check-row" no-gutters>
        <grid-col
          class="pc-meal-plan-shopping-days__button-check-col"
          cols="6"
          md="3"
        >
          <div
            class="pc-meal-plan-shopping-days__button-check gc-button-check gc-button-check--selected"
          >
            <div class="gc-button-check__text-container">
              <AnimatedPlaceholder width="30px" height="16px" />
              <AnimatedPlaceholder
                width="20px"
                height="20px"
                borderRadius="20px"
              />
            </div>
          </div>
        </grid-col>
        <grid-col
          class="pc-meal-plan-shopping-days__button-check-col"
          cols="6"
          md="3"
        >
          <div
            class="pc-meal-plan-shopping-days__button-check gc-button-check gc-button-check--selected"
          >
            <div class="gc-button-check__text-container">
              <AnimatedPlaceholder width="30px" height="16px" />
              <AnimatedPlaceholder
                width="20px"
                height="20px"
                borderRadius="20px"
              />
            </div>
          </div>
        </grid-col>
      </grid-row>
    </div>
    <div class="pc-meal-plan-shopping-days__heading">
      <AnimatedPlaceholder width="250px" height="24px" />
    </div>
    <div
      class="pc-meal-plan-shopping-days__input-spinner gc-input-number-spinner"
    >
      <div class="gc-input-number-spinner__left-btn btn">
        <AnimatedPlaceholder width="32px" height="32px" borderRadius="32px" />
      </div>
      <div class="gc-input-number-spinner__input-wrap">
        <div
          class="pc-meal-plan-shopping-days__button-check gc-button-check gc-button-spinner"
        >
          <div class="gc-button-check__spinner-container">
            <AnimatedPlaceholder width="10px" height="16px" />
          </div>
        </div>
      </div>
      <div class="gc-input-number-spinner__right-btn btn">
        <AnimatedPlaceholder width="32px" height="32px" borderRadius="32px" />
      </div>
    </div>
  </div>
</template>
<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";
import GridRow from "../../../../global/grid/GridRow";
import GridCol from "../../../../global/grid/GridCol";
export default {
  components: {
    AnimatedPlaceholder,
    GridRow,
    GridCol,
  },
};
</script>
<style>
.pc-meal-plan-shopping-days-skeleton {
  .pc-meal-plan-shopping-days__heading {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  .pc-meal-plan-shopping-days__sub-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .gc-button-check__text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gc-button-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
