<template>
  <div class="pc-form-workout-log">
    <workout-log-success-area v-if="showSuccess"/>
    <div>
      <div
        v-if="showTimer"
        class="pc-form-workout-log__wrapper-timer"
        :style="{ minHeight: '160px' }"
      >
        <transition>
          <timer-completed
            v-if="showTimerCompleted"
            @restart="restartTimers"
          />
          <rest-timer
            v-else-if="hasMetricType('duration') &&showRestTimer"
            :rest-interval="exerciseRestInterval"
            :rest-message="restMessage()"
            :rest-time="exerciseRestTime"
            @ended="restTimerEnded()"
            @rest-timer-start="restTimerStarted($event)"
          />
          <stop-watch
            v-else-if="
              hasMetricType('duration') && !exerciseTimeValue && showMainTimer
            "
            :header="timerHeader"
            :play="playMainTimer"
            :set-i-d="setID"
            :start-value="lastTimerValue"
            @current-time="setWatchTime($event)"
            @paused="stopWatchPaused($event)"
            @resumed="stopWatchResumed($event)"
            @started="stopWatchStarted($event)"
            @stopped="stopWatchStopped($event)"
          />

          <timer
            v-else-if="showWorkoutTimer"
            ref="workoutTimer"
            :play="playMainTimer"
            :rest-header="restText"
            :rest-interval="exerciseRestInterval"
            :rest-time="exerciseRestTime"
            :set-i-d="setID"
            :time="exerciseTime"
            :workout-header="timerHeader"
            :rest-message="restMessage()"
            @workout-timer-end="workoutTimerEnded($event)"
            @rest-timer-start="restTimerStarted($event)"
            @rest-timer-end="restTimerEnded($event)"
            @rest-timer-remaining="setRestTimerRemaining"
            @workout-timer-stopped="workoutTimerStopped($event)"
            @workout-timer-paused="workoutTimerPaused($event)"
            @workout-timer-remaining="workoutTimerRemaining($event)"
            @workout-timer-start="workoutTimerStarted($event)"
          />
          <timer
            v-else-if="
              !hasMetricType('duration') &&
                (exerciseTimeValue > 0 || exerciseRestTime > 0)
            "
            :rest-message="restMessage()"
            :play="playMainTimer"
            :rest-header="restText"
            :rest-interval="exerciseRestInterval"
            :rest-time="exerciseRestTime"
            :set-i-d="setID"
            :time="exerciseTimeValue"
            :workout-header="timerHeader"
            @workout-timer-stopped="workoutTimerEndedWithoutMetric($event)"
            @workout-timer-end="workoutTimerEndedWithoutMetric($event)"
            @rest-timer-end="refreshRestTimerCount($event)"
          />
        </transition>
      </div>

      <div class="pc-form-workout-log__form-wraper">
        <div
          v-for="(form, fIndex) in formData.slice(0, exerciseSetCount)"
          :key="'entry-form-insert-'+fIndex"
          class="pc-form-workout-log__form"
          :class="[
            ...form.wrapClass,
            {
              'pc-form-workout-log__form--with-duration': hasMetricType('duration'),
              'pc-form-workout-log__form--with-text-header': (metricsArranged.length > 2 || fIndex === 0),
              'pc-form-workout-log__form--with-one': metricsArranged.length === 1,
              'pc-form-workout-log__form--with-two': metricsArranged.length === 2,
              'pc-form-workout-log__form--with-three': metricsArranged.length === 3,
              'pc-form-workout-log__form--with-four': metricsArranged.length === 4

            },
          ]"
        >
          <validation-observer
            :ref="'form' + fIndex"
            v-slot="{ errors }"
            tag="div"
          >
            <div
              class="pc-form-workout-log__form-set"
              :class="{
                'pc-form-workout-log__form-set--with-duration': hasMetricType('duration'),
                'pc-form-workout-log__form-set--with-errors': Object.keys(errors).length > 0,
              }"
            >
              <div class="pc-form-workout-log__number-list text-center">
                <text-body-regular
                  weight="extra-bold"
                  class="pc-form-workout-log__set-number"
                >
                  {{ fIndex + 1 }}
                </text-body-regular>
              </div>
              <div
                class="container pc-form-workout-log__wrapper-inputs"
              >
                <div class="row">
                  <div
                    v-for="(entry, eIndex) in form.attributes"
                    :key="'entry-form-entry-'+fIndex+'-'+eIndex"
                    class="col"
                  >
                    <text-body-extra-small
                      v-if="metricsArranged.length > 2 || fIndex === 0"
                      class="pc-form-workout-log__header-input"
                      weight="extra-bold"
                    >
                      {{ getMetricLabel(entry) }}
                    </text-body-extra-small>
                    <validation-provider
                      v-slot="{ errors, invalid, validated}"
                      tag="div"
                      :vid="entry.type"
                      :name="getMetricLabel(entry)"
                      :rules="getValidationByType(entry.type)"
                    >
                      <text-input
                        v-model="entry.value"
                        :class="['text-center','pc-form-workout-log__input','pc-form-workout-log__input--'+entry.type]"
                        :mobile-input-mode="setInputMode (entry.type)"
                        :type="setInputType (entry.type)"
                        :max="22"
                        :maxlength="7"
                        :max-decimal-points="2"
                        :disabled="!(form.current||form.completed)"
                        :error-message="(invalid && validated&&errors)?errors[0]:''"
                        :hide-error-message="true"
                        :mask="setMask(entry.type)"
                        :onkeypress="onMetricKeyPress"
                        @change=" entryChanged(fIndex, eIndex, entry.value, false) "
                        @input=" entryChanged(fIndex, eIndex, entry.value, true) "
                      />
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="pc-form-workout-log__status text-center">
                <div
                  :class="{'pc-form-workout-log__wrapper-buttons': true, 'pc-form-workout-log__wrapper-buttons--with-duration': hasMetricType('duration') }"
                >
                  <div
                    v-if="hasMetricType('duration')"
                    class="pc-form-workout-log__icon-duration"
                  >
                    <icon-play
                      v-if="disableFormPlayButton(fIndex)"
                      size="md1"
                      state="disabled"
                    />
                    <icon-stop
                      v-else-if="playMainTimer && fIndex === setID"
                      size="md1"
                      @click="formTimerStart(fIndex, getMetricTypeIndex('duration'))"
                    />
                    <icon-play
                      v-else-if="!playMainTimer && fIndex === setID"
                      size="md1"
                      @click=" formTimerStart(fIndex, getMetricTypeIndex('duration'))"
                    />
                    <icon-play
                      v-else
                      size="md1"
                      @click="formTimerStart(fIndex, getMetricTypeIndex('duration'))"
                    />
                  </div>
                  <div
                    class="pc-form-workout-log__icon-complete"
                    @click="setCurrentCompleted(fIndex)"
                  >
                    <icon-completed-filled
                      v-if="!(currentFormIndex()===fIndex) && form.completed"
                      size="md1"
                    />
                    <icon-completed-filled
                      v-if="(currentFormIndex()===fIndex) && form.completed"
                      size="md1"
                    />
                    <icon-complete-rounded
                      v-if="!(currentFormIndex()===fIndex) && !form.completed"
                      size="md1"
                      state="disabled"
                    />
                    <icon-complete-rounded
                      v-if="(currentFormIndex()===fIndex) && !form.completed"
                      size="md1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="formErrorMessage(errors)|| setPreviousText(form)"
              class="pc-form-workout-log__other-info"
            >
              <text-body-extra-small
                v-if="formErrorMessage(errors)"
                class="text-center pc-form-workout-log__text-error"
              >
                {{ formErrorMessage(errors) }}
              </text-body-extra-small>
              <text-body-extra-small class=" text-center pc-form-workout-log__text-previous">
                {{ setPreviousText(form) }}
              </text-body-extra-small>
            </div>
          </validation-observer>
        </div>

        <div
          v-show="isAllDefinedSetsCompleted&&flagValueDefaultDisabled"
          class="pc-form-workout-log__form-extra-sets"
          :class="{'pc-form-workout-log__form-extra-sets--empty':formData.slice(exerciseSetCount).length===0}"
        >
          <div
            v-for="(form ) in formData.slice(exerciseSetCount)"
            :key="'entry-form-insert-'+form.index"
            class="pc-form-workout-log__form "
            :class="[
              ...form.wrapClass,
              {
                'pc-form-workout-log__form--with-duration': hasMetricType('duration'),
                'pc-form-workout-log__form--with-text-header': (metricsArranged.length > 2),
                'pc-form-workout-log__form--with-one': metricsArranged.length === 1,
                'pc-form-workout-log__form--with-two': metricsArranged.length === 2,
                'pc-form-workout-log__form--with-three': metricsArranged.length === 3,
                'pc-form-workout-log__form--with-four': metricsArranged.length === 4

              },
            ]"
          >
            <validation-observer
              :ref="'form' + form.index"
              v-slot="{ errors }"
              tag="div"
            >
              <div
                class="pc-form-workout-log__form-set"
                :class="{
                  'pc-form-workout-log__form-set--with-duration': hasMetricType('duration'),
                  'pc-form-workout-log__form-set--with-errors': Object.keys(errors).length > 0,
                }"
              >
                <div class="pc-form-workout-log__number-list text-center">
                  <text-body-regular
                    weight="extra-bold"
                    class="pc-form-workout-log__set-number"
                  >
                    {{ form.index + 1 }}
                  </text-body-regular>
                  <icon-delete
                    class="pc-form-workout-log__set-delete"
                    size="sm2"
                    @click="requestDeleteSet(form.index)"
                  />
                </div>
                <div
                  class="container pc-form-workout-log__wrapper-inputs"
                >
                  <div class="row">
                    <div
                      v-for="(entry, eIndex) in form.attributes"
                      :key="'entry-form-entry-'+form.index+'-'+eIndex"
                      class="col"
                    >
                      <text-body-extra-small
                        v-if="metricsArranged.length > 2 || form.index === 0"
                        class="pc-form-workout-log__header-input"
                        weight="extra-bold"
                      >
                        {{ getMetricLabel(entry) }}
                      </text-body-extra-small>
                      <validation-provider
                        v-slot="{ errors, invalid, validated}"
                        tag="div"
                        :vid="entry.type"
                        :name="getMetricLabel(entry)"
                        :rules="getValidationByType(entry.type)"
                      >
                        <text-input
                          v-model="entry.value"
                          :class="['text-center','pc-form-workout-log__input','pc-form-workout-log__input--'+entry.type]"
                          :mobile-input-mode="setInputMode (entry.type)"
                          :type="setInputType (entry.type)"
                          :max="22"
                          :maxlength="7"
                          :max-decimal-points="2"
                          :disabled="!(form.current||form.completed)"
                          :error-message="(invalid && validated&&errors)?errors[0]:''"
                          :hide-error-message="true"
                          :mask="setMask(entry.type)"
                          :onkeypress="onMetricKeyPress"
                          @change=" entryChanged(form.index, eIndex, entry.value, false) "
                          @input=" entryChanged(form.index, eIndex, entry.value, true) "
                        />
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="pc-form-workout-log__status text-center">
                  <div
                    :class="{'pc-form-workout-log__wrapper-buttons': true, 'pc-form-workout-log__wrapper-buttons--with-duration': hasMetricType('duration') }"
                  >
                    <div
                      v-if="hasMetricType('duration')"
                      class="pc-form-workout-log__icon-duration"
                    >
                      <icon-play
                        v-if="disableFormPlayButton(form.index)"
                        size="md1"
                        state="disabled"
                      />
                      <icon-stop
                        v-else-if="playMainTimer && form.index === setID"
                        size="md1"
                        @click="formTimerStart(form.index, getMetricTypeIndex('duration'))"
                      />
                      <icon-play
                        v-else-if="!playMainTimer && form.index === setID"
                        size="md1"
                        @click=" formTimerStart(form.index, getMetricTypeIndex('duration'))"
                      />
                      <icon-play
                        v-else
                        size="md1"
                        @click="formTimerStart(form.index, getMetricTypeIndex('duration'))"
                      />
                    </div>
                    <div
                      class="pc-form-workout-log__icon-complete"
                      @click="setCurrentCompleted(form.index)"
                    >
                      <icon-completed-filled
                        v-if="!(currentFormIndex()===form.index) && form.completed"
                        size="md1"
                      />
                      <icon-completed-filled
                        v-if="(currentFormIndex()===form.index) && form.completed"
                        size="md1"
                      />
                      <icon-complete-rounded
                        v-if="!(currentFormIndex()===form.index) && !form.completed"
                        size="md1"
                        state="disabled"
                      />
                      <icon-complete-rounded
                        v-if="(currentFormIndex()===form.index) && !form.completed"
                        size="md1"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="formErrorMessage(errors)|| setPreviousText(form)"
                class="pc-form-workout-log__other-info"
              >
                <text-body-extra-small
                  v-if="formErrorMessage(errors)"
                  class="text-center pc-form-workout-log__text-error"
                >
                  {{ formErrorMessage(errors) }}
                </text-body-extra-small>
                <text-body-extra-small class=" text-center pc-form-workout-log__text-previous">
                  {{ setPreviousText(form) }}
                </text-body-extra-small>
              </div>
            </validation-observer>
          </div>
          <button-custom
            v-if="formData.slice(exerciseSetCount).length<3"
            class="pc-form-workout-log__button-add-sets"
            type="outline"
            :text="$t('message[\'workout-log.add-new-set\']')"
            @click="createNewEntry"
          >
            <template #left>
              <icon-add-fill-rounded size="sm2"/>
            </template>
          </button-custom>
        </div>
      </div>

      <validation-provider
        v-slot="{ errors, invalid, validated }"
        class="pc-form-workout-log__wrapper-note"
        tag="div"
        name="note"
        rules="max:1000"
      >
        <text-area
          v-model.trim="note"
          :placeholder="[[$t('message[\'workout-log.add-note\']')]]"
          class="pc-form-workout-log__input-note"
          :cols="3"
          :rows="2"
          maxlength="999"
          :class="{ 'pc-form-workout-log__input-note--with-error': invalid && validated }"
          @change="noteChanged()"
        />
        <text-body-extra-small
          v-if="errors && errors[0]"
          class="pc-form-workout-log__text-error"
        >
          {{ errors[0] }}
        </text-body-extra-small>
      </validation-provider>

      <set-completed-text
        :marked="totalCompleted"
        :total-sets="getTotalLogSets()"
      />
      <popup-workout-log-confirm-without-log
        v-if="confirmPopupShow"
        :visible="confirmPopupShow"
        @close="confirmPopupShow = false"
        @next="confirmSave()"
      />
      <popup-workout-log-personal-best
        v-if="personalBestPopup.show"
        :exercise-name="exerciseName"
        :value="personalBestPopup.value"
        :visible="personalBestPopup.show"
        @close="navigateNextExercise()"
        @next="navigateNextExercise()"
      />
    </div>
    <popup-workout-log-exit
      v-if="exitPopupShow"
      :visible="exitPopupShow"
      @close="exitPopupShow = false"
      @exit="exit()"
    />
    <div class="pc-form-workout-log__wrapper-buttons">
      <button-primary
        v-if="!forceEdit"
        class="pc-form-workout-log__button-submit"
        type="button"
        :text="$t('message[\'workout-log.complete-exercise\']') "
        @click="gotoNextExercise()"
      />
      <button-primary
        v-if="forceEdit"
        class="pc-form-workout-log__button-submit"
        type="button"
        :text="$t('message[\'workout-log.save-text\']') "
        @click="gotoNextExercise()"
      />
      <button-secondary
        :disabled="parseInt(totalCompleted) === parseInt(getTotalLogSets())"
        v-if="forceEdit"
        class="pc-form-workout-log__button-cancel"
        type="button"
        :text="$t('message[\'general.cancel\']') "
        @click="cancelEdit()"
      />
      <popup-workout-log-confirm-remove-extra-sets
        v-if="popupExtraSetDelete.show"
        :visible="popupExtraSetDelete.show"
        :set-index="popupExtraSetDelete.index"
        @remove-extra-set="deleteSet"
        @close="popupExtraSetDelete.show=false"
      />
    </div>
  </div>
</template>
<script>
import PopupWorkoutLogConfirmWithoutLog from './popups/PopupWorkoutLogConfirmWithoutLog'
import PopupWorkoutLogPersonalBest from './popups/PopupWorkoutLogPersonalBest'
import StopWatch from './TimerStopWatch'
import Timer from './Timer'
import RestTimer from './TimerRest'
import WorkoutLogSuccessArea from './ContainerFullscreenSuccess'
import workoutLogEntryFormMixin from '../../../../../mixins/component-mixins/page/workout/workoutLogEntryFormMixin'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import SetCompletedText from './TextSetComplete'
import '../../../../../helpers/validation'
import PopupWorkoutLogExit from './popups/PopupWorkoutLogExit'
import IconStop from '../../../../root/icons/IconStop'
import IconPlay from '../../../../root/icons/IconPlay'
import IconCompletedFilled from '../../../../root/icons/IconCompleteFilled'
import IconCompleteRounded from '../../../../root/icons/IconCompleteRounded'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import TextInput from '../../../../global/inputs/TextInput'
import TextArea from '../../../../global/inputs/TextArea'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import TimerCompleted from './TimerCompleted'
import IconDelete from '@/components/root/icons/IconDeleteLinear'
import ButtonCustom from '@/components/global/buttons/ButtonCustom'
import IconAddNew from '@/components/layout/template-2/root/icons/IconAddNew'
import IconAddRounded from '@/components/root/icons/IconAddMoreRounded'
import IconAddFillRounded from '@/components/root/icons/IconAddFillRounded'
import PopupWorkoutLogConfirmRemoveExtraSets
  from '@/components/layout/template-1/workout/page-components/popups/PopupWorkoutLogConfirmRemoveExtraSet'
import {enableFlexibleWorkoutLog} from '@/includes/TemplateSettings'

export default {
  name: 'WorkoutLogEntryForm',
  components: {
    PopupWorkoutLogConfirmRemoveExtraSets,
    IconAddFillRounded,
    IconAddRounded,
    IconAddNew,
    ButtonCustom,
    IconDelete,
    TimerCompleted,
    ButtonSecondary,
    ButtonPrimary,
    TextArea,
    TextInput,
    TextBodyRegular,
    TextBodyExtraSmall,
    IconCompleteRounded,
    IconCompletedFilled,
    IconPlay,
    IconStop,
    SetCompletedText,
    ValidationProvider,
    ValidationObserver,
    PopupWorkoutLogConfirmWithoutLog,
    PopupWorkoutLogPersonalBest,
    PopupWorkoutLogExit,
    StopWatch,
    Timer,
    WorkoutLogSuccessArea,
    RestTimer
  },
  mixins: [workoutLogEntryFormMixin],
  props: {
    exerciseRestInterval: {},
    exerciseRestTime: {}
  },
  data: function () {
    return {
      showSuccess: false,
      restText: this.$i18n.t('message["general.rest"]'),
      showMainTimer: true,
      playMainTimer: false,
      isMainTimerStopped: false
    }
  },
  computed: {
    flagValueDefaultDisabled: function () {
      return enableFlexibleWorkoutLog()
    },
    showTimer: function () {
      return this.hasMetricType('duration') || this.exerciseTimeValue > 0 || this.exerciseRestTime > 0
    },
    showWorkoutTimer: function () {
      return this.hasMetricType('duration') && this.exerciseTime > 0 && this.exerciseTimeValue > 0 && this.showMainTimer
    },
    showTimerCompleted: function () {
      if (this.exerciseRestTime > 0) {
        return this.totalCompleted >= this.timerCurrentSet && this.restTimerSet >= this.timerCurrentSet
      } else {
        return this.totalCompleted >= this.timerCurrentSet
      }
    }
  },
  mounted () {
    if (this.hasMetricType('duration')) {
      const current = this.formData.find((form) => {
        return form.current
      })
      current.attributes.forEach((ele) => {
        if (ele.type === 'duration') {
          this.exerciseTime = this.service.timeToSeconds(ele.value)
        }
      })
      if (this.lastTimerValue) {
        this.exerciseTime = parseInt(this.lastTimerValue)
      }
    }
  }
}
</script>
