<template>
  <div class="pc-card-debit-card" v-on="$listeners" v-bind="$attrs" >
    <div class="pc-card-debit-card__icon-text">
      <icon-payment-card size="sm4"/>
      <div>
        <text-body-small line-height="lh-lg" weight="extra-bold">{{$t('message["billing-info.card-last-digits"]',{digits : lastDigits})}}</text-body-small>
        <text-body-extra-small
          line-height="lh-lg"
          class="pc-card-debit-card__description"
          v-if="isNativeApp">{{$t('message["billing-info.native-app-stripe-description"]')}}</text-body-extra-small>
      </div>

    </div>

    <div class="pc-card-debit-card__arrow" v-if="!isNativeApp">
      <icon-forward size="sm2"/>
      </div>
  </div>
</template>

<script>
import IconPaymentCard from '../../../../../root/icons/IconPaymentCard'
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import IconForward from '../../../../../root/icons/IconForward'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
export default {
  name: 'CardDebitCard',
  components: { TextBodyExtraSmall, IconForward, TextBodySmall, IconPaymentCard },
  props: {
    lastDigits: {
      default: ''
    }
  },
  data: function () {
    return {
      isNativeApp : false
    }
  },
  mounted () {
    this.isNativeApp = isMobileNativeApp()
  }
}
</script>

<style scoped>

</style>
