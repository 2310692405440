import DashboardAPI from './api/dashboard/DashboardAPI'

export default class AnnounceKitService {
    api = new DashboardAPI()

    constructor () {
      if (!AnnounceKitService.instance) {
        AnnounceKitService.instance = this
      }
      return AnnounceKitService.instance
    }

    getConfigs () {
        return new Promise(async (resolve, reject) => {
            this.api.getAnnounceConfig()
                .then((res) => {
                    resolve(res.data)
                }).catch(err => {
                    reject(err)
                })
        })
    }
}