<template>

  <popup
    :visible="visible"
    @hide="closePopup"
    v-if="visible"
    class="gc-popup-meal-dislike"
    modal-class="gc-popup-meal-dislike pc-popup-suggested-meal-swap"
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <popup-title>
      <popup-button-close :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }" @hide="closePopup"/>
    </popup-title>
    <popup-body>
      <popup-head class="text-center" >
        <popup-text-heading v-if="status==='init'&&!showSwap">{{$t('message["popup.add-meal-dislikes-header"]')}}</popup-text-heading>
        <popup-text-heading v-else-if="status==='init'">{{$t('message["meal-plan.suggested-meal-swap.title"]')}}</popup-text-heading>
        <div v-if="status==='processing'" class="text-center">
          <popup-text-heading v-if="!swap">{{ $t('message["meal-plan.disliking"]')}}</popup-text-heading>
          <popup-text-heading v-else>{{ $t('message["meal-plan.disliking-and-swapping"]')}}</popup-text-heading>
          <loading-buffer :height="'100'"></loading-buffer>
        </div>
        <div  v-if="status==='success'">
          <popup-text-heading v-if="!swap" >{{$t('message["meal-plan.disliked"]')}}</popup-text-heading>
          <popup-text-heading v-else>{{$t('message["meal-plan.disliked-and-swapped"]')}}</popup-text-heading>
        </div>
      </popup-head>
      <popup-content class="text-center" >

        <transition name="fade" v-if="status==='init'&&!showSwap">
        <popup-text-body
          class="text-center info-modal__subtitle mb-2"
        >{{$t('message["messages.dislike-meal-popup"]')}}</popup-text-body>
        </transition>
        <transition name="fade"  v-else-if="status==='init'">
          <container-meal-swap-options
            :meal="meal"
            v-model="selectedMeal"
          />
        </transition>

        <icon-complete-filled :size="'lg3'" v-if="status==='success'"/>
        <div v-if="error">
          <p class="app-error-msg">{{error}}</p>
        </div>
        <div v-if="status==='error'">
          <div class="text-center"/>

          <p class="text-center info-modal__subtitle mb-2">{{ errorMessage }}</p>
        </div>
        <div v-if="status==='error'|| status==='success'" ></div>
        <popup-footer v-if="status==='init'" class="pc-popup-suggested-meal-swap__footer">
          <button-primary class="gc-popup-meal-dislike__button-dislike-and-swap" v-if="!isSwapped&&canSwap&&!showSwap" @click="showSwap=true" :text="swapAndDislikeButtonText"></button-primary>
          <button-primary class="gc-popup-meal-dislike__button-dislike-and-swap" v-else-if="!isSwapped&&canSwap" @click="confirmed(true)" :text="$t('message[\'swap.confirm-button-text\']')" :disabled="!selectedMeal"></button-primary>
          <button-secondary class="gc-popup-meal-dislike__button-dislike" v-if="!showSwap" @click="confirmed(false)" :text="dislikeButtonText"></button-secondary>
        </popup-footer>
      </popup-content>
    </popup-body>

  </popup>
</template>
<script>

import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import MealDislikeService from '../../../services/MealDislikeService'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import mealSwapMixin from '../../../mixins/component-mixins/global/mealSwapMixin'
import DefineInclude from '../../../DefineInclude'

import Popup from './Popup'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupFloatingFooter from './popup-sub-components/PopupFloatingFooter'
import PopupFooter from './popup-sub-components/PopupFooter'
import PopupTextBody from './popup-sub-components/PopupTextBody'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from './popup-sub-components/PopupTextSubHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import IconCompleteFilled from '../../root/icons/IconCompleteFilled'
import ProfileService from '../../../services/ProfileService'
import ContainerMealSwapOptions
  from '@/components/layout/template-1/meal-plan/page-components/ContainerMealSwapOptions.vue'
import { i18n } from '@/helpers/localization/i18n'

export default {
  name: 'PopupAddDislikes',
  components: {
    ContainerMealSwapOptions,
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary,
    IconCompleteFilled
  },
  mixins: [popupAnimationMixin, NativeAppMixin, mealSwapMixin],
  props: {
    visible: {
      default: false
    },
    canSwap: {
      default: true
    },
    meal: {
      default: 'This meal'
    },
    isSwapped: {
      default: false
    },
    mealPlanID: {
      default: 0,
      type: Number
    },
    swapData: {
      default: function () {
        return {}
      },
      type: Object
    }
  },
  data: function () {
    return {
      showSwap: false,
      successImage: DefineInclude.imagesUrl + 'swap-success.svg',
      error: '',
      swap: false,
      showClose: true,
      status: 'init',
      imagesUrl: this.$appConfig.imagesUrl,
      swapAndDislikeButtonText: this.$t('message["popup.add-meal-dislikes-with-swap-button"]'),
      dislikeButtonText: this.$t('message["popup.add-meal-dislikes-button"]'),
      ingredientsToSwap: [],
      selectedMeal: null,
    }
  },
  computed: {
    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'processing'
    }
  },
  methods: {
    i18n () {
      return i18n
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    async confirmed (swap) {
      await this.setIngredients(swap)
    },
    async setIngredients (swap) {
      this.status = 'processing'
      const promises = []
      this.meal.getRecipes().forEach(recipe => {
        promises.push(this.getRepeatIngredientSwap(recipe, this.$route.params.mid))
      })
      let results = await Promise.all(promises)

      results.forEach((repeatIngredientSwap) => {
        this.ingredientsToSwap = this.ingredientsToSwap.concat(repeatIngredientSwap)
      })

      let profileService = new ProfileService()
      await profileService.setProfileData()
      let ingredients = this.ingredientsToSwap
      let excludedIngredients = profileService.getExcludedIngrediants()
      let ingredientsArray = ingredients.map(x => x.id)
      let commonIngredients = ingredientsArray.filter(e => excludedIngredients.indexOf(e) !== -1)
      if (commonIngredients.length > 0) {
        for (let i = 0; i < commonIngredients.length;) {
          const idxObj = ingredients.findIndex(object => {
            return object.id === commonIngredients[i]
          })
          ingredients.splice(idxObj, 1)
          i++
        }
      }
      this.sendRequest(swap, ingredients)
    },
    sendRequest (swap, repeatIngredientSwap) {
      this.swap = swap
      this.status = 'processing'
      let dislikeService = new MealDislikeService()
      this.showClose = false

      let promises = [dislikeService.setDislike({'meal_id': this.meal.getReferenceMealId()})]
      if (swap) {
        promises.push(this.swapMeal())
      }
      Promise.all(promises).then(data => {
        this.meal.setDisliked(true)
        this.status = 'success'
        this.$emit('success', {id: this.meal.getReferenceMealId(), isSwapped: swap})
        setTimeout(() => {
          this.logEvent('MealPlan.DislikedMeal', {meal: this.meal.getName()})
          if (repeatIngredientSwap.length > 0) {
            this.showUpdateDislikesPopup(repeatIngredientSwap, this.meal.getId())
          }
          this.closePopup()
        }, 1000)
      }).catch((err) => {
        if (err.config && err.config.url.includes('substitute-meal') && err.data && err.data.errors) {
          this.error = this.$i18n.t('message["meal-dislikes.unable-to-swap"]')
        } else {
          this.error = this.$i18n.t('message["general.common-error-message"]')
        }
        this.status = 'init'
        this.showClose = true
      })
    },
    swapMeal () {
      return new Promise((resolve, reject) => {
        let promises = []
        this.meal.getRecipes().forEach(recipe => {
          promises.push(this.getRepeatIngredientSwap(recipe))
        })
        Promise.all(promises).then(repeatIngredientSwap => {
          const allRepeatIngredientSwap = []
          repeatIngredientSwap.forEach(item => {
            allRepeatIngredientSwap.concat(item)
          })
          this.meal.swapWithMeal(this.selectedMeal).then(() => {
            resolve({'repeatIngredientSwap': allRepeatIngredientSwap})
          }).catch(err => {
            reject(err)
          })
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
