import DefineInclude from '../../DefineInclude'

export default class Member {
  id = null
  firstName = null
  lastName = null
  email = null
  avatar = null
  isOwner = null

  constructor (member) {
    if (member && member.hasOwnProperty('id')) {
      this.id = member.id
      this.firstName = member.first_name
      this.lastName = member.last_name
      this.email = member.email
      this.avatar = member.avatar
      this.isOwner = member.is_owner
    }
  }

  getId () {
    return this.id
  }

  getFirstName () {
    return this.firstName
  }

  getLastName () {
    return this.lastName
  }

  getFullName () {
    return this.firstName + ' ' + this.lastName
  }

  getEmail () {
    return this.email
  }

  getAvatar () {
    return this.avatar ? this.avatar : DefineInclude.imagesUrl + 'default-profile.png'
  }

  getIsOwner () {
    return this.isOwner
  }
}
