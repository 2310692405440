import NotificationAPI from './api/notification/NotificationAPI'
import lodash from 'lodash'
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";
export default class NotificationService {
  data = null
  constructor () {
    if (!NotificationService.instance) {
      NotificationService.instance = this
    }
    return NotificationService.instance
  }
  setNotifications (notifications) {
    this.data = lodash.uniqWith(notifications, lodash.isEqual)
  }

  getAll (force = false) {
    return new Promise((resolve, reject) => {
      if (this.data !== null && !force) {
        resolve(this.data)
      } else {
        this.getFromAPI().then(data => {
          this.data = data
          resolve(data)
        }).catch((error) => {
          this.handleServerError(error)
        })
      }
    })
  }

  getFromAPI () {
    const service = new NotificationAPI()
    return new Promise((resolve, reject) => {
      service.getList().then(response => {
        // filter unique the notifications by notification object. url,message,type,action
        this.setNotifications(response.data)
        resolve(this.data)
      }).catch((error) => {
          this.handleServerError(error)
      })
    })
  }

  cancelSubscription (url, payload = {}) {
    const api = new NotificationAPI()
    return api.sendPostRequest(url, payload)
  }

  resetNotifications () {
    this.data = null
  }

  handleServerError (error) {
    APIErrorHandler.handleError(error.response)
  }
}
