import Vue from 'vue'
import Router from 'vue-router'
import SubscriptionSettingsMain from '../components/layout/template-1/subscription/Main'
import FitnessDiaryMain from '../components/layout/template-1/fitness-diary/Main'
import WorkoutMain from '../components/layout/template-1/workout/Main'
import MealPlanMain from '../components/layout/template-1/meal-plan/Main'
import ShoppingListMain from '../components/layout/template-1/meal-plan/shopping/Main'
import ProfileMain from '../components/layout/template-1/profile/Main'
import DashboardMain from '../components/layout/template-1/dashboard/Main.vue'
import ContentPageMain from '../components/layout/template-1/content/Main'
import ExternalPage from '../components/layout/template-1/external-page/Main'
import ProgressCheckInMain from '../components/layout/template-1/progress/Main'
import RequestNewPassword from '../components/layout/template-1/auth/PageRequestNewPassword'
import AddNewPassword from '../components/layout/template-1/auth/PageAddNewPassword'
import SignIn from '../components/layout/template-1/auth/PageSignIn'
import Logout from '../components/layout/template-1/auth/Logout'
import ContactUs from '../components/layout/template-1/contact/Main'
import IAPMain from '../components/layout/template-1/iap/Main'
import store from '../store/index'
import AuthService from '../services/AuthService'
import ImageService from '../services/ImageService'
import { checkNativeAppVersion, isMobileNativeApp } from '../includes/NativeAppCommon'
import customConfig, { env } from '../../config/custom-config'
import DefineInclude from '../DefineInclude'
import { loadLanguageAsync } from '../helpers/localization/i18n'
import PageMenu from '../components/layout/template-2/profile/PageMenu'
import PageSignUpPayment from '../components/layout/template-1/sign-up/PageSignUpPayment'
import PageSignUpComplete from '../components/layout/template-1/sign-up/PageSignUpComplete'
import PageSignUpRegister from '../components/layout/template-1/sign-up/PageSignUpRegister'
import PageInvitationsMain from '../components/layout/template-1/invitations/PageInvitationsMain'
import PageNotFound from '../components/layout/template-1/common/PageNotFound'
import PageAccountDeletionConfirmation from '../components/layout/template-1/auth/PageAccountDeletionConfirmation.vue'
import moment from 'moment'
import { isMobile } from 'mobile-device-detect'
import PageSignUpPaymentConfirm from '../components/layout/template-1/sign-up/PageSignUpPaymentConfirm'
import { checkIsCoach, checkIsCreator } from '../helpers/user'
import PageAccountDeletion from '@/components/layout/template-1/auth/PageAccountDeletion'
import { showSignUpFlowForAllTypes } from '@/includes/TemplateSettings'
import PageSignUpRegisterExternal from '@/components/layout/template-1/sign-up/PageSignUpRegisterExternal.vue'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: DashboardMain,
      name: 'Home',
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '',
      redirect: '/dashboard',
      name: 'Home',
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    { path: '/logout',
      component: Logout
    },
    {
      path: DefineInclude.appUrlList.onboard + '/:form',
      name: 'OnboardMain',
      component: SubscriptionSettingsMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.pageAccountDeletionConfirmation,
      name: 'AccountDeletionConfirmation',
      component: PageAccountDeletionConfirmation,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: DefineInclude.appUrlList.accountDeletion,
      name: 'AccountDeletion',
      component: PageAccountDeletion,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: DefineInclude.appUrlList.profile,
      name: 'ProfileMain',
      component: ProfileMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.onboard,
      name: 'OnboardMain',
      component: SubscriptionSettingsMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    }, {
      path: DefineInclude.appUrlList.progress,
      name: 'progressMain',
      component: FitnessDiaryMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.workoutUpdate,
      name: 'WorkoutUpdateMain',
      component: ProgressCheckInMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.workout,
      name: 'WorkoutMain',
      component: WorkoutMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    }, {
      path: DefineInclude.appUrlList.workoutBase,
      name: 'WorkoutMainList',
      component: WorkoutMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.mealPlanUpdate,
      name: 'MealPlanUpdateMain',
      component: ProgressCheckInMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.mealPlan,
      name: 'MealPlanMain',
      component: MealPlanMain,
      props: true,
      meta: {
        requiresAuth: true,
        transition: 'slide-fade'
      }
    }, {
      path: DefineInclude.appUrlList.shoppingList,
      name: 'ShoppingListMain',
      component: ShoppingListMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.mealPlanBase,
      name: 'MealPlanMainList',
      component: MealPlanMain,
      props: true,
      meta: {
        requiresAuth: true,
        transition: 'slide-fade'
      }
    },
    {
      path: DefineInclude.appUrlList.dashboard,
      name: 'DashboardMain',
      component: DashboardMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.contentPage,
      name: 'ContentPageMain',
      component: ContentPageMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.externalPage,
      name: 'ExternalPageMain',
      component: ExternalPage,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: DefineInclude.appUrlList.signIn,
      name: 'signIn',
      component: SignIn,
      props: true,
      meta: {
        requiresAuth: false
      }
    }, {
      path: DefineInclude.appUrlList.forgotPasswordRequest,
      name: 'forgotPasswordRequest',
      component: RequestNewPassword,
      props: true,
      meta: {
        requiresAuth: false
      }
    }, {
      path: DefineInclude.appUrlList.resetPassword,
      name: 'forgotPasswordRequest',
      component: AddNewPassword,
      props: true,
      meta: {
        requiresAuth: false
      }
    }, {
      path: DefineInclude.appUrlList.forgotPassword,
      name: 'forgotPassword',
      component: AddNewPassword,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: DefineInclude.appUrlList.contact,
      name: 'contactUs',
      component: ContactUs,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.pageInvite,
      name: 'pageInvite',
      component: PageInvitationsMain,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/iap/sign-up',
      name: 'IAP',
      component: IAPMain,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: DefineInclude.appUrlList.signUp,
      name: 'PageSignUp',
      component: PageSignUpRegister,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: DefineInclude.appUrlList.signUpExternal,
      name: 'PageSignUpExternal',
      component: PageSignUpRegisterExternal,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: DefineInclude.appUrlList.signUpPay,
      name: 'PageSignUpPay',
      component: PageSignUpPayment,
      props: true,
      meta: {
        requiresAuth: true
      }
    }, {
      path: DefineInclude.appUrlList.alreadySubscribed,
      name: 'PageSignUpAlreadySubscribed',
      component: PageSignUpPayment,
      props: true,
      meta: {
        requiresAuth: true
      }
    }, {
      path: DefineInclude.appUrlList.restartSignUp,
      name: 'restartSignUp',
      component: PageSignUpPayment,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.signUpThankYou,
      name: 'PageSignUpThankYou',
      component: PageSignUpComplete,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.signUpConfirmPayment,
      name: 'PageSignUpPaymentConfirm',
      component: PageSignUpPaymentConfirm,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: DefineInclude.appUrlList.signUpConfirmPaymentLegacy,
      name: 'PageSignUpPaymentConfirm',
      component: PageSignUpPaymentConfirm,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/menu',
      name: 'PMenu',
      component: PageMenu,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    { path: '/404', component: PageNotFound },
    { path: '*', redirect: '/404' }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (checkSkipUrl(to)) {
    if(!showSignUpFlowForAllTypes()){
      window.location.href = to.fullPath
    }

  }

  if ('loading' in to.query && to.query.loading === '0') {
    store.commit('hideLoading')
  } else {
    store.commit('showLoading')
  }

  setPervoiusRouter(from)

  if (typeof to.meta !== 'undefined' && to.meta.requiresAuth) {
    const auth = new AuthService()
    // if localstorage token is invalid
    if ((checkIsCreator() || checkIsCoach()) && to.name !== 'DashboardMain') {
      store.commit('hideLoading')
      next('/dashboard')

      return
    }

    if (env === 'production' && to.name.includes('OnboardMain') && (!from.name)) {
      if (document.referrer && document.referrer.includes('thank-you')) {
        localStorage.removeItem('refreshToken')
        auth._refreshToken = null
      }
      auth.getTokenFromAPI()
        .then(() => {
          setAppData(to, () => {
            next()
          })
        })
        .catch(() => {
          localStorage.removeItem('authToken')
          localStorage.removeItem('refreshToken')
          auth.logOut()
        })
    } else if ((!auth.isValidToken() || (!from.name && env === 'production')) && (to.name.includes('OnboardMain') || !isMobile)) {
      if ((checkIsCreator() || checkIsCoach()) || auth._refreshToken) {
        setAppData(to, () => {
          next()
        })
      } else {
        auth.getTokenFromAPI()
          .then(() => {
            setAppData(to, () => {
              next()
            })
          })
          .catch(() => {
            localStorage.removeItem('authToken')
            localStorage.removeItem('refreshToken')
            auth.logOut()
          })
      }
    } else if (!auth.isValidToken() && !from.name) {
      if ((checkIsCreator() || checkIsCoach())) {
        setAppData(to, () => {
          next()
        })
      }
      // get the token forms api
      auth.setTokenFormRefreshToken()
        .then(() => {
          setAppData(to, () => {
            goToPrevoiusSessionPage(next, !from.name)
          })
        })
        .catch(() => {
          auth.logOut()
        })
    } else {
      setAppData(to, () => {
        goToPrevoiusSessionPage(next, !from.name)
      })
    }
  } else {
    setAppData(to, () => {
      goToPrevoiusSessionPage(next, !from.name)
    })
  }
})

let isLastRouteRedirected = false
function goToPrevoiusSessionPage (next, isSessionStart = false) {
  if (isSessionStart && !isLastRouteRedirected && isMobileNativeApp()) {
    let lastRouteString = localStorage.getItem('lastSessionRoute') || null

    if (lastRouteString) {
      let routeInfo = JSON.parse(lastRouteString)
      isLastRouteRedirected = true
      if (routeInfo.date_time && moment().diff(moment(routeInfo.date_time), 'minutes') < 15) {
        localStorage.removeItem('lastSessionRoute')
        next(routeInfo)
        return
      }
    }
  }
  next()
}

router.afterEach((to, from) => {
  routerChangeEvent(to)
  setBackButtonDisable(from,to)
})
function setAppData (newRoute, callback) {
  if (!store.getters.getAppLoaded) {
    const imageService = new ImageService()
    const initAppData = async () => {
      if (!store.getters.getAppLoaded) {
        const lang = 'def'

        // get the language
        await loadLanguageAsync(lang)

        // set images instance
        await imageService.setImages()
      }
    }

    initAppData().then(() => {
      store.commit('setImages')
      // load related images before page mount
      imageService.setBeforeRouteImageListCache(newRoute).finally(() => {
        imageService.setAfterRouteImageListCache(newRoute)
        store.commit('setAppLoaded', true)
        callback()
      })
    }).catch(() => {
      store.commit('setAppLoaded', true)
      callback()
    })
  } else {
    callback()
  }
}

function routerChangeEvent (to) {
  const path = to.path
  const queryParams = to.query
  const detail = { path: path, query_params: queryParams, query: to.query, date_time: moment().format() }
  const event = new CustomEvent('router-changed', { detail: detail })
  localStorage.setItem('lastSessionRoute', JSON.stringify(detail))
  document.dispatchEvent(event)
}

function checkIsPageToRedirect (to, from) {
  return (from.name !== null && to.name !== from.name && to.name !== 'ContentPageMain')
}

/**
 * check skip url to reload in config file and if current url is a skip url returns true
 * @returns {boolean}
 */
function checkSkipUrl (to) {
  const skipUrlReload = customConfig.skipUrlReload || false
  if (typeof isUrl === 'function' && skipUrlReload) {
    const skipUrl = DefineInclude.appUrlListToSkip
    for (const property in skipUrl) {
      if (checkURL(skipUrl[property], to.path)) {
        return true
      }
    }
  }
  return false
}

/**
 * using /util/url-pattern.js
 * @returns {boolean}
 */
function checkURL (pattern, currentUrlPath) {
  const matcher = new UrlPattern(pattern)
  const matched = matcher.match(currentUrlPath)
  return (matched !== null)
}

function setPervoiusRouter (from) {
  store.commit('setPreviousRoute', from)
}
function beforeRouteUnload (event) {
  event.preventDefault()
  event.returnValue = '' // For older browsers
  // Custom confirmation message
  const confirmationMessage = 'Are you sure you want to leave this page?'

  // Display the confirmation dialog
  return confirmationMessage
}

let backButtonDisabled = false
function setBackButtonDisable (from, to) {
  if (backButtonDisabled) {
    window.removeEventListener('beforeunload', beforeRouteUnload)
  }

  if (!to.fullPath.includes('/pay') && (from.fullPath.includes('/subscribe/'))) {
    backButtonDisabled = true
    history.pushState(null, null, document.URL)
    window.addEventListener('beforeunload', beforeRouteUnload)
  }
}

export default router
