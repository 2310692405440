import SignUpAPI from '../api/SignUp/SignUpAPI'
import AuthService from '@/services/AuthService'
export default class SignUpExternalPurchaseService {
  _activationCode = null
  _planUUID = null
  _user = null
  _subscription = null

  constructor (activationCode) {
    this._activationCode = activationCode
  }

  getPlanUUID () {
    return this._planUUID
  }

  async loadData () {
    const api = new SignUpAPI()
    const response = await api.getExternalPurchaseData(this._activationCode).then(data => data.data)
    this._planUUID = response.subscription.pricing_plan_uuid
    this._user = response.user
    this._subscription = response.subscription

  }

  getSubscriptionStatus () {
    return this._subscription.status
  }  getSubscriptionId () {
    return this._subscription.id
  }

  getUserEmail () {
    return this._user.email
  }

  getUserFirstName(){
    return this._user.first_name
  }
  getUserLastName(){
    return this._user.last_name
  }

  isRegisteredUser () {
    return !this._user.incomplete || false
  }

  getVerificationCode(){
    return this._user.verification_code
  }
}
