<template>

  <popup
    @hide="closePopup"
    modal-class="pc-popup-workout-log-mark-incomplete"
    :visible="visible"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-head>
        <popup-text-heading v-if="state=== 'init'">{{ $t('message[\'popup.workout-log-mark-incomplete.title\']')}}
        </popup-text-heading>
        <popup-text-heading v-else-if="state=== 'processing'" >{{ $t('message[\'popup.workout-log-mark-incomplete.title-processing\']')}}
        </popup-text-heading>
        <popup-text-heading v-else-if="state=== 'success'" >{{ $t('message[\'popup.workout-log-mark-incomplete.title-completed\']')}}
        </popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body v-if="state=== 'init'">
          <p v-html="$t('message[\'popup.workout-log-mark-incomplete.description\']')"></p>
        </popup-text-body>
        <popup-text-body v-else-if="state=== 'failure'">
          <p v-html="$t('message[\'general.common-error-message\']')"></p>
        </popup-text-body>
        <loading-buffer :height="50" v-else-if="state==='processing'"></loading-buffer>
        <icon-completed-filled v-else-if="state=== 'success'"
                               size="lg3"
                               class="pc-popup-workout-log-mark-incomplete__icon-success" />
      </popup-content>
      <popup-footer>
        <button-primary class="pc-popup-workout-log-mark-incomplete__button-submit"
                        v-if="state=== 'init'"
                        :text="$t('message[\'workout-log.mark-incomplete-confirm\']')"
                        v-on:click="doAction()"
        />
      </popup-footer>
    </popup-body>
  </popup>

</template>

<script>

import LoadingBuffer from '../../../global/widgets/LoadingBuffer'
import { mapActions, mapGetters } from 'vuex'
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../../../../global/popups/Popup'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import IconCompletedFilled from '../../../../../root/icons/IconCompleteFilled'

export default {
  name: 'PopupWorkoutLogMarkIncomplete',
  props: ['visible', 'exerciseID'],
  mixins: [popupAnimationMixin],
  components: {
    IconCompletedFilled,
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    ButtonSecondary,
    PopupContent,
    PopupButtonClose,
    PopupTitle,
    Popup,
    LoadingBuffer
  },
  data: function () {
    return {
      successImage: this.$appConfig.imagesUrl + 'swap-success.svg',
      state: 'init', // init,processing,success,failure
      title: '',
      imagesUrl: this.$appConfig.imagesUrl

    }
  },
  computed: {
    ...mapGetters({
      service: 'singleExercisePageStore/getCurrentWorkoutLogService'
    })
  },
  methods: {
    ...mapActions({
      setExerciseIncomplete: 'workoutLogStore/setExerciseIncomplete'
    }),
    doAction () {
      this.state = 'processing'
      this.service.setIncompleteExercise(parseInt(this.exerciseID)).then(data => {
        this.state = 'success'
        this.$emit('success', parseInt(this.exerciseID))
        this.setExerciseIncomplete(this.exerciseID)
        setTimeout(() => {
          this.closePopup()
        }, 2000)
      }).catch(() => {
        this.state = 'failure'
        this.$emit('failure')
        setTimeout(() => {
          this.$emit('close')
        }, 2000)
      })
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
