import BaseComponent from '../../../components/layout/template-1/global/base/BaseComponent'
import Swap from '../../../components/layout/template-1/workout/widgets/ExerciseSwapMain'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import feedbackMixin from '../../feedbackMixin'
import { getIdFromUrl } from 'vue-youtube'
import DashBoardService from '../../../services/DashboardService'
import { flagValue } from '../../../includes/TemplateSettings'
import UserEventsService from '../../../services/UserEventsService'
import store from "@/store";

export default {
  extends: BaseComponent,
  mixins: [feedbackMixin],
  props: {
    showCompleteButton: {
      default: true
    },
    showSetsArea: {
      default: true
    }
  },
  data: function () {
    return {
      workoutData: this.$route.query,
      components: {
        workoutLog: {
          enabled: false,
          metrics: []
        }
      },
      genericExerciseID: null,
      currentTab: 'log',
      videoImage: '',
      showHeader: true,
      noOfSets: '',
      setsCount: '',
      currentSet: 1,
      restSet: 1,
      exerciseTime: 0,
      restInterval: 0,
      restTime: 0,
      headerOptions: {
        show: true,
        left: 'previous-emit',
        right: 'help',
        modifiers: ['with-small-title', 'with-bg-color'],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      cardComponent: '',
      contentComponent: '',
      contentComponentData: {},
      popupComponents: {
        swap: Swap
      },
      feedBackPopup: {
        show: false
      },
      dayName: '',
      weekName: '',
      exerciseSwapEnabled: false,
      pageClass: ['macroapp--page-common', 'macroapp--page-workout-video'],
      fullScreen: '',
      videoOptions: {
        // youtube/vimeo
        type: '',
        poster: '',
        url: '',
        id: '',
        controls: true,
        title: '',
        mute: false
      },
      workoutLogEnabled: false,
      showWorkoutLogSuccess: false,
      instructions: [],
      instructionsPopup: {
        title: '',
        instructions: '',
        visible: false
      },
      setInstructionsPopup: {
        title: '',
        instructions: '',
        visible: false
      },
      swapPopup: {
        visible: false
      },
      isCircuit: false,
      deletedExercise: [],
      nextExercise: {
        id: 0
      },
      prevExercise: {
        id: 0
      },
      setType: this.$i18n.t('message["workouts.set-type-normal"]'),
      firstTime: false,
      cardImageStyle: {},
      exerciseGroup: {
        setName: '',
        currentID: 1,
        totalExercises: 1
      },
      restText: this.$i18n.t('message["general.rest"]')
    }
  },
  computed: {
    ...mapGetters({
      isCompleted: 'workoutLogStore/getCurrentExerciseCompleted',
      getIncompleteExercises: 'workoutLogStore/getCompletedExercises',
      getCompletedExercises: 'workoutLogStore/getCompletedExercises',
      getCompletedExercisesCount: 'workoutLogStore/getCompletedExercisesCount',
      getIncompleteExercisesCount: 'workoutLogStore/getIncompleteExercisesCount',
      getExerciseDataStatus: 'singleExercisePageStore/getExerciseDataStatus',
      exerciseData: 'singleExercisePageStore/getExerciseData',
      workoutProgram: 'singleExercisePageStore/getCurrentWorkoutProgram',
      getWorkoutDataStatus: 'singleExercisePageStore/getWorkoutDataStatus',
      workoutLogService: 'singleExercisePageStore/getCurrentWorkoutLogService'
    }),
    setsText: function () {
      let sets = []
      if (this.noOfSets) {
        sets = this.noOfSets.split('/')
      } else {
        return ''
      }
      let isAllSetsHaveOneRep = sets.every((set) => {
        return set.trim() === '1'
      })
      if (isAllSetsHaveOneRep) {
        return sets.length + ' ' + this.$i18n.tc('message[\'general.set\']', sets.length)
      } else {
        return this.$i18n.t('message[\'general.reps\']') + ' ' + this.noOfSets
      }
    },
    showSetInstructionButton () {
      const text = this.setInstructionsPopup.instructions
      if (Array.isArray(text)) {
        const index = text.findIndex(ele => {
          ele = ele || ''
          return Boolean(ele.replace(/<\/?[^>]+(>|$)/g, '').trim())
        })
        return index !== -1
      } else if (typeof text === 'string' || text instanceof String) {
        return Boolean(text.replace(/<\/?[^>]+(>|$)/g, '').trim())
      }
      return false
    },
    workoutTimerText: function () {
      return this.$i18n.tc('message["general.set"]', 1) + ' ' + this.currentSet
    },
    timerCompleted () {
      if (this.exerciseTime > 0 && this.restTime > 0) {
        return (this.currentSet > this.setsCount) && (this.restSet > this.setsCount)
      } else {
        return this.currentSet > this.setsCount
      }
    }
  },
  watch: {
    currentTab: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        const logData = {
          exercise: this.exerciseData
        }
        this.logEvent('WorkoutLog.ViewedForm', logData)
      }
    },
    getExerciseDataStatus: function (newVal, oldVal) {
      if (newVal === 'loaded') {
        this.loadPageData()
        this.hideLoading()
      }
    },
    getWorkoutDataStatus: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal === 'loaded') {
          this.loadExerciseData()
          this.getNextExercise()
        }
      }
    },
    getIncompleteExercises: {
      deep: true,
      handler (value) {

      }
    }
  },
  methods: {
    ...mapGetters({
      getLogDataFromStore: 'workoutLogStore/getFormLogData'
    }),
    ...mapActions({
      loadExerciseData: 'singleExercisePageStore/loadExerciseData',
      resetExerciseStatus: 'singleExercisePageStore/resetStatus',
      loadWorkoutData: 'singleExercisePageStore/loadWorkoutData'
    }),
    ...mapMutations({
      setCurrentExerciseCompleted: 'workoutLogStore/setCurrentExerciseCompleted',
      viewInstructionsPopup: 'instructionPopupStore/show',
      setInstructionsPopupContent: 'instructionPopupStore/setContent',
      resetWorkoutData: 'singleExercisePageStore/resetWorkoutDataForce',
      resetExerciseData: 'singleExercisePageStore/resetExerciseDataForce',
      setStoreData: 'singleExercisePageStore/init',
      setWorkoutLogService: 'singleExercisePageStore/setWorkoutLogService',
      setLogDataToStore: 'workoutLogStore/setLogFormData',
      setCurrentExerciseInStore: 'workoutLogStore/setCurrentExercise'
    }),
    setToStore () {
      this.setLogDataToStore({ id: parseInt(this.workoutData.exercise), log: { formData: [], lastTimerValue: this.lastTimerValue, currentSet: this.currentSet } })
    },
    setFeedbackPopup () {
      this.setFeedbackEvent('workout-log', 10)
      this.setFeedbackFeatureFlag('workout_log_feedback_popup_show')
      const featureName = this.$i18n.t('message[\'workout-log.title\']')
      this.setFeedbackPopupData(this.$i18n.t('message[\'popup.feed-back.title\']', { feature: featureName }), this.$i18n.t('message[\'popup.feed-back.placeholder\']'))
    },
    setExerciseTime () {
      // set exercise Time
      this.exerciseTime = 0
      if (this.exerciseData.getExerciseTime()) {
        this.exerciseTime = this.exerciseData.getExerciseTime()
        if (this.exerciseData.getExerciseTimeInterval() === 'minute') {
          this.exerciseTime = this.exerciseTime * 60
        } else if (this.exerciseData.getExerciseTimeInterval() === 'hour') {
          this.exerciseTime = this.exerciseTime * 60 * 60
        }
      }
    },
    loadPageData () {
      this.components.workoutLog = {
        ...this.exerciseData.getWorkoutLogData(),
        exerciseID: this.exerciseData.getID(),
        exerciseName: this.exerciseData.getName(),
        sets: this.exerciseData.getSetsRepsString(),
        exerciseTimeValue: this.exerciseData.getExerciseTime(),
        exerciseRestTime: this.exerciseData.getRestTime(),
        exerciseTimeInterval: this.exerciseData.getExerciseTimeInterval(),
        exerciseRestInterval: this.exerciseData.getRestTimeInterval(),
        forceEdit: true
      }
      this.headerOptions.header.mainTitle = this.exerciseData.getName()
      this.headerOptions.mainTitle = this.exerciseData.getName()
      let dashBoardService = new DashBoardService()
      this.exerciseSwapEnabled = dashBoardService.isExerciseSwapEnabled()

      this.noOfSets = this.exerciseData.getSetsRepsString()
      this.setsCount = this.exerciseData.getSetsCount()

      this.videoImage = this.exerciseData.getImage()
      // set details

      this.setType = this.exerciseData.getSetType().name
      this.setInstructionsPopup.title = this.exerciseData.getSetType().name
      this.setInstructionsPopup.instructions = [
        this.exerciseData.getSetType().description
      ]

      this.setExerciseTime()

      this.restInterval = this.exerciseData.getRestTimeInterval()
      this.restTime = this.exerciseData.getRestTime()

      const video = this.exerciseData.getVideoUrl()
      this.instructions = this.exerciseData.getInstructions()
      if (video) {
        const videoUrl = video
        if (this.getVimeoIdFromUrl(videoUrl)) {
          // vimeo video
          this.setVimeoVideoArea(videoUrl, this.getVimeoIdFromUrl(videoUrl))
        } else if (getIdFromUrl(videoUrl)) {
          // youtube video
          this.setYoutubeVideoArea(videoUrl, getIdFromUrl(videoUrl))
        } else if (this.getCustomPageNameFromUrl(videoUrl)) {
          this.setContentArea(this.getCustomPageNameFromUrl(video))
        }
      } else {
        this.resetVideoOptions()
      }
      this.instructionsPopup.instructions = this.exerciseData.getInstructions()

      this.setCurrentExerciseInStore(parseInt(this.workoutData.exercise))
      const storeData = this.getLogDataFromStore(parseInt(this.workoutData.exercise))
      if (storeData.currentSet) {
        this.currentSet = storeData.currentSet
        this.restSet = this.currentSet
      }

      if (storeData.lastTimerValue) {
        this.exerciseTime = parseInt(storeData.lastTimerValue) || this.exerciseTime
      }
      this.afterLoadPageData()
    },
    afterLoadPageData () {
      // donothing
    },
    setVideoThumbToImage () {
      const videoUrl = this.exerciseData.getVideoUrl()
      if (videoUrl) {
        if (this.getVimeoIdFromUrl(videoUrl)) {
          let id = this.getVimeoIdFromUrl(videoUrl)

          this.videoOptions.type = 'vimeo'
          this.videoOptions.url = videoUrl
          this.videoOptions.id = id

          this.videoOptions.poster = ''
          this.getVimeoImage()
            .then(url => {
              this.videoOptions.poster = url
            }).catch(() => {
              // ignoring the error
            })
        }
      }
    },
    resetVideoOptions () {
      this.cardComponent = ''
      this.videoOptions = {
        type: '',
        poster: '',
        url: '',
        id: '',
        controls: true,
        title: '',
        mute: false
      }
    },
    getNextExercise () {
      // next exercise
      this.workoutLogEnabled = this.workoutProgram.getWorkoutLogEnabled() || false
      let dayID = parseInt(this.workoutData.day)
      let weekID = parseInt(this.workoutData.week)
      const dayData = this.workoutProgram.getDayByID(dayID)
      const weekData = this.workoutProgram.getWeekByID(weekID)
      this.weekName = this.numberWordToNumeric(weekData.getName())
      this.dayName = this.numberWordToNumeric(dayData.getName())
      this.isCircuit = dayData.getIsCircuit()
      if (!this.workoutLogEnabled || this.isCircuit) {
        this.currentTab = 'instructions'
      }
      if (this.isCircuit) {
        const circuitName = this.$i18n.t('message["workout.mode-circuit"]')
        const circuitType = this.workoutProgram.getSetTypes().find(set => {
          return set.name.trim() === circuitName
        })
        if (circuitType) {
          this.setInstructionsPopup.instructions = [circuitType.description]
          this.setInstructionsPopup.title = circuitName
          this.setInstructionsPopupContent(this.setInstructionsPopup)
        }
      }

      const currentExerciseID = parseInt(this.workoutData.exercise)
      const currentExercise = this.workoutProgram.getExerciseByID(currentExerciseID)

      const isCompleted = currentExercise.getIsCompleted()
      this.setCurrentExerciseCompleted(isCompleted)
      let currentSetID = ''

      let assignNextExercise = false
      for (const exercise of dayData.exercises) {
        if (assignNextExercise) {
          this.nextExercise = {
            workout: this.workoutData.workout,
            week: this.workoutData.week,
            day: this.workoutData.day,
            exercise: exercise.getID(),
            from: this.$route.query.from || '',
            grouped: dayData.isSetAMultipleSet(exercise.getSetType().name)
          }
          break
        }
        if (exercise.getID() === parseInt(this.workoutData.exercise)) {
          currentSetID = exercise.getSetType().name
          assignNextExercise = true
        } else {
          this.prevExercise = {
            exercise: exercise.getID(),
            day: this.workoutData.day,
            week: this.workoutData.week,
            workout: this.workoutData.workout,
            from: this.$route.query.from || '',
            grouped: dayData.isSetAMultipleSet(exercise.getSetType().name)
          }
        }
      }
      this.exerciseGroup.setName = currentSetID
      if (this.isSetAMultipleSet(currentSetID)) {
        let currentGroupIDs = dayData.exercises.filter(exercise => {
          return exercise.getSetType().name === currentSetID
        })
        currentGroupIDs = currentGroupIDs.map(exercise => {
          return exercise.id
        })
        const index = currentGroupIDs.findIndex(exID => {
          return parseInt(exID) === parseInt(this.params.exercise)
        })

        this.exerciseGroup.currentID = index + 1

        this.exerciseGroup.totalExercises = currentGroupIDs.length
      }
    },
    isSetAMultipleSet (type) {
      const sets = [this.$i18n.t('message["workouts.set-type-super"]'), this.$i18n.t('message["workouts.set-type-giant"]')]
      return sets.some(eType => {
        return type.includes(eType)
      })
    },
    swapSuccessHandle () {
      this.loadPageData()
      this.hideExerciseSwapPopup()
    },
    async setYoutubeVideoArea (url, id) {
      this.videoOptions.type = 'youtube'
      this.videoOptions.url = url
      this.videoOptions.id = id

      this.videoOptions.poster = this.exerciseData.getImage()

      this.videoOptions.title = this.exerciseData.getName()

      let dashBoardService = new DashBoardService()
      this.videoOptions.mute = dashBoardService.isExerciseVideoMuted()

      this.cardComponent = 'YoutubeVideo'
    },
    async setVimeoVideoArea (url, id) {
      this.videoOptions.type = 'vimeo'
      this.videoOptions.url = url
      this.videoOptions.id = id

      this.videoOptions.poster = ''
      if (this.exerciseData.hasImage()) {
        // if thumbnail exists
        this.videoOptions.poster = this.exerciseData.getImage()
      } else {
        this.exerciseData.loadVideoImageFromVideoUrl().then(url => {
          this.videoOptions.poster = url
        })
      }

      this.videoOptions.title = this.exerciseData.getName()
      let dashBoardService = new DashBoardService()
      this.videoOptions.mute = dashBoardService.isExerciseVideoMuted()

      this.cardComponent = 'VimeoVideo'
    },
    setContentArea (pageName) {
      this.contentComponent = 'ContentPageArea'
      this.contentComponentData = {
        hideHeaderImage: false,
        pageName: pageName
      }
    },
    navigatePrevious () {
      this.navigateToPerviousPage()
    },
    showInstructionsPopup () {
      if (!this.instructionsPopup.visible) {
        this.instructionsPopup.instructions = this.exerciseData.getInstructions()
        this.instructionsPopup.visible = true
      }
    },
    hideInstructionsPopup () {
      this.instructionsPopup.visible = false
      this.setInstructionsPopup.visible = false
    },
    showExerciseSwapPopup () {
      this.swapPopup.visible = true
    },
    hideExerciseSwapPopup () {
      this.swapPopup.visible = false
    },
    navigateExerciseList () {
      this.$router.push({
        path: this.$appConfig.appUrlList.workout,
        query: {
          loading: 0,
          workout: this.workoutData.workout,
          week: this.workoutData.week,
          day: this.workoutData.day
        }
      })
    },
    navigateWorkoutProgram () {
      this.showLoading()
      this.$router.push({
        path: this.$appConfig.appUrlList.workout,
        query: {
          workout: this.workoutData.workout
        }
      })
    },
    goToExerciseByID (id) {
      this.$router.replace({
        path: this.$appConfig.appUrlList.workout,
        query: {
          workout: this.workoutData.workout,
          week: this.workoutData.week,
          day: this.workoutData.day,
          mode: this.workoutData.mode || 'standard',
          from: this.$route.query.from || '',
          grouped: this.workoutData.grouped || false,
          exercise: id
        }
      })
    },
    goToExerciseByQuery (queryParams) {
      this.$router.replace({
        path: this.$appConfig.appUrlList.workout,
        query: {loading: 0, ...queryParams}
      })
    },
    goToExerciseList () {
      this.showLoading()
      this.swapPopup.visible = true
      setTimeout(() => {
        window.location.reload()
      }, 500)
    },

    completeExercise () {
      if (!this.workoutLogEnabled || this.isCompleted) {
        this.navigateNextWorkout()
        return
      }
      this.workoutLogService.markExerciseComplete({ note: '' }, parseInt(this.workoutData.exercise)).then(() => {
        this.fullScreen = true
        this.showWorkoutLogSuccess = true
        setTimeout(() => {
          this.showWorkoutLogSuccess = false
          if (flagValue('workout_log_feedback_popup_show')) {
            UserEventsService.sendWorkoutLogFeedBackPopupView()
          }
          this.navigateNextWorkout()
        }, 2500)
      })
    },
    logSaveData () {
      const logData = {
        logged_note: false,
        logged_metrics: false,
        all_sets_completed: false,
        available_metrics: [],
        day: this.dayName,
        week: this.weekName,
        exercise: this.exerciseData.name
      }
      this.logEvent('WorkoutLog.RecordedEntry', logData, true)
    },
    navigateNextWorkout () {
      if (!this.nextExercise.exercise) {
        this.navigateToPerviousPage()
      } else {
        this.goToExerciseByQuery(this.nextExercise)
      }
    },

    navigatePerviousExercise () {
      if (!this.prevExercise.id) {
        this.navigateToPerviousPage()
      } else {
        this.goToExerciseByQuery(this.prevExercise)
      }
    },
    showSets () {
      this.setInstructionsPopupContent(this.setInstructionsPopup)
      this.viewInstructionsPopup()
    },
    setFullscreen (val) {
      this.fullScreen = val
    },
    makeIncompleted () {
      this.isCompleted = false
    },
    setStyles () {
    },
    reloadData () {
      this.showLoading()
      this.swapPopup.visible = false
      this.resetExerciseData()
      this.resetWorkoutData()

      this.loadWorkoutData()
    },
    setDeletedExercise (id) {
      this.deletedExercise.push(id.toString())
    },
    secondsToDisplayTime (totalSeconds) {
      let minutes = parseInt((totalSeconds / 60))
      let seconds = parseInt((totalSeconds % 60))
      let timeString = (minutes < 10) ? '0' + minutes : minutes
      timeString = timeString + ' : '
      timeString = timeString + ((seconds < 10) ? '0' + seconds : seconds)
      return timeString
    },
    navigateToPerviousPage () {

      if (this.$route.query.from && this.$route.query.from === 'workout-program') {
        this.navigateWorkoutProgram()
      } else {
        this.navigateExerciseList()
      }
    },
    logExerciseViewedEvent () {
      this.logEvent('Viewed Exercise', {
        workout_program_id: this.exerciseData.getProgramID(),
        exercise_title: this.exerciseData.getName(),
        day: this.dayName,
        week: this.weekName
      }, false)
    },
    restTimerEnded () {
      this.restSet++
    },
    workoutTimerEnded () {
      this.setExerciseTime()
      this.lastTimerValue = 0
      this.currentSet++
      this.setToStore()
    },
    workoutTimerRemaining (event) {
      this.lastTimerValue = event.remaining.totalSeconds
      this.setToStore()
    },
    restartTimer () {
      this.currentSet = 1
      this.restSet = 1
      this.setToStore()
    }
  },
  destroyed () {
    this.resetExerciseStatus()
    this.resetNotificationBarSettings()
  }
}
