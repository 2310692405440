<template>
  <page-secondary :header="headerOptions" class="gc-page-connect-wearable-success">
    <page-container class="container">
    </page-container>
  </page-secondary>
</template>

<script>
import store from './../../../store/index'
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import pageMixin from '../../../mixins/pageMixin'
import PageSecondary from '../pages/PageSecondary'
import PageContainer from '../pages//page-sub-components/PageContainer'
import StepTrackerService from '@/services/StepTrackerService'

export default {
  name: 'pageConnectWearableSuccess',
  extends: BaseComponent,
  mixins: [pageMixin],
  components: {
    PageContainer,
    PageSecondary,
    LoadingBuffer

  },
  data: function () {
    return {
      headerOptions: {
        show: false
      },
      loading: true,
      pageClass: ['macroapp--page-common']
    }
  },
  watch: {},
  created () {
  },
  async mounted () {
    let source = sessionStorage.getItem('latestWearableConnectionAttempt')
    let service = new StepTrackerService()

    await service.getConnectedDataSources().then((response) => {
      if (source) {
        if (response.hasOwnProperty(source.toLowerCase())) {
          if (response[source.toLowerCase()]) {
            service.setNewConnectedDataSource(source).then(() => {
              this.showLoading()
              this.delayedRouterPush({
                path: '/dashboard',
                query: {page: 'step-tracker'}
              })
            })
          }
        }
      } else {
        this.$store.commit('showServerErrorPopup')
      }
    }).finally(
    )
  },
  methods: {}

}
</script>
