<template>
  <div :class="componentClasses">
    <div class="gc-input-search-and-select__input-wrapper">
      <input
          type="text"
          v-model="search"
          @input="onChange"
          class="gc-input-search-and-select__input"
          ref="input"
          @click="clicked()"
          :maxlength="maxValue"
          :minlength="minValue"
          :placeholder="placeholder"
          :disabled="disabled"
      />
      <div class="gc-input-search-and-select__icons">
        <icon-down-arrow @click="clicked()" v-if="!isOpen" :size="'md1'"/>
        <icon-up-arrow @click="clicked()" v-if="isOpen" :size="'md1'"/>
      </div>
    </div>
    <ul
        v-show="isOpen"
        class="gc-input-search-and-select__list-wrapper"
    >
      <li
          v-for="(result, i) in results"
          :key="i"
          @click="setResult(result)"
          class="gc-input-search-and-select__list-item"
      >
        <text-content :lineHeight="'multi'">{{ result }}</text-content>
      </li>
      <li
          v-if="noResult"
          class="gc-input-search-and-select__list-item__no-result"
      >
        <text-content :lineHeight="'multi'">{{ $t('message[\'general.no-results\']') }}</text-content>
      </li>
    </ul>
    <text-content
        v-if="errorMessage.length > 0"
        :weight="'medium'"
        :size="'sm1'"
        :lineHeight="'multi'"
        class="gc-input-search-and-select__error">
      {{ errorMessage }}
    </text-content>
  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import IconDownArrow from '@/components/root/icons/IconDownArrow.vue'
import IconUpArrow from '@/components/root/icons/IconUpArrow.vue'

export default {
  name: 'InputSearchAndSelect',
  components: {
    IconUpArrow,
    IconDownArrow,
    TextContent
  },
  data () {
    return {
      search: '',
      results: {},
      isOpen: false,
      noResult: false
    }
  },
  props: {
    items: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    inputFontSize: {
      type: Number,
      default: 18
    },
    inputLineHeight: {
      type: Number,
      default: 1.5
    },
    inputFontWeight: {
      type: Number,
      default: 700
    },
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 100
    },
    placeholder: {
      default: 'Search'
    },
    errorMessage: {
      default: ''
    },
    disabled: {
      default: false
    }
  },
  watch: {
    search: function (val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-search-and-select': true,
        'gc-input-search-and-select--error': this.errorMessage.length > 0,
        'gc-input-search-and-select--disabled': this.disabled
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    this.setInputCssValues()
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    clicked () {
      this.clearInput()
      this.isOpen = !this.isOpen
      if (this.search.length < 1) {
        this.results = this.items
      } else {
        this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
      }
    },
    filterResults () {
      this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1)

      if (this.results.length < 1) {
        this.noResult = true
      } else {
        this.noResult = false
        this.isOpen = true
      }
    },
    onChange () {
      this.filterResults()
    },
    setResult (result) {
      this.search = result
      this.$emit('result', result)
      this.isOpen = false
    },
    setInputCssValues () {
      const input = this.$refs.input
      input.style.fontSize = this.inputFontSize + 'px'
      input.style.fontWeight = this.inputFontWeight
      input.style.lineHeight = this.inputLineHeight
    },
    handleClickOutside (event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
      if (this.results.length < 1) {
        this.$emit('clearValue')
        this.search = ''
      }
    },
    clearInput () {
      this.search = ''
      this.$emit('clearValue')
    }
  }
}
</script>
