<template>
  <grid-row class="pc-card-list-product-page">
    <grid-col cols="12">
    <header-simple
      class="pc-card-list-product-page__header"
      :header-text="headerText"
    />
    </grid-col>
    <div v-if="listStyle === 'list'" style="width: 100%">
      <grid-col v-for="page in pages" cols="12"  :key="'product-page-card-' + page.name">
        <card-navigation
          class="pc-card-list-product-page__card"

          :image="page.image"
          :header-text="page.name"
          @click="navigate(page.url)"
        />
      </grid-col>
    </div>
    <div class="pc-card-list-product-page__card__grid-wrapper" v-if="listStyle === 'grid'">
      <grid-col class="pc-card-list-product-page__card__grid-col" v-for="page in pages" cols="6"  :key="'product-page-card-' + page.name">
        <card-navigation
          class="pc-card-list-product-page__card"
          :image="page.image"
          :header-text="page.name"
          @click="navigate(page.url)"
        />
      </grid-col>
    </div>
    <div class="pc-card-list-product-page__card__accordion-wrapper" v-if="listStyle === 'accordion'">
      <grid-col class="pc-card-list-product-page__card__grid-col" v-for="page in pages" cols="12" :lg="(pages.length===1) ? 12 :6" :key="'product-page-card-' + page.name">
        <button-primary-accordion
          class="pc-card-list-product-page__card__accordion"
          :text="page.name"
          @click="navigate(page.url)">
          <img v-if="page.image" class="pc-card-list-product-page__card__accordion__image" :src="page.image" alt=""/>
        </button-primary-accordion>
      </grid-col>
    </div>
  </grid-row>
</template>
<script>
import HeaderSimple from '../../../../global/headers/HeaderSimple'
import CardFeaturedImage from '../../../../global/cards/CardFeaturedImage'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import CardNavigation from '../../../../global/cards/CardNavigation.vue'
import GridCol from '../../../../global/grid/GridCol'
import GridRow from '../../../../global/grid/GridRow'
import ButtonPrimaryAccordion from '../../../../global/buttons/ButtonPrimaryAccordion.vue'

export default {
  name: 'ListCardProductPagesAccordion',
  components: {
    GridCol,
    GridRow,
    CardFeaturedImage,
    HeaderSimple,
    CardNavigation,
    ButtonPrimaryAccordion
  },
  mixins: [NativeAppMixin],
  props: {
    headerText: {
      default: ''
    },
    pages: {
      default: []
    },
    listStyle: {
      default: 'grid'
    }
  },
  methods: {
    navigate (url) {
      if (url.startsWith('/page')) {
        this.showLoading()
        this.$router.push({path: url})
      } else {
        this.handleUrlNavigate(url)
      }
    }
  }
}
</script>

<style scoped></style>
