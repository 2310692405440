<template>
    <div  :class="componentClasses">
      <icon-warning size="sm1" class="gc-alert-injury__icon"/>
      <text-content :weight="'medium'" :lineHeight="'multi'" :size="'sm1'">

        <strong>{{ name }} </strong>
        <a v-html="description"></a>
      </text-content>
    </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import IconWarning from '../../root/icons/IconWarning'

export default {
  name: 'AlertInjury',
  components: {
    IconWarning,
    TextContent
  },
  props: {
    name: {
      default: []
    },
    description: {
      default: []
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-alert-injury': true
      }
    }
  },
  methods: {}
}
</script>
