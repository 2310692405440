<template>
  <div :class="componentClasses" v-on="$listeners">
    <text-body-extra-small class="gc-calender-slider-cal-day__month" >{{month}}</text-body-extra-small>
    <text-body-regular class="gc-calender-slider-cal-day__day" :weight="'extra-bold'" :line-height="'multi'">{{day}}</text-body-regular>
    <div v-if="isLogged === true" >
      <icon-dot
        class="gc-calender-slider-cal-day__logged"
      />
    </div>
  </div>
</template>

<script>
import TextContent from '../../../root/TextContent'
import IconDot from '../../../root/icons/IconDot'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import TextBodyRegular from '../../typography/TextBodyRegular'

export default {
  name: 'CalenderSliderCalDay',
  components: {
    TextBodyRegular,
    TextBodyExtraSmall,
    TextContent,
    IconDot
  },
  props:
    {
      isLogged: {
        default: false
      },
      isSelected: {
        default: false
      },
      month: {
        default: ''
      },
      day: {
        default: ''
      }
    },
  computed: {
    componentClasses: function () {
      return {
        'gc-calender-slider-cal-day': true,
        'gc-calender-slider-cal-day-selected': this.isSelected === true
      }
    }
  }
}
</script>

<style scoped>

</style>
