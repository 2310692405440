const theme= 2

const IconUpArrow = (theme) => (theme===1)?import('./IconUpArrow'):import('@/components/layout/template-2/root/icons/IconUpArrow')
const IconUpArrow2 = (theme) => (theme===1)?import('./IconUpArrow2'):import('@/components/layout/template-2/root/icons/IconUpArrow2')
const IconDownArrow = (theme) => (theme===1)?import('./IconDownArrow'):import('@/components/layout/template-2/root/icons/IconDownArrow')
const IconClose = (theme) =>(theme===1)?import('./IconClose'):import('@/components/layout/template-2/root/icons/IconClose')

const components = {
  IconUpArrow,
  IconDownArrow,
  IconClose,
  IconUpArrow2
};

export {
  IconUpArrow,
  IconDownArrow,
  IconClose,
  IconUpArrow2
}


// Export as a plugin to be installed globally
export default {
  install(Vue) {
    Object.keys(components).forEach(name => {
      Vue.component(name, components[name]);
    });
  }
};
