<template>
  <div
    :style="loaderStyles"
    :class="componentClasses"
    ref="loading"
    v-if="loadingState&&!isSkeletonAdded"
  ></div>
</template>
<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'page-loader',
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  computed: {
    loaderStyles: function(){
      return {
        backgroundImage: `url(${this.imagesUrl}loader.svg)`
      }
    },
    isSkeletonAdded: function () {
      return this.$route.fullPath.includes('workout') || this.$route.fullPath.includes('meal-plans') || this.$route.fullPath.includes('page')|| this.$route.fullPath.endsWith('dashboard')
    },
    componentClasses: function () {
      return ['preloader', ('preloader--'+this.pageType)]
    },
    ...mapState({
      loadingState: 'loading'
    }),
    ...mapGetters({
      pageType: 'pageStore/pageType'
    }),
  }
}
</script>
