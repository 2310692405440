<template>
    <div class="pc-meal-plan-shopping-list-skelton">
        <AnimatedPlaceholder width="300px" height="16px" />
        <div class="pc-input-group-ingredients">
            <div v-for="list in lists" :key="list" class="pc-input-shopping-list-group-ingredients-group pc-input-shopping-list-group-ingredients-group--hidden">
                <div class="rc-button rc-button--border-radius-sm1 rc-button--type-outline gc-button-secondary-accordion pc-input-shopping-list-group-ingredients-group__accordion">
                    <div class="gc-button-secondary-accordion__left-section">
                        <AnimatedPlaceholder class="gc-button-secondary-accordion__left-section--check" width="20px" height="20px" />
                        <AnimatedPlaceholder width="40px" height="20px" />
                    </div>
                    <div class="gc-button-secondary-accordion__right-section">
                        <AnimatedPlaceholder width="16px" height="26px" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AnimatedPlaceholder from '@/components/global/animators/AnimatedPlaceholder.vue'

export default {
    data () {
        return {
            lists: [1,2,3,4,5,6,7,8,9,10]
        }
    },
    components: {
        AnimatedPlaceholder
    }
}
</script>
<style>
.pc-meal-plan-shopping-list-skelton {
    .gc-button-secondary-accordion__left-section--check {
        margin-right: 15px;
    }
}
</style>