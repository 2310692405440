<template>
  <div
    class="gc-placeholder"
    v-on="$listeners"
    :style="{
      width: width || '',
      height: height || '20px',
      borderRadius: borderRadius || '4px',
    }"
  ></div>
</template>

<script>
export default {
  name: "gc-placeholder",
  props: {
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    borderRadius: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
