<template>
  <div class="pc-meal-skeleton">
    <div class="gc-card-meal-featured">
      <div class="gc-nutrition-details-bar__values-wrapper container">
        <div>
        <AnimatedPlaceholder class="gc-nutrition-details-bar__count" width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      <div>
        <AnimatedPlaceholder class="gc-nutrition-details-bar__count" width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      <div>
        <AnimatedPlaceholder class="gc-nutrition-details-bar__count" width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      <div>
        <AnimatedPlaceholder class="gc-nutrition-details-bar__count" width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      <div>
        <AnimatedPlaceholder class="gc-nutrition-details-bar__count" width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      </div>
      
    </div>
    <div class="container gc-meal__action-area">
      <div>
        <AnimatedPlaceholder width="64px" height="20px" />
      </div>
      <div>
        <AnimatedPlaceholder width="64px" height="20px" />
      </div>
    </div>
    <div class="container gc-page__container pc-meal-plan-meal__container">
      <div class="pc-accordion-recipe-tile pc-accordion-recipe-tile--with-sub-text">
        <div class="pc-accordion-recipe-tile__left-section">
          <div class="pc-accordion-recipe-tile__image">
            <AnimatedPlaceholder width="75px" height="75px" borderRadius="10px" />
          </div>
          <div class="pc-accordion-recipe-tile__text-section">
            <div class="pc-accordion-recipe-tile__text rc-text-content">
              <AnimatedPlaceholder width="150px" height="28px" />
            </div>
            <div class="pc-accordion-recipe-tile__sub-text rc-text-content">
              <AnimatedPlaceholder width="64px" height="12px" />
            </div>
          </div>
        </div>
      </div>
      <div class="pc-accordion-recipe-tile__separator"></div>
      <div class="gc-card-text">
        <div class="gc-card-text__header">
          <AnimatedPlaceholder width="120px" height="24px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="120px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="240px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="200px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="180px" height="10px" />
        </div>
      </div>
      <div class="gc-card-text">
        <div class="gc-card-text__header">
          <AnimatedPlaceholder width="120px" height="24px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="120px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="240px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="200px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="180px" height="10px" />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";

export default {
  components: {
    AnimatedPlaceholder,
  },
}
</script>
<style>
.pc-meal-skeleton {
  .gc-nutrition-details-bar__values-wrapper {
    .gc-nutrition-details-bar__count {
      margin-bottom: 8px;
    }
  }

  .pc-accordion-recipe-tile__text {
    margin-bottom: 12px;
  }

  .pc-accordion-recipe-tile  {
    margin-bottom: 10px;
  }

  .gc-card-text__header {
    display: flex;
    justify-content: center;
  }

  .gc-card-text__text {
    margin-bottom: 8px;
  }

  .gc-card-text {
    margin-bottom: 12px;
  }
} 
</style>
