<template>
  <div
    class="gc-page gc-page-form"
    v-bind="$attrs"
    v-on="$listeners">
    <grid-row class="p-0 m-0">
      <grid-col v-if="isDesktop" lg="6" class="p-0 m-0">
        <image-base :src="desktopBgImage" class="gc-page-form__image-side"/>
      </grid-col>
      <grid-col lg="6" class="p-0 m-0">
        <transition name="up-slide-fade" >
        <div class="gc-page__content" v-if="show">
          <slot/>
        </div>
        </transition>
      </grid-col>

    </grid-row>

  </div>
</template>

<script>
import HeaderPage from '../headers/HeaderPage'
import GridRow from '../grid/GridRow'
import GridCol from '../grid/GridCol'
import ImageBase from '../../layout/template-2/root/Image'
import {mapMutations} from "vuex";

export default {
  name: 'PageForm',
  props: {
    desktopImage: {
      default: ''
    },
    type:{
      type: String,
      default: 'secondary', // primary, secondary
    },
  },
  components: { ImageBase, GridCol, GridRow, HeaderPage },
  watch: {
    type: {
      deep: true,
      immediate: true,
      handler: function () {
        this.setPageType(this.type)
      }
    },
  },
  computed: {
    desktopBgImage: function () {
      let defaultImage = this.getStoreImage('progressCheckIn.start')
      if (this.$route.name === 'OnboardMain') {
        defaultImage = this.getStoreImage('onboard.startBackground')
      }
      return this.desktopImage || defaultImage
    }
  },
  data: function () {
    return {
      show: false
    }
  },
  beforeMount () {
    // this use for the page transition
    this.show = !this.isDesktop
  },
  mounted () {
    setTimeout(() => {
      // this use for the page transition
      this.show = true
      this.$emit('ready')
    }, 20)
    $('#app:has(.gc-page)').addClass('gc-app')
  },
  methods: {
    ...mapMutations({
      setPageType : 'pageStore/setPageType'
    })
  }
}
</script>

<style scoped>

</style>
