import SideMenu from '../components/global/navigators/SideBar'
import PwaPopup from '../components/layout/template-1/dashboard/widgets/PWAPopUp'
import BaseComponent from '../components/layout/template-1/global/base/BaseComponent'
import {mapGetters, mapMutations} from 'vuex'
import FirstLoadService from '../services/FirstLoadService'
import DefineInclude from '../DefineInclude'
import {checkNativeAppVersion} from '../includes/NativeAppCommon'
import {isSafari, isIOS, isMobile, isAndroid, isChrome} from 'mobile-device-detect'
import headerEventBus from '../event-buses/headerEventBus'
import DashboardService from '../services/DashboardService'
import {checkIsMember} from '../helpers/user'

export default {
  components: {
    SideMenu,
    PwaPopup
  },
  props: {
    options: {required: true},
    modifiers: {
      required: false,
      default: function () {
        return []
      }
    },
    isTransparent: {
      default: false
    },
    isTopFixed: {
      default: false
    },
    isFixed: {
      default: false
    },
    mainTitle: {
      default: ''
    },
    subTitle: {
      default: ''
    }
  },
  extends: BaseComponent,
  data: function () {
    return {
      navClasses: [],
      rightIcons: [],
      profileImage: DefineInclude.imagesUrl + 'default-profile.png',
      showShoppingListEmailPopup: false,
      rightIconActive: false,
      pwaPopup: {
        visible: false
      },
      isCommunityEnabled: false
    }
  },
  watch: {
    options: {
      handler (val) {
        this.setRightBlink()
      },
      deep: true
    },

    'options.right': function (val) {
      this.setRightIcons()
    },
    instructionsPopupVisibility (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.hideInstructionsPopup()
      }
    }
  },
  mounted () {
    this.setRightIcons()
    let auth = this.$route.meta.requiresAuth
    if (auth) {
      this.getUserData()
      let dashboardService = new DashboardService()
      dashboardService.getUserConfig().then(() => {
        this.isCommunityEnabled = dashboardService.isCommunityEnabled()
      })
    }
  },
  updated () {
    if ($('.app-background-color').length > 0 && $('.app-nav--with-bg-color').length === 0) {
      const background = window.getComputedStyle($('.app-background-color')[0])
      const elements = $('.app-nav .app-nav__left button img, .app-nav .app-nav__right button img , .app-nav .app-nav__left a img ')
      if (this.lightOrDark(background.getPropertyValue('background-color'))) {
        elements.css('filter', 'invert(1)')
      } else {
        elements.css('filter', 'invert(0)')
      }
    }
  },
  computed: {
    ...mapGetters({
      instructionsPopupVisibility: 'instructionPopupStore/visible',
      instructionsPopupDescription: 'instructionPopupStore/instructions',
      instructionsPopupNotes: 'instructionPopupStore/notes',
      instructionsPopupFirstTimeViewKey: 'instructionPopupStore/firstTimeViewKey',
      notificationBarSettings: 'pageStore/notificationBarSettings',
      previousRoute: 'getPreviousRoute',
      unreadCommunityNotificationsExist: 'communityStore/isUnreadNotificationsExist'
    }),
    notificationBarShow () {
      return (this.notificationBarSettings.show && this.notificationCount > 0 && !(typeof this.params.page !== 'undefined' && this.params.page === 'notifications'))
    },
    notificationCount () {
      return this.$store.getters.getNotificationCount
    },
    notificationList () {
      return this.$store.getters.getNotificationList
    }
  },
  methods: {
    ...mapMutations({
      setInstructionPopupFirstTimeViewed: 'instructionPopupStore/setFirstTimeViewed',
      backButtonClicked: 'pageStore/backButtonClicked',
      addButtonClicked: 'pageStore/addButtonClicked',
      editButtonClicked: 'pageStore/editButtonClicked',
      closeButtonClicked: 'pageStore/closeButtonClicked',
      plusIconClicked: 'pageStore/addButtonClicked',
      messageButtonClicked: 'pageStore/messageButtonClicked',
      infoIconClicked: 'pageStore/infoButtonClicked'
    }),
    showHelpBulb () {
      return this.rightIcons.includes('help') &&
        (this.checkIsNotEmptyTextArray(this.instructionsPopupDescription) || this.checkIsNotEmptyTextArray(this.instructionsPopupNotes))
    },
    setRightIcons () {
      if (typeof this.options.right !== 'undefined') {
        this.rightIcons = this.options.right.split('|')
      }
    },
    infoButtonClicked () {
      this.infoIconClicked()
      headerEventBus.$emit('info-button-click')
    },
    addButtonClicked () {
      this.plusIconClicked()
      headerEventBus.$emit('add-button-click')
    },
    closeButtonClicked () {
      headerEventBus.$emit('close-button-click')
    },
    sendButtonClick () {
      headerEventBus.$emit('send-button-click')
    },
    editButtonClick () {
      headerEventBus.$emit('edit-button-click')
    },
    markReadButtonClick () {
      headerEventBus.$emit('read-all-button-click')
    },
    closeButtonClick () {
      headerEventBus.$emit('close-button-click')
    },
    subTitleClicked () {
      headerEventBus.$emit('sub-title-click')
    },
    setRightBlink () {
      if (this.options.rightBlink) {
        const rightBlinkInterval = setInterval(() => {
          if (this.rightIconActive) {
            this.rightIconActive = false
          } else {
            this.rightIconActive = true
          }

          if (!this.options.rightBlink && !this.rightIconActive) {
            clearInterval(rightBlinkInterval)
          }
        }, 500)
        setTimeout(() => {
          this.options.rightBlink = false
        }, 2000)
      }
    },
    hideInstructionsPopup () {
      const firstLoadService = new FirstLoadService()
      if (firstLoadService.getFirstTimeStatus(this.instructionsPopupFirstTimeViewKey)) {
        this.setInstructionPopupFirstTimeViewed()
        headerEventBus.$emit('instruction-popup-viewed', {
          firstTimeKey: this.instructionsPopupFirstTimeViewKey,
          firstTime: true
        })
        this.setRightBlink()
      }
    },
    previous (type = '') {
      if (type === 'emit') {
        headerEventBus.$emit('back-button-click')
        this.backButtonClicked()
      } else {
        this.showLoading()
        if (this.$router.currentRoute.name !== 'ExternalPageMain') {
          this.$router.go(-1)
        } else {
          this.$router.push(this.previousRoute)
        }
      }
    },
    showHelpPopup () {
      this.viewInstructionsPopup()
      headerEventBus.$emit('info-bulb-button-click')
      headerEventBus.$emit('instruction-popup-viewed', {})
    },
    showMenu () {
      // TODO - add menu show hide after menu is ready
    },
    getUserData () {
      if (!checkIsMember()) {
        return
      }
      let service = new DashboardService()
      service.getUserData().then(data => {
        this.userName = data.first_name
        this.profileImage = data.image || DefineInclude.imagesUrl + 'default-profile.png'
        this.fitnessDiaryEnabled = data.subscriptions.some(subscription => {
          return subscription.status !== 'pending-data'
        })
      })
    },

    doNavRightAction () {
      // do nothing
    },
    goToNotificationPage () {
      this.delayedRouterPush({
        path: DefineInclude.appUrlList.dashboard,
        query: {page: 'notifications', tab: 'other'}
      })
    },
    goToDashboard () {
      const path = '/dashboard'
      if (!('page' in this.$route.query) && this.$route.fullPath.includes(path)) {
        window.scrollTo({top: 0, behavior: 'smooth'})
        return
      }

      this.delayedRouterPush({
        path: DefineInclude.appUrlList.dashboard
      })
    },
    isAndroidPWA () {
      return ['fullscreen', 'standalone', 'minimal-ui'].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      )
    },
    goToProfilePage () {
      this.delayedRouterPush({
        path: DefineInclude.appUrlList.profile
      })
    },
    showPwaPopup () {
      if (checkNativeAppVersion()) {
        return false
      }
      // ios check
      const isIosDevice = isIOS && isMobile && isSafari
      // android PWA check
      const isAndroidDevice = isAndroid && isMobile && isChrome && !this.isAndroidPWA()

      // pwa check
      const isInStandaloneMode = () =>
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone ||
        document.referrer.includes('android-app://')
      const manifest = document.querySelector('link[rel=\'manifest\']')

      return manifest && !isInStandaloneMode() && (isIosDevice || isAndroidDevice)
    },
    navigate (path) {
      if (path !== this.$router.currentRoute.fullPath) {
        // holding router so slick dom breaking is not visible before fade effect
        this.delayedRouterPush({
          path: path
        })
      }
    }
  }
}
