<template>
  <div
    :class="componentClasses"
    ref="page"
    v-bind="$attrs"
    v-on="$listeners">

      <navigator-side-menu v-if="showSideBar" class="gc-page-secondary__navigator-side-menu "/>

        <header-page

          v-if="header.show"
          :options="header"
          :is-transparent="header.isTransparent"
          :is-fixed="header.isFixed"
          :modifiers="header.modifiers"
          :main-title="mainTitle"
          :sub-title="subTitle"
          @height-updated="headerUpdated"/>
        <div
          class="gc-page__content"
          :style="contentAreaStyles">
          <slot/>
        </div>
  </div>
</template>

<script>
import HeaderPage from '../headers/HeaderPage'
import pageMixin from '../../../mixins/component-mixins/global/page/pageMixin'
import NavigatorSideMenu from '../navigators/NavigatorSideMenu'
import GridRow from '../grid/GridRow'
import GridCol from '../grid/GridCol'
import {mapMutations} from "vuex";

export default {
  name: 'PageSecondary',
  mixins: [pageMixin],
  props: {
    desktopSideBar: {
      default: true
    },
    /**
     * header component props
     * refer header component prop
     * sample structure
     *  @example
     *  {
     *    isTransparent: false,
     *    isFixed: false,
     *    show: true,
     *    left: 'previous',
     *    right: 'message',
     *    rightBlink: false,
     *    modifiers: [],
     *    mainTitle: 'Day 1',
     *    subTitle: ''
     *   }
     */
    type:{
      type: String,
      default: 'secondary', // primary, secondary
    },
    header: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      mainTitle: '',
      subTitle: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-page': true,
        'gc-page-secondary': true,
        'gc-page--with-side-bar': this.showSideBar
      }
    },
    showSideBar: function () {
      let auth = this.$route.meta.requiresAuth
      return this.desktopSideBar && this.isDesktop && auth
    }
  },
  components: { GridCol, GridRow, HeaderPage, NavigatorSideMenu },
  watch: {
    type: {
      deep: true,
      immediate: true,
      handler: function () {
        this.setPageType(this.type)
        document.body.style.backgroundColor = this.type === 'primary' ?  'var(--bg-homePages)': 'var(--bg-infoPages)'
        document.documentElement.backgroundColor = this.type === 'primary' ?  'var(--bg-homePages)': 'var(--bg-infoPages)'


      }
    },
    header: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.header && this.header.mainTitle) {
          this.mainTitle = this.header.mainTitle
        } else if (this.header && this.header.header && this.header.header.mainTitle) {
          this.mainTitle = this.header.header.mainTitle
        }

        if (this.header && this.header.subTitle) {
          this.subTitle = this.header.subTitle
        } else if (this.header && this.header.header && this.header.header.subTitle) {
          this.subTitle = this.header.header.subTitle
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setPageType : 'pageStore/setPageType'
    })
  }

}
</script>

<style scoped>

</style>
