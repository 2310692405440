<template>
  <popup
    v-if="visible"
    ref="textToMealModal"
    :visible="visible"
    :error="error"
    :state="state"
    modal-class="pc-popup-text-to-meal"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close @hide="closePopup" />
    </popup-title>
    <popup-body>
      <popup-head class="text-center">
        <popup-text-heading
          v-if="state==='init'"
          class="pc-popup-text-to-meal__heading"
        >
          {{ $t('message["food-diary.smart-add"]') }}
        </popup-text-heading>
        <popup-text-heading v-if="state==='submitting'">
          {{ $t('message["food-diary.doing-the-magic"]') }}
        </popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body
          v-if="state==='init'"
          class="text-center"
        >
          <div class="pc-popup-text-to-meal__description">
            {{ $t('message["food-diary.smart-add-description"]') }}
          </div>
        </popup-text-body>

        <text-area
          v-if="state==='init'"
          v-model="mealIngredients"
          :placeholder="$t('message[\'food-diary.smart-add-input-placeholder\']')"
          :errorMessage="error"
        />

        <div v-if="state==='submitting'">
          <loading-buffer height="100" />
        </div>
      </popup-content>
      <popup-floating-footer v-if="state==='init'">
        <button-primary
          :text="$t('message[\'general.generate\']')"
          :disabled="!mealIngredients"
          @click="generate()"
        />
      </popup-floating-footer>
    </popup-body>
  </popup>
</template>

<script>
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import BaseComponent from '../../global/base/BaseComponent'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import FoodDiaryService from '../../../../../services/FoodDiaryService'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupTextSubHeading from '../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import PopupFloatingFooter from '../../../../global/popups/popup-sub-components/PopupFloatingFooter'
import TextArea from '../../../../global/inputs/TextArea'
import ChipPrimary from '../../../../global/chips/ChipPrimary'
import { error } from 'jquery'
// TODO - lang
export default {
  name: 'PopupTextToMeal',
  components: {
    PopupFloatingFooter,
    IconCompleteFilled,
    PopupTextSubHeading,
    ButtonPrimary,
    PopupTextBody,
    PopupContent,
    PopupTextHeading,
    PopupHead,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    LoadingBuffer,
    TextArea,
    ChipPrimary
  },
  extends: BaseComponent,
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    error: {
      required: false,
      type: String
    },
    state: {
      required: true,
      type: String
    }
  },
  data: function () {
    return {
      mealIngredients: ''
    }
  },
  watch: {
    mealIngredients(newVal, oldVal) {
      if (this.error && newVal !== oldVal) {
        this.clearError();
      }
    }
  },
  methods: {
    generate () {
      this.$emit('generateMeal', this.mealIngredients)
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    },
    clearError() {
      this.$emit('clearError')
    }

  }
}
</script>
