import Vue from 'vue'
import Vuex from 'vuex'

import PeriodTrackerService from '../../services/period-tracker/PeriodTrackerService'
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    hasError: false,
    service: new PeriodTrackerService(),
    firstRecord: {},
    periodLogs: [],
    prediction: {},
    isPageInit: false
  },
  mutations: {
    setFirstRecord (state, payload) {
      state.firstRecord = payload
    },
    setPeriodLogs (state, payload) {
      payload.forEach(element => {
        state.periodLogs.push(element)
      })
    },
    setPrediction (state, payload) {
      state.prediction = payload
    },
    changePageInitStatus (state, payload) {
      state.isPageInit = payload
    }
  },
  actions: {
    async pageInit ({commit, state}) {
      // loading details
      await Promise.all([
        state.service
          .setFirstRecord(),
        state.service
          .setHistory(),
        state.service
          .setPrediction()
      ])
        .then(() => {
          commit('setFirstRecord', state.service.getFirstRecord())
          commit('setPeriodLogs', state.service.getHistory())
          commit('setPrediction', state.service.getPrediction())
          commit('changePageInitStatus', true)
        }).catch(err => {
          let sentryEventID = APIErrorHandler.logSentry(err)
          APIErrorHandler.showErrorPopup(sentryEventID, 'period-tracker')
          commit('changePageInitStatus', false)
        })
    }
  },
  getters: {
    getService (state) {
      return state.service
    },
    getFirstRecord (state) {
      return state.firstRecord
    },

    getPeriodLogs (state) {
      return state.periodLogs
    },

    getPrediction (state) {
      return state.prediction
    },

    getPageInitStatus (state) {
      return state.isPageInit
    }
  }
}
