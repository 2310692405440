import ProfileApi from '@/services/api/profile/ProfileApi'
import DeviceAPI from '@/services/api/creator/DeviceAPI'
import {checkIsCreator, checkIsMember} from '@/helpers/user'
import axios from "axios";

export default class PushNotificationsService {
  instance =null
  groupList = []
  constructor() {
    if (!PushNotificationsService.instance) {
      PushNotificationsService.instance = this
    }
    return PushNotificationsService.instance
  }


  registerDevice(subscriptionID) {
    console.log(`registering: ${subscriptionID}`)
    const payload = {
      subscription_id: subscriptionID
    }
    const savedSubId = localStorage.getItem('pushNotificationSubscriptionId')
    if (savedSubId === subscriptionID) {
      return
    }
    if (checkIsMember()) {
      console.log(`registering member: ${subscriptionID}`)
      return this.registerMemberDevice(payload)
    } else {
      console.log(`registering creator: ${subscriptionID}`)
      return this.registerCreatorDevice(payload)
    }

  }

  registerMemberDevice(payload) {
    const profile = new ProfileApi()
    return profile.addPushNotificationDevices(payload).then(res => {
      localStorage.setItem('pushNotificationSubscriptionId', payload.subscription_id)
      return res.data
    })
  }

  registerCreatorDevice(payload) {
    const profile = new DeviceAPI()
    return profile.post(payload).then(res => {
      localStorage.setItem('pushNotificationSubscriptionId', payload.subscription_id)

      return res.data
    })
  }


  getNotificationGroups() {

    if(this.groupList && this.groupList.length > 0) {
      return new Promise((resolve) => {
        resolve(this.groupList)
      })

    }

    const profile = new ProfileApi()
    return profile.getNotificationGroups().then(res => {
      this.groupList = res.data
      return res.data
    })
  }

  subscribeToGroup(groupId) {
    const profile = new ProfileApi()
    return profile.subscribeNotificationGroups(groupId).then(res => {
      return res.data
    })

  }

  unsubscribeFromGroup(groupId) {
    const profile = new ProfileApi()
    return profile.unsubscribeNotificationGroups(groupId).then(res => {
      return res.data
    })

  }

}
