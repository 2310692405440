<template>
    <div class="gc-card-habits-list-skeleton">
        <div class="gc-card-habits-list__wrapper">
            <div class="gc-card-habits-list__header-wrapper">
                <div class="gc-card-habits-list__header-wrapper-left-section">
                    <div class="gc-card-habits-list__header-text">
                        <AnimatedPlaceholder width="114px" />
                    </div>
                    <div class="gc-card-habits-list__header-description">
                        <AnimatedPlaceholder width="160px" height="12px" />
                    </div>
                </div>
                <div class="gc-card-habits-list__header-wrapper-right-section">
                    <AnimatedPlaceholder width="10px" height="20px" />
                </div>
            </div>
            <div class="gc-card-habits-list__wrapper-content">
                <div v-for="item in [1, 2, 3, 4, 5]" :key="item" class="gc-card-habits-list__wrapper-list-item">
                    <div class="gc-card-habits-list__wrapper-list-title">
                        <AnimatedPlaceholder width="160px" height="14px" />
                    </div>
                    <div class="gc-card-habits-list__wrapper-list-icons">
                        <AnimatedPlaceholder class="gc-card-habits-list__wrapper-list-icons--left" width="24px" height="24px" borderRadius="50px" />
                        <AnimatedPlaceholder width="24px" height="24px" borderRadius="50px" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AnimatedPlaceholder from '../animators/AnimatedPlaceholder.vue';

export default {
    components: {
        AnimatedPlaceholder
    }
}
</script>
<style>


</style>
