import Vue from 'vue'
import Vuex from 'vuex'
import NutritionService from "@/services/nutrition/NutritionService";
import WorkoutProgramsService from "@/services/WorkoutProgramsService";
import DashboardService from "@/services/DashboardService";

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    challengeWorkoutPlans: null,
    challengeMealPlans: null,
    challengeProductPages: null,
    productPlans: {page: null},
    notifications: null,
    workouts: null,
    mealPlanNotAChallenge: null,
  },
  mutations: {
    setAllMealPlans(state, mealPlans) {
      state.allMealPlans = mealPlans
    },
    setChallengeMealPlans(state, challengeMealPlans) {
      state.challengeMealPlans = challengeMealPlans
    },
    setMealPlanOptions(state, mealPlanOptions) {
      state.mealPlanOptions = mealPlanOptions
    },
    setMealPlanNotAChallenge(state, mealPlanNotAChallenge) {
      state.mealPlanNotAChallenge = mealPlanNotAChallenge
    },
    setChallengeWorkoutPlans(state, challengeWorkoutPlans) {
      state.challengeWorkoutPlans = challengeWorkoutPlans
    },
    setWorkouts(state, workouts) {
      state.workouts = workouts
    },
    setProductPlansPages(state, productPlansPages) {
      state.productPlans.pages = productPlansPages
    },
    setChallengeProductPages(state, challengeProductPages) {
      state.challengeProductPages = challengeProductPages
    }
  },
  actions: {
    //load mealPlans
    async loadMealPlans({commit}) {
      let mealService = new NutritionService()
      await mealService.getMealPlansFromAPI()

      commit('setAllMealPlans', mealService.getActiveMealPlans())
      commit('setChallengeMealPlans', mealService.getChallengePlans())
      commit('setMealPlanOptions', mealService.getActiveMealPlans())
      commit('setMealPlanNotAChallenge', mealService.getNotChallengePlans())
    },
    // load workout plans
    async loadWorkoutPlans({commit}) {
      let workoutProgramsService = new WorkoutProgramsService()
      await workoutProgramsService.getWorkoutProgramListFromAPI()

      commit('setChallengeWorkoutPlans', workoutProgramsService.getChallengeWorkoutPrograms())
      commit('setWorkouts', workoutProgramsService.workoutPrograms)
    },
    // load product plans
    async loadProductPlans({commit}) {
      const service = new DashboardService()
      await service.getPageData()
      commit('setProductPlansPages', service.getProductPages())
      commit('setChallengeProductPages', service.getChallengeProductPages())
    },
  },
  getters: {
    allMealPlans: state => state.mealPlanNotAChallenge,
    challengeMealPlans: state => state.challengeMealPlans,
    mealPlanOptions: state => state.mealPlanNotAChallenge,
    mealPlanNotAChallenge: state => state.mealPlanNotAChallenge,
    challengeWorkoutPlans: state => state.challengeWorkoutPlans,
    workouts: state => state.workouts,
    productPlans: state => state.productPlans,
    challengeProductPages: state => state.challengeProductPages
  }
}
