/**
 * check is supported mobile App version
 * @returns {boolean}
 */
export function checkNativeAppVersion () {
  const userAgent = navigator.userAgent
  return (userAgent.indexOf('MacroActiveMobileApp/2.1.0') !== -1)
}

/**
 * check is mobile APP
 * @returns {boolean}
 */
export function isMobileNativeApp () {
  const userAgent = navigator.userAgent
  return (userAgent.indexOf('MacroActiveMobileApp') !== -1)
}

/**
 * @deprecated don't use
 */

export function checkSpecialUserTag () {
  if (typeof $('meta[name=ma-reviewer-mode]').attr('content') !== 'undefined') {
    if ($('meta[name=ma-reviewer-mode]').attr('content') === 'false') {
      return false
    } else if ($('meta[name=ma-reviewer-mode]').attr('content') === false) {
      return false
    }
  }
  return true
}

export function checkIsPWA () {
  return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://') || ['fullscreen', 'standalone', 'minimal-ui'].some(
    (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
  )
}
