<template>
  <text-content
  :size= "'md4'"
  :weight= "'medium'"
  :lineHeight= this.lineHeight
  :decoration= this.decoration>
    <slot></slot>
  </text-content>
</template>

<script>
import TextContent from '../../root/TextContent'

export default {
  name: 'text-body-large',
  components: {
    TextContent
  },
  props: {
    decoration: {
      default: ' '
    },
    lineHeight: {
      default: 'single'
    }
  }
}
</script>

<style scoped></style>
