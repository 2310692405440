import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'

export default class extends BaseAPI {
  _nutritionAnalysisBaseUrl = ''

  constructor () {
    super()
    this._nutritionAnalysisBaseUrl = DefineInclude.nutritionAnalysisAPIURL
  }

  getNutritionAnalysis (payload) {
    return axios.post(this._nutritionAnalysisBaseUrl + 'analyze', payload, { headers: this._getPostHeaders })
  }
}
