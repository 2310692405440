<template>
  <grid-row :class="actionButtonsClasses">
    <grid-col
        v-for="(notification, id) in notifications"
        v-if="
                showNotification &&
                !notification.action.url.includes(
                  'api/notifications/subscription/'
                )
              "
        v-bind:key="'dashboard-notification' + id"
        :lg="notifications.length === 1 ? 12 : 6"
    >
      <button-progress-update
          :text="notification.message"
          :action="notification.action"
      />
    </grid-col>
  </grid-row>
</template>
<script>
import ButtonProgressUpdate from './page-components/ButtonProgressUpdate'
import GridCol from '../../../global/grid/GridCol'
import GridRow from '../../../global/grid/GridRow'

export default {
  name: 'ListProgressNotifications',
  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    showNotification: {
      type: Boolean,
      default: true
    },
    actionButtonsClasses: {
      type: Object,
      default: () => {
        return { 'pc-dashboard__action-buttons': true }
      }
    }
  },
  components: {
    ButtonProgressUpdate,
    GridCol,
    GridRow
  }
}
</script>
