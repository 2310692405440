<template>
  <div
    class="pc-dashboard-skeleton"
    ref="loading"
    v-if="loadingState && !isSkeletonAdded"
  >
    <div class="pc-dashboard-skeleton-wrap">
      <div class="pc-dashboard-skeleton-desktop">
        <AnimatedPlaceholder width="100%" height="100vh" borderRadius="0" />
      </div>
      <div class="pc-dashboard-skeleton-desktop-mobile">
        <div class="pc-dashboard-skeleton__banner-section">
          <AnimatedPlaceholder width="100%" height="300px" />
        </div>
        <div class="container">
          <div class="pc-dashboard-skeleton__first-section">
            <AnimatedPlaceholder width="100%" height="20px" />
            <AnimatedPlaceholder width="100%" height="20px" />
            <AnimatedPlaceholder width="100%" height="20px" />
            <AnimatedPlaceholder width="100%" height="20px" />
            <AnimatedPlaceholder width="40%" height="20px" />
          </div>
          <div class="pc-dashboard-skeleton__second-section">
            <AnimatedPlaceholder
              width="100%"
              height="160px"
              borderRadius="10px"
            />
          </div>
          <div class="pc-dashboard-skeleton__second-section">
            <AnimatedPlaceholder
              width="100%"
              height="160px"
              borderRadius="10px"
            />
          </div>
          <div class="pc-dashboard-skeleton__second-section">
            <AnimatedPlaceholder
              width="100%"
              height="160px"
              borderRadius="10px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AnimatedPlaceholder from "../animators/AnimatedPlaceholder";

export default {
  name: "page-loader",
  components: { AnimatedPlaceholder },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl,
    };
  },
  computed: {
    isSkeletonAdded: function () {
      return (
        this.$route.fullPath.includes("workout") ||
        this.$route.fullPath.includes("meal-plans") ||
        this.$route.fullPath.includes("page")
      );
    },
    componentClasses: function () {
      return ["preloader", "preloader--" + this.pageType];
    },
    ...mapState({
      loadingState: "loggingLoading",
    }),
    ...mapGetters({
      pageType: "pageStore/pageType",
    }),
  },
};
</script>
