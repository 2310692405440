import axios from 'axios'
import DefineInclude from '../../DefineInclude'
import APIErrorHandler from './APIErrorHandler'
import AuthService from '../AuthService'

export default class APIHandler {
  static initAxiosConfig () {
    APIHandler.initAPIErrorConfig()
    APIHandler.initAuthHeaderConfig()
  }

  static initAPIErrorConfig () {
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      return response
    }, async function (error) {
      const originalRequest = error.config
      if (error && error.response && error.response.status && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        let auth = new AuthService()

        let token = await auth.getTokenFormRefreshToken()
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        originalRequest.headers['Authorization'] = 'Bearer ' + token
        const axiosApiInstance = axios.create()
        return axiosApiInstance(originalRequest)
      }

      if (error.config.url.includes(DefineInclude.apiUrl) || error.config.url.includes(DefineInclude.apiV2Url)) {
        APIErrorHandler.handleError(error.response)
      }
      return Promise.reject(error)
    })
  }

  static initAuthHeaderConfig () {

    // Add a request interceptor
    axios.interceptors.request.use(
      config => {

        const auth = new AuthService()
        if(auth.isCreator()){
          const url = new URL(config.url)
          const urls = [
            DefineInclude.apiUrl+'meal-plans',
            DefineInclude.apiUrl+'workout-programs',
            DefineInclude.apiUrl+'me/meal-preference',
            DefineInclude.apiUrl+'diary',
            DefineInclude.habitsTrackerAPIUrl,
            DefineInclude.periodTrackerAPIUrl,
            DefineInclude.foodDiaryAPIURL
          ];

          const myHost = url.host;
          const myHostPathname = url.pathname;

          const isHostUsed = urls.some(url => {
            try {
              const urlObj = new URL(url);
              return urlObj.host === myHost && myHostPathname.startsWith(urlObj.pathname)
            } catch (e) {
              return false;
            }
          });

          if(isHostUsed &&!myHostPathname.includes('community')){
           config.url = config.url.replace(url.origin,'https://platform-mock-api.macroactivemvp.com')
           /// config.url = config.url.replace(url.origin,'http://127.0.0.1:8000')
          }
        }
        const token = localStorage.getItem('authToken')
        if (token) {
          config.headers.Authorization = 'Bearer ' + token
        }
        if(!config.headers['Content-Type']){
          config.headers['Content-Type'] = 'application/json'
        }

        if (!window.navigator.onLine) {
          if (config.method === 'get') {
            config.cancelToken = true
            APIErrorHandler.handleNoConnection()
          }
        }
        return config
      },
      error => {
        return Promise.reject(error)
      })
  }
}
