import ContentPageApi from './api/content-page/ContentPageApi'
import ContentPageFactoryService from './ContentPageFactoryService'
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";

export default class ContentPageService {
  data = {
    listItems: {},
    headerImage: ''
  }

  pageData = {}
  _pageName = ''
  _type = ''
  constructor (pageName, type='custom-page') {
    this._pageName = pageName
    this._type = type
  }

  getPageType(){
    return this._type

  }
  getPageData(){
    return this.pageData
  }
  setPageData () {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.pageData).length !== 0) {
        resolve()
      } else {
        const contentPageApi = new ContentPageApi(this._pageName)

        contentPageApi.getList([]).then((data) => {
          let rootItems = []
          let subItems = []
          this.pageData = data.data
          this.data.headerImage = data.data.page.header_image

          // setting list items
          if (typeof data.data.root_items !== 'undefined') {
            data.data.root_items.forEach(function (rootItemVal, index, array) {
              rootItems[rootItemVal.id] = {
                id: rootItemVal.id,
                name: rootItemVal.name,
                sequence: rootItemVal.sequence,
                content: rootItemVal.content,
                subItems: []
              }

              rootItemVal.sub_items.forEach(function (subItemVal, index, array) {
                subItems[subItemVal.id] = {
                  id: subItemVal.id,
                  name: subItemVal.name,
                  sequence: subItemVal.sequence,
                  content: subItemVal.content
                }
              })

              rootItems[rootItemVal.id].subItems = Object.assign({}, subItems)
              subItems = []
            })
          }

          this.data.listItems = Object.assign({}, rootItems)
          rootItems = []
          const factory = new ContentPageFactoryService()
          factory.setPage(this._pageName, this)
          resolve()
        }).catch(err => {
          let sentryEventID = APIErrorHandler.logSentry(err)
          APIErrorHandler.showErrorPopup(sentryEventID, 'content-page')
          reject(err)
        })
      }
    })
  }

  getListData () {
    return {
      items: this.data.listItems,
      headerImage: this.data.headerImage
    }
  }

  getDetails (rootItemID, subItemID) {
    const rootItem = Object.values(this.data.listItems).find(item => {
      return parseInt(rootItemID) === item.id
    })
    const subItemObj = Object.values(rootItem.subItems).find(item => {
      return parseInt(subItemID) === item.id
    })
    return {
      title: subItemObj.name,
      content: subItemObj.content
    }
  }
}
