<template>
    <div class="pc-card-apple-in-app">
        <div class="pc-card-apple-in-app__icon">
          <icon-payment-apple-in-app size="sm4"></icon-payment-apple-in-app>
        </div>
        <div class="pc-card-apple-in-app__text">
          <text-body-small line-height="lh-lg" weight="extra-bold">{{$t('message["billing-info.apple-pay-header"]')}}</text-body-small>
          <text-body-extra-small v-if="isNativeApp"  line-height="lh-lg" >{{$t('message["billing-info.apple-pay-description"]')}}</text-body-extra-small>
        </div>
    </div>
</template>

<script>
import IconPaymentAppleInApp from '../../../../../root/icons/IconPaymentAppleInApp'
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import TextBodyExtraSmall from '../../../../../global/typography/TextBodyExtraSmall'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
export default {
  name: 'CardAppleInApp',
  components: { TextBodyExtraSmall, TextBodySmall, IconPaymentAppleInApp },
  data: function () {
    return {
      isNativeApp : false
    }
  },
  mounted () {
    this.isNativeApp = isMobileNativeApp()
  }
}
</script>

<style scoped>

</style>
