<template>
  <chip :class="componentClasses">
    <slot />
  </chip>
</template>

<script>
import Chip from '../../root/Chip'

export default {
  name: 'ChipPrimary',
  components: {
    Chip
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-chip-primary': true
      }
    }
  }
}
</script>

<style scoped></style>
