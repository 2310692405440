<template>
  <div
    v-if="getExerciseDataStatus === 'loaded'"
    class="pc-container-single-exercise"
  >
    <page-container-fluid>
      <grid-row no-gutters>
        <grid-col cols="12" lg="6">
          <video-player
            v-if="videoOptions.url"
            v-bind="videoOptions"
            class="pc-container-single-exercise__video"
            @fullScreen="setFullscreen"
            @image-loading-error="setVideoThumbToImage()"
          >
            <div slot="left" v-if="!fullScreen">
              <div class="pc-container-single-exercise__video-sets">
                <text-body-extra-small
                  weight="extra-bold"
                  class="pc-container-single-exercise__video-text-sets"
                  >{{ setsText }}
                </text-body-extra-small>
                <icon-info
                  class="pc-container-single-exercise__sets-info-icon"
                  :size="'sm2'"
                  v-if="
                    exerciseData &&
                    exerciseData.isSetHavingFailureSetOrRepRanges()
                  "
                  @click="iconInfoClicked"
                />
              </div>
              <text-body-extra-small
                weight="extra-bold"
                class="pc-container-single-exercise__video-text-tempo"
                v-if="exerciseData && exerciseData.getTempo()"
                >{{ $t("message['workouts.tempo']") }}
                {{ exerciseData.getTempo() }}
              </text-body-extra-small>
              <badge-important
                v-if="showSetsArea"
                class="pc-container-single-exercise__video-set-type"
              >
                <text-body-extra-small
                  line-height="lh-lg"
                  weight="extra-bold"
                  >{{ this.setType }}</text-body-extra-small
                >
                <!--            <icon-question-rounded size="sm2" v-if="showSetInstructionButton" @click="showSets()"></icon-question-rounded>-->
              </badge-important>
            </div>
            <div slot="right" v-if="!fullScreen">
              <icon-swap
                v-if="exerciseSwapEnabled"
                size="md1"
                class="pc-container-single-exercise__video-icon-swap"
                @click="showExerciseSwapPopup"
              />
            </div>
          </video-player>

          <div
            class="pc-container-single-exercise__content-image"
            v-bind:style="cardImageStyle"
            style="overflow: visible"
            v-if="cardComponent === ''"
          >
            <img
              :src="videoImage"
              class="pc-container-single-exercise__image-img"
              alt=""
            />
            <div class="pc-container-single-exercise__overlay"></div>
            <div
              class="pc-container-single-exercise__left-area"
              v-if="!fullScreen"
            >
              <div class="pc-container-single-exercise__video-sets">
                <text-body-extra-small
                  weight="extra-bold"
                  class="pc-container-single-exercise__video-text-sets"
                  >{{ setsText }}
                </text-body-extra-small>
                <icon-info
                  class="pc-container-single-exercise__sets-info-icon"
                  :size="'sm2'"
                  v-if="
                    exerciseData &&
                    exerciseData.isSetHavingFailureSetOrRepRanges()
                  "
                  @click="iconInfoClicked"
                />
              </div>
              <text-body-extra-small
                weight="extra-bold"
                class="pc-container-single-exercise__image-text-tempo"
                v-if="exerciseData && exerciseData.getTempo()"
                >{{ $t("message['workouts.tempo']") }}
                {{ exerciseData.getTempo() }}
              </text-body-extra-small>
              <badge-important
                v-if="showSetsArea"
                class="pc-container-single-exercise__image-set-type"
              >
                <text-body-extra-small
                  line-height="multi"
                  weight="extra-bold"
                  >{{ this.setType }}</text-body-extra-small
                >
                <!--              <icon-question-rounded size="sm2"  v-if="showSetInstructionButton" @click="showSets()"></icon-question-rounded>-->
              </badge-important>
            </div>
            <div
              class="pc-container-single-exercise__right-area"
              v-if="!fullScreen"
            >
              <icon-swap
                v-if="exerciseSwapEnabled"
                size="md1"
                class="pc-container-single-exercise__image-icon-swap"
                @click="showExerciseSwapPopup"
              />
            </div>
            <div class="pc-container-single-exercise__timer-wrapper">
              <timer
                class="pc-container-single-exercise__timer"
                :color="'white'"
                :rest-interval="restInterval"
                :rest-time="restTime"
                :time="exerciseTime"
                v-if="
                  contentComponent !== '' &&
                  (parseInt(restTime) > 0 || parseInt(exerciseTime) > 0) &&
                  !(!isCircuit && workoutLogEnabled)
                "
              />
            </div>
          </div>
        </grid-col>
      <grid-col cols="12" lg="6">
        <div class="workout-exercise__content" ref="contentCom" :style="contentComponentStyles">
        <!--   page wrapper -->
        <page-container>
          <div class="workout-exercise__muscles">
            <text-body-extra-small>
              <span v-html="showMuscles( )">  </span>
            </text-body-extra-small>
          </div>
          <hr class="workout-exercise__muscles-seperator">
          <navigation-tabs-secondary  v-if="(!isCircuit&&workoutLogEnabled)" class="pc-container-single-exercise__navigation-tabs">
            <navigation-tabs-secondary-tab
              v-if="(!isCircuit&&workoutLogEnabled)"
              :selected="currentTab==='history'"
              :text="$t('message[\'workout-log.history\']')"
              @click="currentTab='history'"
            />
            <navigation-tabs-secondary-tab
              v-if="(!isCircuit&&workoutLogEnabled)"
              :selected="currentTab==='log'"
              t :text="$t('message[\'workout-log.log\']')"
              @click="currentTab='log'"
            >
              <icon-completed-filled v-if="isCompleted" size="sm2" class="pc-container-single-exercise__navigation-tab-completed"></icon-completed-filled>
            </navigation-tabs-secondary-tab>
            <navigation-tabs-secondary-tab
              v-if="(Array.isArray(instructions)&&instructions.length!==0)"
              :selected="currentTab==='instructions'"
              :text="$t('message[\'general.instructions\']')"
              @click="currentTab='instructions'"/>
          </navigation-tabs-secondary>
          <container-workout-log-history
            :exercise-i-d="params.exercise"
            :exercise-name="exerciseData.getName()"
            :day-name="dayName"
            :week-name="weekName"
            :workout-i-d="params.workout"
            v-bind="components.workoutLog"
            v-if=" currentTab==='history'&&(!isCircuit&&workoutLogEnabled)"
            @deleted="setDeletedExercise($event)"
            @nextExercise="navigateNextWorkout"
          />
          <workout-log-exercise
            :exercise-group="exerciseGroup"
            v-bind="components.workoutLog"
            :day-i-d="params.day"
            :day-name="dayName"
            :week-name="weekName"
            :force-edit="$route.query['workout-log']==='edit'"
            :is-completed="isCompleted"
            :week-i-d="params.week"
            :show-navigator="false"
            :workout-i-d="params.workout"
            :sets-count="setsCount"
            v-if="workoutLogEnabled"
            v-show=" currentTab==='log' &&(!isCircuit&&workoutLogEnabled)"
            @data-deleted="makeIncompleted()"
            @nextExercise="navigateNextWorkout"
            @perviousExercise="navigatePerviousExercise"
          />
          <div v-if=" currentTab==='instructions'">
            <div v-if="contentComponent===''&&(parseInt(restTime)>0||parseInt(exerciseTime)>0)&&!(!isCircuit&&workoutLogEnabled)">
              <timer-completed @restart="restartTimer" v-if="timerCompleted"></timer-completed>
              <timer
                :workout-header="workoutTimerText"
                :rest-header="restText"
                :rest-interval="restInterval"
                :rest-time="restTime"
                :time="exerciseTime"
                v-else
                v-on:rest-timer-end="restTimerEnded($event)"
                v-on:workout-timer-end="workoutTimerEnded($event)"
                v-on:workout-timer-remaining="workoutTimerRemaining($event)"
              />
            </div>
            <text-body-extra-small
              v-if="contentComponent===''&&((Array.isArray(instructions)&&instructions.length!==0)||(exerciseData.getMuscles() && exerciseData.getMusclesCount()>0))"
              line-height="multi"
              class="pc-container-single-exercise__text-instructions"
            >
              <p class="pc-container-single-exercise__text-instructions"
                 v-for="(instruction,iid) in instructions"
                 :key="'instruction-'+iid"
              >
                <span class="app-content-wrap" v-html="instruction"></span>
              </p>
            </text-body-extra-small>

                <content-page-area
                  :hide-header-image="true"
                  :page-name="contentComponentData.pageName"
                  v-if="contentComponent !== ''"
                />

                <div
                  class="fixed-bottom footer pc-container-single-exercise__footer"
                  v-if="showCompleteButton && nextExercise && !fullScreen"
                >
                  <button-primary
                    @click="completeExercise()"
                    type="button"
                    :text="$t('message[\'workout-log.complete-exercise\']')"
                  />
                </div>
              </div>
            </page-container>
          </div>
        </grid-col>
      </grid-row>
    </page-container-fluid>
    <workout-log-success-area v-if="showWorkoutLogSuccess" />
    <swap
      v-if="exerciseData !== null && swapPopup.visible"
      :exercise="exerciseData"
      :visible="swapPopup.visible"
      @successHandle="reloadData()"
      @successReloadHandle="reloadData()"
    ></swap>
    <popup-exercise-reps
      v-if="showExerciseRepsPopup"
      :popup-visibility="showExerciseRepsPopup"
      @close="closePopup()"
    />
  </div>

  <ContainerSingleExerciseSkeleton v-else></ContainerSingleExerciseSkeleton>
</template>

<script>
import pageMixin from "../../../../../mixins/pageMixin";
import exerciseMixin from "../../../../../mixins/page-mixins/workout/exerciseMixin";
import Instructions from "../../../../global/popups/PopupInstructions";
import WorkoutLogSuccessArea from "./ContainerFullscreenSuccess";
import ContentPageArea from "../../content/widgets/ContentPageArea";
import Timer from "./Timer";
import Swap from "./popups/PopupSuggestedExerciseSwap";
import WorkoutLogExercise from "../page-components/ContainerWorkoutLogExercise";
import VideoPlayer from "../../../../global/players/VideoPlayerOld";
import NavigationTabsSecondary from "./NavigationTabsSecondary";
import NavigationTabsSecondaryTab from "./navigation-tabs-secondary-sub-components/NavigationTabsSecondaryTab";
import IconCompletedFilled from "../../../../root/icons/IconCompleteFilled";
import PageContainer from "../../../../global/pages/page-sub-components/PageContainer";
import TextBodyExtraSmall from "../../../../global/typography/TextBodyExtraSmall";
import IconInfo from "../../../../root/icons/IconInfo";
import BadgeImportant from "../../../../global/badges/BadgeImportant";
import IconSwap from "../../../../root/icons/IconSwap";
import ButtonPrimary from "../../../../global/buttons/ButtonPrimary";
import ContainerWorkoutLogHistory from "./ContainerWorkoutLogHistory";
import IconQuestionRounded from "../../../../root/icons/IconQuestionRounded";
import PopupExerciseReps from "../../../../global/popups/PopupExerciseReps";
import TimerCompleted from "./TimerCompleted";
import PageContainerFluid from "../../../../global/pages/page-sub-components/PageContainerFluid";
import GridRow from "../../../../global/grid/GridRow";
import GridCol from "../../../../global/grid/GridCol";
import ContainerSingleExerciseSkeleton from "./ContainerSingleExerciseSkeleton.vue";
import headerEventBus from "@/event-buses/headerEventBus";

export default {
  name: "ContainerSingleExercise",
  mixins: [pageMixin, exerciseMixin],
  components: {
    GridCol,
    GridRow,
    PageContainerFluid,
    TimerCompleted,
    IconQuestionRounded,
    ButtonPrimary,
    IconSwap,
    BadgeImportant,
    IconInfo,
    TextBodyExtraSmall,
    PageContainer,
    IconCompletedFilled,
    NavigationTabsSecondaryTab,
    NavigationTabsSecondary,
    VideoPlayer,
    Instructions,
    ContainerWorkoutLogHistory,
    WorkoutLogSuccessArea,
    ContentPageArea,
    Timer,
    ContainerSingleExerciseSkeleton,
    // VimeoVideo,
    Swap,
    WorkoutLogExercise,
    PopupExerciseReps,
  },
  data() {
    return {
      contentComponentStyles: {},
      showExerciseRepsPopup: false,
    };
  },
  watch: {
    headerOptions: {
      deep: true,
      immediate: true,
      handler: function () {
        this.$emit("header-text", this.headerOptions.header);
      },
    },
    getExerciseDataStatus: function () {
      this.$nextTick(() => {
        this.onWindowResize();
      });
    },
    fullScreen(val) {
      // hide header on full screen
      this.headerOptions.show = !val;
      this.setHeader(this.headerOptions);
      this.setPageClass();

      // set page modifier on fullscreen
      this.setPageFullScreenModifier();
    },
  },
  beforeDestroy() {
    // remove page full screen on browser back or any destroy event
    this.fullScreen = false;
    this.setPageFullScreenModifier();
    window.removeEventListener("resize", this.onWindowResize);
    headerEventBus.$off("back-button-click", this.navigatePrevious);
  },
  mounted() {
    this.setStoreData({
      workoutId: this.workoutData.workout,
      exerciseID: this.workoutData.exercise,
    });
    this.afterLoadPageData();
    this.loadWorkoutData();
    this.setStyles();
    this.setNotificationBarSettings({ show: false });
    this.setFeedbackPopup();
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();
    headerEventBus.$on("back-button-click", this.navigatePrevious);
  },
  methods: {
    onWindowResize() {
      this.contentComponentStyles = {};
      if (this.isDesktop && this.$refs.contentCom) {
        this.contentComponentStyles.height =
          window.innerHeight -
          this.$refs.contentCom.getBoundingClientRect().top -
          3 +
          "px";
        this.contentComponentStyles["overflow-y"] = "scroll";
      }
    },
    afterLoadPageData() {
      this.setInstructionsPopupContent(this.setInstructionsPopup);
    },
    showMuscles() {
      const muscles = this.exerciseData.getMuscles().filter((muscle) => {
        return this.exerciseData.getPreferredMuscle() !== muscle
      })
      const muscleStr = (muscles.length > 0) ? (((this.exerciseData.getPreferredMuscle()) ? ', ' : '') + muscles.join(', ')) : ''
      const prefStr = (this.exerciseData.getPreferredMuscle()) ? '<b class="info-modal__text-preferred-muscle">' + this.exerciseData.getPreferredMuscle() + '</b>' : ''
      return '<strong>' + this.$i18n.t('message[\'workouts.muscles\']') + ': </strong> ' + prefStr + muscleStr
    },
    setPageFullScreenModifier() {},
    iconInfoClicked() {
      this.showExerciseRepsPopup = true;
    },
    closePopup() {
      this.showExerciseRepsPopup = false;
    },
  },
};
</script>

<style scoped></style>
