<template>
    <div class="pc-dashboard__btn-progress-action-skeleton">
        <div class="pc-dashboard__btn-progress-action">
            <div>
                <AnimatedPlaceholder class="pc-dashboard__btn-progress-action-skeleton--main-title" width="220px" height="18px" />
                <AnimatedPlaceholder width="180px" height="10px" />
            </div>
            <div>
                <AnimatedPlaceholder width="16px" height="28px" />
            </div>
        </div>
    </div>
</template>
<script>
import AnimatedPlaceholder from '@/components/global/animators/AnimatedPlaceholder'

export default {
    components: {
        AnimatedPlaceholder
    }
}
</script>
<style>
.pc-dashboard__btn-progress-action-skeleton {
    .pc-dashboard__btn-progress-action-skeleton--main-title {
        margin-bottom: 12px;
    }
}
</style>