<template>
  <popup
    :visible="true"
    @hide="closePopup"
    modal-class="gc-feature-tour-popup"
    no-close-on-backdrop
    ref="modal"
    v-if="true"
  >
    <popup-title>
      <popup-button-close
        @hide="closePopup($event), submitCloseEvent('Instructions', 'x')"
      />
    </popup-title>
    <popup-body>
      <popup-head
        class="gc-feature-tour-popup__heading text-center"
        v-if="title[currentIndex]"
      >
        <popup-text-heading>{{ title[currentIndex] }}</popup-text-heading>
      </popup-head>
      <popup-content>
        <div v-for="(item, index) in featureInfo[currentIndex]" :key="index">

          <video
            class="gc-feature-tour-popup__video"
            v-if="opened && item.video"
            autoplay
            loop
            muted
            playsinline
          >
            <source :src="item.video" type="video/mp4"/>
          </video>
          <loading-buffer v-else-if="!opened && item.video"/>
          <image-base
            class="gc-feature-tour-popup__image"
            v-if="item.image"
            :src="item.image"
          />
          <div
            class="gc-feature-tour-popup__content"
            v-if="item.content"
            v-html="item.content"
          ></div>
          <button-primary v-if="item.navigate && (item.navigate.url|| item.navigate.text)" style="margin-top: 24px"
                          :text='item.navigate.text||$i18n.t("message[\"popup-feature-tour.navigate\"]")'
                          @click="navigate( item.navigate.url)"></button-primary>
        </div>
      </popup-content>
    </popup-body>
  </popup>
</template>

<script>
import ImageBase from '../../root/Image'
import Popup from './Popup'
import PopupBody from './popup-sub-components/PopupBody'
import PopupButtonClose from './popup-sub-components/PopupButtonClose'
import PopupContent from './popup-sub-components/PopupContent'
import PopupHead from './popup-sub-components/PopupHead'
import PopupTextHeading from './popup-sub-components/PopupTextHeading'
import PopupTitle from './popup-sub-components/PopupTitle'
import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import {mapGetters, mapMutations} from 'vuex'
import store from '../../../store'
import moment from 'moment'
import ButtonPrimary from '../buttons/ButtonPrimary'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'

export default {
  name: 'PopupFeatureTour',
  components: {
    LoadingBuffer,
    ButtonPrimary,
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupHead,
    PopupTextHeading,
    PopupContent,
    ImageBase
  },
  data: function () {
    return {
      opened: false,
      popupShownTime: null,
      popupClosedTime: null,
      currentIndex: 0
    }
  },
  mixins: [popupAnimationMixin],

  mounted () {
    this.logFeatureViewEvent()
    setTimeout(() => {
      this.opened = true
    }, 3500)
  },

  computed: {
    ...mapGetters({
      title: 'featurePopupStore/title',
      featureInfo: 'featurePopupStore/featureInfo',
      feature: 'featurePopupStore/getFeature'
    })

  },

  methods: {
    ...mapMutations({
      hideFeaturePopup: 'featurePopupStore/hidePopup'
    }),
    logFeatureViewEvent () {
      this.popupShownTime = moment()
      this.logEvent('FeatureTour.SawDashboardPrompt', {
        feature: this.feature
      }, true)
    },
    logFeatureViewedEvent () {
      this.popupClosedTime = moment()
      this.logEvent('FeatureTour.ClosedDashboardPrompt', {
        feature: this.feature,
        view_duration: Math.floor(moment.duration(this.popupClosedTime.diff(this.popupShownTime)).as('seconds'))
      }, true)
    },
    navigate (path) {
      if(path){
        this.$router.push(path)
      }

      this.closePopup()
    },
    closePopup (e) {
      this.logFeatureViewedEvent()
      if ((this.title.length - 1) > this.currentIndex) {
        this.currentIndex++
        this.logFeatureViewEvent()
        return
      }
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        store.commit('featurePopupStore/setPopupShown')
        this.hideFeaturePopup()
        this.$emit('close')
      })
    }
  }
}
</script>
