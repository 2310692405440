<template>
  <div class="pc-workout-list">
    <card-empty
      class="pc-workout-list__card-empty"
      v-if="workouts.length === 0 || typeof workouts[0] === 'undefined'"
      :text="emptyText"
    >
      <icon-no-workouts :size="'sm4'" />
    </card-empty>
    <div v-for="workout in workouts" :key="workout.id">
      <div
        v-if="
          !workout.getAvailability() && workout.getPlanType() === 'challenge'
        "
        class="pc-workout-list__card-challenge-plan-wrapper"
      >
        <header-simple :header-text="workout.planName" />
        <card-challenge
          type="workout"
          :image="workout.getBackgroundImage()"
          :key="'workout-program-challenge-' + workout.getID()"
          :title="workout.getName()"
          :activation-time="workout.getActiveAt()"
        />
      </div>
      <card-navigation
        class="pc-workout-list__card-featured-image"
        v-else-if="type === 'card'"
        :key="'workout-program-card-' + workout.getID()"
        :header-text="workout.getName()"
        :image="workout.getBackgroundImage()"
        @click="navigateToProgram(workout.getID())"
      >
      </card-navigation>
      <!-- <image-base
        v-if="!showTabImage"
        class="pc-dashboard__featured-image-bg--blur"
        :src="backgroundTabImage"
      /> -->
      <div v-else-if="type === 'week-slider'">
        <header-simple
          class="pc-workout-list__slider-challenge-plan-header"
          v-if="workout.planType === 'challenge'"
          :header-text="workout.planName"
        />
        <slider-workout-program
          :key="'workouts-list-item-' + workout.getID()"
          :workout="workout"
          :page="page"
          :activeWorkout="activeWorkout"
          @activeWorkout="setActiveWorkout"
        />
      </div>
    </div>
  </div>
</template>
<script>
import HeaderSimple from "../../../../global/headers/HeaderSimple";
import IconNoWorkouts from "../../../../root/icons/IconWorkouts";
import CardEmpty from "./CardEmpty";
import DashBoardService from "../../../../../services/DashboardService";
import SliderWorkoutProgram from "../../../../global/sliders/SliderWorkoutProgram";
import CardNavigation from "../../../../global/cards/CardNavigation";
import CardChallenge from "../../../../global/cards/CardChallenge";
import { showWorkoutWeekSlider } from "../../../../../includes/TemplateSettings";
import GridRow from "../../../../global/grid/GridRow";
import GridCol from "../../../../global/grid/GridCol";
import ImageBase from "../../../../root/Image";
export default {
  name: "CardListWorkouts",
  components: {
    GridCol,
    GridRow,
    CardChallenge,
    CardNavigation,
    HeaderSimple,
    CardEmpty,
    IconNoWorkouts,
    SliderWorkoutProgram,
    ImageBase,
  },
  props: {
    workouts: {
      default: [],
    },
    page: {
      default: "dashboard",
    },
    headerText: {
      default: "",
    },
    widgetType: {
      default: "",
    },
  },
  data: function () {
    return {
      activeWorkout: null,
      type: "card",
      emptyText: "",
      readyCount: 0,
    };
  },
  computed: {
    showEmptyCard: function () {
      return (
        !Array.isArray(this.workouts) ||
        this.workouts.length === 0 ||
        typeof this.workouts[0] === "undefined"
      );
    },
  },
  created() {
    let service = new DashBoardService();
    service.getPageData().then((data) => {
      this.emptyText = data.contents["no-workout-programs-msg"];
    });
  },
  mounted() {
    if (this.widgetType === "card") {
      this.type = "card";
      return;
    }
    if (this.isDesktop || showWorkoutWeekSlider()) {
      this.type = 'card'
    } else {
      this.type = "card";
    }

    if (
      this.type !== 'card' ||
        this.workouts.length === 0 ||
        typeof this.workouts[0] === 'undefined'
    ) {
      this.$emit("ready");
    }
  },
  methods: {
    setActiveWorkout(workout) {
      this.activeWorkout = workout;
    },
    ready() {
      this.readyCount++;
      if (this.workouts.length <= this.readyCount) {
        this.$emit("ready");
      }
    },
    navigateToProgram(id) {
      const navigationPath = "/workout-programs";

      if (
        navigationPath === this.$route.path &&
        this.$route.query.workout &&
        this.$route.query.workout.toString() === id.toString() &&
        Object.entries(this.$route.query).length === 1
      ) {
        return;
      }

      // holding router so slick dom breaking is not visible before fade effect
      this.$router.push({
        path: navigationPath,
        query: { workout: id, load: 0 }
      });
    },
  },
};
</script>
