export default {
  methods:{
    getConfigByPageId(pageName){

      let config = templateConfig.page_config|| {}
      return Object.assign({"ver":"1.0","header":{"variation":"style1"},"widgetOrder":[], "widgets":[{"code":"descriptionSelection","variation":"style1"}]},config[pageName])
    }
  }

}
