<template>
  <div>
    <popup-text-body class="pc-popup-suggested-meal-swap__description">
      {{ $t('message["meal-plan.suggested-meal-swap.description"]') }}
    </popup-text-body>
    <navigation-bar class="pc-popup-suggested-meal-swap__tabs">
      <navigation-bar-tab
        :active="tab === 'random'"
        @click="
          tab = 'random';
          page = 1;
        "
      >
        {{ $t('message["meal-plan.suggested-meal-swap.random-meals"]') }}
      </navigation-bar-tab>
      <navigation-bar-tab
        :active="tab === 'favorite'"
        @click="
          tab = 'favorite';
          page = 1;
        "
      >
        {{ $t('message["meal-plan.suggested-meal-swap.favorite-meals"]') }}
      </navigation-bar-tab>
    </navigation-bar>
    <loading-buffer v-if="loading" :height="'75'" />
    <div class="pc-popup-suggested-meal-swap__tab-content">
      <div>
        <input-radio-card
          class="pc-popup-suggested-meal-swap__card"
          v-for="suggestion in currentMeals.slice((page - 1) * 3, page * 3)"
          :key="suggestion.id"
          :id="suggestion.id"
          :type="meal.getType().name"
          :name="suggestion.name"
          :images="suggestion.images"
          v-model="selectedMeal"
        />
        <text-body-extra-small
          class="pc-popup-suggested-meal-swap__no-result"
          v-if="!loading && currentMeals.length === 0 && tab === 'favorite'"
        >
          {{
            $t('message["meal-plan.suggested-meal-swap.favorite-no-results"]')
          }}
        </text-body-extra-small>

        <div v-if="!loading && currentMeals.length === 0 && tab === 'random'">
          <text-body-extra-small
            class="pc-popup-suggested-meal-swap__no-result"
          >
            {{
              $t(
                'message["meal-plan.suggested-meal-swap.suggested-no-results"]'
              )
            }}
          </text-body-extra-small>
          <text-area
            class="pc-popup-suggested-meal-text-area"
            v-model="emailText"
            :placeholder="textAreaPlaceholder"
          />
        </div>
      </div>

      <text-body-small v-if="currentMeals.length > 3" @click="showMore">
        {{
          $t(
            'message["meal-plan.suggested-meal-swap.no-matching-alternatives"]'
          )
        }}
        <p class="link">
          {{ $t('message["meal-plan.suggested-meal-swap.show-more"]') }}
        </p></text-body-small
      >
    </div>
  </div>
</template>
<script>
import InputRadioCard from "@/components/global/inputs/InputRadioCard.vue";
import PopupTextBody from "@/components/global/popups/popup-sub-components/PopupTextBody.vue";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall.vue";
import TextBodySmall from "@/components/global/typography/TextBodySmall.vue";
import LoadingBuffer from "@/components/layout/template-1/global/widgets/LoadingBuffer.vue";
import NavigationBarTab from "@/components/global/navigators/navigation-bar-sub-components/NavigationBarTab.vue";
import NavigationBar from "@/components/global/navigators/NavigationBar.vue";
import ProfileService from "@/services/ProfileService";
import TextArea from "@/components/global/inputs/TextArea.vue";

export default {
  name: "ContainerMealSwapOptions",
  components: {
    TextArea,
    LoadingBuffer,
    InputRadioCard,
    PopupTextBody,
    TextBodyExtraSmall,
    TextBodySmall,
    NavigationBarTab,
    NavigationBar,
  },
  props: {
    meal: {},
    value: {},
  },
  data: function () {
    return {
      supportEmail: "supportEmail",
      loading: true,
      suggestedMeals: [],
      tab: "random",
      selectedMeal: null,
      page: 1,
      emailText: "",
      textAreaPlaceholder: this.$i18n.t(
        'message["popup.suggested-meal-swap.request-text-area-placeholder"]'
      ),
    };
  },
  watch: {
    tab: function () {
      this.selectedMeal = null;
      this.page = 1;
      this.$emit("selected-meal-type", this.tab);
    },
    emailText: function () {
      this.$emit("email-text-change", this.emailText);
    },
    value: {
      handler: function () {
        if (this.value !== this.selectedMeal) {
          this.selectedMeal = this.value;
        }
      },
      immediate: true,
    },
    selectedMeal: function () {
      this.$emit("input", this.selectedMeal);
      this.$emit("selected-meal-type", this.tab);
    },
    currentMeals: function () {
      this.$emit("no-results", !!this.currentMeals.length);
    },
  },
  computed: {
    currentMeals: function () {
      return this.tab === "favorite"
        ? this.suggestedFavMeals
        : this.suggestedRandomMeals;
    },
    suggestedRandomMeals: function () {
      return this.suggestedMeals.filter((meal) => !meal.is_favorite);
    },
    suggestedFavMeals: function () {
      return this.suggestedMeals.filter((meal) => !!meal.is_favorite);
    },
  },
  created() {
    this.$emit("selected-meal-type", this.tab);
    const getFavMeals = this.meal.getSwapMealOptions(true);
    const getRandomMeals = this.meal.getSwapMealOptions();

    Promise.all([getFavMeals, getRandomMeals])
      .then(([favMeals, randomMeals]) => {
        favMeals = favMeals.data.map((meal) => {
          return {
            id: meal.id,
            name: meal.recipes.map((recipe) => recipe.name).join(", "),
            images: meal.recipes.flatMap((recipe) => recipe.images),
            is_favorite: meal.is_favorite,
          };
        });
        randomMeals = randomMeals.data.map((meal) => {
          return {
            id: meal.id,
            name: meal.recipes.map((recipe) => recipe.name).join(", "),
            images: meal.recipes.flatMap((recipe) => recipe.images),
            is_favorite: meal.is_favorite,
          };
        });
        this.suggestedMeals = [...favMeals, ...randomMeals];
        this.logEvent("MealPlan.MealSwapInitiated", {
          no_of_alternatives: this.suggestedMeals.length,
        });
        this.loading = false;
        this.$emit("loaded");
      })
      .catch(() => {
        this.loading = false;
        this.suggestedMeals = [];
        this.$emit("loaded");
      });
    const account = new ProfileService();
    if (account.getSupportEmail()) {
      this.supportEmail = account.getSupportEmail();
    } else {
      account.setProfileData().then(() => {
        this.supportEmail = account.getSupportEmail();
      });
    }
  },
  methods: {
    showMore() {
      const meals =
        this.tab === "random"
          ? this.suggestedRandomMeals
          : this.suggestedFavMeals;
      if (this.page * 3 >= meals.length) {
        this.page = 1;
      } else {
        this.page++;
      }

      const div = document.querySelector("#gc-popup-body-content");
      div.scrollTop = 0;
    },
  },
};
</script>
