import { render, staticRenderFns } from "./IconDownArrow.vue?vue&type=template&id=46175a8b&scoped=true"
import script from "./IconDownArrow.vue?vue&type=script&lang=js"
export * from "./IconDownArrow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46175a8b",
  null
  
)

export default component.exports