<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7" clip-path="url(#clip0_35419_2347)">
        <path d="M8.23055 4.09253L15.8973 11.4259C16.0294 11.5535 16.0353 11.7645 15.9077 11.8973C15.7804 12.0301 15.5695 12.0346 15.4363 11.9077L8.00008 4.79501L0.563787 11.9077C0.43066 12.0347 0.219721 12.0301 0.0924387 11.8973C0.0305939 11.8328 -1.09491e-08 11.7495 -1.45635e-08 11.6668C-1.8406e-08 11.5789 0.0345001 11.4917 0.102876 11.4259L7.76961 4.09253C7.89852 3.96947 8.10164 3.96947 8.23055 4.09253Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_35419_2347">
          <rect width="16" height="16" fill="white" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 16 16)"/>
        </clipPath>
      </defs>
    </svg>


  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconUpArrow',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-up-arrow': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
