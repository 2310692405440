<template>
  <page-secondary
    class="pc-dashboard pc-dashboard--creator"
    :header="headerOptions"
  >
    <page-container-fluid
      breakpoint="lg"
      :reverse="true"
    >
      <grid-row no-gutters>
        <grid-col lg="12">
          <image-featured
            :always-show-menu="true"
            :has-notifications="false"
            :page-header="''"
          />
        </grid-col>
        <page-container class="pc-creator-dashboard__container">
          <div>
            <button-primary-forward
              v-for="community in communitiesLinks"
              :key="'link-community-' + community.id"
              :text="community.name"
              @click="goToCommunity(community.id)"
            />
            <button-primary-forward
              v-if="showPageCreatorAnalyticsLink"
              :text="'Analytics'"
              @click="gotoAnalytics()"
            />
            <button-primary-forward
              v-if="showDashboardEditorLink"
              :text="$t('message[\'creator.layout-editor.edit-member-dashboard\']')"
              @click="goToEditPage()"
            />
          </div>
          <div>
            <button-primary-forward
              v-if="evergreenProgressEnabled && isCreator"
              class="pc-dashboard__transformation-btn"
              :text="'Transfomations'"
              @click="goToTransfomations()"
            />
          </div>
          <div class="pc-dashboard__announce-kit-loader" v-if="!isAnnounceKitLoaded">
            <loading-buffer />
          </div>
          <div v-if="widgetsData.length > 0 && isAnnounceKitLoaded">
            <announce-kit-container
              :widgets-data="widgetsData"
              :announce-kit-configs="announceKitConfigs"
            />
          </div>
        </page-container>
      </grid-row>
      <container-chat class="pc-dashboard__container-chat" />
    </page-container-fluid>
  </page-secondary>
</template>

<script>
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import ImageFeatured from './page-components/headers/HeaderDashboardStyle1.vue'
import PageSecondary from '../../../global/pages/PageSecondary'
import CommunityService from '../../../../services/community/CommunityService'
import ButtonPrimaryForward from '../../../global/buttons/ButtonPrimaryForward'
import ContainerChat from '../../../global/containers/ContainerChat'
import {mapActions, mapMutations} from 'vuex'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
import NativeAppService from '@/services/NativeAppService'
import {enableDashboardVariations, flagValue, getCreatorAnalyticsConfig} from '@/includes/TemplateSettings'
import DashBoardService from '../../../../services/DashboardService'
import {checkIsCreator} from '@/helpers/user'
import AnnounceKitService from '../../../../services/AnnounceKitService'
import AnnounceKitContainer from './page-components/AnnounceKitContainer'
import LoadingBuffer from '../../template-1/global/widgets/LoadingBuffer'
import MemberProgressService from '../../../../services/api/member-progress/MemberProgressService'
import store from "@/store";

export default {
  name: 'PageCreatorDashboard',
  components: {
    ButtonPrimaryForward,
    PageSecondary,
    ImageFeatured,
    GridCol,
    GridRow,
    PageContainerFluid,
    PageContainer,
    ContainerChat,
    AnnounceKitContainer,
    LoadingBuffer
  },
  data: function () {
    return {
      showDashboardEditorLink: false,
      evergreenProgressEnabled: false,
      isAnnounceKitLoaded: false,
      isCreator: false,
      communitiesLinks: [],
      showPageCreatorAnalyticsLink: false,
      headerOptions: {
        show: false,
        left: 'back',
        right: '',
        helpContent: '',
        rightDisabled: false,
        modifiers: [],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      notificationsCheckTimer: null,
      announceKitConfigs: {},
      widgetsData: []
    }
  },
  mounted () {
    this.showDashboardEditorLink = enableDashboardVariations()
    this.isCreator = checkIsCreator()
    this.getEvergreenEnableConfigs()
    this.loadCommunities()
    this.setVisibilityOfCreatorAnalyticsLink()
    this.getAnnounceConfig()
    this.hideLoading()
    store.commit('hideLoggingLoading')
    if(isMobileNativeApp()){
      const nativeApp = new NativeAppService()
      nativeApp.getPushDeviceStateRequest()
      console.log('Native App')
    }

    this.setPageTitle('Dashboard')

    this.initCommunityNotificationDetails()
    // notification status will be updated every 30 seconds
    this.notificationsCheckTimer = setInterval(() => this.initCommunityNotificationDetails(), 2*60*1000)
    if(isMobileNativeApp()){
      const nativeApp = new NativeAppService()
      nativeApp.getPushDeviceStateRequest()
    }
    this.setPageTitle('Dashboard')
  },
  beforeDestroy () {
  },
  methods: {
    ...mapMutations({
      setPageTitle: 'pageStore/setPageTitle'
    }),
    async getEvergreenEnableConfigs () {
      const memberProgressService = new MemberProgressService()
      const response = await memberProgressService.getEvergreenCreatorEnableConfigs()

      this.evergreenProgressEnabled = response.data.active
    },
    async getAnnounceConfig () {
      try {
        this.isAnnounceKitLoaded = false
        const announceKitService = new AnnounceKitService()
        let response = await announceKitService.getConfigs()

        if (response && response.widgets) {
          this.widgetsData = response.widgets
          this.announceKitConfigs = response
          this.isAnnounceKitLoaded = true
        }
      } catch (error) {
          console.error('An error occurred:', error)
          this.isAnnounceKitLoaded = false
      }
    },
    goToTransfomations () {
      this.delayedRouterPush({
        path: '/dashboard?member-progress'
      })
    },
    setVisibilityOfCreatorAnalyticsLink () {
      this.showPageCreatorAnalyticsLink = !!getCreatorAnalyticsConfig()
      if (this.showPageCreatorAnalyticsLink) {
        const hasEnabledUsers = getCreatorAnalyticsConfig().user_emails || []
        if (Array.isArray(hasEnabledUsers) && hasEnabledUsers.length > 0) {
          const dashBoardService = new DashBoardService()
          dashBoardService.getUserConfig().then((config => {
            this.showPageCreatorAnalyticsLink = hasEnabledUsers.includes(config.email)
          })).catch(() => {
            this.showPageCreatorAnalyticsLink = false
          })
        }
      }
    },
    async loadCommunities () {
      let community = new CommunityService()
      await community
        .getCommunities()
        .then(async () => {
          this.communitiesLinks = await community.getActiveCommunities()
        })
        .catch((err) => {
          console.log(err)
          this.hideLoading()
        })
      this.hideLoading()
    },
    goToCommunity (communityId) {
      this.delayedRouterPush({
        path: '/dashboard?community=' + communityId
      })
    },
    gotoAnalytics () {
      this.delayedRouterPush({
        path: '/dashboard?page=creator-analytics'
      })
    },goToEditPage () {
      this.delayedRouterPush({
        path: '/dashboard?page=edit&edit=1'
      })
    }
  }
}
</script>
