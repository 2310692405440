<template>
  <!-- for mobile tab view -->
  <div class="pc-header-dashboard-v1 pc-dashboard__main-featured-area pc-dashboard__main-featured-area--mobile" :class="featuredAreaClasses"
       v-if="!isDesktop">

    <div class="pc-dashboard__featured-image" :style="heroCardStyles">
      <nav-bar-header :options="headerOptions" :alwaysShowMenu="alwaysShowMenu" style="width: 100%">

        <template v-slot:right>
          <icon-create-shortcut class="app-nav__btn-pwa" v-if="showPwaPopup()" @click="pwaPopup.visible = true" size="md1" />
          <popup-p-w-a-instructions
            :visible="pwaPopup.visible"
            v-bind:key="'pwa-popup'"
            v-if="pwaPopup.visible"
            v-on:close="pwaPopup.visible = false"
          ></popup-p-w-a-instructions>
          <div v-if="isCommunityEnabled">
            <div class="pc-dashboard__nav-bar-header-bell-icon"
                 :class="{ 'pc-dashboard__nav-bar-header-bell-icon--new-notification': unreadCommunityNotificationsExist }"
                 @click="goToNotificationPage">
              <icon-bell-filled v-if="unreadCommunityNotificationsExist"/>
              <icon-bell v-else/>
            </div>
          </div>
        </template>
      </nav-bar-header>
      <text-content
        :size="'md3'"
        class="pc-dashboard__main-featured-area--gretting-text"
        :weight="'extra-bold'"
        :line-height="'lh-md'"
      >
        {{ pageHeader }}
      </text-content>
    </div>

  </div>
  <!-- for desktop view -->
  <div v-else class="pc-dashboard__main-featured-area pc-dashboard__main-featured-area--desktop">

    <nav-bar-header :options="headerOptions"/>
    <div class="container pc-dashboard-notification-desktop-icon-wrap" v-if="isCommunityEnabled">
      <div class="pc-dashboard__nav-bar-header-bell-icon"
           :class="{ 'pc-dashboard__nav-bar-header-bell-icon--new-notification': unreadCommunityNotificationsExist }"
           @click="goToNotificationPage">
        <icon-bell-filled v-if="unreadCommunityNotificationsExist"/>
        <icon-bell v-else/>
      </div>
    </div>
    <div class="pc-dashboard__main-featured-area__content">
      <div class="pc-dashboard__featured-image" :style="heroCardStylesDesktop">
        <image-base v-if="!showTabImage" class="pc-dashboard__featured-image-bg--blur" :src="backgroundTabImage"/>
        <image-base v-if="!showTabImage" class="pc-dashboard__featured-image-on-top" :src="backgroundTabImage"/>
        <text-content
          :size="'md3'"
          class="pc-dashboard__main-featured-area--gretting-text"
          :weight="'extra-bold'"
          :line-height="'lh-md'"
        >
          {{ pageHeader }}
        </text-content>

      </div>

    </div>
  </div>
</template>

<script>
import NavBarHeader from '../NavBarHeader.vue'
import ImageBase from '../../../../template-2/root/Image.vue'
import IconBell from '../../../../../root/icons/IconBell.vue'
import IconBellFilled from '../../../../../root/icons/IconBellFilled.vue'
import HeaderDashboardMixin from "@/mixins/page-mixins/dashboard/headerDashboardMixin";
import PopupPWAInstructions from "@/components/global/popups/PopupPWAInstructions.vue";
import IconCreateShortcut from "@/components/root/icons/IconCreateShortcut.vue";
import TextContent from "@/components/root/TextContent.vue";

export default {
  name: 'HeaderImageFeatured',
  components: {
    TextContent,
    IconCreateShortcut, PopupPWAInstructions,
    ImageBase,
    NavBarHeader,
    IconBell,
    IconBellFilled
  },
  mixins: [HeaderDashboardMixin],
  beforeMount() {
    document.querySelector('body').classList.add('header-dashboard-v1');
  },
  beforeDestroy() {
    document.querySelector('body').classList.remove('header-dashboard-v1');
  },
}
</script>
