import ToningAPI from "@/services/api/creator/toning/ToningAPI";

export default class {


  __api =  new ToningAPI()
  layout = {
    dashboard: {
      name: 'dashboard',
      data: {}
    }
  }
  constructor() {
   this.__api = new ToningAPI()

  }

  getDashboardData(){
    return this.__api.getComponentsByName('dashboard').then(data=> {
      this.layout.dashboard.data =data.data
      return data.data
    })
  }

  setDashboardData(data){
    return this.__api.setComponentsByName('dashboard',data).then(data=> {
      this.layout.dashboard.data =data.data
      return data.data
    })
  }

}
