<template>
  <div>
    <div class="gc-page__content-image gc-page__content-image__placeholder">
      <animated-placeholder class=" gc-page__main-image gc-page__main-image__placeholder" v-show="!loaded"  :height="placeholderHeight" />
    </div>

    <div class="gc-page__content-image" id="imageWrapper" :class="customImageClass" v-on="$listeners" v-if="contentImage" :style="{ backgroundImage: `url(${contentImage})` }">
      <!-- Main centered image -->
      <img :src="contentImage"  @load="loaded = true" @error="handleError" alt="Main Image" class="gc-page__main-image"  v-show="showImage" />
    </div>
  </div>
</template>


<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";


export default {
  name: 'PageContentImage',
  components: {
    AnimatedPlaceholder
  },
  data() {
    return {
      loaded: false,
      placeholderHeight: '260px',
      showImage: true
    }
  },
  props: {
    contentImage: {
      type: String,
      required: false
    }
  },
  computed: {
    customImageClass() {
      return {
        'gc-page__content-image--hidden': !this.loaded
      }
    }
  },
  methods: {
    handleError() {
      this.loaded = true;
      this.showImage = false;
    }
  }
}
</script>



