<template>
  <page-image-cover :type="'primary'"
                    :background-image="backgroundImage" :header="headerOptions"
                    class="pc-meal-plan-home pc-meal-plan--home">
    <page-container class="pc-meal-plan--home__container">
      <div class="pc-meal-plan--home-text">
        <badge-meal-plan-pair v-if="isPaired"/>
        <text-heading-2 :weight="'extra-bold'" :line-height="'multi'" class="pc-meal-plan--home-heading">
          {{ title }}
        </text-heading-2>

      </div>
      <div class="pc-meal-plan--home__action-buttons">
        <button-primary-icon-cart
          @click="navigateToShop"
          class="pc-meal-plan--home-button"
          :text="$t('message[\'general.shopping-list\']')"
        />
        <container-meal-plan-email-summary
          class="pc-meal-plan_container-meal-plan-email-summary"
          :meal-plan-id="mealPlanID"/>
      </div>

      <div class="pc-meal-plan--home-text-slider">
        <text-body-small :line-height="'multi'" class="pc-meal-plan--home-sub-heading">
          <p v-html="getDescription()"></p>
        </text-body-small>
      </div>
      <slider-meal-plan
        class="pc-meal-plan--home-slider"
        v-if="days.length > 0"
        :days="days"
        :meal-plan-id="mealPlanID"
        @navigateNext="navigateNext"
      >
      </slider-meal-plan>
      <PageHomeSliderSkeleton v-else/>
      <!-- <loading-buffer v-else  :height="200"></loading-buffer> -->
      <button-meal-change-request  v-if="mealChangeRequestFlagEnabled" :mealId="mealPlanID"/>
    </page-container>
    <div v-if="!isDesktop" class="macroapp-background__gradient"></div>
  </page-image-cover>
</template>

<script>
import {slickMixin} from '../../../../mixins/slickMixin'
import UserEventsService from '../../../../services/UserEventsService'
import pageMealPlanMixin from '../../../../mixins/component-mixins/page/meal-plan/pageMealPlanMixin'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import TextHeading2 from '../../../global/typography/TextHeading2'
import SliderMealPlan from '../../../global/sliders/SliderMealPlan'
import ButtonPrimaryIconCart from '../../../global/buttons/ButtonPrimaryIconCart'
import TextBodySmall from '../../../global/typography/TextBodySmall'
import PageImageCover from '../../../global/pages/PageImageCover'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import ContainerMealPlanEmailSummary from './page-components/ContainerMealPlanEmailSummary'
import BadgeMealPlanPair from './page-components/BadgeMealPlanPair'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import ButtonMealChangeRequest
  from '@/components/layout/template-1/meal-plan/page-components/ButtonMealChangeRequest.vue'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import GridCol from "@/components/global/grid/GridCol.vue";
import {enableMealPlanQuestionnaire, newDashboardLayout} from "@/includes/TemplateSettings";
import PageHomeSliderSkeleton from './page-components/PageHomeSliderSkeleton'
export default {
  name: 'PageHome',
  components: {
    GridCol,
    ButtonMealChangeRequest,
    TextBodyExtraSmall,
    BadgeMealPlanPair,
    ContainerMealPlanEmailSummary,
    ButtonPrimary,
    PageImageCover,
    PageContainer,
    TextHeading2,
    SliderMealPlan,
    ButtonPrimaryIconCart,
    TextBodySmall,
    PageHomeSliderSkeleton
  },
  mixins: [pageMealPlanMixin, slickMixin],
  data: function () {
    return {
      slick: {
        slickOptions: {
          centerMode: true,
          slidesToShow: 1,
          variableWidth: true,
          autoplay: false,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          infinite: false
        }
      },
      headerOptions: {
        show: true,
        isTransparent: true,
        left: 'menu',
        right: 'help',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-bg-color', 'small-title'],
        formData: {
          image: ''
        },
        mainTitle: ''
      },
      pageClass: ['macroapp--page-meal-plan']
    }
  },
  computed: {
    mealChangeRequestFlagEnabled: function () {
      return enableMealPlanQuestionnaire()
    }
  },
  created() {
    this.setImages()
  },
  mounted () {
    this.setPageClass()

    this.logEvent('Viewed Meal Plan', {meal_plan_id: this.mealPlanID}, false)
    UserEventsService.sendMealPlanFeedBackPopupView()
  },
  methods: {
    afterGetMealPlanOptionData () {
      this.reInit()
      pageReadyEvent()
    },
    getDay (days) {
      const routes = days.map((day) => {
        return day.id
      })
      return routes
    },
    afterGetMealPlanOptionDataError (err) {
      if (err.response && err.response.status === 404) {
        this.goToNotFoundPage()
      }
      this.hideLoading()
    },
    goToNotFoundPage () {
      this.$router.replace({path: '/404'})
    }
  },

  destroyed () {
    this.resetBackground()
  }
}
</script>
