<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5014 3.57275e-05C7.44513 -0.000872782 2.88646 3.04468 0.951558 7.71623C-0.983113 12.3878 0.0871113 17.7648 3.66346 21.3391C8.54465 26.2203 16.4585 26.2203 21.3394 21.3391C26.2206 16.4581 26.2206 8.54434 21.3394 3.66315C19.0007 1.31101 15.8184 -0.00791375 12.5014 3.57275e-05ZM19.2462 9.501L11.327 17.0655C11.2189 17.1688 11.0753 17.227 10.9259 17.2283C10.7714 17.2276 10.6235 17.1672 10.5129 17.0598L6.01832 12.5652C5.79187 12.3387 5.79187 11.9717 6.01832 11.7452C6.24476 11.5188 6.6118 11.5188 6.83824 11.7452L10.9315 15.8388L18.4438 8.66358C18.675 8.44213 19.042 8.44985 19.2637 8.68107C19.4854 8.91229 19.4774 9.27932 19.2462 9.501Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from "../../../mixins/component-mixins/root/iconMixin";

export default {
  name: "IconCompletedFilledSingleColor",
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        "rc-icon": true,
        "rc-icon-dot": true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses,
      };
    },
  },
};
</script>

<style scoped></style>
