import FoodDiaryService from '../../../../services/FoodDiaryService'
import { mapGetters, mapMutations } from 'vuex'
import DashBoardService from '../../../../services/DashboardService'
import headerEventBus from '../../../../event-buses/headerEventBus'
import moment from 'moment'
import FoodDiarySavedMealsService from '../../../../services/FoodDiarySavedMealsService'
import logMealMixin from '@/mixins/component-mixins/page/food-diary/logMealMixin'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  mixins:[logMealMixin],
  data: function () {
    return {
      currentTab: 'meal-plan',
      service: new FoodDiaryService(),
      mealLog: null,
      currentDate: null,
      showNetCarbs: false,
      popupLogSuccess: {
        saved: false,
        visible: false
      },
      enableSavedMeals: true,
      headerOptions: {
        show: true,
        left: '',
        right: 'close',
        rightBlink: false,
        modifiers: ['with-bg-color', 'small-title'],
        mainTitle: this.$i18n.t('message["food-diary.title"]'),
        header: {
          mainTitle: this.$i18n.t('message["food-diary.title"]'),
          subTitle: ''
        }
      }
    }
  },
  watch: {
    currentTab: function () {
      if (this.currentTab === 'new-meal') {
        this.setUserEventCustomMealAddingInitiated()
      }
    }
  },
  computed: {
    ...mapGetters({
      'getPreviousRoute': 'getPreviousRoute',
      getLastPage: 'foodDiaryStore/getLastPage'
    })
  },
  created () {
    this.setPageTitle(this.$t('message["food-diary.title"]'))
  },
  beforeMount () {
    this.hideLoading()
    pageReadyEvent()
    let dashBoardService = new DashBoardService()
    this.showNetCarbs = dashBoardService.isNetCarbsAsSeparateNutrient()
    this.setMealLog()
  },
  mounted () {
    headerEventBus.$on('close-button-click', this.goBack)
    this.setUserEventMealLoggingInitiated()

    pageReadyEvent()
  },
  beforeDestroy () {
  //  this.setRouteTransition({ name: 'fade', mode: 'out-in' })
    this.setLastSelectedRestaurantName('')
    headerEventBus.$off('close-button-click', this.goBack)
  },
  methods: {
    ...mapMutations({
      setTodayLog: 'foodDiaryStore/setTodayLog',
      updateMealLogsWithUpdatedMealLog: 'foodDiaryStore/updateMealLogsWithUpdatedMealLog',
      setEditableMealLog: 'foodDiaryStore/setEditableMealLog',
      setLastSelectedRestaurantName: 'foodDiaryStore/setLastSelectedRestaurantName',
      setLastSelectedMeals: 'foodDiaryStore/setLastSelectedMeals',
      setRouteTransition: 'setRouteTransition'
    }),
    setUserEventCustomMealAddingInitiated () {
      let context = (this.getPreviousRoute.path.includes('/dashboard')) ? 'dashboard' : 'food-diary'
      this.logEvent('FoodDiary.CustomMealAddingInitiated', {
        'context': context
      })
    },
    goBack () {
      this.setLastSelectedMeals(null)
      this.setEditableMealLog(null)
      this.setRouteTransition({ name: 'fade', mode: 'in-out' })
      let nextRoute = {}
      let query = {loading: '0'}
      if (this.getLastPage === 'dashboard') {
        nextRoute.path = '/dashboard'
      } else {
        nextRoute.path = '/meal-plans'
        query = {'page': 'food-diary', loading: '0'}
      }
      nextRoute.hash = '#foodDairy'
      nextRoute.query = query
      this.$router.replace(nextRoute)
    },
    setUserEventMealLoggingInitiated () {
      let context = (this.getPreviousRoute.path.includes('/dashboard')) ? 'dashboard' : 'food-diary'
      this.logEvent('FoodDiary.MealLoggingInitiated', {
        'context': context
      })
    },
    requestCustomMeal () {
      this.changeTab('custom-meal')
    },
    changeTab (tabName) {
      this.currentTab = tabName
    },

    dineOutMealSaved () {
      const savedMeals = new FoodDiarySavedMealsService()
      savedMeals.reset()
      this.enableSavedMeals = false
      this.$nextTick(() => {
        this.enableSavedMeals = true
      })
    },
    setMealLog () {
      this.mealLog = this.getEditableMealLogFromStore()
      if (!this.mealLog) {
        this.$router.push({
          path: 'meal-plans',
          query: {
            page: 'food-diary',
            loading: '0'
          }})
      }
      this.headerOptions.mainTitle = this.firstLetterCaps(this.$i18n.t('message[\'food-diary.add-meal\']'))
      this.headerOptions.subTitle = this.getDayText()
    },
    ...mapGetters({
      getEditableMealLogFromStore: 'foodDiaryStore/getEditableMealLog'
    }),

    getDayText () {
      if (this.getDateString() === moment().format('ll')) {
        return this.$i18n.t('message["general.today"]') + ' (' + this.getDateString() + ')'
      } else {
        return this.getDateString()
      }
    },
    getDateString () {
      if (this.mealLog && this.mealLog.logged_date) {
        return moment(this.mealLog.logged_date).format('ll')
      }
      return moment().format('ll')
    },
    setSuccess (event) {
      this.popupLogSuccess.visible = true
      this.popupLogSuccess.saved = event.saved || false
      this.setLastSelectedMeals(null)
      if (event.type === 'custom-meal') {
        let foodDiarySavedMealsService = new FoodDiarySavedMealsService()
        foodDiarySavedMealsService.reset()
      }
    },
    emitLog (log) {
      this.updateMealLogsWithUpdatedMealLog(log)

      if (log.logged_date === moment().locale('en').format('YYYY-MM-DD')) {
        this.setTodayLog(log)
      }
      this.mealLog = null
      this.$nextTick(() => {
        this.mealLog = log
      })
    },
    emitLogWithoutRefresh (log) {
      this.updateMealLogsWithUpdatedMealLog(log)

      if (log.logged_date === moment().locale('en').format('YYYY-MM-DD')) {
        this.setTodayLog(log)
      }
      this.mealLog = log

    },
    requiredMacroAndCalChanged (log) {
      this.mealLog = log
    }

  }

}
