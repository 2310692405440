<template>
  <div :class="componentClasses" v-on="$listeners" @click="clicked()">
    <div class="gc-action__wrap">
      <icon-favorite
        v-if="state === 'active'"
        :size="'sm3'"
        class="gc-meal__action-icon"
      />
      <icon-favorite-linear v-else :size="'sm3'" class="gc-meal__action-icon" />
    </div>

    <text-body-small v-if="showText" class="gc-meal__action-text">
      {{ $t('message[\'meal-plan.favorite\']')}}
    </text-body-small>
  </div>
</template>

<script>
import IconFavorite from "../../root/icons/IconFavorite";
import IconFavoriteLinear from "../../root/icons/IconFavoriteLinear";
import TextBodySmall from "../typography/TextBodySmall";

export default {
  name: "ActionMealFavorite",
  components: {
    IconFavorite,
    IconFavoriteLinear,
    TextBodySmall,
  },
  props: {
    state: {
      type: String,
      default: "",
    },
    showText: {
      type: Boolean,
      default: true,
    },
    limitText: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    componentClasses: function () {
      return {
        "gc-meal__action": true,
        "gc-meal__action-favorite": true,
        "gc-meal__action-disabled": this.state === "disabled" || this.disabled,
        "gc-meal__action-onTap": this.state === "onTap",
        "gc-meal__action-active": this.state === "active",
      };
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.favorite"]');
      if (this.limitText) {
        text = text.substring(0, 2) + "...";
      }
      return text;
    },
  },

  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>
<style scoped></style>
