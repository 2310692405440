<template>
    <div class="gc-card-navigation-skeleton">
        <div class="gc-card-navigation">
            <div class="gc-header-simple">
                <AnimatedPlaceholder width="200px" height="18px" />
                <AnimatedPlaceholder width="12px" height="24px" />
            </div>
        </div>
    </div>
</template>
<script>
import AnimatedPlaceholder from '../animators/AnimatedPlaceholder'

export default {
    components: {
        AnimatedPlaceholder
    }
}
</script>
<style>
.gc-card-navigation-skeleton {
    .gc-card-navigation {
        margin-bottom: 12px;
    }
}
</style>