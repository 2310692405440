<template>
  <div class="pc-card-dashboard-layout-info">
    <div>
      <icon-info size="sm2"></icon-info>
    </div>
    <div>
      <text-body-extra-small>
        <span style="font-weight: 700;">Note:</span> <span>This template is generated based on the features currently enabled, and the actual Members’ Dashboard may vary.</span>
      </text-body-extra-small>


    </div>
  </div>
</template>

<script>
import IconInfo from "@/components/root/icons/IconInfo.vue";
import TextBodyMedium from "@/components/global/typography/TextBodyMedium.vue";
import TextBodySmall from "@/components/global/typography/TextBodySmall.vue";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall.vue";

export default {
  name: "CardDashboardLayoutInfo",
  components: {TextBodyExtraSmall, TextBodySmall, TextBodyMedium, IconInfo}
}
</script>
