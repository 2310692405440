<template>
    <div class="pc-not-found">
      <div class="pc-not-found__content container">
          <icon-page-not-found class="pc-not-found__icon" />
          <text-body-medium class="pc-not-found__title" line-height="lh-lg" weight="extra-bold" >
            {{$t("message['page-not-found.title']")}}
          </text-body-medium>
        <text-body-small class="pc-not-found__description"  line-height="lh-lg">
          {{$t("message['page-not-found.description']")}}
        </text-body-small>

        <button-custom class="pc-not-found__button-back" type="outline" :text="backButtonText" @click="goBack"/>
      </div>
    </div>
</template>

<script>
import IconPageNotFound from '../../../root/icons/IconPageNotFound'
import TextBodyMedium from '../../../global/typography/TextBodyMedium'
import TextBodySmall from '../../../global/typography/TextBodySmall'
import ButtonCustom from '../../../global/buttons/ButtonCustom'
import { mapGetters } from 'vuex'
import AuthService from '../../../../services/AuthService'

export default {
  name: 'PageNotFound',
  components: {
    ButtonCustom,
    TextBodySmall,
    TextBodyMedium,
    IconPageNotFound },
  beforeMount () {
    document.body.style.backgroundColor = templateConfig.style_variables['$bg-infoPages']
  },
  mounted () {
    this.hideLoading()
  },
  computed: {
    ...mapGetters({
      previousRoute: 'getPreviousRoute'
    }),
    backButtonText: function () {
      let auth = new AuthService()
      if (this.previousRoute.name || !auth.isValidToken()) {
        return this.$t('message[\'page-not-found.back\']')
      }
      return this.$t('message[\'page-not-found.back-to-dashboard\']')
    }
  },
  methods: {
    goBack () {
      let auth = new AuthService()
      if (!auth.isValidToken()) {
        this.$router.replace({path: '/login'})
      } else if (!this.previousRoute.name) {
        this.$router.replace(this.previousRoute)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped>

</style>
