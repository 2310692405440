<template>
  <div class="gc-page__content-image">
    <AnimatedPlaceholder width="100%" height="210px" />
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";

export default {
  components: { AnimatedPlaceholder },
};
</script>
