<template>
  <page class="page-workout" :header="headerOptions" >
    <page-container>
      <!--   page wrapper -->
      <div class="page-workout__header-wrapper" v-if="program">
        <text-heading2
          :lineHeight="'multi'"
          :weight="'extra-bold'"
          class="page-workout__header"
          >{{ program.getName() }}</text-heading2
        >
        <text-body-extra-small
          :lineHeight="'multi'"
          class="page-workout__sub-header"
          v-html="program.getDescription()"
        ></text-body-extra-small>
      </div>

      <div
        class="page-workout__card-image-wrapper"
        v-bind:key="'week' + key"
        v-for="(week, key) in weeks"
      >
        <text-body-regular
          :lineHeight="'multi'"
          :weight="'extra-bold'"
          class="page-workout__card-image-title"
          v-once
        >
          {{ numberWordToNumeric(week.getName()) }}
        </text-body-regular>
        <card-image
          @click="navigateNext(week.getID())"
          class="page-workout__card-image"
          :image="week.getTileImage()"
          :completed="week.getIsCompleted()"
        />
      </div>
      <popup-not-found-error v-if="showPageNotFoundPopup" @close="goBack()" />
    </page-container>
  </page>
</template>

<script>
import pageSingleWorkoutMixin from "../../../../mixins/component-mixins/page/workout/pageSingleWorkoutMixin";
import Page from "../../../global/pages/Page";
import PageContainer from "../../../global/pages/page-sub-components/PageContainer";
import TextHeading2 from "../../../global/typography/TextHeading2";
import TextBodyExtraSmall from "../../../global/typography/TextBodyExtraSmall";
import TextBodyRegular from "../../../global/typography/TextBodyRegular";
import CardImage from "./page-components/CardImage";
import PopupNotFoundError from "../../../global/popups/PopupNotFoundError";

export default {
  name: "PageWeeks",
  mixins: [pageSingleWorkoutMixin],
  components: {
    PopupNotFoundError,
    CardImage,
    TextBodyRegular,
    TextBodyExtraSmall,
    TextHeading2,
    PageContainer,
    Page,
  },
  data: function () {
    return {
      showPageNotFoundPopup: false,
    };
  },
  beforeMount() {
    this.loadPageData();
  },
  mounted() {
    this.setPageClass();
    this.setHeader(this.headerOptions);
    // this.logEvent('Viewed Workout Program', {
    //   workout_program_id: this.params.workout
    // })
  },
  methods: {
    onWorkoutProgramLoadError() {
      this.showPageNotFoundPopup = true;
    },
    goBack() {
      this.$router.go(-1);
    },
    afterGetMealPlanOptionDataError(response) {
      if (response && response.status === 404) {
        this.goToNotFoundPage();
      }
      this.hideLoading();
    },
    goToNotFoundPage() {
      this.$router.replace({ path: "/404" });
    },
  },
};
</script>
