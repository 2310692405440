<template>
  <div
    :class="componentClasses"
  >
    <div class="pc-timer__wrap d-flex">
      <transition>
        <countdown
          ref="countdownWorkout"
          @progress="countdownProgress"
          :time="time*1000"
          :auto-start="play"
          v-if="show"
          class="pc-timer-group pc-timer__left"
          v-on:end="workoutEnded()"
        >
          <template slot-scope="props">
            <div class="pc-timer-workout__text-area pc-timer-workout__text-area--with-header"
                 v-if="!!name && state!=='started'">
              <text-content
                class="pc-timer-workout__header"
                size="lg1"
                line-height="multi"
                weight="extra-bold">
                <span>{{name}}</span>
              </text-content>
              <div class="pc-timer-workout__time">
                <text-content
                  class="pc-timer-workout__time-value"
                  size="sm4"
                  line-height="multi"
                  weight="extra-bold"
                >
                  <span>{{headerTime (props.totalMinutes, props.seconds)}} {{headerTimeType (props.totalMinutes, props.seconds)}}</span>
                </text-content>
              </div>
            </div>

            <div class="pc-timer-workout__text-area pc-timer-workout__text-area--without-header"
                 style="transform: translate3d(0,0,0)"
                 v-else-if="state!=='started'">
              <text-content
                class="pc-timer-workout__time-value"
                size="xl1"
                line-height="multi"
                weight="medium"
              >
                <span class="pc-timer-set-timer__time"
                >{{setTimerNumbers(props.totalMinutes) }}:{{setTimerNumbers(props.seconds) }}</span>
              </text-content>

            </div>
            <div class="pc-timer-workout__text-area pc-timer-workout__text-area--playing" v-else>
              <div class="pc-timer-workout__time">
                <text-content
                  class="pc-timer-workout__time-value"
                  size="xl1"
                  line-height="multi"
                  weight="medium"
                >
                  <span v-if="play">{{setTimerNumbers(props.totalMinutes) }}:{{setTimerNumbers(props.seconds) }}</span>
                  <span v-if="!play">{{headerTime (props.totalMinutes, props.seconds)}} {{headerTimeType (props.totalMinutes, props.seconds)}}</span>
                </text-content>
              </div>
            </div>

          </template>
        </countdown>
      </transition>
      <div class="pc-timer__right text-right" @click="playWorkoutCountdown">
          <span v-if="play">
            <icon-pause size="lg4" class="pc-timer-workout__icon-pause"></icon-pause>
          </span>
        <span v-if="!play">
             <icon-play size="lg4" class="pc-timer-workout__icon-play"/>

          </span>
      </div>
    </div>
    <transition>
      <div class="pc-timer__footer">
        <div class="row">
          <div class="col text-center" v-if="play">
            <text-content
              class="timer__play-btn pc-timer__action-button"
              size="sm1"
              weight="extra-bold"
              line-height="multi"
              v-on:click="restartCountdown()"
            >{{$t('message["general.restart"]')}}
            </text-content>
          </div>
          <div class="col text-center" v-if="showRest">
            <text-content
              class="timer__play-btn pc-timer__action-button"
              size="sm1"
              weight="extra-bold"
              line-height="multi"
              v-on:click="showRestCountdown()"
            >{{$t('message["general.rest"]')}}
            </text-content>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>
<script>
import Vue from 'vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import timerWorkoutMixin from '../../../../../mixins/component-mixins/global/timers/timerWorkoutMixin'
import IconPlay from '../../../../root/icons/IconPlay'
import IconPause from '../../../../root/icons/IconPause'
import TextContent from '../../../../root/TextContent'

Vue.component(VueCountdown.name, VueCountdown)
export default {
  name: 'WorkoutTimer',
  components: {
    TextContent,
    IconPause,
    IconPlay
  },
  mixins: [timerWorkoutMixin],
  computed: {
    componentClasses: function () {
      return {
        'pc-timer': true,
        'pc-timer-workout': true,
        'pc-timer-workout--pending': this.state === 'pending',
        'pc-timer-workout--playing': this.state === 'started',
        'pc-timer-workout--paused': this.state === 'paused'
      }
    }
  }
}
</script>
