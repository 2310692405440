<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '../../../../../../helpers/validation'
import pageConfigMixin from '../../../../../../mixins/pageConfigMixin'
import { mapMutations } from 'vuex'

/**
   * parent class of the all subscription-form-components
   * defined the basic structure of the subscription-forms-components
   * handle the component forms submit and validation
   */
export default {
  name: 'FormBaseComponent',
  mixins: [pageConfigMixin],
  props: {
    inputKey: {
      type: [String, Number],
      required: true
    },
    errors: {
      type: [Array, Object],
      default: function () {
        return {}
      }
    },
    title: {
      type: Object,
      default: function () {
        return { main: '', sub: '', image: '' }
      }
    },
    inputValue: {
      default: ''
    },
    submitText: {
      default: ''
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    extraData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    validationRules: {
      type: String,
      default: ''
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      error: [''],
      header: '',
      isValueChanged: false,
      nextComponentID: 0,
      pageClass: [''],
      validated: {
        status: false,
        error: ''
      }
    }
  },
  beforeCreate () {
    document.body.style.backgroundColor = templateConfig.style_variables['$bg-infoPages']
  },
  mounted () {
    this.setPageClass()
  },
  destroyed () {
    this.showLoading()
    if (this.pageClass && document.querySelectorAll('.macroapp').length > 0) {
      setTimeout(() => {
        if (Array.isArray(this.pageClass)) {
          this.pageClass.forEach(cls => {
            if (cls) {
              this.removeAppClass(cls)
            }
          })
        } else {
          this.removeAppClass(this.pageClass)
        }
      }, 200)
    }
  },
  methods: {
    ...mapMutations({
      viewInstructionsPopup: 'instructionPopupStore/show',
      setInstructionsPopupContent: 'instructionPopupStore/setContent'
    }),
    /**
       * set the page main class modifier class
       */
    setPageClass () {
      // set page class for custom styles
      document.querySelectorAll('.macroapp').forEach(element => {
        if (Array.isArray(this.pageClass)) {
          this.pageClass.forEach(className => {
            if (className) {
              element.classList.add(className)
            }
          })
        } else if (this.pageClass) {
          element.classList.add(this.pageClass)
        }
      })
      this.hideLoading()
    },
    /**
     * remove the page main class modifier class
     *
     * */
    removeAppClass (appClass) {
      document.querySelectorAll('.macroapp').forEach(appEle => {
        appEle.classList.remove(appClass)
      })
    },
    /**
       * set the forms data and validate value
       * @param event
       */
    setFormData (event) {
    },
    /**
       * navigate to the next component in Main after validation is passed
       */
    nextComponent () {
      const inputObj = { key: this.inputKey, value: this.inputValue }
      this.$emit('submit', inputObj)
    }

  }
}
</script>
