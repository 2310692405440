<template>
  <div>
    <component :key="page" v-bind:is="dynamicComponent"></component>
  </div>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import PageNotifications from '../../template-1/dashboard/PageNotifications'
import PageDashboard from './PageDashboard.vue'
import MainPageMixin from '../../../../mixins/mainPageMixin'
import DashboardService from '../../../../services/DashboardService'
import PeriodTracker from './PagePeriodTracker'
import MoodTracker from './PageMoodTracker'
import PageStepTracker from './PageStepTracker'
import HabitsTracker from './PageHabitsTracker'
import HabitsTrackerManager from './PageHabitsTrackerManager'
import NutritionService from '../../../../services/nutrition/NutritionService'
import PageCommunity from './PageCommunity'
import PageCreatorDashboard from './PageCreatorDashboard'
import {checkNotAMember} from '@/helpers/user'
import PageNavigation from './PageNavigation'
import PageMemberProgress from './PageMemberProgress'
import PageCreatorAnalytics from '../creator/PageCreatorAnalytics'
import { showNewDashboardLayout} from '@/includes/TemplateSettings'
import {mapActions} from 'vuex'
import DashBoardService from '../../../../services/DashboardService'
import moment from 'moment/moment'
import UserEventsService from '@/services/UserEventsService'
import PageDashboardEditor from "@/components/layout/template-1/dashboard/PageDashboardEditor.vue";
import ImageService from "@/services/ImageService";
import store from "@/store";
import PageDashboardNewLayout from "@/components/layout/template-1/dashboard/PageDashboardNewLayout.vue";

export default {
  name: 'DashBoardMain',
  mixins: [MainPageMixin],
  extends: BaseComponent,
  components: {},
  data: function () {
    return {
      components: {
        'dashboard-home': {design1: PageDashboardNewLayout},
        'dashboard-home-creator': {design1: PageCreatorDashboard},
        'dashboard-home-creator-edit': {design1: PageDashboardEditor},
        'creator-analytics': {design1: PageCreatorAnalytics},
        'dashboard-notifications': {design1: PageNotifications},
        'mood-tracker': {design1: MoodTracker},
        'step-tracker': {design1: PageStepTracker},
        community: {design1: PageCommunity},
        'habit-tracker': {design1: HabitsTracker},
        'habit-tracker-manage': {design1: HabitsTrackerManager},
        'period-tracker': {design1: PeriodTracker},
        navigation: {design1: PageNavigation},
        'member-progress': {design1: PageMemberProgress},
      },
    }
  },
  computed: {
    page: function () {
      let queryParams = this.$route.query

      if (checkNotAMember()) {
        if ('community' in queryParams) {
          return 'community'
        }
        if ('edit' in queryParams) {
          return 'dashboard-home-creator-edit'
        }
        if ('member-progress' in queryParams) {
          return 'member-progress'
        }
        if ('page' in queryParams && queryParams.page === 'notifications') {
          return 'dashboard-notifications'
        } else if (
          'page' in queryParams &&
          queryParams.page === 'creator-analytics'
        ) {
          return 'creator-analytics'
        } else {
          return 'dashboard-home-creator'
        }
      }

      if ('page' in queryParams && queryParams.page === 'notifications') {
        return 'dashboard-notifications'
      } else if ('community' in queryParams) {
        return 'community'
      } else if ('page' in queryParams && queryParams.page === 'navigation') {
        return 'navigation'
      } else if ('page' in queryParams && queryParams.page === 'mood-tracker') {
        return 'mood-tracker'
      } else if ('page' in queryParams && queryParams.page === 'step-tracker') {
        return 'step-tracker'
      } else if (
        'page' in queryParams &&
        queryParams.page === 'habit-tracker-manage'
      ) {
        return 'habit-tracker-manage'
      } else if (
        'page' in queryParams &&
        queryParams.page === 'habit-tracker'
      ) {
        return 'habit-tracker'
      } else if (
        'page' in queryParams &&
        queryParams.page === 'period-tracker'
      ) {
        return 'period-tracker'
      } else if ('member-progress' in queryParams) {
        return 'member-progress'
      } else {
        return 'dashboard-home'
      }
    },
  },
  mounted () {
    this.setPageName(this.page)
  },
  created () {
    if (showNewDashboardLayout()) {
    //  this.components['dashboard-home'].design1 = PageDashboardNewLayout
    }
    this.showMealFavoritesFeaturePopup()
  },
  beforeRouteEnter: function (to, from, next) {
    store.commit('showLoggingLoading')
    if (checkNotAMember()) {
      let initCreatorData = async () => {
        let service = new DashboardService()
        await service.getPageData()
        await service.getUserConfig()
      }

      initCreatorData().finally(() => {
        store.commit('hideLoggingLoading')
        next()
      })
      return
    }

    let initData = async () => {
      let service = new DashboardService()
      await service.getPageData()
      await service.getUserData()
      let mealService = new NutritionService()
      await mealService.init()
      await service.getUserConfig()


      const images = new ImageService()
      images.setBeforeRouteImageListCache(to)
    }

    initData().finally(() => {
      store.commit('hideLoggingLoading')
      next()
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (!to.query.hasOwnProperty('loading')) {
    //  this.showLoading()
    }
    if (from.fullPath.includes('notifications')) {
      this.initCommunityNotificationDetails()
    }
    if (from.fullPath.includes('habit-tracker')) {
      setTimeout(() => {
        next()
      }, 400)
    } else {
      next()
    }
  },
  methods: {
    ...mapActions({
      initCommunityNotificationDetails: 'communityStore/initiate'
    }),
    showMealFavoritesFeaturePopup () {
      const pathText = this.$i18n.t('message[\'meal-favorites.feature-popup-description-two-path\']')
      const pathHTML = `<b>${pathText}</b>`
      const popupContent = this.$i18n.t('message[\'meal-favorites.feature-popup-description-one\']') + '<br><br>' + this.$i18n.t('message[\'meal-favorites.feature-popup-description-two\']', {path: pathHTML})
      const dashboard = new DashBoardService()
      const nService = new NutritionService()
      let allMealPlans = nService.getAllMealPlans()
      if (
        allMealPlans.length != 0 &&
        templateConfig.instance_config_timestamps
          .enable_meal_favorites &&
        templateConfig.instance_config_timestamps
          .enable_meal_favorites.created_at
      ) {
        const enabledDate = moment(
          templateConfig.instance_config_timestamps
            .enable_meal_favorites.created_at
        )
        const userRegDate = dashboard.getRegisteredDate()
        if (userRegDate.isBefore(enabledDate)) {
          UserEventsService.mealFavoritesFeaturePopupView('', popupContent)
        }
      }
    },
  },
}
</script>
