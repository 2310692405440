<template>
  <card-content>
    <card-habits-list-skeleton v-if="isLoading"/>
    <div v-if="!isLoading && habitsEntries" :class="componentClasses">
      <div class="gc-card-habits-list__wrapper">
        <div
          class="gc-card-habits-list__header-wrapper"
          @click="clickForward()"
        >
          <div class="gc-card-habits-list__header-wrapper-left-section">
            <div class="gc-card-habits-list__header-text">
              <text-content
                :size="'sm4'"
                :weight="'extra-bold'"
                :line-height="'multi'"
              >
                {{ $t('message["habit-tracker.header"]') }}
              </text-content>
              <div @click.stop="clickedInfo()">
                <icon-info
                  class="gc-card-habits-list__info-icon"
                  :size="'sm4'"
                />
              </div>
            </div>
            <div class="gc-card-habits-list__header-description">
              <text-content :size="'sm1'" :line-height="'multi'">
                {{
                  $t('message["habit-tracker.header-description"]', {
                    date: date,
                  })
                }}
              </text-content>
            </div>
          </div>
          <div class="gc-card-habits-list__header-wrapper-right-section">
            <icon-forward
              class="gc-card-habits-list__forword-icon"
              :size="'sm4'"
            />
          </div>
        </div>
        <!-- <loading-buffer v-if="isLoading" :height="'200'" /> -->
        <div
          v-if="!isLoading && habitsEntries"
          class="gc-card-habits-list__wrapper-content">
          <transition-group
            name="fade"
            tag="div"
            class="gc-card-habits-list__wrapper-content"
          >
            <div
            v-for="(entry, entryIndex) in habitsFromIds"
              :key="entryIndex"
              class="gc-card-habits-list__wrapper-list-item"
            >
              <div class="gc-card-habits-list__wrapper-list-title">
                <text-content
                  :size="'sm2'"
                  :weight="'medium'"
                  :line-height="'multi'"
                >
                  {{ entry.habit.getName() }}
                </text-content>
              </div>
              <div class="gc-card-habits-list__wrapper-list-icons">
                <!-- show when obj has this ID and label === -->
                <!-- show when the habit is completed -->
                <icon-completed-filled-single-color
                  v-if="showIconByhHabitsStatuses(entry.habit.id, 'yes')"
                  :disabled="false"
                  :size="'md1'"
                  @click="handleMarkHabit('yes', entry.habit.id)"
                />
                <!-- show when obj has this ID and label !== or not ID -->
                <icon-completed-linear
                  v-else-if="showIconByhHabitsStatuses(entry.habit.id)"
                  :disabled="false"
                  :size="'md1'"
                  @click="handleMarkHabit('yes', entry.habit.id)"
                />
                <!-- show when obj has this ID and label === -->
                <icon-arrow-circular-filled-v3
                  v-if="showIconByhHabitsStatuses(entry.habit.id, 'skip')"
                  :disabled="false"
                  :size="'md1'"
                  @click="handleMarkHabit('skip', entry.habit.id)"
                />
                <!-- show when obj has this ID and label !== or not ID -->
                <icon-arrow-circular-linear-v3
                  v-else-if="showIconByhHabitsStatuses(entry.habit.id)"
                  :disabled="false"
                  :size="'md1'"
                  @click="handleMarkHabit('skip', entry.habit.id)"
                />
              </div>
            </div>

          </transition-group>
        </div>
        <div
          v-if="
            !isLoading &&
            hadHabits &&
            (!habitsEntries || habitsFromIds.length === 0)
          "
          class="gc-card-habits-list__all-complete"
        >
          <text-body-extra-small>
            {{ $t('message["habit-tracker.card-all-complete"]') }}
          </text-body-extra-small>
        </div>
        <div
          v-if="
            !isLoading &&
            !hadHabits &&
            (!habitsEntries || habitsFromIds.length === 0)
          "
          class="gc-card-habits-list__getting-started"
        >
          <text-body-extra-small>
            {{ $t('message["habit-tracker.card-getting-started"]') }}
          </text-body-extra-small>
        </div>
      </div>
      <div v-if="!isLoading">
        <text-body-extra-small
          class="gc-card-habits-list__wrapper-list-button gc-card-habits-list__wrapper-list-button--manage"
          v-if="
            (!habitsEntries || widgetHabitsList().length === 0) && !hadHabits
          "
          @click="handleClickManage"
        >
          {{ $t('message["habit-tracker.card-button"]') }}
        </text-body-extra-small>
        <text-body-extra-small
          class="gc-card-habits-list__wrapper-list-button"
          v-if="habitsEntries && widgetHabitsRestList().length > 0"
          @click="handleClickMore"
        >
          <!-- {{ $t('message["habit-tracker.card-button-more"]') }} -->
          {{ widgetHabitsRestList().length }} more
        </text-body-extra-small>
      </div>
    </div>
  </card-content>
</template>
<script>
import TextContent from "../../root/TextContent";
import HeaderSimple from "../headers/HeaderSimple";
import IconCompletedLinear from "../../root/icons/IconCompletedLinear";
import IconForward from "../../root/icons/IconForward";
import IconInfo from "../../root/icons/IconInfo";
import IconIncompletedLinear from "../../root/icons/IconIncompletedLinear";
import IconArrowCircularLinearV3 from "../../root/icons/IconArrowCircularLinearV3";
import IconArrowCircularFilledV3 from "../../root/icons/IconArrowCircularFilledV3";
import IconCompletedFilledSingleColor from "../../root/icons/IconCompletedFilledSingleColor";
import IconIncompletedFilled from "../../root/icons/IconIncompletedFilled";
import CardContent from "./CardContent";
import DashBoardService from "../../../services/DashboardService";
import { mapGetters, mapMutations } from "vuex";
import GridCol from "../grid/GridCol";
import GridRow from "../grid/GridRow";
import moment from "moment";
import HabitDayEntries from "@/services/habits-tracker/HabitDayEntries";
import TextBodyExtraSmall from "../typography/TextBodyExtraSmall";
import HabitEntry from "@/services/habits-tracker/HabitEntry";
import LoadingBuffer from "@/components/layout/template-1/global/widgets/LoadingBuffer.vue";
import CardHabitsListSkeleton from './CardHabitsListSkeleton'

export default {
  name: "CardHabitsList",
  components: {
    GridCol,
    GridRow,
    TextContent,
    HeaderSimple,
    IconCompletedLinear,
    IconForward,
    IconInfo,
    IconIncompletedLinear,
    IconCompletedFilledSingleColor,
    IconIncompletedFilled,
    IconArrowCircularLinearV3,
    IconArrowCircularFilledV3,
    CardContent,
    TextBodyExtraSmall,
    LoadingBuffer,
    CardHabitsListSkeleton
  },
  props: {
    /**
     * list of habits with statuses for 1 day
     * @type {{date: String entries: HabitEntry[]}}
     * date format: 2024-09-03
     * @type HabitEntry
     *  {
     *    date: String,
     *    entry: {
     *        habit_id,
     *        id,
     *        created_at,
     *        set_on,
     *        updated_at,
     *        label: 'yes' | 'skip'
     *    },
     *    habit: Habit
     *  }
     */
    habitsEntries: {
      type: HabitDayEntries, //
      required: true,
      default: new HabitDayEntries(moment().locale('en').format('YYYY-MM-DD'), []),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submittingEntryIndexes: [],
      habitsIdsToShow: [],
      habitsStatuses: {}, // {[key: id]: 'yes' | 'skip'}
      isSubmitting: false,
    };
  },
  mounted () {
    console.log("%cCardHabitsList.vue => ", 'background-color: yellow; color: black; padding: 0 5px;', {
        habitsEntries: this.habitsEntries,
        habitsIdsToShow: this.habitsIdsToShow,
        habitsStatuses: this.habitsStatuses,
        loading: this.isLoading,
        restList: this.widgetHabitsRestList()
      });
    this.setWidgetList();
  },
  watch: {
    isLoading: {
      handler: function () {
        this.setWidgetList();
        console.log(
          "%cCardHabitsList.vue => ",
          "background-color: yellow; color: black; padding: 0 5px;",
          {
            habitsEntries: this.habitsEntries,
            habitsIdsToShow: this.habitsIdsToShow,
            habitsStatuses: this.habitsStatuses,
          }
        );
      },
      // immediate: true,
      // deep: true,
    },
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: "getPlanExpiredPopupShow",
    }),
    date() {
      return moment().format("ll");
    },
    componentClasses() {
      return {
        "gc-card-habits-list": true,
      };
    },
    habitsFromIds() {
      // ^ this is a list of ids filtered to show in the widget, with 5 items
      // used as a source to mark, remove and add habits to the widget
      // because service will update lists instantly
      const res = this.habitsIdsToShow.map((id) => {
        const list = this.habitsEntries.getEntries();
        const entry = list.find((inst) => inst.habit.id === id);
        return entry;
      });
      return res;
    },
    hadHabits() {
      return this.habitsEntries && this.habitsEntries.getEntries().length > 0;
    },
    hasExpired() {
      let dashboard = new DashBoardService();
      if (!dashboard.isEntriesEditable() && !this.getPlanExpiredPopupShow) {
        return true;
      }

      return false;
    }
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: "showSubscriptionExpiredPopup",
    }),
    logger() {
      console.log("%cDebug?: ", 'color: red', {rest: this.widgetHabitsRestList(), all: this.widgetHabitsAll()})
    },
    setWidgetList() {
      if(!this.habitsEntries) return console.log("No habits entries");
      // get habits ID from the service to set to a local state for a further manipulation
      const habitsIdsList = this.widgetHabitsList().map(inst => inst.habit.id);
      this.habitsIdsToShow = habitsIdsList;
    },
    /** fixed array of first 5 habits */
    widgetHabitsList() {
      const fullList = this.widgetHabitsAll();
      return fullList.slice(0, 5);
    },
    // get all habits, sorted by last set_on date
    widgetHabitsAll() {
      if(!this.habitsEntries){
        return []
      }
      const habits = this.habitsEntries.getUnmarkedEntries();
      // habits.sort((a, b) => {
      //   const dateA = new Date(a.getHabitLastUseDate());
      //   const dateB = new Date(b.getHabitLastUseDate());

      //   if(dateA > dateB) return -1;
      //   if(dateA < dateB) return 1;

      //   if(a.habit.getName().toLowerCase() > b.habit.getName().toLowerCase()) return 1;
      //   if(a.habit.getName().toLowerCase() < b.habit.getName().toLowerCase()) return -1;

      //   return 0;
      // });
      return habits;
    },
    // rest habits unshowed in the widget
    widgetHabitsRestList() {
      // limit habits to 5 items
      return this.widgetHabitsAll().slice(5);
    },
    clickForward() {
      this.clickHeader();
      this.logEvent("HabitTracker.OpenedTrackerCalendar", {}, false);
    },
    showIconByhHabitsStatuses(id, status = null) {
      // ^ this method is used to determine if a particular icon should be shown
      // based on the local state where marked entries will be saved as properties of the object

      //order is important, first test less likely outcome
      if(status) {
        // testing filled icon
        // if status passed but not such prop in the object - its not marked, false
        if(!this.habitsStatuses[id]) return false;
        // if there is a record and status is not matching - not this icon was clicked, true
        if(this.habitsStatuses[id] && this.habitsStatuses[id] !== status) return false;
        //otherwise it was clicked, true
        return true;
      } else {
        //then apply state to the rest of cases
        return true;
      }
    },
    getLogStatusName(raw) {
      switch (raw) {
        case "yes":
          return "completed";

        default:
          return "skipped";
      }
    },
    clickedInfo() {
      this.$emit("showInstructions");
    },
    clickHeader() {
      this.$emit("header-click");
    },
    //HT-TODO - OpenedTrackerManager event has been added, notify
    handleClickManage() {
      this.$emit("manage-click");
      this.logEvent("HabitTracker.OpenedTrackerManager", {}, false);
    },
    handleClickMore() {
      this.$emit("header-click");
      this.logEvent("HabitTracker.OpenedTrackerCalendar", {}, false);
    },
    handleMarkHabit(status, habitId) {
        if(this.isSubmitting) return console.log("Already in process");
        // some guards left from the previous implementation
        let dashboard = new DashBoardService();
        if (this.hasExpired) return this.showSubscriptionExpiredPopup("habitTracker_select_status");
        if(!dashboard.isEntriesEditable()) {
          this.showSubscriptionExpiredPopup("habitTracker_select_status");
          return console.log("Entries are not editable");
        }

        this.isSubmitting = true;

        const clickedEntry = this.habitsEntries.entries.find(inst => inst.habit.id === habitId);
        if(!clickedEntry) return console.error("Habit not found", habitId);

        if(this.habitsStatuses[clickedEntry.habit.id]) {
          this.isSubmitting = false;
          return console.log("Already in process", clickedEntry.habit.id)
        }

        const nextHabit = this.widgetHabitsRestList().length === 1 ? this.widgetHabitsRestList()[0] : this.widgetHabitsRestList().shift();
        this.habitsStatuses[clickedEntry.habit.id] = status; // add status to local object, this is a source of "icons" visibility

        clickedEntry.saveEntry(status, false).then((newEntry) => {
          // remove last unseen habit from the list

          if(this.widgetHabitsRestList().length === 1) this.widgetHabitsRestList().shift();

          this.isSubmitting = false;

          setTimeout(() => {
            this.deferredRemoveMarkedHabit(habitId, nextHabit?.habit?.id);
          }, 1500);
          this.logEvent(
            "HabitTracker.HabitStatusChecked",
            {
              habit: clickedEntry.habit.getName(),
              status: this.getLogStatusName(status),
              tracking_date: clickedEntry.getDate(),
            },
            false
          );
          clickedEntry.setEntry(newEntry)
        }).catch((error) => {
          console.error(error)
          // this.habitsStatuses[clickedEntry.habit.id] = null;
          if(nextHabit) {
            this.widgetHabitsRestList().unshift(nextHabit);
          }
          if(!clickedEntry.habit.id) return
          this.habitsStatuses[clickedEntry.habit.id] = null;
        })

    },

    // method to remove marked habit from the widget with a delay,
    // by removing it's ID from the source list - habitsIdsToShow
    deferredRemoveMarkedHabit(removedHabitId, addedHabitId) {
      this.habitsIdsToShow = this.habitsIdsToShow.filter( id => id !== removedHabitId);
      // also remove status record from the object habitsStatuses
      this.habitsStatuses[removedHabitId] = null;
      // trigger next delayed step, adding another habit
      if(!addedHabitId) return console.log("No more habits to show");
      setTimeout(() => {
        this.defferedAddUnseenHabit(addedHabitId);
      }, 500);
    },
    // method to add another habit to the widget with a delay
    // will use the rest of the habits from the list to get 1 unshowed and add it to the widget
    defferedAddUnseenHabit(addedHabitId) {
      // const unseenHabits = this.widgetHabitsAll().find( habit => habit.habit.id === addedHabitId);
      // if(unseenHabits.length === 0) return console.log("No more habits to show");
      // const nextHabit = unseenHabits.shift();
      if(!addedHabitId) return console.log("No more habits to show", addedHabitId);
      this.habitsIdsToShow.push(addedHabitId);
    },
  },
};
</script>
