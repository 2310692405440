<template>
    <div class="pc-meal-plan-home-slider-skeleton">
        <div class="gc-card-meal__slider">
            <div class="gc-card-meal__slider-top">
                <div class="gc-card-meal__header">
                    <AnimatedPlaceholder width="100px" height="30px" />
                </div>
                <div class="gc-card-meal__sub-header">
                    <AnimatedPlaceholder width="140px" height="12px" />
                </div>
            </div>
            <div class="gc-card-meal__slider-bottom">
                <div class="gc-card-meal__button">
                    <AnimatedPlaceholder width="60px" height="20px" />
                </div>
            </div>
        </div>
        <div class="gc-card-meal__slider">
            <div class="gc-card-meal__slider-top">
                <div class="gc-card-meal__header">
                    <AnimatedPlaceholder width="100px" height="30px" />
                </div>
                <div class="gc-card-meal__sub-header">
                    <AnimatedPlaceholder width="140px" height="12px" />
                </div>
            </div>
            <div class="gc-card-meal__slider-bottom">
                <div class="gc-card-meal__button">
                    <AnimatedPlaceholder width="60px" height="20px" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AnimatedPlaceholder from '@/components/global/animators/AnimatedPlaceholder'

export default {
    components: {
        AnimatedPlaceholder
    }
}
</script>
<style>
.pc-meal-plan-home-slider-skeleton {
    display: flex;
    .gc-card-meal__sub-header {
        margin-top: 8px;
    }

    .gc-card-meal__slider {
        margin-right: 20px;
    }
}

</style>