import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import { mapGetters, mapMutations } from 'vuex'
import lodash from 'lodash'
import ShoppingListService from '../../../../services/ShoppingListService'
import MealPlanService from '../../../../services/MealPlanService'
import {
  enableGroupedIngredients,
  enableShoppingListIngredientsFlag
} from '@/includes/TemplateSettings'

export default {
  extends: BaseComponent,
  data: function () {
    return {
      state: 'init',
      selectedItems: [],
      shoppingItems: [],
      groupedShoppingItems: [],
      showCartButton: false,
      mealPlanID: 0,
      instructionsPopup: {
        instructions: '',
        title: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedDays: 'shoppingStore/days',
      shoppedItemsCount: 'shoppingStore/shoppedItemsCount',
      shoppedItems: 'shoppingStore/shoppedItems',
      peopleCount: 'shoppingStore/peopleCount'
    }),
    showItem (itemID) {
      return !lodash.includes(this.selectedItems, itemID)
    },
    showGroupedShoppingList: function () {
      return !!enableGroupedIngredients() && enableShoppingListIngredientsFlag()
    }
  },
  watch: {
    selectedItems: function (val, old) {
      if (val.length > old.length) {
        const newlyAddedItems = lodash.difference(val, old)
        this.logAddItems(newlyAddedItems)
      }
      this.setSelectedItems({ items: val })
    }
  },
  methods: {
    ...mapMutations({
      'setSelectedItems': 'shoppingStore/selectedItems'
    }),

    goBack(){
        this.$router.go(-1)
    },

    getShoppingListData () {
      let service = new ShoppingListService()
      this.hideLoading()
      if(this.shoppingItems.length===0){
        this.state = 'loading'
      }

      service
        .get({ days: this.selectedDays }, this.mealPlanID)
        .then((res) => {
          this.shoppingItems = lodash.sortBy(res.shopping_items, 'name')
          if (this.showGroupedShoppingList) {
            service.group(res.shopping_items)
            this.groupedShoppingItems = service.ingredientsByGroups
          }
        }).catch(() => {
          this.showCartButton = false
        }).finally(() => {
          this.selectedItems = this.shoppedItems
          this.logEvent('MealPlan.ShoppingListViewed', {
            meal_plan_id: this.mealPlanID
          }, false)
          this.showCartButton = true
        this.state = 'loaded'
        })
    },
    getMealPlanData () {
      const service = new MealPlanService()
      service.getMealPlan(this.mealPlanID).then((response) => {
        this.instructionsPopup.instructions = [response.plan.description]
        // calling to setContent function in the pageStore
        this.setInstructionsPopupContent(this.instructionsPopup)
      }).catch(() => {
        this.instructionsPopup = {
          instructions: '',
          title: ''
        }
      })
    },
    logAddItems (ids) {
      if (Array.isArray(ids)) {
        ids.forEach(ele => {
          const item = this.shoppingItems.find(item => {
            return item.id === ele
          })
          if (item) {
            this.logEvent('ShoppingList.CheckedOffItem', { ingredient: item.name }, false)
          }
        })
      }
    }
  }
}
