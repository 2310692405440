import { mapGetters, mapMutations } from 'vuex'
import { flagValue, showBottomNavigationBar, showMyJourneyWidget } from '@/includes/TemplateSettings'
import DashboardService from '@/services/DashboardService'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
import NativeAppService from '@/services/NativeAppService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import UserEventsService from '@/services/UserEventsService'
import MemberProgressService from '@/services/api/member-progress/MemberProgressService'
import FeaturesService from '@/services/FeaturesService'
import NutritionService from '@/services/nutrition/NutritionService'
import { isSafari } from 'mobile-device-detect'
import FirstLoadService from '@/services/FirstLoadService'
import NotificationService from '@/services/NotificationService'
import WorkoutProgramsService from '@/services/WorkoutProgramsService'
import pageMixin from '@/mixins/pageMixin'

export default  {
  mixins:[pageMixin],
  computed: {
    ...mapGetters({
      notificationBarSettings: "pageStore/notificationBarSettings",
      notificationCount: "getNotificationCount",
    }),
    showBottomBar: function () {
      return showBottomNavigationBar() && !this.isDesktop;
    },
    showChatWidget() {
      return !(showBottomNavigationBar() && !this.isDesktop);
    },
    hasSubscriptionAndOneOffWorkoutPrograms() {
      return (
        this.hasWorkouts &&
        (this.challengeWorkoutPlans.length === 0 || this.workouts.length > 0)
      );
    },
    hasSubscriptionAndOneOffMealPlans() {
      return (
        this.hasMealPlans &&
        (this.challengeMealPlans.length === 0 ||
          this.mealPlanOptions.length > 0)
      );
    },

    actionButtonsClasses: function () {
      return {
        "pc-dashboard__action-buttons": this.showNotification,
      };
    },
    notificationBarShow() {
      return (
        this.notificationBarSettings.show &&
        this.notificationCount > 0 &&
        !(this.params.page && this.params.page === "notifications")
      );
    },
    evergreenProgressEnabled() {
      return flagValue("enable_ever_green_member_progress", true);
    },
    habitsTrackerEnabled() {
      return this.habitsTrackerEnabledByCreator
    },
    moodTrackerEnabled() {
      return flagValue("enable_mood_tracker", true);
    },
    periodTrackerEnabledByFeatureFlag() {
      return flagValue("show_period_tracker", true);
    },
    periodTrackerEnabled() {
      let service = new DashboardService();
      return service.userData.gender && service.userData.gender === "f";
    },
  },
  data: function () {
    return {
      habitsTrackerEnabledByCreator: false,
      isEvergreenVisited: true,
      evergreenConfig: {},
      activeWorkout: null,
      backgroundImage: this.getStoreImage("dashboard.background"),
      showWorkoutSlider: false,
      text: {
        mealPlans: "Meal Plans",
        workoutPlans: "Workout Plans",
      },
      pwaPopup: {
        visible: false,
      },
      themeSwitchPopup: {
        visible: false,
      },
      productPlans: {
        header: "",
        pages: [],
      },
      preVisited: true,
      pageHeader: "",
      pageTitle: "",
      pageContent: " ",
      headerOptions: {
        show: true,
        left: "menu",
        right: "pwa",
        helpContent: "",
        rightDisabled: false,
        modifiers: ['small-title'],
        header: {
          mainTitle: "",
          subTitle: "",
        },
      },
      showNotification: false,
      notifications: [],
      fitnessDiaryEnabled: false,
      hasWorkouts: false,
      workouts: [],
      hasMealPlans: false,
      mealPlanOptions: [],
      mealPlanNotAChallenge: [],
      description: "",
      pageClass: ["macroapp--page-dashboard--with-card"],
      title: {
        main: "",
        sub: "Get back on your Track",
      },
      loaded: {
        mealPlan: false,
        workout: false,
        dashboard: false,
        notification: false,
      },
      habitsTrackerEnabledByCreator: false,
      isMyJourneyWidgetEnabled: false,
      challengeMealPlans: [],
      challengeWorkoutPlans: [],
      challengeProductPages: [],
    };
  },
  watch: {
    loaded: {
      handler(value) {
        if (
          value.mealPlan &&
          value.workout &&
          value.dashboard &&
          value.notification
        ) {
          this.hideLoading(1);
        }
      },
      deep: true,
    },
  },
  created() {
    this.logEvent('Viewed Dashboard', {
      variation: ((showBottomNavigationBar()&&! this.isDesktop)? 'bottom_nav_bar':'original')
    })
  },
  beforeMount() {
    this.getMemberProgressConfigs();
    this.toggleMyJourneyWidget();
    if (
      typeof localStorage.getItem("currentWorkoutPlan") === "undefined" ||
      localStorage.getItem("currentWorkoutPlan") === null
    ) {
      this.preVisited = false;
    }
    if(isMobileNativeApp()){
      const nativeApp = new NativeAppService()
      nativeApp.getPushDeviceStateRequest()
    }

    document.body.style.backgroundColor =
      templateConfig.style_variables["$bg-homePages"];
  },

  beforeDestroy() {},
  methods: {
    ...mapMutations({
      viewInstructionsPopup: "instructionPopupStore/show",
      setInstructionsPopupContent: "instructionPopupStore/setContent",
      setTrainerDetails: "pageStore/setTrainer",
    }),
    async checkEvegreenEnabled() {
      this.isEvergreenVisited = await UserEventsService.checkItemVisit(
        "everGreen",
        this.evergreenConfig.id
      );
    },
    async getMemberProgressConfigs() {
      if (!this.evergreenProgressEnabled) {
        return;
      }
      const memberProgressService = new MemberProgressService();
      const response = await memberProgressService.getMemberConfigData();

      this.evergreenConfig = response.data;
      this.checkEvegreenEnabled();
    },

    showFeatureUpdatesPopup() {
      if (flagValue("enable_feature_updates_popup", false)) {
        let features = new FeaturesService();
        features.getNewFeatureUpdateContents().then((contents) => {
          if (contents.length > 0) {
            let instructionsPopup = {
              instructions: contents,
              title: this.$i18n.t('message["popup.feature-updates.title"]'),
            };
            this.setInstructionsPopupContent(instructionsPopup);
            this.viewInstructionsPopup();
          }
        });
      }
    },
    navigateToWorkout(id) {
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.workout,
        query: { workout: id },
      });
    },
    setImages() {
      this.setBackgroundImage(this.getStoreImage("dashboard.background"));
    },
    hideInstructionsPopup() {
      this.instructionsPopup.visible = false;
      this.setInstructionsPopup.visible = false;
    },
    showMealPlans() {
      let mealService = new NutritionService();
      mealService
        .init()
        .then(() => {
          this.allMealPlans = mealService.getActiveMealPlans();
          this.challengeMealPlans = mealService.getChallengePlans();
          this.mealPlanOptions = mealService.getActiveMealPlans();
          this.mealPlanNotAChallenge = mealService.getNotChallengePlans();
        })
        .finally(() => {
          this.loaded.mealPlan = true;
        });
    },

    showPwaPopup() {
      // ios check
      const isIos = this.$device.ios;
      let isSafariBrowser = isSafari;
      let firstTime = new FirstLoadService();
      // pwa check
      const isInStandaloneMode = () =>
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone ||
        document.referrer.includes("android-app://");

      if (
        document.querySelector("link[rel='manifest']") &&
        firstTime.getFirstTimeStatus("pwaPopUpView") &&
        !isInStandaloneMode() &&
        isIos &&
        isSafariBrowser
      ) {
        this.pwaPopup.visible = true;
        this.logEvent("Viewed PWA Install Instructions", {}, false);
        firstTime.setFirstTimeStatus("pwaPopUpView");
      }
    },
    showThemeSwitchPopup() {
      this.themeSwitchPopup.visible = false;
    },
    setNotification() {
      let notifService = new NotificationService();
      notifService
        .getFromAPI()
        .then((res) => {
          let allNotifications = res;
          this.notifications = res.filter((notification) => {
            return (
              typeof notification.action.url !== "undefined" &&
              (notification.action.url.includes("workout-programs/update") ||
                (notification.action.url.includes(
                    "api/notifications/subscription/"
                  ) &&
                  notification.action.url.includes("/cancel")) ||
                notification.action.url.includes("meal-plans/update"))
            );
          });
          this.notifications = this.notifications.map((notification) => {
            if (notification.action.url.includes("workout-programs/update")) {
              notification.colorClass = "btn-notification--color-red";
              notification.message = this.$i18n.t(
                'message["notification-workout-update-progress"]'
              );
            } else if (notification.action.url.includes("meal-plans/update")) {
              notification.colorClass = "btn-notification--color-orange";
              notification.message = this.$i18n.t(
                'message["notification-meal-plan-update-progress"]'
              );
            } else {
              notification.message = this.$i18n.t(
                'message["notification-update-progress"]'
              );
            }
            return notification;
          });
          this.showNotification = this.notifications.length !== 0;
          this.loaded.notification = true;
          let notifiCount = allNotifications.length - this.notifications.length;
          this.$store.commit("setNotificationCount", notifiCount);
          this.$store.commit("setNotificationsList", allNotifications);
        })
        .finally(() => {
          this.loaded.notification = true;
        });
    },
    showDashboardContent() {
      const service = new DashboardService();
      service
        .getPageData()
        .then((data) => {
          service.getRegisteredDate();
          this.pageContent = data.contents["page-content"];
          this.pageTitle = data.contents["page-title"];
          this.pageHeader = data.contents["page-header"];
          this.productPlans.header = data.contents["product-pages-header"];
          this.text.workoutPlans = data.contents["workout-programs-header"];
          this.text.mealPlans = data.contents["meal-plans-header"];
          this.productPlans.pages = service.getProductPages();
          this.challengeProductPages = service.getChallengeProductPages();
          this.setPageTitle(this.pageTitle);
          this.habitsTrackerEnabledByCreator = service.isHabitTrackerEnabled();
        })
        .finally(() => {
          this.loaded.dashboard = true;
        });

      service.getUserData().then(() => {
        this.hasMealPlans = service.hasActiveMealPlans();
        this.hasWorkouts = service.hasActiveWorkoutPrograms();
        this.fitnessDiaryEnabled = service.isFitnessDiaryEnabled();

        let productPages = service.getProductPages();
        let hasMealPlans = this.hasMealPlans || this.mealPlanOptions.length > 0;
        let hasWorkouts = this.hasWorkouts || this.workouts.length > 0;

        if (
          Array.isArray(productPages) &&
          productPages.length === 1 &&
          !hasMealPlans &&
          !hasWorkouts
        ) {
          let defaultProductPage = productPages.find((page) => {
            return page.is_default;
          });
          if (
            !defaultProductPage &&
            Array.isArray(productPages) &&
            productPages.length === 1
          ) {
            defaultProductPage = productPages[0];
          }
          const url = defaultProductPage.url || "";
          if (url && url.startsWith("/page") && sessionStorage.getItem('productPlanRedirected')!=='1') {
            sessionStorage.setItem('productPlanRedirected','1')
            this.$router.replace({ path: url });
          }
        }
      });
    },
    showWorkouts() {
      let workoutProgramsService = new WorkoutProgramsService();
      workoutProgramsService
        .setWorkoutProgramList()
        .then(() => {
          this.challengeWorkoutPlans =
            workoutProgramsService.getChallengeWorkoutPrograms();
          this.workouts = workoutProgramsService.workoutPrograms;
        })
        .catch(() => {
          this.hideLoading();
        })
        .finally(() => {
          this.loaded.workout = true;
        });
    },
    showSlider(id) {
      this.preVisited = id > 3;
    },
    toggleMyJourneyWidget() {
      this.isMyJourneyWidgetEnabled = showMyJourneyWidget();
    },
  },
  destroyed() {
    this.resetHeader();
    this.resetBackground();
    document.body.style.removeProperty("backgroundColor");
  },

}
