export const AppConfig = {
  onBoarding: {
    mapping: {
      activityLvl: [
        {
          systemValue: 1.2,
          type: 'range',
          range: { min: 1, max: 1.2 }
        },
        {
          systemValue: 1.375,
          type: 'range',
          range: { min: 1.2, max: 1.375 }
        },
        {
          systemValue: 1.55,
          type: 'range',
          range: { min: 1.375, max: 1.55 }
        },
        {
          systemValue: 1.725,
          type: 'range',
          range: { min: 1.55, max: 1.725 }
        }
      ],
      bodyFat: [
        {
          systemValue: 0.03,
          type: 'range',
          range: { min: 0, max: 3 }
        },
        {
          systemValue: 0.04,
          type: 'range',
          range: { min: 4, max: 4 }
        },
        {
          systemValue: 0.05,
          type: 'range',
          range: { min: 5, max: 5 }
        },
        {
          systemValue: 0.06,
          type: 'range',
          range: { min: 6, max: 6 }
        },
        {
          systemValue: 0.07,
          type: 'range',
          range: { min: 7, max: 7 }
        },
        {
          systemValue: 0.08,
          type: 'range',
          range: { min: 8, max: 8 }
        },
        {
          systemValue: 0.09,
          type: 'range',
          range: { min: 9, max: 9 }
        },
        {
          systemValue: 0.10,
          type: 'range',
          range: { min: 10, max: 10 }
        },
        {
          systemValue: 0.11,
          type: 'range',
          range: { min: 11, max: 11 }
        },
        {
          systemValue: 0.12,
          type: 'range',
          range: { min: 12, max: 12 }
        },
        {
          systemValue: 0.13,
          type: 'range',
          range: { min: 13, max: 13 }
        },
        {
          systemValue: 0.14,
          type: 'range',
          range: { min: 14, max: 14 }
        },
        {
          systemValue: 0.15,
          type: 'range',
          range: { min: 15, max: 15 }
        },
        {
          systemValue: 0.16,
          type: 'range',
          range: { min: 16, max: 16 }
        },
        {
          systemValue: 0.17,
          type: 'range',
          range: { min: 17, max: 17 }
        },
        {
          systemValue: 0.18,
          type: 'range',
          range: { min: 18, max: 18 }
        },
        {
          systemValue: 0.19,
          type: 'range',
          range: { min: 19, max: 19 }
        },
        {
          systemValue: 0.20,
          type: 'range',
          range: { min: 20, max: 20 }
        },
        {
          systemValue: 0.21,
          type: 'range',
          range: { min: 21, max: 21 }
        },
        {
          systemValue: 0.22,
          type: 'range',
          range: { min: 22, max: 22 }
        },
        {
          systemValue: 0.23,
          type: 'range',
          range: { min: 23, max: 23 }
        },
        {
          systemValue: 0.24,
          type: 'range',
          range: { min: 24, max: 24 }
        },
        {
          systemValue: 0.25,
          type: 'range',
          range: { min: 25, max: 25 }
        },
        {
          systemValue: 0.26,
          type: 'range',
          range: { min: 26, max: 26 }
        },
        {
          systemValue: 0.27,
          type: 'range',
          range: { min: 27, max: 27 }
        },
        {
          systemValue: 0.28,
          type: 'range',
          range: { min: 28, max: 28 }
        },
        {
          systemValue: 0.29,
          type: 'range',
          range: { min: 29, max: 29 }
        },
        {
          systemValue: 0.30,
          type: 'range',
          range: { min: 30, max: 30 }
        },
        {
          systemValue: 0.31,
          type: 'range',
          range: { min: 31, max: 31 }
        },
        {
          systemValue: 0.32,
          type: 'range',
          range: { min: 32, max: 32 }
        },
        {
          systemValue: 0.33,
          type: 'range',
          range: { min: 33, max: 33 }
        },
        {
          systemValue: 0.34,
          type: 'range',
          range: { min: 34, max: 34 }
        },
        {
          systemValue: 0.35,
          type: 'range',
          range: { min: 35, max: 35 }
        },
        {
          systemValue: 0.36,
          type: 'range',
          range: { min: 36, max: 36 }
        },
        {
          systemValue: 0.37,
          type: 'range',
          range: { min: 37, max: 37 }
        },
        {
          systemValue: 0.38,
          type: 'range',
          range: { min: 38, max: 38 }
        },
        {
          systemValue: 0.39,
          type: 'range',
          range: { min: 39, max: 39 }
        },
        {
          systemValue: 0.40,
          type: 'range',
          range: { min: 40, max: 40 }
        },
        {
          systemValue: 0.41,
          type: 'range',
          range: { min: 41, max: 41 }
        },
        {
          systemValue: 0.42,
          type: 'range',
          range: { min: 42, max: 42 }
        },
        {
          systemValue: 0.43,
          type: 'range',
          range: { min: 43, max: 43 }
        },
        {
          systemValue: 0.44,
          type: 'range',
          range: { min: 44, max: 44 }
        },
        {
          systemValue: 0.45,
          type: 'range',
          range: { min: 45, max: 45 }
        },
        {
          systemValue: 0.46,
          type: 'range',
          range: { min: 46, max: 46 }
        },
        {
          systemValue: 0.47,
          type: 'range',
          range: { min: 47, max: 47 }
        },
        {
          systemValue: 0.48,
          type: 'range',
          range: { min: 48, max: 48 }
        },
        {
          systemValue: 0.49,
          type: 'range',
          range: { min: 49, max: 49 }
        },
        {
          systemValue: 0.50,
          type: 'range',
          range: { min: 50, max: 50 }
        },
        {
          systemValue: 0.51,
          type: 'range',
          range: { min: 51, max: 51 }
        },
        {
          systemValue: 0.52,
          type: 'range',
          range: { min: 52, max: 52 }
        },
        {
          systemValue: 0.53,
          type: 'range',
          range: { min: 53, max: 53 }
        },
        {
          systemValue: 0.54,
          type: 'range',
          range: { min: 54, max: 54 }
        },
        {
          systemValue: 0.55,
          type: 'range',
          range: { min: 55, max: 55 }
        },
        {
          systemValue: 0.56,
          type: 'range',
          range: { min: 56, max: 56 }
        },
        {
          systemValue: 0.57,
          type: 'range',
          range: { min: 57, max: 57 }
        },
        {
          systemValue: 0.58,
          type: 'range',
          range: { min: 58, max: 58 }
        },
        {
          systemValue: 0.59,
          type: 'range',
          range: { min: 59, max: 59 }
        },
        {
          systemValue: 0.60,
          type: 'range',
          range: { min: 60, max: 60 }
        },
        {
          systemValue: 0.61,
          type: 'range',
          range: { min: 61, max: 61 }
        },
        {
          systemValue: 0.62,
          type: 'range',
          range: { min: 62, max: 62 }
        },
        {
          systemValue: 0.63,
          type: 'range',
          range: { min: 63, max: 63 }
        },
        {
          systemValue: 0.64,
          type: 'range',
          range: { min: 64, max: 64 }
        },
        {
          systemValue: 0.65,
          type: 'range',
          range: { min: 65, max: 65 }
        },
        {
          systemValue: 0.66,
          type: 'range',
          range: { min: 66, max: 66 }
        },
        {
          systemValue: 0.67,
          type: 'range',
          range: { min: 67, max: 67 }
        },
        {
          systemValue: 0.68,
          type: 'range',
          range: { min: 68, max: 68 }
        },
        {
          systemValue: 0.69,
          type: 'range',
          range: { min: 69, max: 69 }
        },
        {
          systemValue: 0.70,
          type: 'range',
          range: { min: 70, max: 100 }
        }
      ],
      wGoal: [
        {
          systemValue: 1,
          type: 'single',
          value: 1
        },
        {
          systemValue: 2,
          type: 'single',
          value: 2
        },
        {
          systemValue: 3,
          type: 'single',
          value: 3
        },
        {
          systemValue: 4,
          type: 'single',
          value: 4
        },
        {
          systemValue: 5,
          type: 'single',
          value: 5
        },
        {
          systemValue: 6,
          type: 'single',
          value: 6
        }

      ]
    }
  }
}
