import MealPlanAPI from '../api/meal-plan/MealPlanAPI'
import MealPlanService from './MealPlanService'
import FoodDiaryService from './FoodDiaryService'
import lodash from 'lodash'
import MealDislikeService from '../MealDislikeService'
import MealFavouritesService from './MealFavouritesService'
import GenericMealService from './GenericMealService'
import { checkIsMember } from '@/helpers/user'

/**
 * NutritionService class handles the meal plans and related services.
 */
export default class NutritionService {

  _mealPlans = []
  _pageContent = {}
  _APIService = null
  _foodDiaryService = null
  _deslikes = new MealDislikeService()
  _meals = []

  /**
   * Constructor for the NutritionService class.
   * If an instance doesn't exist, it creates one and assigns it to the instance property.
   * It also initializes the API service.
   */
  constructor () {
    if (!NutritionService.instance) {
      NutritionService.instance = this
    }
    this._APIService = new MealPlanAPI()
    return NutritionService.instance
  }

  /**
   * Initializes the meal plans.
   * If the user is not a member or if the meal plans are already loaded, it resolves the promise immediately.
   * Otherwise, it fetches the meal plans from the API.
   */
  init () {
    return new Promise((resolve) => {
      if (!checkIsMember()) {
        resolve([])
      }
      if (this._mealPlans.length) {
        resolve(this._mealPlans)
      } else {
        this.getMealPlansFromAPI().then(() => {
          resolve(this._mealPlans)
        })
      }
    })
  }

  /**
   * Returns the active meal plans.
   */
  getActiveMealPlans () {
    return this._mealPlans.filter(mealPlan => mealPlan.isAvailable())
  }

  /**
   * Returns all the meal plans.
   */
  getAllMealPlans () {
    return this._mealPlans
  }

  /**
   * Returns the challenge plans.
   */
  getChallengePlans () {
    return this._mealPlans.filter(plan => plan.getPricingPlanType() === 'challenge' || plan.getActivateAt())
  }

  /**
   * Returns the non-challenge plans.
   */
  getNotChallengePlans () {
    return this._mealPlans.filter(plan => !(plan.getPricingPlanType() === 'challenge' || plan.getActivateAt()))
  }

  /**
   * Initializes the FoodDiaryService.
   * If there are no active meal plans, it throws an error.
   * Otherwise, it creates a new FoodDiaryService with the latest meal plan.
   */
  async initFoodDiaryService () {
    if (this.getActiveMealPlans().length === 0) {
      throw new Error('not meal plans found')
    }
    if (!this._foodDiaryService) {
      let mealPlan = this.getLatestMealPlan()
      if (!mealPlan.isLoaded()) {
        await mealPlan.getDataFromAPI()
      }

      this._foodDiaryService = new FoodDiaryService(mealPlan)
    }
  }

  /**
   * Sets the detailed meal preferences by fetching them from the API.
   */
  setDetailedMealPreferences(){
    const mealAPI = new MealPlanAPI()
    return  mealAPI.getDetailedPreferences().then(data=>data.data).then(data=>{
      const mealDislikes = new MealDislikeService()
      mealDislikes.setDislikeMeals(data.dislikes)
      const mealFavourites = new MealFavouritesService()
      mealFavourites.setFavouritesMeals(data.favorites)
    })
  }

  /**
   * Returns a new MealDislikeService instance.
   */
  getDislikes(){
    return new MealDislikeService()
  }

  /**
   * Returns a new MealFavouritesService instance.
   */
  getFavourites(){
    return new MealFavouritesService()
  }

  /**
   * Returns the FoodDiaryService instance.
   */
  getFoodDiaryService () {
    return this._foodDiaryService
  }

  /**
   * Returns the latest meal plan.
   */
  getLatestMealPlan () {
    return lodash.last(lodash.orderBy(this.getActiveMealPlans(), '_id'))
  }

  /**
   * Returns the meal plan with the given id.
   */
  getMealPlan (id) {
    return this._mealPlans.find(plan => {
      return plan.getId() === id
    })
  }

  /**
   * Fetches the meal plans from the API and maps them to MealPlanService instances.
   */
  getMealPlansFromAPI () {
    return new Promise((resolve, reject) => {
      let api = new MealPlanAPI()
      api.getList().then(data => data.data).then(data => {
        this._pageContent = data.content
        this.mapMealPlans(data.meal_plans)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * Maps the given meal plans to MealPlanService instances.
   */
  mapMealPlans (mealPlans) {
    this._mealPlans = []
    mealPlans.forEach(mealPlan => {
      this._mealPlans.push(new MealPlanService(mealPlan))
    })
  }

  /**
   * Returns the generic meal with the given id.
   * If the meal doesn't exist, it creates a new one.
   */
  getGenericMeal (id) {
    const meal = this._meals.find(meal => meal.id === id)
    if (meal) {
      return meal
    }
    return this.createGenericMeal(id)
  }

  /**
   * Creates a new generic meal with the given id and adds it to the meals array.
   */
  createGenericMeal (generatedMealId) {
    const meal = new GenericMealService(generatedMealId)
    this._meals.push(meal)
    return meal
  }
}
