export default {
  data: function () {
    return {
      // Height of the header in pixels
      headerHeight: 0
    }
  },
  computed: {
    /**
     * Computes the styles for the content area.
     * Adds padding to the top based on the header height if the header is fixed.
     * @returns {Object} The computed styles for the content area.
     */
    contentAreaStyles: function () {
      return {
        paddingTop: ((this.header.isFixed) ? this.headerHeight : 0) + 'px'
      }
    }
  },
  mounted () {
    // Adds the 'gc-app' class to the element with id 'app' if it contains an element with class 'gc-page'
    if (this.$el.querySelector('.gc-page')) {
      this.$el.classList.add('gc-app')
    }
    if(this.type === 'primary' || this.$refs.page ) {
      this.$refs.page.style.backgroundColor = this.type === 'primary' ?  'var(--bg-homePages)': 'var(--bg-infoPages)'
    }

  },
  methods: {
    /**
     * Updates the header height.
     * @param {number} height - The new height of the header.
     */
    headerUpdated (height) {
      this.headerHeight = height
    }
  },
  destroyed () {
    // Removes the 'gc-app' class from the element with id 'app' if it contains an element with class 'gc-page'
    if (this.$el.querySelector('.gc-page')) {
      this.$el.classList.remove('gc-app')
    }
  }
}
