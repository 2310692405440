import { mapGetters, mapMutations } from "vuex";

/**
 * there are variations of the cart button with same logic
 * to different variation can use this mixin
 */
export default {
  props: {
    groupInfo: {
      default: function () {
        return {
          setName: "",
          currentID: 1,
          totalExercises: 1,
        };
      },
      type: Object,
    },
    exerciseID: {
      default: 0,
      type: Number,
    },
    exerciseName: { default: "" },
    dayName: { default: "" },
    weekName: { default: "" },
    note: { default: "" },
    workoutID: { default: 0 },
    saveType: { default: "save" },
    exerciseTime: { default: 0 },
    exerciseRestInterval: { default: 0 },
    exerciseRestTime: { default: "second" },
    setsCount: {
      default: 0,
      type: Number,
    },
  },
  data: function () {
    return {
      isSubmitting: false,
      showSuccess: false,
      formData: { note: "" },
      restText: this.$i18n.t('message["general.rest"]'),
      currentSet: 1,
      restSet: 1,
      totalSets: 1,
      lastTimerValue: 0,
      timerTime: 0,
      showTimer: false,
    };
  },
  computed: {
    ...mapGetters({
      service: "singleExercisePageStore/getCurrentWorkoutLogService",
    }),
    workoutTimerText: function () {
      return this.$i18n.tc('message["general.set"]', 1) + " " + this.currentSet;
    },
    timerCompleted() {
      if (this.timerTime > 0 && this.exerciseRestTime > 0) {
        return (
          this.currentSet > this.setsCount && this.restSet > this.setsCount
        );
      } else {
        return this.currentSet > this.setsCount;
      }
    },
  },
  mounted() {
    this.formData.note = this.note;
    this.setCurrentExerciseInStore(parseInt(this.exerciseID));
    const storeData = this.getLogDataFromStore(parseInt(this.exerciseID));
    if (storeData.note) {
      this.formData.note = storeData.note;
    }
    if (storeData.currentSet) {
      this.currentSet = storeData.currentSet;
      this.restSet = this.currentSet;
    }
    if (storeData.lastTimerValue) {
      this.timerTime = parseInt(storeData.lastTimerValue) || this.exerciseTime;
    } else {
      this.timerTime = this.exerciseTime;
    }
    this.showTimer = true;
  },
  methods: {
    ...mapGetters({
      getLogDataFromStore: "workoutLogStore/getFormLogData",
    }),
    ...mapMutations({
      setCurrentExerciseInStore: "workoutLogStore/setCurrentExercise",
      unsetLogDataInStore: "workoutLogStore/unsetLogFormData",
      setLogDataToStore: "workoutLogStore/setLogFormData",
    }),
    noteChanged() {
      this.setToStore();
    },
    workoutTimerRemaining(event) {
      this.lastTimerValue = event.remaining.totalSeconds;
      this.setToStore();
    },
    workoutTimerStarted() {
      this.logTimerStartedActivityData();
    },
    logTimerStartedActivityData() {
      const logData = {
        set: this.currentSet,
      };
      this.logEvent("WorkoutLog.TimerStarted", logData);
    },
    setToStore() {
      this.setLogDataToStore({
        id: parseInt(this.exerciseID),
        log: {
          formData: [],
          note: this.formData.note,
          lastTimerValue: this.lastTimerValue,
          currentSet: this.currentSet,
        },
      });
    },
    restMessage() {
      if (this.groupInfo.totalExercises > 1) {
        return this.$i18n.t(
          'message["workout.no-workout-message-timer-with-value-group-set"]'
        );
      } else {
        return this.$i18n.t(
          'message["workout.no-workout-message-timer-with-value"]'
        );
      }
    },
    completeExercise() {
      if (this.isSubmitting) {
        return;
      }
      this.showLoading();
      this.isSubmitting = true;
      this.service
        .markExerciseComplete(this.formData, parseInt(this.exerciseID))
        .then((data) => {
          this.logSaveData();
          this.unsetLogDataInStore(parseInt(this.exerciseID));
          this.showSuccess = true;

          this.$emit("submitted", this.formData);
          this.dataSaved(parseInt(this.exerciseID), data.data);
          setTimeout(() => {
            setTimeout(() => {
              this.showSuccess = false;
            }, 500);
            this.$emit("completed", this.formData);
          }, 2000);
        })
        .finally(() => {
          this.isSubmitting = false;
          this.hideLoading();
        });
    },
    logSaveData() {
      const logData = {
        logged_note: Boolean(this.formData.note),
        logged_metrics: false,
        all_sets_completed: false,
        available_metrics: [],
        exercise: this.exerciseName,
        day: this.dayName,
        week: this.weekName,
      };
      // this.logEvent('WorkoutLog.RecordedEntry', logData, true)
    },
    dataSaved(exerciseID, data) {},
    cancelEdit() {
      this.unsetLogDataInStore(parseInt(this.exerciseID));
      this.$emit("cancel");
      this.$emit("canceled");
    },
    workoutTimerEnded() {
      this.timerTime = this.exerciseTime;
      this.lastTimerValue = 0;
      this.currentSet++;
      this.setToStore();
    },
    restTimerEnded() {
      this.restSet++;
    },
    restart() {
      this.currentSet = 1;
      this.restSet = 1;
      this.setToStore();
    },
  },
};
