export default {
  props: {
    /**
     * Prop to change the status of the icon.
     * @type {String}
     * @default ''
     * @description Expected value: 'disabled'
     */
    state: {
      default: ''
    },
    /**
     * Prop to change the size of the icon.
     * @type {String}
     * @default 'md1'
     * @description Expected values: 'sm1', 'sm2', 'sm3', 'md1', 'md2', 'md3', 'lg1', 'lg2', 'lg3'
     */
    size: {
      default: 'md1'
    }
  },
  computed: {
    /**
     * Computed property to generate CSS classes based on the size prop.
     * @returns {Object} CSS classes for the icon size.
     */
    componentSizeClasses: function () {
      return {
        'rc-icon--sm1': this.size === 'sm1', // 12px
        'rc-icon--sm2': this.size === 'sm2', // 16px
        'rc-icon--sm3': this.size === 'sm3', // 18px
        'rc-icon--sm4': this.size === 'sm4', // 20px
        'rc-icon--md1': this.size === 'md1', // 24px
        'rc-icon--md2': this.size === 'md2', // 28px
        'rc-icon--md3': this.size === 'md3', // 32px
        'rc-icon--md4': this.size === 'md4', // 36px
        'rc-icon--lg1': this.size === 'lg1', // 40px
        'rc-icon--lg2': this.size === 'lg2', // 44px
        'rc-icon--lg3': this.size === 'lg3', // 48px
        'rc-icon--lg4': this.size === 'lg4' // 52px
      }
    },
    /**
     * Computed property to generate CSS classes based on the state prop.
     * @returns {Object} CSS classes for the icon state.
     */
    componentStateClasses: function () {
      return {
        'rc-icon--disabled': this.state === 'disabled'
      }
    }
  },
  methods: {
    /**
     * Method to emit a click event when the icon is clicked.
     * @emits click
     */
    clicked() {
      this.$emit('click')
    }
  }
}
