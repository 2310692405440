<template>
  <popup
    :visible="visible"
    modal-class="pc-popup-meal-plan-change"
    v-if="visible"
    size="md"
    @hide="closePopup()"
  >
    <popup-title>
      <popup-button-close :style="{'visibility' : noCloseOnBackdrop? 'hidden' :'visible' }" @hide="closePopup()"/>
    </popup-title>
    <popup-body class="gc-popup-meal-change__body">
      <popup-head class="text-center">
        <popup-text-heading v-if="status==='init'">{{ title }}</popup-text-heading>
        <popup-text-heading v-if="status==='loading'">{{
            $t('message["meal-plan.email-summary-popup-title-sending"]')
          }}
        </popup-text-heading>
        <popup-text-heading v-if="status==='success'">{{
            $t('message["meal-plan.email-summary-popup-title-done"]')
          }}
        </popup-text-heading>
      </popup-head>
      <popup-content v-if="status==='init'">
        <div class="gc-popup-meal-change__field">
          <popup-text-body
          >{{ $t('message["meal-plan.request-change-popup.field-one-title-text"]') }}
          </popup-text-body>
          <input-search-and-select
            ref="inputReason"
            :placeholder="$t('message[\'meal-plan.request-change-popup.input-placeholder-type-or-select\']')"
            :items="fieldOptions.reason"
            @clearValue="clearValue('reason')"
            @result="setValue('reason', $event)"
          />
        </div>
        <div class="gc-popup-meal-change__field">
          <popup-text-body
          >{{ $t('message["meal-plan.request-change-popup.field-two-title-text"]') }}
          </popup-text-body>
          <input-search-and-select
            ref="inputSpan"
            :placeholder="$t('message[\'meal-plan.request-change-popup.input-placeholder-type-or-select\']')"
            :items="fieldOptions.span"
            @clearValue="clearValue('span')"
            @result="setValue('span',  $event)"/>
          <alert-dotted v-if="alertType === 'meals'"
                        :description="$t('message[\'meal-plan.request-change-popup.alert-text\']')">
            <icon-warning :size="'sm2'"/>
            <text-body-extra-small>{{
                $t('message["meal-plan.request-change-popup.alert-text-meals"]')
              }}
            </text-body-extra-small>
          </alert-dotted>
          <alert-dotted v-if="alertType === 'meal-plans'"
                        :description="$t('message[\'meal-plan.request-change-popup.alert-text\']')">
            <icon-warning :size="'sm2'"/>
            <text-body-extra-small>{{
                $t('message["meal-plan.request-change-popup.alert-text-meal-plans"]')
              }}
            </text-body-extra-small>
          </alert-dotted>
        </div>
        <div class="gc-popup-meal-change__field" v-if="alertType !== 'meals' && alertType !== 'meal-plans'">
          <popup-text-body
          >{{ $t('message["meal-plan.request-change-popup.field-three-title-text"]') }}
          </popup-text-body>
          <text-area :disabled="inputsDisabled"
                     :placeholder="$t('message[\'meal-plan.request-change-popup.input-placeholder-type-only\']')"
                     v-model="formData.information"/>
        </div>
      </popup-content>
      <popup-content class="text-center" v-if="status==='loading'">
        <loading-buffer height="50"/>
      </popup-content>
      <popup-content class="text-center" v-if="status==='success'">
        <icon-complete-filled class="text-center" :size="'lg3'"/>
      </popup-content>
      <popup-content v-if="status==='error'">
        <div class="text-center"/>
        <p class="text-center info-modal__subtitle mb-2">{{ errorMessage }}</p>
      </popup-content>
      <popup-floating-footer v-if="status==='init'">
        <button-primary v-if="!inputsDisabled"
                        :disabled="isSendButtonDisabled"
                        @click="sendRequest()"
                        :text="confirmButtonText">

        </button-primary>
        <button-primary v-else
                        @click="resetAll()"
                        :text="$t('message[\'general.understood\']')">

        </button-primary>
      </popup-floating-footer>
    </popup-body>
  </popup>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import '../../../../../helpers/validation'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import MealPlanService from '../../../../../services/MealPlanService'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import TextInput from '../../../../global/inputs/TextInput'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import PopupFloatingFooter from '../../../../global/popups/popup-sub-components/PopupFloatingFooter.vue'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import PopupTextBody from '@/components/global/popups/popup-sub-components/PopupTextBody.vue'
import InputSearchAndSelect from '@/components/global/inputs/InputSearchAndSelect.vue'
import InputSelect from '@/components/global/inputs/InputSelect.vue'
import TextArea from '@/components/global/inputs/TextArea.vue'
import AlertDotted from '@/components/global/alerts/AlertDotted.vue'
import AlertWarning from '@/components/global/alerts/AlertWarning.vue'
import AlertInjury from '@/components/global/alerts/AlertInjury.vue'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import IconWarning from '@/components/root/icons/IconWarning.vue'

export default {
  name: 'PopupMealPlanChange',
  props: {
    visible: {
      default: false
    },
    mealId: {
      default: 0
    }
  },
  mixins: [popupAnimationMixin],
  components: {
    IconWarning,
    TextBodyExtraSmall,
    AlertInjury,
    AlertWarning,
    AlertDotted,
    TextArea,
    InputSelect,
    InputSearchAndSelect,
    PopupTextBody,
    LoadingBuffer,
    ValidationProvider,
    ValidationObserver,
    Popup,
    PopupTitle,
    PopupContent,
    PopupHead,
    PopupFloatingFooter,
    PopupTextHeading,
    PopupButtonClose,
    PopupBody,
    TextInput,
    TextBodySmall,
    ButtonPrimary,
    PopupFooter,
    IconCompleteFilled
  },
  mounted () {
    this.title = this.$i18n.t('message["meal-plan.request-change-popup.title-text"]')
  },
  watch: {
    formData: {
      handler: function (formData) {
        this.alertType = ''
        this.inputsDisabled = false
        if (formData.span === this.$i18n.t('message["meal-plan.request-change-popup.option-span.next-check-in"]')) {
          if (formData.reason === this.$i18n.t('message["meal-plan.request-change-popup.option-reason.number-of-meal-plans"]')) {
            this.showPopupAlert('meal-plans')
          } else if (formData.reason === this.$i18n.t('message["meal-plan.request-change-popup.option-reason.number-of-meals"]')) {
            this.showPopupAlert('meals')
          }
        } else {
          this.alertType = ''
        }
      },
      deep: true
    }
  },
  computed: {
    noCloseOnBackdrop: function () {
      return this.status === 'success' || this.status === 'loading'
    },
    isSendButtonDisabled: function () {
      return this.formData.span.length === 0 || this.formData.reason.length === 0
    }
  },
  data: function () {
    return {
      status: 'init',
      inputsDisabled: false,
      title: '',
      confirmButtonText: this.$i18n.t('message["meal-plan.request-change-popup.button-text"]'),
      formData: {
        reason: '',
        span: '',
        information: ''
      },
      fieldOptions: {
        reason: [
          this.$i18n.t('message["meal-plan.request-change-popup.option-reason.diet"]'),
          this.$i18n.t('message["meal-plan.request-change-popup.option-reason.number-of-meals"]'),
          this.$i18n.t('message["meal-plan.request-change-popup.option-reason.number-of-meal-plans"]'),
          this.$i18n.t('message["meal-plan.request-change-popup.option-reason.add-dislikes"]'),
          this.$i18n.t('message["meal-plan.request-change-popup.option-reason.caloric-intake"]'),
          this.$i18n.t('message["meal-plan.request-change-popup.option-reason.something-else"]')],
        span: [
          this.$i18n.t('message["meal-plan.request-change-popup.option-span.right-away"]'),
          this.$i18n.t('message["meal-plan.request-change-popup.option-span.next-check-in"]')]
      },
      errorMessage: '',
      alertType: ''
    }
  },
  methods: {
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.status = 'init'
        this.$emit('close')
      })
    },
    sendRequest () {
      if (this.formData.span.length > 0 && this.formData.reason.length > 0) {
        this.status = 'loading'
        let service = new MealPlanService()
        this.logEvent('MealPlan.ChangeRequested',{
          reason_for_change: this.formData.reason,
          effective_from: this.formData.span,
          provided_additional_info: !!this.formData.information
        })
        service.sendMealChangeRequest(this.mealId, this.formData)
          .then(() => {
            this.status = 'success'
            setTimeout(() => {
              this.closePopup()
            }, 1000)
          })
          .catch((error) => {
            this.status = 'error'
            this.errorMessage = error.message
          })
      }
    },
    setValue (type, value) {
      if (type === 'reason') {
        this.formData.reason = value
        if (value === this.$i18n.t('message["meal-plan.request-change-popup.option-reason.number-of-meal-plans"]')) {
          this.clearValue('span')
          this.$refs.inputSpan.clearInput()
          this.fieldOptions.span = [
            this.$i18n.t('message["meal-plan.request-change-popup.option-span.next-check-in"]')]
        } else {
          this.fieldOptions.span = [
            this.$i18n.t('message["meal-plan.request-change-popup.option-span.right-away"]'),
            this.$i18n.t('message["meal-plan.request-change-popup.option-span.next-check-in"]')]
        }
      } else if (type === 'span') {
        this.formData.span = value
      }
    },
    clearValue (type) {
      if (type === 'reason') {
        this.formData.reason = ''
      } else if (type === 'span') {
        this.formData.span = ''
      }
    },
    showPopupAlert (type) {
      this.alertType = type
      this.inputsDisabled = true
    },
    resetAll () {
      this.inputsDisabled = false
      this.$refs.inputReason.clearInput()
      this.$refs.inputSpan.clearInput()
      this.formData.reason = ''
      this.formData.span = ''
      this.alertType = ''
    }
  }
}
</script>
