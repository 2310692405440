<template>
  <div :class="componentClasses">
    <input type="checkbox" :id="name"  v-model="isChecked" @change="onSwitchChange" />
    <label
      class="gc-input-switch-lable"
      :for="name"
      >Toggle
      </label
    >
  </div>
</template>

<script>
export default {
  name: "InputSwitch",
  components: {},
  data() {
    return {
      isChecked: this.value
    };
  },
  props: {
    name:{
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,  // Default state is unchecked (disabled)
    },
  },
  computed: {
    selected: function () {
      return this.value === this.id
    },
    componentClasses: function () {
      return {
        "gc-input-switch": true,
      };
    },
  },
  watch: {
    value(val) {
      this.isChecked = val
    },
  },
  methods: {
    onSwitchChange() {
      this.$emit('input', this.isChecked) // Custom event to emit toggle state
      this.$emit('change', this.isChecked) // Custom event to emit toggle state
    },
  },
};
</script>
