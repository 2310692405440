<template>
  <div class="gc-page-form-footer__wrapper" :style="wrapperStyles">
    <div class="gc-page-form-footer container footer--with-back " :class="footerWrapperClass">
      <button-primary
        v-if="!hideNextButton"
        class="gc-page-form-footer__submit-button"
        type="submit"
        :form="form"
        :disabled="validated"
        :text="buttonText"
        @click="clicked()"></button-primary>
      <text-body-extra-small
        v-if="!hideReturnButton"
        class="gc-page-form-footer__link text-center"
        line-height="multi"
        @click="delayedGoBack">{{$t("message[\'general.go-back\']")}}</text-body-extra-small>
      <slot></slot>
    </div>
  </div>

</template>
<script>
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
export default {
  name: 'PageFormFooter',
  components: {
    ButtonPrimary,
    TextBodyExtraSmall
  },
  props: {
    alwaysFixedOnLg: {
      default: true
    },
    validated: {
      default: false
    },
    fixed: {
      default: true
    },
    text: {
      default: ''
    },
    form: {
      default: ''
    },
    hideReturnButton: {
      default: false
    },
    hideNextButton: {
      default: false
    }
  },
  data: function () {
    return {
      footerWrapperClass: []
    }
  },
  computed: {
    wrapperStyles: function () {
      let styles = {}
      if (this.footerWrapperClass.includes('fixed-bottom') && document.querySelector('.gc-page-form-footer')) {
        styles.height = document.querySelector('.gc-page-form-footer').offsetHeight + 'px'
        styles.clear = 'both'
      }
      return styles
    },
    buttonText () {
      return this.text || this.$i18n.t('message["general.next"]')
    }
  },
  mounted () {
    this.footerWrapperClass = ['fixed-bottom']
    window.addEventListener('resize', this.setFooterClass)
    this.setFooterClass()
  },
  methods: {
    delayedGoBack () {

        this.$router.back()

    },
    setFooterClass () {
      this.$nextTick(() => {
        if ((this.isDesktop && this.alwaysFixedOnLg)) {
          this.footerWrapperClass = ['fixed-bottom']
        } else if (window.innerHeight - 160 > $('.gc-page__container').height()) {
          if (this.fixed) {
            this.footerWrapperClass = ['fixed-bottom']
          } else {
            this.footerWrapperClass = ['custom-footer-padding']
          }
        } else {
          this.footerWrapperClass = ['custom-footer-padding']
        }

        if (this.isDesktop) {
          this.footerWrapperClass.push('gc-page-form-footer--with-bg')
        }
      })
    },
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>
