import BaseAPI from '../../BaseAPI.js'
import DefineInclude from "@/DefineInclude";
import AuthService from "@/services/AuthService";
import axios from "axios";

export default class extends BaseAPI {
  _resource = 'components'

  constructor() {
    super();
    this._baseURL = process.env.VUE_APP_TONING_API_URL

  }

  getComponentsByName(name){

    return axios.get(`${this._baseURL}/creators/components/${name}/data`)
  }
  setComponentsByName(name,data){

    return axios.put(`${this._baseURL}/creators/components/${name}/data`,data)
  }
}
