<template>
    <div class="pc-accordion-recipe-skeleton">
        <div class="gc-card-text">
        <div class="gc-card-text__header">
          <AnimatedPlaceholder width="120px" height="24px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="120px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="240px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="200px" height="10px" />
        </div>
        <div class="gc-card-text__text">
          <AnimatedPlaceholder width="180px" height="10px" />
        </div>
      </div>
    </div>
</template>
<script>
import AnimatedPlaceholder from '@/components/global/animators/AnimatedPlaceholder'

export default {
    components: {
        AnimatedPlaceholder
    }
}
</script>
<style scoped>

</style>
