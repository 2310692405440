<template>
  <div :class="componentClasses">
    <dashboard-notification-bar v-show="showNotificationBar"/>

    <div class="pc-dashboard__nav-bar-header-container">
      <div class="pc-dashboard__nav-bar-header-left-section">
        <side-bar-v2 :class="!bottomBarState" v-if="alwaysShowMenu || !bottomBarState"/>
        <!-- slot for left section -->
        <slot name="left"></slot>
      </div>
      <div class="pc-dashboard__nav-bar-header-center-section" v-if="$slots.center">
        <!-- slot for center section -->
        <slot name="center"></slot>
      </div>

      <div class="pc-dashboard__nav-bar-header-right-section">
        <slot name="right"></slot>
      </div>
    </div>


  </div>
</template>
<script>
import SideBarV2 from '../../../../global/navigators/SideBar'
import appHeaderMixin from '../../../../../mixins/appHeaderMixin'
import DashboardNotificationBar from './DashboardNotificationBar'
import {mapGetters} from 'vuex'
import PopupPWAInstructions from '../../../../global/popups/PopupPWAInstructions'
import SideMenu from '../../global/widgets/SideMenuV2'
import {flagValue, showBottomNavigationBar} from '@/includes/TemplateSettings'
import IconCreateShortcut from '../../../../root/icons/IconCreateShortcut'
import IconBell from '../../../../root/icons/IconBell'
import IconBellFilled from '../../../../root/icons/IconBellFilled'
import {checkIsMember} from '@/helpers/user'

export default {
  name: 'NavBarHeader',
  components: {
    PopupPWAInstructions,
    DashboardNotificationBar,
    SideBarV2,
    SideMenu,
    IconCreateShortcut,
    IconBell,
    IconBellFilled
  },
  props: {
    alwaysShowMenu:{
      default: false
    }
  },
  mixins: [appHeaderMixin],
  data:function (){
    return{
      showSideMenu: false
    }
  },
  mounted() {
    setTimeout(()=>{
      this.showSideMenu = true
    },400)
  },
  computed: {
    sideBarClasses() {
      return {
        'pc-dashboard__nav-bar-header__sidebar': true,
      }
    },
    ...mapGetters({
      notificationBarSettings: 'pageStore/notificationBarSettings',
      notificationCount: 'getNotificationCount'
    }),

    bottomBarState: function () {
      return showBottomNavigationBar()
    },

    showNotificationBar() {
      return checkIsMember()
    },
    showNewSideMenu() {
      return !!flagValue('show_sidebar_v2', false)
    },
    componentClasses: function () {
      return {
        'pc-dashboard__nav-bar-header': true,
        'pc-dashboard__nav-bar-header--with-notification-bar': this.notificationBarShow
      }
    },
    notificationBarShow() {
      return (
        this.notificationBarSettings.show &&
        this.notificationCount > 0 &&
        !(this.params.page && this.params.page === 'notifications')
      )
    }
  }
}
</script>

<style scoped></style>
