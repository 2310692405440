<template>
  <page-secondary :header="headerOptions">
    <page-container>
      <loading-buffer v-if="state==='loading'" />
      <div class="pc-push-notification-card" v-for="group in notificationGroups" :key="group.id">
        <div class="pc-push-notification-card-details">
          <div class="pc-push-notification-card-details__title">
            <TextBodySmall2 :weight="'extra-bold'">{{ group.name }}</TextBodySmall2>
          </div>

          <div class="pc-push-notification-card-details__description">
            <text-body-extra-small
            >{{ group.description }}
            </text-body-extra-small
            >
          </div>
        </div>
        <div class="gc-push-notification-card__button">
          <InputSwitch :name="group.id" v-model="group.subscribed" @input="onToggleChange(group)"></InputSwitch>
        </div>
      </div>
    </page-container>
  </page-secondary>
</template>

<script>
import PageContainer from "../../../../global/pages/page-sub-components/PageContainer.vue";
import BaseComponent from "../../global/base/BaseComponent";
import TextBodySmall2 from "@/components/global/typography/TextBodySmall2";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall";
import InputSwitch from "@/components/global/inputs/InputSwitch.vue";
import PageSecondary from "@/components/global/pages/PageSecondary.vue";
import PushNotificationsService from "@/services/PushNotificationsService";
import headerEventBus from "@/event-buses/headerEventBus";
import {pageReadyEvent} from "@/helpers/dom/events/customEvents";

export default {
  name: "PagePushNotifications",
  extends: BaseComponent,
  components: {
    PageSecondary,
    PageContainer,
    TextBodySmall2,
    TextBodyExtraSmall,
    InputSwitch,
  },
  data: function () {
    return {
      state: 'loading',
      notificationGroups: [],
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['small-title', 'with-bg-color'],
        mainTitle: this.$i18n.t("message['push-notifications.title']"),
        subTitle: ''
      },
      services: {
        pushNotifications: new PushNotificationsService()
      }
    }
  },
  mounted() {
    this.hideLoading();
    this.setPageClass();
    this.setHeader(this.headerOptions);
    headerEventBus.$on("back-button-click", this.navigatePrevious);
    pageReadyEvent();

    this.services.pushNotifications.getNotificationGroups().then(data => {
      this.notificationGroups = data.groups

      this.notificationGroups = this.notificationGroups.map((group) => {
        if (group.name.toLowerCase() === 'Communities'.toLowerCase()) {
          group.description = this.$i18n.t("message['push-notifications.community-description']")
        }
        return group
      }).filter((group) => {
        return !!group.enabled_notifications_count
      })
      this.state = 'loaded'
    })
  },
  methods: {

    onToggleChange(group) {

      if (group.subscribed) {
        this.services.pushNotifications.subscribeToGroup(group.id).then((data) => {
          console.log(data)

        })

      } else {
        this.services.pushNotifications.unsubscribeFromGroup(group.id).then((data) => {
          console.log(data)
        })
      }
    }
  },
};
</script>

<style scoped></style>
