<template>
  <page-secondary :header="headerOptions" class="pc-meal-plan-shopping-cart">
    <page-container class="pc-meal-plan-shopping-cart__container">
     <text-body-extra-small
        class="pc-meal-plan-shopping-cart__empty-message"
        :line-height="'multi'"
        v-if="this.selectedItems.length === 0"
      >
        {{ $t('message["shopping-cart.empty-message"]') }}
      </text-body-extra-small>
      <loading-buffer v-if="loadingItems" />
      <div
        v-if="selectedItems.length > 0"
        class="pc-meal-plan-shopping-cart__cart-list-items"
      >
        <input-group-ingredients
          v-if="this.selectedItems.length > 0 && showGroupedShoppingList"
          :count="peopleCount"
          v-model="selectedItems"
          :grouped-ingredients="groupedShoppingItems"
          type="purchased"/>
        <transition-group name="fade" mode="out-in" appear>
          <input-shopping-list-item
            v-if="!showGroupedShoppingList"
            class="pc-meal-plan-shopping-cart__list-item"
            v-for="(item, key) in shoppingItems"
            :key="'shopping-purchased-list-item' + key"
            v-show="selectedItems.includes(item.id)"
            :title="item.name"
            :image="item.image"
            :quantity="item.quantity"
            :units="item.units"
            :count="peopleCount"
            :item-i-d="item.id"
            v-model="selectedItems"
          />
        </transition-group>
      </div>
    </page-container>
  </page-secondary>
</template>

<script>
import pagePurchasedItemsMixin from '../../../../../mixins/component-mixins/page/shopping-list/pagePurchasedItemsMixin'
import InputShoppingListItem from './page-components/InputShoppingListItem'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import InputGroupIngredients from './page-components/InputGroupIngredients'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import headerEventBus from "@/event-buses/headerEventBus";

export default {
  name: 'PageCart',
  components: {
    InputGroupIngredients,
    InputShoppingListItem,
    PageSecondary,
    PageContainer,
    TextBodyExtraSmall
  },
  mixins: [pagePurchasedItemsMixin],
  data: function () {
    return {
      pageClass: [
        'macroapp--page-notification',
        'page--shopping-cart',
        'macroapp--page--shopping-cart-list',
        'macroapp--page--shopping-cart-shop' // TODO : remove unwanted class macroapp classes
      ],
      headerOptions: {
        show: true,
        isTransparent: false,
        left: 'previous-emit',
        right: 'shopping-list-email',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-bg-color', 'small-title'],
        formData: {
          image: ''
        },
        mainTitle: this.$i18n.t("message['general.purchased-list']")
      }
    }
  },
  created () {
    this.setPageTitle(this.$t('message["general.purchased-list"]'))
  },
  mounted () {
    headerEventBus.$on('back-button-click', this.goBack)
    this.setPageClass()
    this.getMealPlanData()
    this.getShoppingListData()
    pageReadyEvent()
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
  },
  beforeDestroy() {
    headerEventBus.$off('back-button-click')
  }
}
</script>
