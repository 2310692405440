<template>
  <popup
    v-if="true"
    ref="popup"
    :visible="true"
    class="pc-popup-payment-processing"
    :no-close-on-backdrop="true"
  >
    <popup-title style="visibility: hidden">
      <popup-button-close @hide="closePopup" />
    </popup-title>
    <popup-body class="pc-popup-payment-processing__body text-center">
      <popup-head class="text-center">
        <popup-text-heading>{{ title }}</popup-text-heading>
      </popup-head>
      <popup-content>
        <icon-completed-filled
          v-if="state==='success'"
          size="lg3" />

        <div v-else class="pc-sign-up-payment__registration-form-input">
          <text-body-small
            style="text-align: justify"
            :line-height="'multi'"
            class="pc-sign-up-payment__registration-form-label"
          >
            {{ $t('message[\'profile.account-settings.address.post-code\']') }}
          </text-body-small>
          <text-input
            v-model="formData.postcode"
            name="postcode"
            :placeholder="$t('message[\'profile.account-settings.address.post-code\']')"
            class="pc-sign-up-payment__registration-form-input-region"
            :error-message="error"
          />
        </div>
      </popup-content>
      <popup-footer v-if="state==='init'">
        <button-primary
          :text="$t('message[\'profile.update\']')"
          @click="update"
        />
      </popup-footer>

    </popup-body>
  </popup>
</template>

<script>

import Popup from '../../../../global/popups/Popup'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'
import TextInput from '@/components/global/inputs/TextInput.vue'
import ProfileService from '@/services/ProfileService'
import IconCompletedFilled from '@/components/root/icons/IconCompletedFilled.vue'
import {mapGetters} from "vuex";

export default {
  name: 'PopupInvalidAddress',
  components: {
    IconCompletedFilled,
    TextInput,
    TextBodySmall,
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTitle,
    ButtonPrimary
  },
  mixins: [popupAnimationMixin],
  props: {
    country: {
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      service: 'signUpStore/getService',
    })
  },
  data: function () {
    return {
      title: this.$t('message[\'sign-up.postal-code.update\']'),
      formData:{
        country: 0,
        postcode: ""
      },
      error:'',
      state: 'init',
    }
  },
  mounted () {
    this.hideLoading()
    this.formData.country= this.country
  },
  methods: {
    update () {
      if(this.state==='submitting'){
        return
      }
      this.error =''
      this.state = 'submitting'
      const account = new ProfileService()
      account.updatePostalCode(this.formData).then(async ()=>{
        try{
          await this.service.getPlanDetails({forceAPI: true})
          this.state = 'success'
          setTimeout(() => {
            this.closePopup()
          }, 1000)
        }catch (e){

          console.log(e)
          if(e.error==='invalid-address'){
            this.error= this.$i18n.t('message["validation.invalid-postal-code"]')
          } else {
            this.error= e.message
          }
          this.state ='init'
        }
      }).catch(()=>{
        this.state ='init'
          this.error= this.$i18n.t('message["validation.invalid-postal-code"]')
      })
    },
    closePopup (e, callback) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        if (callback) {
          callback()
        }
      })
    }

  }
}
</script>
