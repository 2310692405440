import axios from 'axios'
import DineOutDB from '@/services/index-db/DineOutDB'
import { i18n } from '@/helpers/localization/i18n'
import ProfileService from '@/services/ProfileService'
import CountryService from '@/services/CountryService'
import lodash from 'lodash'

export default class FoodDiaryDineOutService {
  _country = 'New Zealand'
  _dineoutUrls = {}
  dbDef = {
    dbName: 'DineOutDB',
    dbVer: 1
  }
  localization = {
    'New Zealand': {
      mealGroups: ['Salads', 'Wings', 'Chicken', 'Turkey', 'Fish/seafood', 'Steak', 'Beef', 'Burgers', 'Pork', 'Greek/Gyro', 'Pizza', 'Pasta', 'Ham', 'Sausage', 'Bacon', 'Eggs', 'Sandwiches', 'Croissants', 'Paninis', 'Burritos', 'Muffins', 'Bread', 'Cookies/treats', 'Frapuccinos', 'Chocolates', 'Fries', 'Shakes & Smoothies', 'Kid Meals', 'Soups', 'Misc.', 'Wrap or Bowl', 'Protein Filling', 'Veggie Filling', 'Cheese Filling', 'Sauces', 'Seasonings', 'Toppings', 'Chips & Snacks', 'Tea (Tall)', 'Coffee (Tall)', 'Chocolate Drinks (Tall w/ skimmed milk)', 'Frapuccino (Tall w/ skimmed milk)', 'Starters', 'Romanian/Calzone', 'Classic Pizza', 'Leggera Pizzas', 'Al Forno', 'Sides', 'Desserts', 'Dolcetti', 'Veggie', 'Italian', 'Wraps', 'Breakfast', 'Tacos', 'Quesadillas', 'Nachos', 'Red Meat', 'Menu Items', 'Curry', 'Ramen', 'Teppanyaki', 'Donburi', 'Kokoro', 'Pastries', 'Muffins, Cakes and Bakes', 'Snacks', '6 inch subs', 'Sandwiches and Toasties', 'Paninis and Wraps', 'Breakfast Pastries', 'Breakfast Baguettes', 'Brioche and rolls', 'Birchers and yoghurt bowls', 'Baguettes', 'Wraps and Toasties', 'Protein Pots', 'Fruit Pots', 'Fish and Chips', 'Bowls', 'Easter', 'Signature Warm Bowls', 'Base', 'Premiums', 'BBQ Night', 'Fresh juices', 'Juice farmacy', 'Acai Bowls', 'Bottled Juice', 'Bottled juice farmacy', 'Bottled shots', 'Energy kick', 'H2O+', 'Kombucha', 'Souper Food', 'Popcorn', 'Trail mix', 'Pita', 'Ribs', 'Chops', 'Skewer', 'Sodas', 'Dressings', 'Stir Fry', 'Duck', 'Fried Rice', 'Noodles', 'Donuts', 'Cali Burrito', 'Enchilada', 'Nacho Fries', 'Alcoholic Beverage', 'Protein Bar', 'Nuts', 'Rice', 'Protein Mix', 'Sweet Potato']
    },
    'Netherlands': {
      mealGroups: ['Salades', 'Vleugels', 'Kip', 'Kalkoen', 'Vis/zeevruchten', 'Biefstuk', 'Rundvlees', 'Burgers', 'Varken', 'Grieks/gyros', 'Pizza', 'Pasta', 'Ham', 'Worst', 'Spek', 'Eieren', 'Broodjes', 'Croissants', 'Paninis', 'Burrito\'s', 'Muffins', 'Broodjes', 'Koekjes/lekkernijen', 'Frapuccino\'s', 'Chocolade', 'Friet', 'Shakes & Smoothies', 'Kindermaaltijden', 'Soepen', 'Overige', 'Wrap of bowl', 'Eiwitvulling', 'Groentevulling', 'Kaas vulling', 'Sauzen', 'Kruiden', 'Toppings', 'Chips en Snacks', 'Thee (groot)', 'Koffie (groot)', 'Chocoladedrank (groot met magere melk)', 'Frapuccino (groot met magere melk)', 'Voorgerechten', 'Calzone', 'Klassieke pizza', 'N/A', 'Uit de oven', 'sideen', 'Desserts', 'Patisserie', 'Vegetarisch', 'Italiaans', 'Wraps', 'Ontbijt', 'Taco\'s', 'Quesadillas', 'Nacho\'s', 'Rood vlees', 'Menu-opties', 'Curry', 'Ramen', 'Teppanyaki', 'Donburi', 'Kokoro', 'Gebak', 'Muffins, taarten en gebak', 'Snacks', '15 cm subs', 'Broodjes en tosti\'s', 'Panini\'s en wraps', 'Ontbijtgebak', 'N/A', 'Brioche en broodjes', 'Bircher muesli en yoghurt bowls', 'Stokbrood', 'Wraps en tosti\'s', 'N/A', 'Gesneden en verpakt fruit', 'Fish and chips', 'Bowls', 'Pasen', 'N/A', 'Basis', 'N/A', 'BBQ-avond', 'Verse sappen', 'N/A', 'Acai bowls', 'Sap uit fles', 'N/A', 'N/A', 'N/A', 'Water', 'Kombucha', 'Maaltijdsoepen', 'Popcorn', 'Mix van gedroogde vruchten', 'Pita', 'Spareribs', 'Karbonades', 'Spies', 'Frisdranken', 'Dressings', 'Roerbakschotel', 'Eend', 'Gebakken rijst', 'Noedels', 'Donuts', 'N/A', 'Enchilada', 'N/A', 'Alcoholische drank', 'Proteïnereep', 'Noten', 'Rijst', 'Eiwit Mix', 'Zoete Aardappel']
    }
  }

  restaurants = []

  constructor () {
    this._setDineOutBaseURLs()
    this.db = new DineOutDB()
  }

  _setDineOutBaseURLs () {
    const dineOutBaseURL = 'https://macro-main-app.s3.ap-southeast-2.amazonaws.com/dining-out/v9'
    this._dineoutUrls = {
      'United Arab Emirates': dineOutBaseURL + '/dodb-uae.json',
      'New Zealand': `https://macro-main-app.s3.ap-southeast-2.amazonaws.com/dining-out/v10/nz.json`,
      'United States': dineOutBaseURL + '/dodb-us.json',
      'United States of America': dineOutBaseURL + '/dodb-us.json',
      'Australia': `https://macro-main-app.s3.ap-southeast-2.amazonaws.com/dining-out/v10/au.json`,
      'United Kingdom': `https://macro-main-app.s3.ap-southeast-2.amazonaws.com/dining-out/v10/uk.json`,
      'South Africa': dineOutBaseURL + '/dodb-sa.json',
      'Canada': dineOutBaseURL + '/dodb-ca.json',
      'Netherlands': dineOutBaseURL + '/dodb-nl.json'

    }
  }

  showMacroSplitInput(){
    return ['United States','United States of America'].includes(this._country)
  }

  getMealGroups () {
    try {
      return this.localization[this._country].mealGroups
    } catch (e) {
      return this.localization['New Zealand'].mealGroups
    }

  }

  getDineOutUrl () {
    try {
      return this._dineoutUrls[this._country] || this._dineoutUrls['New Zealand']
    } catch (e) {
      return this._dineoutUrls['New Zealand']
    }
  }

  hasDineOutURL () {
    return !!this._dineoutUrls[this._country]
  }

  async init () {
    let country = await this.getCountry()
    this._country = country
    const hasData = await this.db.hasData()
    if (!hasData) {
      let restaurantsRes = await this.loadData(this.getDineOutUrl())
      const restaurants = restaurantsRes.data

      delete restaurants.Drinks
      const restaurantsArray = []
      for (const key in restaurants) {
        if (restaurants.hasOwnProperty(key)) {
          let objectSet = {
            'name': key,
            'meals': this._mapRestaurantsMealsResponse(restaurants[key])
          }
          restaurantsArray.push(objectSet)
        }
      }

      await this.db.addBulkData(restaurantsArray)
    }
  }

  _mapRestaurantsMealsResponse (meals) {
    meals.d= meals.d.map(d=>{
      return this._mapMeal(d)
    })
    meals.m= meals.m.map(m=>{
      return this._mapMeal(m)
    })
    return meals
  }
  _mapMeal(meal){
    const nutr = {
      'calories': meal.k,
      'macronutrients': {
        'fats': meal.f,
        'carbohydrates': meal.c,
        'proteins': meal.p,
        'net_carbohydrates': 0
      }
    }
    return  {...meal,...nutr}
  }

  async getRestaurants (macroSplit = null,strict =false) {
    console.log(macroSplit,strict)
    const restaurants = await this.db.getRestaurants(macroSplit,strict)
    return lodash.sortBy(restaurants, 'name')
  }

  async findRestaurant (name) {
    const restaurants = await this.db.search(name)
    if (restaurants.length > 0) {
      return this._mapRestaurantData(restaurants[0])
    }
    return []
  }

  _mapMealData (meal, restaurantName) {
    let regex = /[^a-zA-Z0-9\s]/g

    const id = (restaurantName + ' ' + meal.n).replace(regex, '')
    return {
      id: id.toLowerCase().replace(/\s+/g, '-'),
      'name': meal.n,
      'calories': meal.k,
      'recipes': [
        {
          'name': meal.n,
          'calories': meal.k,
          'macronutrients': {
            'fats': meal.f,
            'carbohydrates': meal.c,
            'proteins': meal.p,
            'net_carbohydrates': 0
          },
        }
      ]

    }

  }

  _mapRestaurantData (restaurant) {
    const mealGroups = new Map()
    const others = []
    restaurant.meals.m.forEach(meal => {
      const groups = this._categorizeString(meal.n)
      const mapMeal = this._mapMealData(meal, restaurant.name)
      if (groups.length === 0) {
        others.push(mapMeal)
      }
      groups.forEach(group => {
        if (mealGroups.has(group)) {
          const cur = mealGroups.get(group)
          cur.push(mapMeal)
          mealGroups.set(group, cur)
        } else {
          mealGroups.set(group, [mapMeal])
        }
      })

    })
    mealGroups.set(i18n.t('message[\'food-diary.dine-out.other\']'), others)

    if (restaurant.meals.d.length > 0) {

      const drinks = []
      restaurant.meals.d.forEach(drink => {
        const mapMeal = this._mapMealData(drink, restaurant.name)

        drinks.push(mapMeal)
      })
      mealGroups.set(i18n.t('message[\'food-diary.dine-out.drinks\']'), drinks)

    }

    const groups = []
    mealGroups.forEach((key, val) => {
      groups.push({
        name: val,
        meals: lodash.sortBy(key, 'name')
      })
    })
    return {
      name: restaurant.name,
      groups: lodash.sortBy(groups, 'name')
    }
  }

  async getData () {
    const restaurants = await this.db.getAll()

    restaurants.forEach(restaurant => {
      const mapData = this._mapRestaurantData(restaurant)
      this.restaurants.push({
        name: restaurant.name,
        groups: mapData.groups
      })
    })

    return this.restaurants
  }

  _categorizeString (inputString) {
    const matchedGroups = []

    this.getMealGroups().forEach(group => {
      if (inputString.toLowerCase().includes(group.toLowerCase())) {
        matchedGroups.push(group)
      }
    })

    return matchedGroups
  }

  loadData (url) {
    return new Promise((resolve, reject) => {

      var instance = axios.create()
      delete instance.defaults.headers.common['Authorization']
      instance.get(url)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  }

  getCountry () {
    return new Promise(async (resolve, reject) => {
      const account = new ProfileService()
      let countryId = account.address.country
      if (!countryId) {
        await account.setProfileData()
        countryId = account.address.country
      }
      const countryService = new CountryService()
      if (countryService.count === 0) {
        await countryService.setCountries()
      }

      const country = countryService.getCountryById(countryId)
      if (localStorage.getItem('userCountry')) {
        if (localStorage.getItem('userCountry') === country) {
          resolve(country)
        } else {
          let req = indexedDB.deleteDatabase(this.dbDef.dbName)

          req.onsuccess = () => {
            localStorage.setItem('userCountry', country)
            this.dbDef.dbVer = (localStorage.getItem('indexDbVersion') + 1) || 1
            localStorage.setItem('indexDbVersion', this.dbDef.dbVer)
            resolve(country)
          }
        }
      } else {
        localStorage.setItem('userCountry', country)
        localStorage.setItem('indexDbVersion', this.dbDef.dbVer)
        resolve(country)
      }
      resolve(country)
    })
  }
}
