import EventHandlerAPI from './custom-api/EventHandlerAPI'
import store from '../store'
import {i18n} from '@/helpers/localization/i18n'
import {flagValue} from '@/includes/TemplateSettings'
import moment from 'moment'

export default class UserEventsService {
  static disableFeedback = true
  static FirstTimeBottomBarPopupView = null
  static FirstTimeNewDashboardPopupView = null
  static InviteAFriendPageVisit = null
  static CommunityPageVisit = null
  static RegisterBeforeCommunities = null
  static visits = {}

  static sendFitnessDiaryFeedBackPopupView () {
    // disable feedback
    if (UserEventsService.disableFeedback) {
      return Promise.resolve()
    }

    let payload = {
      'event': 'FitnessDiaryEntryAdded',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 10}
      },
      'only_once': true,
      'acknowledge': true
    }

    let featureName = i18n.t('message[\'progress.my-journey\']')
    let api = new EventHandlerAPI()

    return new Promise((resolve, reject) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          store.commit('feedBackPopupStore/setContent', {
            title: i18n.t('message[\'popup.feed-back.title\']', {feature: featureName}),
            flag: 'fitness-diary',
            eventName: 'fitness-diary',
            placeHolderText: i18n.t('message[\'popup.feed-back.placeholder\']')
          })
          setTimeout(() => {
            store.commit('feedBackPopupStore/show')
          }, 4000)
        }
      }).catch(() => {
        // do nothing
      })
    })
  }

  static sendMealPlanFeedBackPopupView () {
    // disable feedback
    if (UserEventsService.disableFeedback) {
      return Promise.resolve()
    }
    if (flagValue('mp_feedback_popup_show') === false) {
      return Promise.resolve()
    }
    let payload = {
      'event': 'MealPlanViewed',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 15}
      },
      'only_once': true,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEvent(payload).then(() => {
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static checkMealPlanFeedBackPopupView () {
    // disable feedback
    if (UserEventsService.disableFeedback) {
      return Promise.resolve()
    }
    if (flagValue('mp_feedback_popup_show') === false) {
      return Promise.resolve()
    }
    let api = new EventHandlerAPI()
    let payload = {
      'event': 'MealPlanViewed',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 15}
      },
      'only_once': true,
      'acknowledge': true
    }
    api.trackAutomationEvent(payload).then((viewData) => {
      if (viewData.data && viewData.data.eligible) {
        store.commit('feedBackPopupStore/setContent', {
          title: i18n.t('message[\'popup.feed-back.title-meal-plan\']', {}),
          flag: 'meal-plan',
          eventName: 'meal-plan',
          placeHolderText: i18n.t('message[\'popup.feed-back.placeholder\']')
        })
        setTimeout(() => {
          store.commit('feedBackPopupStore/show')
        }, 1000)
      }
    })
  }

  static sendShoppingListFeedBackPopupView () {
    // disable feedback
    if (UserEventsService.disableFeedback) {
      return Promise.resolve()
    }
    let payload = {
      'event': 'ShoppingListListViewed',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 3}
      },
      'only_once': true,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          store.commit('feedBackPopupStore/setContent', {
            title: i18n.t('message[\'popup.feed-back.title-shopping-list\']', {}),
            flag: 'shopping-list',
            eventName: 'shopping-list',
            placeHolderText: i18n.t('message[\'popup.feed-back.placeholder\']')
          })
          setTimeout(() => {
            store.commit('feedBackPopupStore/show')
          }, 3000)
        }
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static sendMealPlanSummaryFeedBackPopupView () {
    // disable feedback
    if (UserEventsService.disableFeedback) {
      return Promise.resolve()
    }
    if (flagValue('meal_plan_summary_feedback_popup_show') === false) {
      return Promise.resolve()
    }
    let payload = {
      'event': 'MealPlanSummarySent',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': true,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          store.commit('feedBackPopupStore/setContent', {
            title: i18n.t('message[\'popup.feed-back.title-meal-plan-summary\']', {}),
            flag: 'meal-plan-summary',
            eventName: 'meal-plan-summary',
            placeHolderText: i18n.t('message[\'popup.feed-back.placeholder\']')
          })
          setTimeout(() => {
            store.commit('feedBackPopupStore/show')
          }, 3000)
        }
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static foodDairyFeedBackPopupView (date) {
    // disable feedback
    if (UserEventsService.disableFeedback) {
      return Promise.resolve()
    }
    if (flagValue('food_dairy_feedback_popup_show') === false) {
      return Promise.resolve()
    }
    let lastDate = localStorage.getItem('foodDairyLastLogDate') || null
    if (lastDate && moment(date).isSame(moment(lastDate))) {

    } else {
      localStorage.setItem('foodDairyLastLogDate', date)
    }
    let payload = {
      'event': 'FoodDairyLogged',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 2}
      },
      'only_once': true,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          store.commit('feedBackPopupStore/setContent', {
            title: i18n.t('message[\'popup.feed-back.title-food-dairy\']', {}),
            flag: 'food-diary',
            eventName: 'food-diary',
            placeHolderText: i18n.t('message[\'popup.feed-back.placeholder\']')
          })
          setTimeout(() => {
            store.commit('feedBackPopupStore/show')
          }, 3000)
        }
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static sendWorkoutLogFeedBackPopupView () {
    // disable feedback
    if (UserEventsService.disableFeedback) {
      return Promise.resolve()
    }
    let payload = {
      'event': 'WorkoutLogEntryAdded',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 10}
      },
      'only_once': true,
      'acknowledge': true
    }

    let featureName = i18n.t('message[\'workout-log.title\']')
    let api = new EventHandlerAPI()

    return new Promise((resolve, reject) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          store.commit('feedBackPopupStore/setContent', {
            title: i18n.t('message[\'popup.feed-back.title\']', {feature: featureName}),
            flag: 'workout-log',
            eventName: 'workout-log',
            placeHolderText: i18n.t('message[\'popup.feed-back.placeholder\']')
          })
          setTimeout(() => {
            store.commit('feedBackPopupStore/show')
          }, 3000)
        }
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static checkInviteAFriendPageVisit () {
    let api = new EventHandlerAPI()
    let payload = {
      'event': 'InviteAFriendPageVisit',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': false,
      'acknowledge': true

    }
    return new Promise((resolve, reject) => {
      if (UserEventsService.InviteAFriendPageVisit === false) {
        resolve(false)
        return
      } else if (localStorage.getItem('InviteAFriendPageVisit') === '1') {
        resolve(true)
        return
      }
      api.trackAutomationEvent(payload).then((viewData) => {
        if (viewData.data && viewData.data.eligible) {
          localStorage.setItem('InviteAFriendPageVisit', '1')
        } else {
          UserEventsService.InviteAFriendPageVisit = false
        }
        resolve((viewData.data && viewData.data.eligible))
      }).catch(() => {
        reject(new Error('unable to track'))
      })
    })
  }

  static trackInviteAFriendPageVisit () {
    let payload = {
      'event': 'InviteAFriendPageVisit',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': false,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEvent(payload).then(() => {
        UserEventsService.InviteAFriendPageVisit = true
        localStorage.setItem('InviteAFriendPageVisit', '1')
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static trackCommunityPageVisit (feedId) {
    let payload = {
      'event': 'CommunityPageVisit' + feedId,
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': false,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEvent(payload).then(() => {
        UserEventsService.CommunityPageVisit = true
        localStorage.setItem('CommunityPageVisit' + feedId, '1')
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static checkCommunityPageVisit (id) {
    let api = new EventHandlerAPI()
    let payload = {
      'event': 'CommunityPageVisit' + id,
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': false,
      'acknowledge': true

    }
    return new Promise((resolve, reject) => {
      if (UserEventsService.CommunityPageVisit === false) {
        resolve(false)
        return
      } else if (localStorage.getItem('CommunityPageVisit' + id) === '1') {
        resolve(true)
        return
      }
      api.trackAutomationEvent(payload).then((viewData) => {
        if (viewData.data && viewData.data.eligible) {
          localStorage.setItem('CommunityPageVisit' + id, '1')
        } else {
          UserEventsService.CommunityPageVisit = false
        }
        resolve((viewData.data && viewData.data.eligible))
      }).catch(() => {
        reject(new Error('unable to track'))
      })
    })
  }

  static trackItemVisit (item, itemID) {
    const event = item + 'Visit' + itemID
    let payload = {
      'event': event,
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': false,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEvent(payload).then(() => {
        UserEventsService.visits[event] = true
        localStorage.setItem(event, '1')
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static checkItemVisit (item, itemID) {
    const event = item + 'Visit' + itemID
    let api = new EventHandlerAPI()
    let payload = {
      'event': event,
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': false,
      'acknowledge': true

    }
    return new Promise((resolve, reject) => {
      if (UserEventsService.visits[event] === false) {
        resolve(false)
        return
      } else if (localStorage.getItem(event) === '1') {
        resolve(true)
        return
      }
      api.trackAutomationEvent(payload).then((viewData) => {
        if (viewData.data && viewData.data.eligible) {
          localStorage.setItem(event, '1')
        } else {
          UserEventsService.visits[event] = false
        }
        resolve((viewData.data && viewData.data.eligible))
      }).catch(() => {
        reject(new Error('unable to track'))
      })
    })
  }

  static checkRegisterBeforeCommunities () {
    let api = new EventHandlerAPI()
    let payload = {
      'event': 'RegisterBeforeCommunities',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': false,
      'acknowledge': true

    }
    return new Promise((resolve, reject) => {
      if (UserEventsService.CommunityPageVisit === false) {
        resolve(false)
        return
      } else if (localStorage.getItem('RegisterBeforeCommunities') === '1') {
        resolve(true)
        return
      }
      api.trackAutomationEvent(payload).then((viewData) => {
        if (viewData.data && viewData.data.eligible) {
          localStorage.setItem('RegisterBeforeCommunities', '1')
        } else {
          UserEventsService.RegisterBeforeCommunities = false
        }
        resolve((viewData.data && viewData.data.eligible))
      }).catch(() => {
        reject(new Error('unable to track'))
      })
    })
  }

  static trackRegisterBeforeCommunities () {
    let payload = {
      'event': 'RegisterBeforeCommunities',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': false,
      'acknowledge': true
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      if (localStorage.getItem('RegisterBeforeCommunities') === '1') {
        resolve()
      }

      api.trackEvent(payload).then(() => {
        UserEventsService.RegisterBeforeCommunities = true
        localStorage.setItem('RegisterBeforeCommunities', '1')
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static foodDiaryFeaturePopupView () {
    let payload = {
      'event': 'FoodDairyFeaturePopup',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': true,
      'acknowledge': true
    }

    if (localStorage.getItem('foodDiaryFeaturePopupShown')) {
      return
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          store.commit('featurePopupStore/setContent', {
            title: i18n.t('message[\'food-diary.feature-popup-title\']'),
            feature: 'food-diary-saved-meal',
            featureInfo: [
              {
                video: 'https://d39bs4ovl1ajzi.cloudfront.net/common/food-diary-saved-meals.mp4',
                image: '',
                content: ''
              }
            ]
          })
          localStorage.setItem('foodDiaryFeaturePopupShown', true)
          store.commit('featurePopupStore/showPopup')
        }
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static foodDiaryInfoPopupView () {
    let payload = {
      'event': 'FoodDairyInfoPopup',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': true,
      'acknowledge': true
    }

    if (localStorage.getItem('foodDiaryInfoPopupShown')) {
      return
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          localStorage.setItem('foodDiaryFeaturePopupShown', true)
          store.commit('showFoodDiaryInfoPopup')
        }
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static periodTrackerFeaturePopupView () {
    let payload = {
      'event': 'PeriodTrackerFeaturePopup',
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': true,
      'acknowledge': true
    }

    if (localStorage.getItem('periodTrackerFeaturePopupShown')) {
      return
    }

    let api = new EventHandlerAPI()

    return new Promise((resolve) => {
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          store.commit('featurePopupStore/setContent', {
            title: i18n.t('message[\'period-tracker.feature-popup-title\']'),
            feature: 'period-tracker',
            featureInfo: [
              {
                video: 'https://d39bs4ovl1ajzi.cloudfront.net/common/period-tracker-feature-video.mp4',
                image: '',
                content: ''
              }
            ]
          })
          localStorage.setItem('periodTrackerFeaturePopupShown', true)
          store.commit('featurePopupStore/showPopup')
        }
        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static async createFeaturePopupView (key, titleKey, feature, featureInfo) {
    const payload = {
      'event': key,
      'criteria': {
        'type': 'event_count',
        'attributes': {'count': 1}
      },
      'only_once': true,
      'acknowledge': true
    }

    if (localStorage.getItem(key)) {
      return await Promise.resolve()
    }

    const api = new EventHandlerAPI()

    return new Promise((resolve) => {
      if (featureInfo.video) {
        fetch(featureInfo.video)
      }
      if (featureInfo.image) {
        const image = new Image()
        image.src = featureInfo.image
        image.onload = function () {
          console.log('Image is loaded and cached.')
        }
      }
      api.trackEventWithEligibilityCheck(payload).then(viewData => {
        if (viewData.data && viewData.data.eligible) {
          store.commit('featurePopupStore/setContent', {
            title: i18n.t(`message['${titleKey}']`),
            feature: feature,
            featureInfo: [
              featureInfo
            ]
          })

          localStorage.setItem(key, true)
          store.commit('featurePopupStore/showPopup')
        }

        resolve()
      }).catch(() => {
        resolve()
      })
    })
  }

  static communityFeaturePopupView (navigate) {
    return Promise.resolve()
    /*  TODO: enable later
    return UserEventsService.createFeaturePopupView(
      'CommunityFeaturePopup',
      'community.feature-popup-title',
      'community',
      {
        video: 'https://d39bs4ovl1ajzi.cloudfront.net/common/community-tour.mp4',
        image: '',
        content: '',
        navigate: {
          url: navigate
        }
      }
    ) */
  }

  static bottomBarFeaturePopupView (navigate = '') {
    return UserEventsService.createFeaturePopupView(
      'FirstTimeBottomBarPopupView',
      'bottom-bar.feature-popup-title',
      'bottombar',
      {
        image: 'https://d39bs4ovl1ajzi.cloudfront.net/common/moved-plans.png',
        video: '',
        content: '',
        navigate: {
          url: navigate
        }
      }
    )
  }

  static newDashboardFeaturePopupView (navigate = '', content) {
    return UserEventsService.createFeaturePopupView(
      'FirstTimeNewDashboardPopupView',
      'new-dashboard.feature-popup-title',
      'newDashboard',
      {
        image: 'https://app-redesign-production-builds.s3.ap-southeast-2.amazonaws.com/common/dashboard-new-layout.png',
        video: '',
        content: content,
        navigate: {
          url: navigate
        }
      }
    )
  }

  static mealFavoritesFeaturePopupView (navigate = '', content) {
    return UserEventsService.createFeaturePopupView(
      'FirstTimeMealFavoritesPopupView',
      'meal-favorites.feature-popup-title',
      'mealFavorites',
      {
        image: 'https://d39bs4ovl1ajzi.cloudfront.net/common/favorite-meals.png',
        video: '',
        content: content,
        navigate: {
          url: navigate
        }
      }
    )
  }

  static stepTrackerFeaturePopupView (navigate = '', content) {
    return UserEventsService.createFeaturePopupView(
      'FirstTimeStepTrackerInfoPopupView',
      'step-tracker.introduction-popup.title',
      'stepTracker',
      {
        image: '',
        video: '',
        content: content,
        navigate: {
          url: navigate
        }
      }
    )
  }
}


