<template>
  <div class="pc-form-workout-log pc-form-workout-log--edit">
    <workout-log-success-area v-if="showSuccess" />
    <div>
      <div class="pc-form-workout-log__form-wraper">
        <div
          v-for="(form, fIndex) in formData.slice(0, exerciseSetCount)"
          :key="'entry-form-insert-'+fIndex"
          class="pc-form-workout-log__form"
          :class="[
            ...form.wrapClass,
            {
              'pc-form-workout-log__form--with-duration': hasMetricType('duration'),
              'pc-form-workout-log__form--with-text-header': (metricsArranged.length > 2),
              'pc-form-workout-log__form--with-one': metricsArranged.length === 1,
              'pc-form-workout-log__form--with-two': metricsArranged.length === 2,
              'pc-form-workout-log__form--with-three': metricsArranged.length === 3,
              'pc-form-workout-log__form--with-four': metricsArranged.length === 4
            },
          ]"
        >
          <validation-observer
            :ref="'form' + fIndex"
            v-slot="{ errors }"
            tag="div"
          >
            <div
              class="pc-form-workout-log__form-set"
              :class="{
                'pc-form-workout-log__form-set--with-duration': hasMetricType('duration'),
                'pc-form-workout-log__form-set--with-errors': Object.keys(errors).length > 0,
              }"
            >
              <div class="pc-form-workout-log__number-list text-center">
                <text-body-regular
                  weight="extra-bold"
                  class="pc-form-workout-log__set-number"
                >
                  {{ fIndex + 1 }}
                </text-body-regular>
              </div>
              <div
                class="container pc-form-workout-log__wrapper-inputs"
              >
                <div class="row">
                  <div
                    v-for="(entry, eIndex) in form.attributes"
                    :key="'entry-form-entry-'+fIndex+'-'+eIndex"
                    class="col pc-form-workout-log__wrapper-inputs-input"
                  >
                    <text-body-extra-small
                      v-if="metricsArranged.length > 2 || fIndex === 0"
                      class="pc-form-workout-log__header-input"
                      weight="extra-bold"
                    >
                      {{ getMetricLabel(entry) }}
                    </text-body-extra-small>
                    <validation-provider
                      v-slot="{ errors, invalid, validated }"
                      tag="div"
                      :vid="entry.type"
                      :name="getMetricLabel(entry)"
                      :rules="getValidationByType(entry.type)"
                    >
                      <text-input
                        v-model="entry.value"
                        :class="['text-center','pc-form-workout-log__input','pc-form-workout-log__input--'+entry.type]"
                        :mobile-input-mode="setInputMode (entry.type)"
                        maxlength="6"
                        :disabled="!(form.current||form.completed)"
                        :error-message="(invalid && validated&&errors)?errors[0]:''"
                        :hide-error-message="true"
                        :mask="setMask(entry.type)"
                        @change=" entryChanged(fIndex, eIndex, entry.value, false) "
                        @input=" entryChanged(fIndex, eIndex, entry.value, true) "
                      />
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="pc-form-workout-log__status text-center">
                <div
                  :class="{'pc-form-workout-log__wrapper-buttons': true, 'pc-form-workout-log__wrapper-buttons--with-duration': hasMetricType('duration') }"
                >
                  <div
                    class="pc-form-workout-log__icon-complete"
                    @click="setCurrentCompleted(fIndex)"
                  >
                    <icon-completed-filled
                      v-if="!(currentFormIndex()===fIndex) && form.completed"
                      size="md1"
                    />
                    <icon-completed-filled
                      v-if="(currentFormIndex()===fIndex) && form.completed"
                      size="md1"
                    />
                    <icon-complete-rounded
                      v-if="!(currentFormIndex()===fIndex) && !form.completed"
                      size="md1"
                      state="disabled"
                    />
                    <icon-complete-rounded
                      v-if="(currentFormIndex()===fIndex) && !form.completed"
                      size="md1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="formErrorMessage(errors)|| setPreviousText(form)"
              class="pc-form-workout-log__other-info"
            >
              <div class="">
                <text-body-extra-small
                  v-if="formErrorMessage(errors)"
                  class="text-center pc-form-workout-log__text-error"
                >
                  {{ formErrorMessage(errors) }}
                </text-body-extra-small>
              </div>
              <text-body-extra-small class=" text-center pc-form-workout-log__text-previous">
                {{ setPreviousText(form) }}
              </text-body-extra-small>
            </div>
          </validation-observer>
        </div>

        <div
          v-show="isAllDefinedSetsCompleted&&flagValueDefaultDisabled"
          class="pc-form-workout-log__form-extra-sets"
          :class="{'pc-form-workout-log__form-extra-sets--empty':formData.slice(exerciseSetCount).length===0}"
        >
          <div
            v-for="(form ) in formData.slice(exerciseSetCount)"
            :key="'entry-form-insert-'+form.index"
            class="pc-form-workout-log__form "
            :class="[
              ...form.wrapClass,
              {
                'pc-form-workout-log__form--with-duration': hasMetricType('duration'),
                'pc-form-workout-log__form--with-text-header': (metricsArranged.length > 2),
                'pc-form-workout-log__form--with-one': metricsArranged.length === 1,
                'pc-form-workout-log__form--with-two': metricsArranged.length === 2,
                'pc-form-workout-log__form--with-three': metricsArranged.length === 3,
                'pc-form-workout-log__form--with-four': metricsArranged.length === 4

              },
            ]"
          >
            <validation-observer
              :ref="'form' + form.index"
              v-slot="{ errors }"
              tag="div"
            >
              <div
                class="pc-form-workout-log__form-set"
                :class="{
                  'pc-form-workout-log__form-set--with-duration': hasMetricType('duration'),
                  'pc-form-workout-log__form-set--with-errors': Object.keys(errors).length > 0,
                }"
              >
                <div class="pc-form-workout-log__number-list text-center">
                  <text-body-regular
                    weight="extra-bold"
                    class="pc-form-workout-log__set-number"
                  >
                    {{ form.index + 1 }}
                  </text-body-regular>
                  <icon-delete
                    class="pc-form-workout-log__set-delete"
                    size="sm2"
                    @click="requestDeleteSet(form.index)"
                  />
                </div>
                <div
                  class="container pc-form-workout-log__wrapper-inputs"
                >
                  <div class="row">
                    <div
                      v-for="(entry, eIndex) in form.attributes"
                      :key="'entry-form-entry-'+form.index+'-'+eIndex"
                      class="col"
                    >
                      <text-body-extra-small
                        v-if="metricsArranged.length > 2 || form.index === 0"
                        class="pc-form-workout-log__header-input"
                        weight="extra-bold"
                      >
                        {{ getMetricLabel(entry) }}
                      </text-body-extra-small>
                      <validation-provider
                        v-slot="{ errors, invalid, validated}"
                        tag="div"
                        :vid="entry.type"
                        :name="getMetricLabel(entry)"
                        :rules="getValidationByType(entry.type)"
                      >
                        <text-input
                          v-model="entry.value"
                          :class="['text-center','pc-form-workout-log__input','pc-form-workout-log__input--'+entry.type]"
                          :mobile-input-mode="setInputMode (entry.type)"
                          :type="setInputType (entry.type)"
                          :max="22"
                          :maxlength="7"
                          :max-decimal-points="2"
                          :disabled="!(form.current||form.completed)"
                          :error-message="(invalid && validated&&errors)?errors[0]:''"
                          :hide-error-message="true"
                          :mask="setMask(entry.type)"
                          :onkeypress="onMetricKeyPress"
                          @change=" entryChanged(form.index, eIndex, entry.value, false) "
                          @input=" entryChanged(form.index, eIndex, entry.value, true) "
                        />
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="pc-form-workout-log__status text-center">
                  <div
                    :class="{'pc-form-workout-log__wrapper-buttons': true, 'pc-form-workout-log__wrapper-buttons--with-duration': hasMetricType('duration') }"
                  >
                    <div
                      v-if="hasMetricType('duration')"
                      class="pc-form-workout-log__icon-duration"
                    >
                      <icon-play
                        v-if="disableFormPlayButton(form.index)"
                        size="md1"
                        state="disabled"
                      />
                      <icon-stop
                        v-else-if="playMainTimer && form.index === setID"
                        size="md1"
                        @click="formTimerStart(form.index, getMetricTypeIndex('duration'))"
                      />
                      <icon-play
                        v-else-if="!playMainTimer && form.index === setID"
                        size="md1"
                        @click=" formTimerStart(form.index, getMetricTypeIndex('duration'))"
                      />
                      <icon-play
                        v-else
                        size="md1"
                        @click="formTimerStart(form.index, getMetricTypeIndex('duration'))"
                      />
                    </div>
                    <div
                      class="pc-form-workout-log__icon-complete"
                      @click="setCurrentCompleted(form.index)"
                    >
                      <icon-completed-filled
                        v-if="!(currentFormIndex()===form.index) && form.completed"
                        size="md1"
                      />
                      <icon-completed-filled
                        v-if="(currentFormIndex()===form.index) && form.completed"
                        size="md1"
                      />
                      <icon-complete-rounded
                        v-if="!(currentFormIndex()===form.index) && !form.completed"
                        size="md1"
                        state="disabled"
                      />
                      <icon-complete-rounded
                        v-if="(currentFormIndex()===form.index) && !form.completed"
                        size="md1"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="formErrorMessage(errors)|| setPreviousText(form)"
                class="pc-form-workout-log__other-info"
              >
                <text-body-extra-small
                  v-if="formErrorMessage(errors)"
                  class="text-center pc-form-workout-log__text-error"
                >
                  {{ formErrorMessage(errors) }}
                </text-body-extra-small>
                <text-body-extra-small class=" text-center pc-form-workout-log__text-previous">
                  {{ setPreviousText(form) }}
                </text-body-extra-small>
              </div>
            </validation-observer>
          </div>
          <button-custom
            v-if="formData.slice(exerciseSetCount).length<3"
            class="pc-form-workout-log__button-add-sets"
            type="outline"
            :text="$t('message[\'workout-log.add-new-set\']')"
            @click="createNewEntry"
          >
            <template #left>
              <icon-add-fill-rounded size="sm2" />
            </template>
          </button-custom>
        </div>
      </div>

      <validation-provider
        v-slot="{ errors, invalid, validated }"
        class="pc-form-workout-log__wrapper-note"
        tag="div"
        name="note"
        rules="max:1000"
      >
        <text-area
          v-model.trim="note"
          :placeholder="[[$t('message[\'workout-log.add-note\']')]]"
          class="pc-form-workout-log__input-note"
          :cols="3"
          :rows="2"
          maxlength="999"
          :class="{ 'pc-form-workout-log__input-note--with-error': invalid && validated }"
          @change="noteChanged()"
        />
        <text-body-extra-small
          v-if="errors[0]"
          class="pc-form-workout-log__text-error"
        >
          {{ errors[0] }}
        </text-body-extra-small>
      </validation-provider>

      <set-completed-text
        :marked="totalCompleted"
        :total-sets="getSetsCount()"
      />
      <popup-workout-log-confirm-without-log
        v-if="confirmPopupShow"
        :visible="confirmPopupShow"
        :button-text="$t('message[\'general.proceed\']')"
        @close="confirmPopupShow = false"
        @next="confirmSave()"
      />
      <popup-workout-log-personal-best
        v-if="personalBestPopup.show"
        :exercise-name="exerciseName"
        :value="personalBestPopup.value"
        :visible="personalBestPopup.show"
        :button-text="$t('message[\'workout-log.back-to-history\']')"
        @close="navigateNextExercise()"
        @next="navigateNextExercise()"
      />
    </div>
    <popup-workout-log-exit
      v-if="exitPopupShow"
      :visible="exitPopupShow"
      @close="exitPopupShow = false"
      @exit="exit()"
    />
    <div class="pc-form-workout-log__wrapper-buttons">
      <button-primary
        v-if="!forceEdit"
        class="pc-form-workout-log__button-submit"
        type="button"
        :text="$t('message[\'workout-log.complete-exercise\']') "
        @click="gotoNextExercise()"
      />
      <button-primary
        v-if="forceEdit"
        class="pc-form-workout-log__button-submit"
        type="button"
        :text="$t('message[\'workout-log.save-text\']') "
        @click="gotoNextExercise()"
      />
      <button-secondary
        v-if="forceEdit"
        class="pc-form-workout-log__button-cancel"
        type="button"
        :text="$t('message[\'general.cancel\']') "
        @click="cancelEdit()"
      />
      <popup-workout-log-confirm-remove-extra-sets
        v-if="popupExtraSetDelete.show"
        :visible="popupExtraSetDelete.show"
        :set-index="popupExtraSetDelete.index"
        @remove-extra-set="deleteSet"
        @close="popupExtraSetDelete.show=false"
      />
    </div>
  </div>
</template>
<script>
import workoutLogEntryFormMixin from '../../../../../mixins/component-mixins/page/workout/workoutLogEntryFormMixin'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import PopupWorkoutLogConfirmWithoutLog from './popups/PopupWorkoutLogConfirmWithoutLog'
import PopupWorkoutLogPersonalBest from './popups/PopupWorkoutLogPersonalBest'
import StopWatch from '../widgets/StopWatch'
import Timer from './Timer'
import WorkoutLogSuccessArea from './ContainerFullscreenSuccess'
import SetCompletedText from './TextSetComplete'
import '../../../../../helpers/validation'
import PopupWorkoutLogExit from './popups/PopupWorkoutLogExit'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import TextArea from '../../../../global/inputs/TextArea'
import TextInput from '../../../../global/inputs/TextInput'
import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import IconCompleteRounded from '../../../../root/icons/IconCompleteRounded'
import IconCompletedFilled from '../../../../root/icons/IconCompleteFilled'
import IconPlay from '@/components/root/icons/IconPlay'
import ButtonCustom from '@/components/global/buttons/ButtonCustom'
import IconAddFillRounded from '@/components/root/icons/IconAddFillRounded'
import IconStop from '@/components/root/icons/IconStop'
import IconDelete from '@/components/root/icons/IconDeleteLinear'
import PopupWorkoutLogConfirmRemoveExtraSets
  from '@/components/layout/template-1/workout/page-components/popups/PopupWorkoutLogConfirmRemoveExtraSet'
import { enableFlexibleWorkoutLog } from '@/includes/TemplateSettings'

export default {
  name: 'FormEditWorkoutLog',
  components: {
    PopupWorkoutLogConfirmRemoveExtraSets,
    IconDelete,
    IconStop,
    IconAddFillRounded,
    ButtonCustom,
    IconPlay,
    ButtonSecondary,
    ButtonPrimary,
    TextArea,
    TextInput,
    TextBodyRegular,
    TextBodyExtraSmall,
    IconCompleteRounded,
    IconCompletedFilled,
    SetCompletedText,
    ValidationProvider,
    ValidationObserver,
    PopupWorkoutLogConfirmWithoutLog,
    PopupWorkoutLogPersonalBest,
    PopupWorkoutLogExit,
    StopWatch,
    Timer,
    WorkoutLogSuccessArea
  },
  mixins: [workoutLogEntryFormMixin],
  props: {
    exerciseRestInterval: {},
    exerciseRestTime: {},
    exerciseTime: {}
  },
  data: function () {
    return {
      showSuccess: false,
      restText: this.$i18n.t('message["general.rest"]'),
      showMainTimer: true,
      playMainTimer: false,
      isMainTimerStopped: false
    }
  },
  computed: {
    flagValueDefaultDisabled:function(){
      return enableFlexibleWorkoutLog()
    },
  },
  mounted () {

    const sets = this.sets.split('/')
    const storeData = this.getLogDataFromStore(parseInt(this.exerciseID))
    if (typeof storeData !== 'undefined' && storeData.formData.length > 0 &&
      storeData.formData[0].attributes.length === this.metrics.length && sets.length === storeData.formData.length) {
      const isMatchMetrics = storeData.formData[0].attributes.every(att => {
        return this.metrics.some(metric => metric.type === att.type)
      })
      if (isMatchMetrics) {
        return
      }
    }
    this.formData.forEach((form, id) => {
      if (this.isFormAllFilled(form)) {
        this.formData[id].completed = true
        this.formData[id].current = false
        if (this.formData[id + 1]) {
          this.formData[id + 1].current = true
        } else {
          this.formData[id].current = true
        }
      }
      this.formData[id].isPlayed = true
    })
  },
  methods: {}
}
</script>
