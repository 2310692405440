import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import { mapGetters, mapMutations } from 'vuex'
import MealPlanService from '../../../../services/MealPlanService'
import headerEventBus from "@/event-buses/headerEventBus";

export default {
  extends: BaseComponent,
  data: function () {
    return {

      peopleCount: 1,
      daysHeader: 'shopping-list-header',
      instructionsPopup: {
        instructions: '',
        title: ''
      },
      content: {},
      selectedDays: [],
      days: [],
      mealPlanID: 0,
      pageLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      getSelectedDays: 'shoppingStore/days',
      getSelectedPeopleCount: 'shoppingStore/peopleCount',

    })
  },
  watch: {
    peopleCount: function (newVal, oldVal) {
      newVal = parseInt(newVal) || 0
      oldVal = parseInt(oldVal) || 0
      if (newVal > oldVal) {
        this.logEvent('Adjusted Shopping List Size', {
          meal_plan_id: this.mealPlanID,
          size: newVal,
          type: 'increased'
        }, false)
      } else if (newVal < oldVal) {
        this.logEvent('Adjusted Shopping List Size', {
          meal_plan_id: this.mealPlanID,
          size: newVal,
          type: 'decreased'
        }, false)
      }
      this.setPeopleCount({ count: newVal })
    },
    selectedDays: function (val) {
      this.setSelectedDays({ days: val })
    }
  },
  beforeMount () {

    this.mealPlanID = this.$route.params.mid
    this.selectedDays = this.getSelectedDays
    this.peopleCount = this.getSelectedPeopleCount
  },
  methods: {
    ...mapMutations({
      setRouteTransition: 'setRouteTransition',
      viewInstructionsPopup: 'instructionPopupStore/show',
      setInstructionsPopupContent: 'instructionPopupStore/setContent',
      setPeopleCount: 'shoppingStore/people',
      setSelectedDays: 'shoppingStore/days'
    }),

      goBack(){
        this.$router.replace({
          path: this.$appConfig.appUrlList.mealPlan.replace(':mid', this.mealPlanID),
          query: { page: 'meal-plan',loading: 0 }
        })
      },

    getMealPlanData () {
      this.loadSkeleton = true
      const service = new MealPlanService()
      service.getMealPlan(this.mealPlanID).then(response => {
        let dayID = 1
        this.days = response.plan.weeks[0].days.map(day => {
          return {
            id: dayID++,
            text: this.numberWordToNumeric(day.name)
          }
        })
        this.selectedDays = this.getSelectedDays.slice(0,this.days.length)
        this.setSelectedDays(this.selectedDays)
        this.daysHeader = response.content['shopping-list-header']
        this.instructionsPopup.instructions = [response.plan.description]
        // calling to setContent function in the pageStore
        this.setInstructionsPopupContent(this.instructionsPopup)
      }).catch(() => {
        // do nothing
      }).finally(() => {
        this.loadSkeleton = false
        // this.hideLoading()
        this.pageLoaded = true
      })
    },
    toggleSelectedDays (value) {
      if (this.selectedDays.includes(value)) {
        let index = this.selectedDays.indexOf(value)
        if (index !== -1) {
          this.selectedDays.splice(index, 1)
        }
      } else {
        this.selectedDays.push(value)
      }
    },
    toggleAllDays () {
      let day = 1
      while (day <= 7) {
        if (!this.selectedDays.includes(day)) {
          this.selectedDays.push(day)
        }
        day++
      }
    },
    toggleNoDays () {
      this.selectedDays.splice(0, this.selectedDays.length)
    },
    createList () {
     // this.setRouteTransition({ name: 'up-slide-fade', mode: 'in-out' })
      if ((parseInt(this.peopleCount) < 1 || this.selectedDays.length < 1)) {
        return
      }

      this.$router.push({
        path: this.$appConfig.appUrlList.shoppingList.replace(':mid', this.mealPlanID),
        query: { page: 'shop',loading: 0 }
      })
    }
  }
}
