<template>
  <div class="pc-container-stripe-payment-element">
    <loading-buffer
      v-if="!isZeroOneOff&&loading"
      :height="100"
    />
    <form id="payment-form">
      <div id="link-authentication-element"/>
      <div
        id="payment-element"
        :dir="dir"
      ></div>
      <div
        id="address-element"
        :dir="dir"
      >
        <!-- Elements will create form elements here -->
      </div>
      <alert-dotted
        v-if="paymentError.message"
        class="pc-sign-up-payment__warning"
      >
        <icon-warning
          size="sm3"
          class="pc-sign-up-payment__warning-icon"
        />
        <div class="pc-sign-up-payment__content">
          <text-body-small
            :weight="'extra-bold'"
            :line-height="'multi'"
            class="pc-sign-up-payment__warning-name"
          >
            {{ $t('message["sign-up.payment-failed"]') }}
          </text-body-small>

          <text-body-extra-small
            :line-height="'multi'"
            class="pc-sign-up-payment__warning-description"
          >
            {{ paymentError.message }}
          </text-body-extra-small>
        </div>
      </alert-dotted>
      <slot></slot>
      <div class="pc-sign-up-payment__stripe-elements-text-wrapper">
        <icon-secure-lock
          class="pc-sign-up-payment__secure-lock-icon"
          :size="'sm2'"
        />
        <text-body-extra-small
          class="pc-sign-up-payment__secure-text"
          v-html="$t('message[\'sign-up.payment.stripe-secure-text\']')"
        />
        <icon-stripe-powered class="pc-sign-up-payment__stripe-powered-badge"/>
      </div>
      <button-primary
        id="submit"
        :disabled="(!isZeroOneOff || (isZeroOneOff&& (formState==='submitting' || formState==='success'))) && (formState==='submitting' || formState==='success' || !formCompleted)"
        :text="payButtonText"
        class="pc-sign-up-payment__footer-button"
        @click="pay"
      >
        <icon-loading-buffer
          v-if="!isZeroOneOff && formState==='submitting'"
          class="pc-sign-up-payment__footer-button--spinner"
          size="md1"
        />
      </button-primary>
      <popup-payment-processing
        v-if="popupPaymentProcessing.show"
        @redirected="redirected=true"
      />
      <div id="error-message">
        <!-- Display error message to your customers here -->
      </div>
    </form>
  </div>
</template>

<script>
import SignUpService from '../../../../../services/sign-up/SignUpService'
import LanguageService from '../../../../../services/LanguageService'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import AlertDotted from '../../../../global/alerts/AlertDotted'
import IconWarning from '../../../../root/icons/IconWarning'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import IconLoadingBuffer from '../../../../root/icons/IconLoadingBuffer'
import PopupPaymentProcessing from './PopupPaymentProcessing'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import IconSecureLock from '../../../../root/icons/IconSecureLock'
import IconStripePowered from '../../../../root/IconStripePowered'
import { mapMutations } from 'vuex'
import TextInput from '@/components/global/inputs/TextInput.vue'
import InputText from '@/components/global/inputs/TextInput.vue'
import * as Sentry from '@sentry/browser'
import TextBodyRegular from '@/components/global/typography/TextBodyRegular.vue'
import TextBodyMedium from '@/components/global/typography/TextBodyMedium.vue'
import TextBodySmall2 from '@/components/global/typography/TextBodySmall2.vue'

export default {
  name: 'ContainerStripePaymentElement',
  components: {
    TextBodySmall2,
    TextBodyMedium,
    TextBodyRegular,
    InputText,
    TextInput,
    LoadingBuffer,
    PopupPaymentProcessing,
    IconLoadingBuffer,
    ButtonPrimary,
    AlertDotted,
    IconWarning,
    TextBodySmall,
    TextBodyExtraSmall,
    IconSecureLock,
    IconStripePowered
  },
  props: {
    supportedPaymentMethods: {
      default: function () {
        return []
      }
    },
    stripeAccountCountryCode: {
      required: true
    },
    stripeAccount: {
      required: true
    },
    price: {
      required: true
    },
    currency: {
      required: true
    },
    stripeKey: {
      required: true,
      type: String
    },
    fullName: {
      required: true,
      type: String
    },
    planType: {
      required: true,
      type: String
    },
    email: {
      required: true,
      type: String
    },
    amount: {
      required: true,
      type: Number
    },
    country: {
      required: true,
      type: String
    },
    postalCode: {
      required: true,
      type: String
    },
    paymentMethods: {
      default: () => [],
      type: Array
    },
    coupon: {
      default: '',
      type: String
    },
    payButtonText: {
      default: 'Pay',
      type: String
    }
  },
  data: function () {
    return {
      popupPaymentProcessing: {
        show: false
      },
      formData: {
        postcode: ''
      },
      loading: true,
      redirected: false,
      dir: 'ltr',
      formState: 'init',
      elements: null,
      formCompleted: false,
      clientSecret: null,
      subscription: null,
      thankYouUrl: null,
      paymentError: {
        message: '',
        code: ''
      }
    }
  },
  beforeMount () {
    let lang = new LanguageService()
    if (lang.getAppLang() === 'ar-sa') {
      this.dir = 'rtl'
    }
  },
  computed:{
    isZeroOneOff: function (){
      console.log(this.planType, this.amount)
      return this.planType==='one-off' && this.amount === 0
    }
  },
  mounted () {
    let lang = new LanguageService()
    const stripe = Stripe(this.stripeKey, {
      locale: lang.getLangForStripe(),
      stripeAccount: this.stripeAccount,
      betas: ['netbanking_beta_1', 'netbanking_bank_beta_1'],
    })

    console.log(this.paymentMethods)
    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: this.getColorVariable('$button-primary'),
        colorBackground: this.getColorVariable('$bg-infoPages'),
        colorDangerText: this.getColorVariable('$error-state'),
        colorText: this.getColorVariable('$text-primary-on-bg'),
        colorPrimaryText: this.getColorVariable('$text-primary-on-bg'),
        colorTextSecondary: this.getColorVariable('$text-secondary-on-bg'),
        colorIconTabSelected: this.getColorVariable('$button-secondary-outline-and-text'),
        colorTextOutlineContentChoices: this.getColorVariable('$text-area-bg-outline'),
        spacingUnit: '0px',
        spacingGridRow: '10px',
        borderRadius: '6px',
        fontLineHeight: '1.5'
        // See all possible variables below
      },
      rules: {
        '.Tab': {
          border: '1px solid var(--colorTextOutlineContentChoices)',
          color: 'var(--colorPrimaryText)',
          lineHeight: 1.5,
          marginBottom: '10px',
          borderRadius: '8px',
          paddingBottom: '10px',
          paddingTop: '10px'
        },
        '.TabLabel': {
          fontSize: '12px',
          fontWeight: '700'
        },
        '.Tab--selected': {
          border: '1px solid var(--colorIconTabSelected)',
          color: 'var(--colorIconTabSelected)',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorIconTabSelected)'
        },
        '.Tab--selected:focus': {
          border: '1px solid var(--colorIconTabSelected)',
          color: 'var(--colorIconTabSelected)',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorIconTabSelected)'
        },
        '.Label': {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: 1.5,
          marginBottom: '8px',
          color: 'var(--colorPrimaryText)'
        },
        '.Input': {
          paddingTop: '15px',
          paddingBottom: '15px',
          paddingLeft: '20px',
          paddingRight: '20px',
          background: '#FFFFFF',
          border: '1px solid #E5E5E5',
          borderRadius: '10px',
          lineHeight: 1.5,
          fontWeight: 700,
          marginBottom: '2px',
          fontSize: '18px'

        },
        '.Input::placeholder': {
          fontWeight: 700
        },
        '.Error': {
          textAlign: 'center'
        },
        '.TermsText': {

          fontWeight: 400,
          fontSize: '12px',
          lineHeight: 1.5,
          marginBottom: '12px'
        }
      }
    }
    const mode = this.planType === 'subscription' ? 'subscription' : 'payment'
    const amount = parseInt(this.amount) // amount = payment amount * 100 (convert to cents)
    const currency = this.currency.toLowerCase()
    if(!this.isZeroOneOff){
    this.elements = stripe.elements({
      appearance,
      mode: mode,
      amount: amount,
      currency,
      paymentMethodTypes: this.paymentMethods,

    })
    const paymentElementOptions = {
      layout: 'tabs',
      fields: {
        billingDetails: {
          address: {
            country: 'never',
            postalCode: 'never',
          },

        }
      },

      defaultValues: {
        billingDetails: {
          name: this.fullName,
          email: this.email,
          address: {
            country: this.country,
            postal_code: this.postalCode,
          },
        }
      }
    }
    const paymentElement = this.elements.create('payment', paymentElementOptions)


    paymentElement.mount('#payment-element')
    paymentElement.on('change', (event) => {
      console.log('change event', event)
      this.loading = false
      this.formCompleted = event.complete

      this.paymentError.message = ''
      this.paymentError.code = ''
    })

    paymentElement.on('loaderror', (event) => {
      this.paymentError.message = event.error.message
      this.paymentError.code = event.error.code
    })

    paymentElement.on('ready', (event) => {
      this.paymentError.message = event.error.message
      this.paymentError.code = event.error.code
    })
    }
    const form = document.getElementById('payment-form')

    form.addEventListener('submit', async (event) => {
      event.preventDefault()
      if(this.isZeroOneOff){
        return
      }
      if (this.formState === 'submitting') {
        return
      }
      this.formState = 'submitting'
      const elements = this.elements
      if(!this.clientSecret){
        this.clientSecret = await this.createPaymentIndent()
      }

      const clientSecret = this.clientSecret

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit()
      if (submitError) {
        this.formState = 'error'
        this.paymentError.message = submitError.message
        this.paymentError.code = submitError.code
        Sentry.captureMessage('Checkout stripe Error :' + this.paymentError.code + ' ' + this.paymentError.message)
        return
      }

      let response
      const confirmParams = {
        // `Elements` instance that was used to create the Payment Element
        elements,
        clientSecret,
        confirmParams: {
                   payment_method_data:{
                      billing_details:{
                        name: this.fullName,
                        email: this.email,
                        address: {
                          country: this.country,
                          postal_code: this.postalCode,
                        },
                      }
                    },
          return_url: location.origin + '/subscribe/' + this.$route.params.plan + '/sign-up/confirm-payment?subscription=' + this.subscription
        },
        redirect: 'if_required'
      }

      if (clientSecret.includes('pi_')) {
        response = await stripe.confirmPayment(confirmParams)
      } else {
        response = await stripe.confirmSetup(confirmParams)
      }

      console.info('stripe confirm pasyment result stripe.confirmPayment() clientSecret ', clientSecret)
      console.log(response)
      const { error } = response
      console.log(error)
      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        this.formState = 'error'
        this.paymentError.message = error.message
      } else {
        this.checkSubscriptionStatus()
        setTimeout(() => {
          this.formState = 'success'
        }, 2000)

        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    })
  },
  methods: {
    ...mapMutations({
      setRouteTransition: 'setRouteTransition'
    }),
    checkSubscriptionStatus () {
      let popupPaymentProcessingTimeout = null
      const planID = this.$route.params.plan
      const service = new SignUpService(planID)
      this.subscription = this.subscription || this.getSubscriptionIDFromThankUouPage()
      service.checkSubscriptionStatus(this.subscription || this.getSubscriptionIDFromThankUouPage()).then(subscription => {
        if (subscription.ready) {

          this.showLoading()
          this.setRouteTransition({
            name: 'none',
            mode: 'out-in'
          })
          setTimeout(() => {
            this.goToThankYouPage()
          }, 1000)
          popupPaymentProcessingTimeout && clearTimeout(popupPaymentProcessingTimeout)
          return
        }
        popupPaymentProcessingTimeout = setTimeout(() => {
          this.popupPaymentProcessing.show = true
          this.checkSubscriptionStatus()
        }, 3000)

      })
    },

    getSubscriptionIDFromThankUouPage () {
      // Sample URL
      const url = this.thankYouUrl

      // Regular expression pattern to match the ID
      const pattern = /\/subscribe\/([^\/]+)\/thank-you/

      // Extracting the ID using the match method
      const match = url.match(pattern)

      // Check if a match is found
      if (match) {
        // Extracted ID will be in the second captured group
        const id = match[1]
        console.log('Extracted ID:', id)
        return parseInt(id)
      } else {
        console.log('ID not found in the URL.')
      }

    },
    goToThankYouPage () {
      if (this.redirected) {
        return
      }
      if (this.thankYouUrl) {
        this.showLoading()
      //TODO : remove this
        const thankYouUrl = this.thankYouUrl.replace('https://nz-alpha-customer.alpha-macro.com/', '/').replace(location.origin,'')
        this.$router.push({ path: thankYouUrl })
        return;
      }
      this.$router.push({ path: this.$appConfig.appUrlList.signUpThankYou.replace(':subscription', this.subscription) })
    },
    createPaymentIndent () {
      return new Promise((resolve, reject) => {
        const planID = this.$route.params.plan
        const service = new SignUpService(planID)
        service.getStripePaymentToken(this.coupon, this.formData.postcode).then(data => {

          this.thankYouUrl = data.thankYouUrl
          this.subscription = data.subscription_id || this.getSubscriptionIDFromThankUouPage()

          if (!data.piClientSecret && !data.siClientSecret) {
            this.goToThankYouPage()
            this.showLoading()
            reject(new Error('coupon is greater than price'))

            return
          }
          const key = data.piClientSecret || data.siClientSecret

          resolve(key)
        }).catch(err => {
          this.formState = 'error'
          // handle error 422
          const dataRes = err.response.data
          if(err.response.data.reason && err.response.data.reason==='customer_currency_mismatch'){

            this.$emit('customer-currency-mismatch',err.response.data)
          } else if(err.response.data.reason){
            alert(err.response.data.reason)
          } else if(err.response.status === 422){
            this.paymentError.message = Object.values(dataRes.errors).toString()
            if(Object.keys(dataRes.errors).includes('postcode')){
              setTimeout(() => {
                this.$emit('invalid-postcode',dataRes.errors.postalcode )
              }, 3000)

            }

          } else {
            this.paymentError.message = dataRes.message || Object.values(dataRes.errors).toString() || 'An error occurred'

          }
          this.$nextTick(() => {
            //scroll to .pc-sign-up-payment__warning-name
            const element = document.querySelector('.pc-sign-up-payment__warning-name')
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' })
            }
          })

        })
      })
    },
    async pay () {
      if(this.isZeroOneOff){
        this.formState = 'submitting'
        this.clientSecret = await this.createPaymentIndent()
      }
    },
    retrievePaymentIntent (clientSecret) {
      const stripe = Stripe(this.stripeKey)
      stripe.retrievePaymentIntent(clientSecret).then(paymentIndent => {
        console.log(paymentIndent)
      })
    },
    getColorVariable (variable) {
      return templateConfig.style_variables[variable]
    }
  }
}
</script>

<style scoped>

</style>
