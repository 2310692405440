<template>
  <div>
    <!-- <loading-buffer
      v-if="!this.timeout && isLoadingBuffer"
      :height="180"/> -->
      <card-dashboard-skeleton v-if="!this.timeout && isLoadingBuffer" />
    <div class="pc-card-period-tracker" v-if="!isLoading">
      <header-simple class="pc-card-period-tracker__header" :headerText="$t('message[\'period-tracker.title\']')" :icon="'forward-small'"  @click="navigateHome"></header-simple>
      <div class="pc-card-period-tracker__content">
          <div  v-if="!!firstRecord.getId()" class=" pc-card-period-tracker__content--wrapper">
            <div class="pc-card-period-tracker__content-left">
              <div
                class="pc-card-period-tracker__progress-circle">
                <period-tracker-progress :periodCycle="firstRecord.getCycleLength()" :periodDaysCount="firstRecord.getPeriodLength()" :periodStartDate="new Date(lastHistory.getActualDate())" />
              </div>
            </div>
            <div class="pc-card-period-tracker__content-right">
              <div v-if="hasLatestLog && !isInsidePredictionScope" class="pc-card-period-tracker__info">
                <div>
                  <div class="pc-card-period-tracker__info--day-count-warapper">
                    <text-content v-if="nextPeriodPrediction === 0" :size="'sm2'" :lineHeight="'multi'" :weight="'extra-bold'">{{$t('message[\'period-tracker.info-title-end-date\']')}}
                      <span>{{$t('message[\'period-tracker.info-tomorrow\']', {days: nextPeriodPrediction})}}</span>
                    </text-content>
                    <text-content v-else :size="'sm2'" :lineHeight="'multi'" :weight="'extra-bold'">{{$t('message[\'period-tracker.info-title\']')}}
                      <span>{{$tc('message[\'period-tracker.info-days-count\']', nextPeriodPrediction, {days: nextPeriodPrediction})}}</span>
                    </text-content>
                  </div>
                  <text-content class="pc-card-period-tracker__info--date" :size="'sm2'" :lineHeight="'multi'" :weight="'medium'">
                    {{$t('message[\'period-tracker.info-date-title\']')}}:
                    <span>{{formattedDate}}</span>
                  </text-content>
                </div>
                <div>
                  <text-body-extra-small
                    class="pc-card-period-tracker__button-log-now"
                    :weight="'extra-bold'"
                    :decoration="'underline'"
                    :line-height="'multi'"
                    @click.stop="clickAddNew()">
                    {{ $t('message["food-diary.log-card-log-now"]') }}
                  </text-body-extra-small>
                </div>

              </div>
              <div v-else-if="isInsidePredictionScope" class="pc-card-period-tracker__info">
                <div>
                  <div class="pc-card-period-tracker__info--day-count-warapper">
                    <text-content v-if="nextPeriodPrediction === 0" :size="'sm2'" :lineHeight="'multi'" :weight="'extra-bold'">{{$t('message[\'period-tracker.info-title-no-latest-log-ends-today\']')}}
                      <span>{{$t('message[\'period-tracker.info-today\']', {days: nextPeriodPrediction})}}</span>
                    </text-content>
                    <text-content v-else :size="'sm2'" :lineHeight="'multi'" :weight="'extra-bold'">{{$t('message[\'period-tracker.info-title-no-latest-log\']')}}
                      <span>{{$tc('message[\'period-tracker.info-days-count\']', nextPeriodPrediction, {days: nextPeriodPrediction})}}</span>
                    </text-content>
                  </div>
                  <text-content v-if="hasFullLogged" class="pc-card-period-tracker__info--date" :size="'sm2'" :lineHeight="'multi'" :weight="'medium'">
                    {{$t('message[\'period-tracker.info-date-title-no-latest-log\']')}}:
                    {{periodStartDate}}
                  </text-content>
                  <text-content v-else class="pc-card-period-tracker__info--date" :size="'sm2'" :lineHeight="'multi'" :weight="'medium'">
                    {{$t('message[\'period-tracker.info-date-title-with-incompleted-logs\']')}}:
                    <span>{{periodStartDate}}</span>
                  </text-content>

                </div>
                <div>
                  <text-body-extra-small
                    class="pc-card-period-tracker__button-log-now"
                    :weight="'extra-bold'"
                    :decoration="'underline'"
                    :line-height="'multi'"
                    @click.stop="clickAddNew()">
                    {{ $t('message["food-diary.log-card-log-now"]') }}
                  </text-body-extra-small>
                </div>

              </div>
              <div v-else-if="!isInsidePredictionScope" class="pc-card-period-tracker__info">
                <div>
                  <div class="pc-card-period-tracker__info--day-count-warapper">
                    <text-content v-if="nextPeriodPrediction === 0" :size="'sm2'" :lineHeight="'multi'" :weight="'extra-bold'">{{$t('message[\'period-tracker.info-title-prediction-end-date\']')}}
                      <span>{{$t('message[\'period-tracker.info-tomorrow\']', {days: nextPeriodPrediction})}}</span>
                    </text-content>
                    <text-content v-else :size="'sm2'" :lineHeight="'multi'" :weight="'extra-bold'">{{$t('message[\'period-tracker.info-title-no-latest-log-without-inside-period-scope\']')}}
                      <span>{{$tc('message[\'period-tracker.info-days-count\']', nextPeriodPrediction, {days: nextPeriodPrediction})}}</span>
                    </text-content>
                  </div>
                  <text-content class="pc-card-period-tracker__info--date" :size="'sm2'" :lineHeight="'multi'" :weight="'medium'">
                    {{$t('message[\'period-tracker.info-date-title\']')}}:
                    <span>{{formattedDate}}</span>
                  </text-content>
                </div>
                <div>
                  <text-body-extra-small
                    class="pc-card-period-tracker__button-log-now"
                    :weight="'extra-bold'"
                    :decoration="'underline'"
                    :line-height="'multi'"
                    @click.stop="clickAddNew()">
                    {{ $t('message["food-diary.log-card-log-now"]') }}
                  </text-body-extra-small>
                </div>

              </div>
            </div>
          </div>
          <div class="pc-card-period-tracker__info--empty" v-else>
            <text-content
              class="pc-card-period-tracker__info-text"
              :size="'sm1'"
              :lineHeight="'multi'"
              >{{$t('message[\'period-tracker.card.empty-message\']')}}
            </text-content>
            <text-body-extra-small
                class="pc-card-period-tracker__button-log-now--empty"
                :weight="'extra-bold'"
                :decoration="'underline'"
                :line-height="'multi'"
                @click.stop="clickAddNew()">
                {{ $t('message["food-diary.log-card-log-now"]') }}
              </text-body-extra-small>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import RightArrowSvg from '../../fitness-diary/widgets/svgs/RightArrowSvg'
import PlusSvg from '../../fitness-diary/widgets/svgs/PlusSvg'
import IconForward from '../../../../root/icons/IconForward'
import TextContent from '../../../../root/TextContent'
import PeriodTrackerProgress from '../../../../global/progress-bar/PeriodTrackerProgress.vue'

import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import IconAdd from '../../../../root/icons/IconAdd'
import HeaderSimple from '../../../../global/headers/HeaderSimple'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import PeriodTrackerCardMixin from '../../../../../mixins/component-mixins/page/period-tracker/PeriodTrackerCardMixin'
import CardDashboardSkeleton from '../../../../global/cards/CardDashboardSkeleton'
export default {
  name: 'CardPeriodTracker',
  mixins: [PeriodTrackerCardMixin],
  components: {
    RightArrowSvg,
    PlusSvg,
    IconForward,
    TextContent,
    PeriodTrackerProgress,
    LoadingBuffer,
    IconAdd,
    HeaderSimple,
    TextBodyExtraSmall,
    CardDashboardSkeleton
  },
  beforeMount () {
    this.pageInit()
  }
}
</script>

<style scoped>

</style>
