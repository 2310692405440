import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'

export default class MemberProgressService extends BaseAPI {
    _memeberProgressBaseUrl = ''
    _memeberProgressBaseUrlV1 = ''
    _memberDetailsBaseUrl = ''

    constructor () {
      super()
      this._memeberProgressBaseUrlV1 = DefineInclude.memberProgressApiUrlV1
      this._memeberProgressBaseUrl = DefineInclude.memberProgressApiUrl
      this._memberDetailsBaseUrl = DefineInclude.apiUrl
    }

    getProgressData () {
      return new Promise((resolve, reject) => {
        const url = this._memeberProgressBaseUrlV1
        const pathArr = ['photo-transformations', 'transformations']

        axios.get(url + this._getPath(pathArr), {}, {headers: this._getCustomHeaders()}).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    }

    getCustomerDetails (customerId) {
      return new Promise((resolve, reject) => {
        const url = this._memberDetailsBaseUrl
        const pathArr = ['trainer', 'member-info']

        axios.post(url + this._getPath(pathArr), { 'id': customerId}).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    }

    getMemberConfigData () {
      return new Promise((resolve, reject) => {
        const url = this._memeberProgressBaseUrl
        const pathArr = ['photo-transformations', 'members', 'transformations', 'config']

        axios.get(url + this._getPath(pathArr), {headers: this._getCustomHeaders()}).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    }

    _getCustomHeaders () {
      let headers = this._headers
      headers['Authorization'] = 'Bearer ' + this._token
      headers['X-Api-Host'] = window.location.origin
      return headers
    }

    getEvergreenCreatorEnableConfigs () {
      return new Promise((resolve, reject) => {
        const url = this._memeberProgressBaseUrl
        const pathArr = ['photo-transformations', 'optin', 'status']
  
        axios.get(url + this._getPath(pathArr), {headers: this._getCustomHeaders()}).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    }
}
