<template>
  <div class="pc-meal-plan-skeleton">
    <div
      class="gc-nutrition-details-bar__values-wrapper pc-meal-plan-day__nutrition-details-bar"
    >
      <div>
        <AnimatedPlaceholder width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      <div>
        <AnimatedPlaceholder width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      <div>
        <AnimatedPlaceholder width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      <div>
        <AnimatedPlaceholder width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
      <div>
        <AnimatedPlaceholder width="40px" />
        <AnimatedPlaceholder width="40px" height="12px" />
      </div>
    </div>
    <div class="pc-meal-plan-day__cart-button rc-button rc-button--border-radius-sm1 rc-button--type-solid gc-button-primary-forward gc-button-primary-forward--with-sub-text">
      <div class="gc-button-primary-forward__left-section">
        <div class="gc-button-primary-forward__left-section-icon">
          <AnimatedPlaceholder width="24px" height="20px" />
        </div>
        <div class="gc-button-primary-forward__text-section">
          <div class="gc-button-primary-forward__text-section-text">
            <AnimatedPlaceholder width="118px" height="24px" />
          </div>
          <div>
            <AnimatedPlaceholder width="40px" height="12px" />  
          </div>
        </div>
      </div>
      <div class="gc-button-primary-forward__right-section">
        <AnimatedPlaceholder width="12px" height="24px" />
      </div>
    </div>
    <div class="gc-card-meal__wrapper">
      <div class="gc-card-meal__head">
        <div class="gc-card-meal gc-card-meal__without-calories">
        <div class="gc-card-meal__badge-wrapper">
          <AnimatedPlaceholder width="100px" height="31px" />
        </div>
        <div class="gc-card-meal__title rc-text-content">
          <AnimatedPlaceholder width="260px" height="24px" />
        </div>
      </div>
      </div>
      <div class="gc-meal-card__action-area">
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
     </div>
    </div>
    <div class="gc-card-meal__wrapper">
      <div class="gc-card-meal__head">
        <div class="gc-card-meal gc-card-meal__without-calories">
        <div class="gc-card-meal__badge-wrapper">
          <AnimatedPlaceholder width="100px" height="31px" />
        </div>
        <div class="gc-card-meal__title rc-text-content">
          <AnimatedPlaceholder width="260px" height="24px" />
        </div>
      </div>
      </div>
      <div class="gc-meal-card__action-area">
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
     </div>
    </div>
    <div class="gc-card-meal__wrapper">
      <div class="gc-card-meal__head">
        <div class="gc-card-meal gc-card-meal__without-calories">
        <div class="gc-card-meal__badge-wrapper">
          <AnimatedPlaceholder width="100px" height="31px" />
        </div>
        <div class="gc-card-meal__title rc-text-content">
          <AnimatedPlaceholder width="260px" height="24px" />
        </div>
      </div>
      </div>
      <div class="gc-meal-card__action-area">
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
        <div>
          <AnimatedPlaceholder width="64px" height="20px" />
        </div>
     </div>
    </div>
    
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";

export default {
  components: {
    AnimatedPlaceholder,
  },
};
</script>

<style lang="scss" scoped>
.pc-meal-plan-skeleton
  .gc-nutrition-details-bar__values-wrapper
  .gc-placeholder {
  margin-bottom: 8px;
}

.pc-meal-plan-skeleton 
.gc-button-primary-forward__left-section
.gc-button-primary-forward__left-section-icon {
  margin-right: 10px;
}
.pc-meal-plan-skeleton {
  .gc-button-primary-forward__text-section {
    .gc-button-primary-forward__text-section-text {
      margin-bottom: 5px;
    }
  }

  .pc-meal-plan-day__cart-button {
    background-color: #fff;
  }

  .gc-card-meal__wrapper {
    display: block;
    margin-bottom: 24px;
  }
}
</style>
