import APIErrorHandler from '../api-handler/APIErrorHandler'
import PeriodTrackerApi from '../api/period-tracker/PeriodTrackerApi'
import PeriodFirstRecord from './PeriodFirstRecord'
import PeriodPrediction from './PeriodPrediction'
import PeriodLog from './PeriodLog'
import lodash from 'lodash'

export default class PeriodTrackerService {
  /**
   * Period tracker API instance
   * @type {null|PeriodTrackerApi}
   * @private
   */
  _api = null

  /**
   * Period details
   * @type {{}|PeriodFirstRecord}
   * @private
   */
  _firstRecord = {}

  /**
   * Period logs
   * @type {*[]}
   * @private
   */
  _periodLogs = []

  /**
   * Next prediction
   * @type {{}|PeriodPrediction}
   * @private
   */
  _prediction = {}

  /**
   * Constructor
   */
  constructor () {
    this._api = new PeriodTrackerApi()
    this._firstRecord = new PeriodFirstRecord()
    this._prediction = new PeriodPrediction()
  }

  /**
   * Setting period details to the instance
   * @returns {Promise}
   */
  setFirstRecord () {
    return new Promise((resolve, reject) => {
      this._api.getFirstTimePeriod()
        .then(data => {
          if (!lodash.isEmpty(data.data)) {
            this._firstRecord = new PeriodFirstRecord(data.data)
          }
          resolve()
        }).catch(error => {
          PeriodTrackerService.handleServerError(error.response)
          reject(error)
        })
    })
  }

  /**
   * Returning period details
   * @returns {{}|PeriodFirstRecord}
   */
  getFirstRecord () {
    return this._firstRecord
  }

  /**
   * Setting next prediction to the instance
   * @returns {Promise}
   */
  setPrediction () {
    return new Promise((resolve, reject) => {
      this._api.getPrediction()
        .then(data => {
          if (!lodash.isEmpty(data.data.data)) {
            this._prediction = new PeriodPrediction(data.data.data[0])
          }
          resolve()
        }).catch(error => {
          PeriodTrackerService.handleServerError(error.response)
          reject(error)
        })
    })
  }

  /**
   * Returning next prediction
   * @returns {{}|PeriodPrediction}
   */
  getPrediction () {
    return this._prediction
  }

  /**
   * Creating a new log
   * @param cycleId
   * @param actualDate
   * @param periodLength
   * @returns {Promise}
   */
  createLog (cycleId, actualDate, periodLength) {
    return new Promise((resolve, reject) => {
      this._api.updateCycle(
        cycleId,
        actualDate,
        periodLength
      )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          PeriodTrackerService.handleServerError(error.response)
          reject(error)
        })
    })
  }

  /**
   * Setting period logs to the instance
   * @returns {Promise}
   */
  setHistory () {
    return new Promise((resolve, reject) => {
      this._api.getPeriods()
        .then(data => {
          this._periodLogs = []
          if (!lodash.isEmpty(data.data.data)) {
            if (data.data.data && data.data.data.length > 0) {
              data.data.data.forEach(periodLog => {
                this._periodLogs.push(new PeriodLog(periodLog))
              })
            }
          }
          resolve()
        }).catch(error => {
          PeriodTrackerService.handleServerError(error.response)
          reject(error)
        })
    })
  }

  /**
   * Returning history logs
   * @returns {*[]}
   */
  getHistory () {
    return this._periodLogs
  }

  /**
   * Updating bulk cycle details
   * @param cycles
   * @returns {Promise}
   */
  updateCycles (cycles) {
    return new Promise((resolve, reject) => {
      this._api.updateAllCycles(cycles)
        .then(data => {
          resolve()
        }).catch(error => {
          PeriodTrackerService.handleServerError(error.response)
          reject(error)
        })
    })
  }

  /**
   * Handle errors
   * @param response
   */
  static handleServerError (response) {
    if (response && response.status !== 401 && response.status !== 429) {
      let sentryEventID = APIErrorHandler.logSentry(response)
     // APIErrorHandler.handleInternalServerError(response, sentryEventID)
    }
  }
}
