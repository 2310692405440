<template>
  <page-secondary :header="headerOptions" class="pc-meal-plan-day">
    <div>
      <div v-if="!skeltonLoad" class="pc-meal-plan-day__meal-change-button">
        <button-meal-change-request
          v-if="mealChangeRequestFlagEnabled"
          :mealId="mealPlanID"
        />
      </div>
      <page-container class="pc-meal-plan-day__container">
        <PageDaySkeleton v-if="skeltonLoad" />
        <div v-else-if="mealPlanDay">
          <bar-nutrition-details
            class="pc-meal-plan-day__nutrition-details-bar"
            v-if="showNutritionCard"
            :macroNutrients="macroNutrients"
            :calories="calories"
          />
          <cart-button
            class="pc-meal-plan-day__cart-button"
            v-if="showCart"
            :dayid="dayIndex"
            :page-config="getWidgetConfig('cart-button')"
            :type="'list'"
            :key="'cart-button'"
          />
          <grid-row class="pc-meal-plan-day__card-meal-row">
            <grid-col
              class="pc-meal-plan-day__card-meal-col"
              lg="6"
              v-for="(meal, key) in mealBoxes"
              :key="'meal-box' + key"
            >
              <meal-card
                :day-i-d="$route.query['day']"
                :show-swap="isSwapEnabled"
                :day-title="numberWordToNumeric(headerOptions.mainTitle)"
                :meal-options="meal"
                @meal-swap-success="swapSuccess"
              />
            </grid-col>
          </grid-row>
        </div>
      </page-container>
      <popup-day-recipe-email
        v-if="dayRecipeEmailPopup.show"
        :calories="nutritionOptions.calories"
        :day-i-d="$route.query['day']"
        :day-name="headerOptions.mainTitle"
        :macronutrients="nutritionOptions.macronutrients"
        :meal-plan-i-d="$route.params.mid"
        :meal-plan-day="mealPlanDay"
        :visible="dayRecipeEmailPopup.show"
        @close="hideDayRecipeEmailPopup()"
      />
    </div>
  </page-secondary>
</template>

<script>
import MealCard from "./page-components/CardMeal";
import BaseComponent from "../global/base/BaseComponent";
import CartButton from "./widgets/CartButton";
import { mapGetters, mapMutations } from "vuex";
import pageConfigMixin from "../../../../mixins/pageConfigMixin";
import UserEventsService from "../../../../services/UserEventsService";
import BarNutritionDetails from "./../../../global/bars/BarNutritionDetails";
import PageSecondary from "../../../global/pages/PageSecondary";
import PageContainer from "../../../global/pages/page-sub-components/PageContainer";
import {
  enableMealPlanQuestionnaire,
  flagValue,
  showNutritionInformationFlag,
} from "../../../../includes/TemplateSettings";
import headerEventBus from "../../../../event-buses/headerEventBus";
import PopupDayRecipeEmail from "./page-components/PopupDayRecipeEmail";
import GridRow from "../../../global/grid/GridRow";
import GridCol from "../../../global/grid/GridCol";
import NutritionService from "../../../../services/nutrition/NutritionService";
import { pageReadyEvent } from "@/helpers/dom/events/customEvents";
import ButtonMealChangeRequest from "@/components/layout/template-1/meal-plan/page-components/ButtonMealChangeRequest.vue";
import PageDaySkeleton from "./PageDaySkeleton.vue";

export default {
  name: "Days",
  components: {
    ButtonMealChangeRequest,
    GridCol,
    GridRow,
    MealCard,
    CartButton,
    BarNutritionDetails,
    PageSecondary,
    PageContainer,
    PopupDayRecipeEmail,
    PageDaySkeleton,
  },
  extends: BaseComponent,
  mixins: [pageConfigMixin],
  data: function () {
    return {
      isSwapEnabled: true,
      showCart: false,
      popupData: {},
      nutritionOptions: {},
      showNutritionCard: false,
      mealBoxes: [],
      dayIndex: 0,
      headerOptions: {
        show: true,
        isTransparent: false,
        left: "previous-emit",
        right: "message",
        rightDisabled: true,
        helpContent: "",
        modifiers: ["small-title"],
        formData: {
          image: "",
        },
        header: {
          mainTitle: "",
          subTitle: "",
        },
        mainTitle: "",
        subTitle: "",
      },
      instructionsPopup: {
        instructions: "",
        title: "",
      },
      dayRecipeEmailPopup: {
        show: false,
      },
      firstTime: false,
      macroNutrients: [],
      calories: [],
      mealPlanDay: null,
      mealPlanID: 0,
      skeltonLoad: true
    };
  },
  computed: {
    ...mapGetters({
      getMessageButtonClick: "pageStore/getMessageButtonClick",
      headerBackButtonClick: "pageStore/getBackButtonClick",
    }),
    mealChangeRequestFlagEnabled: function () {
      return enableMealPlanQuestionnaire();
    },
  },
  watch: {
    getMessageButtonClick: function (newVal, oldVal) {
      if (newVal > oldVal && !this.dayRecipeEmailPopup.show) {
        this.dayRecipeEmailPopup.show = true;
      }
    },
    headerBackButtonClick: function (newVal, oldVal) {
      this.navigatePrevious();
    },
  },
  mounted() {
    this.mealPlanID = this.$route.params.mid;
    window.scrollTo(0, 0);
    // adding header Event Bus listener
    headerEventBus.$on("send-button-click", this.showPopupRecipeEmail);
    this.showSingleDayRecipe();
    headerEventBus.$on('back-button-click', this.navigatePrevious)
    this.showSingleDayRecipe()
    this.$parent.pageClass =
      "macroapp--page-common macroapp--page-meal-plan-single-day";
    this.$parent.setPageClass();
    this.setHeader(this.headerOptions);

    this.loadMealPlanData();

    UserEventsService.checkMealPlanFeedBackPopupView();
    this.logEvent(
      "MealPlan.ViewedSingleDay",
      {
        type: this.headerOptions.mainTitle,
      },
      true
    );
    pageReadyEvent();
  },

  methods: {
    setShoppingListData(dayIndex) {
      this.dayIndex = dayIndex + 1;
      this.$store.commit("shoppingStore/people", { count: 1 });
      this.$store.commit("shoppingStore/days", { days: [this.dayIndex] });

      this.showCart = true;
    },
    setMealPlanDayData(day) {
      this.headerOptions.mainTitle = this.numberWordToNumeric(day.getName());
      this.mealBoxes = day.getMeals();
      this.nutritionOptions.calories = day.getCalories();
      this.nutritionOptions.macronutrients = day.getMacroNutrients();
      this.addMacroNutrients();
      this.addCalories();
      this.showNutritionCard = showNutritionInformationFlag();
    },
    setPlanData(plan) {
      this.instructionsPopup.instructions = [plan.getDescription()];
      this.instructionsPopup.notes = plan.getNotes() || "";
      // calling to setContent function in the pageStore
      this.setInstructionsPopupContent(this.instructionsPopup);
      this.isSwapEnabled = plan.isSwapEnabled();

      const day = plan.getDay(parseInt(this.$route.query.day));
      this.mealPlanDay = day;
      this.setMealPlanDayData(day);
      this.setShoppingListData(
        plan.getDayIndex(parseInt(this.$route.query.day))
      );
    },
    swapSuccess() {
      const service = new NutritionService();
      let plan = service.getMealPlan(parseInt(this.$route.params.mid));
      this.mealPlanDay = null;
      plan.getDataFromAPI().then(() => {
        this.setPlanData(plan);
      });
    },
    loadMealPlanData() {
      this.skeltonLoad = true
      const service = new NutritionService();
      let plan = service.getMealPlan(parseInt(this.$route.params.mid));
      if (plan.isLoaded()) {
        this.setPlanData(plan);
        this.skeltonLoad = false
        this.hideLoading();
        return;
      }
      plan
        .getDataFromAPI()
        .then(() => {
          this.setPlanData(plan);
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status &&
            err.response.status === 404
          ) {
            this.headerOptions.mainTitle = 404;
          }
        })
        .finally(() => {
          this.hideLoading();
          this.skeltonLoad = false
        });
    },
    ...mapGetters({
      getMealSwapStatus: "mealSwapStore/getMealSwapStatus",
    }),
    ...mapMutations({
      setRouteTransition: 'setRouteTransition'
    }),
    navigatePrevious () {
      this.setRouteTransition({ name: 'zero-fade', mode: 'in-out' })
      this.$router.push({
        path: [
          this.$appConfig.appUrlList.mealPlanBase,
          this.$route.params.mid,
        ].join("/"),
        query: {
          loading: "0",
        },
      });
    },
    showSingleDayRecipe() {
      this.headerOptions.right = "message";
    },
    hideDayRecipeEmailPopup() {
      this.dayRecipeEmailPopup.show = false;
    },
    ...mapMutations({
      viewInstructionsPopup: "instructionPopupStore/show",
      setInstructionsPopupContent: "instructionPopupStore/setContent",
    }),
    onDurationInit(val) {
      this.totalTime += parseInt(val);
    },
    addMacroNutrients() {
      this.macroNutrients = [];
      const Protein = this.nutritionOptions.macronutrients.proteins;
      this.macroNutrients.push({
        unit: this.$i18n.t("message['general.g']"),
        name: this.$i18n.t("message['general.proteins']"),
        qut: Protein,
      });
      const fat = this.nutritionOptions.macronutrients.fats;
      this.macroNutrients.push({
        unit: this.$i18n.t("message['general.g']"),
        name: this.$i18n.t("message['general.fats']"),
        qut: fat,
      });
      const Carb = this.nutritionOptions.macronutrients.carbohydrates;
      this.macroNutrients.push({
        unit: this.$i18n.t("message['general.g']"),
        name: this.$i18n.t("message['general.carbs']"),
        qut: Carb,
      });
      if (this.nutritionOptions.macronutrients.show_net_carbs === true) {
        const NetCarb = this.nutritionOptions.macronutrients.net_carbohydrates;
        this.macroNutrients.push({
          unit: this.$i18n.t("message['general.g']"),
          name: this.$i18n.t("message['general.carbs-net']"),
          qut: NetCarb,
        });
      }
    },
    addCalories() {
      this.calories = [];
      const calories = this.nutritionOptions.calories;
      this.calories.push({
        unit: "",
        name: this.$i18n.tc("message['general.calories']", calories),
        qut: calories,
      });
    },
    showPopupRecipeEmail() {
      this.dayRecipeEmailPopup.show = true;
    },
  },
  beforeDestroy () {
    headerEventBus.$off('send-button-click', this.showPopupRecipeEmail)
    headerEventBus.$off('back-button-click', this.navigatePrevious)
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
