<template>
  <page-secondary
    :header="headerOptions"
    class="pc-content"
  >
    <page-container>
      <div
        v-for="progress in progressData"
        :key="progress._id"
      >
        <card-member-progress
          :progress-details="progress"
        />
      </div>
    </page-container>
  </page-secondary>
</template>
<script>
import BaseComponent from '../global/base/BaseComponent'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../global/pages/PageSecondary'
import pageMixin from '../../../../mixins/pageMixin'
import CardMemberProgress from '../../../global/cards/CardMemberProgress'
import MemberProgressService from '../../../../services/api/member-progress/MemberProgressService'
import { mapGetters } from 'vuex'
export default {
  name: 'PageMemberProgress',
  components: {
    PageContainer,
    PageSecondary,
    CardMemberProgress
  },
  extends: BaseComponent,
  mixins: [pageMixin],
  data () {
    return {
      progressData: [],
      headerOptions: {
        isTopFixed: false,
        isTransparent: false,
        isFixed: true,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      }
    }
  },
  mounted () {
    this.hideLoading()
    this.getProgressData()
    this.headerOptions.mainTitle = this.$i18n.t('message["member-progress.header.main-title"]')
  },
  computed: {
    ...mapGetters({
      headerBackButtonClick: 'pageStore/getBackButtonClick'
    })
  },
  watch: {
    headerBackButtonClick: function () {
      this.navigatePrevious()
    }
  },
  methods: {
    navigatePrevious () {
      this.$router.push({
        path: [
          this.$appConfig.appUrlList.dashboard
        ].join('/')
      })
    },
    async getProgressData () {
      const memberProgressService = new MemberProgressService()
      const response = await memberProgressService.getProgressData()

      this.progressData = response.data.data
    }
  }
}
</script>
