<template>
  <page :header="header" class="gc-page-recommended-habits">
    <page-container>
      <validation-observer ref="form" v-slot="{ handleSubmit }">
        <div class="gc-page-recommended-habits__header-wrapper">
          <text-heading-4
            class="gc-page-recommended-habits__title text-center"
            weight="extra-bold"
            lineHeight="multi"
            >{{ mainTitle }}
          </text-heading-4>
          <div class="gc-page-recommended-habits__sub-title text-center">
            <text-body-small
              class="gc-page-recommended-habits__info-link"
              weight="medium"
              lineHeight="multi"
              v-html="description"
            >
            </text-body-small>
          </div>
        </div>
        <div class="gc-page-recommended-habits__content">
          <loading-buffer v-if="recommendedHabits.length===0" :height="200"/>
          <form
            v-else
            id="form-input-recommended-habits"
            ref="formContent"
            class="gc-page-recommended-habits__form"
            @submit.prevent="handleSubmit(submitData)"
          >
            <div class="gc-page-recommended-habits__form-top">
              <text-body-small
                class="gc-page-recommended-habits__form-top-habits-numbers"
                >{{ selectedText }}</text-body-small
              >

              <text-body-extra-small
                weight="extra-bold"
                class="gc-page-recommended-habits__label-select"
                @click="toggleSelectAll"
                >{{ selectedToggleText }}
              </text-body-extra-small>
            </div>

            <div class="gc-page-recommended-habits__list-inputs">
              <div
                class="gc-page-recommended-habits__input"
                v-for="habit in recommendedHabits"
                :key="habit.habit"
              >
                <input-check-box-squared
                  :id="habit.habit"
                  v-model="selectedHabits"
                />
                <text-body-small
                  class="gc-page-recommended-habits__input-text"
                  >{{ habit.habit }}</text-body-small
                >
              </div>

            </div>
          </form>
        </div>
      </validation-observer>
    </page-container>
    <page-footer
      ref="footer"
      :form="'form-input-recommended-habits'"
    ></page-footer>
  </page>
</template>

<script>
import FormBaseComponent from "../../layout/template-1/global/widgets/forms/FormBaseComponent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ButtonPrimary from "../buttons/ButtonPrimary";
import TextBodyExtraSmall from "../typography/TextBodyExtraSmall";
import TextHeading4 from "../typography/TextHeading4";
import ButtonChoice from "../buttons/ButtonChoice";
import IconInfo from "../../root/icons/IconInfo";
import Page from "@/components/global/pages/PageForm.vue";
import PageContainer from "@/components/global/pages/page-sub-components/PageContainer.vue";
import PageFooter from "@/components/global/pages/page-sub-components/PageFormFooter.vue";
import HabitsTrackerService from "@/services/habits-tracker/HabitsTrackerService";
import InputCheckBoxSquared from "@/components/global/inputs/InputCheckBoxSquared.vue";
import TextBodyRegular from "@/components/global/typography/TextBodyRegular.vue";
import TextBodySmall from "@/components/global/typography/TextBodySmall.vue";
import ButtonLink from "@/components/global/buttons/ButtonLink.vue";
import lodash from "lodash";
import * as Sentry from "@sentry/browser";
import DashBoardService from "@/services/DashboardService";
import LoadingBuffer from "@/components/layout/template-1/global/widgets/LoadingBuffer.vue";


export default {
  name: "FormHabitSuggestion",
  extends: FormBaseComponent,
  components: {
    LoadingBuffer,
    ButtonLink,
    TextBodySmall,
    TextBodyRegular,
    InputCheckBoxSquared,
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodyExtraSmall,
    ValidationObserver,
    ValidationProvider,
    ButtonChoice,
    PageFooter,
    IconInfo,
  },
  props: ["infoText"],
  data: function () {
    return {
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: "",
        right: "",
        rightBlink: false,
        modifiers: [],
        mainTitle: "",
        subTitle: "",
      },
      habitTrackService: new HabitsTrackerService(),
      previousHabits: [],
      recommendedHabits: [],
      userHabits: [],
      selectedHabits: [],
    };
  },

  computed: {
    mainTitle: function () {
      return (
        this.title.main ||
        this.$i18n.t("message['habit-tracker.progress.title']")
      );
    },
    description: function () {
      return (
        this.title.sub ||
        this.$i18n.t("message['habit-tracker.progress.description']")
      );
    },
    selectedText: function () {
      return this.$i18n.t("message['habit-tracker.progress.selected']", {
        total: this.recommendedHabits.length,
        selected: this.selectedHabits.length,
      });
    },
    selectedToggleText: function () {
      if (this.selectedHabits.length === this.recommendedHabits.length) {
        return this.$i18n.t("message['general.unselect-all']");
      }
      return this.$i18n.t("message['general.select-all']");
    },
  },
  mounted() {
    this.getSuggestions().catch(()=>{
      this.hideLoading();
      this.submitData()
    });
  },
  methods: {
    toggleSelectAll() {
      const selectedLength = this.selectedHabits.length;
      const recommendedLength = this.recommendedHabits.length;
      //delesect all if selected === all recommended in the list
      if(selectedLength === recommendedLength) return this.selectedHabits = [];
      // otherwise select all, no matter is that +1 or all
      this.selectedHabits = this.recommendedHabits.map((habit) => habit.habit);
    },
    async getSuggestions() {
      this.showLoading();
      try {
        const dashboard = new DashBoardService();

        if (!dashboard.isHabitTrackerEnabled()) {
          await this.submitData()
          return
        }
        const api = new HabitsTrackerService();
        const suggestions = await api.setRecommendedHabits()

        if(suggestions.length === 0) {
          await this.submitData()
          return
        }

        console.log("SETTING OPTIONS: ", suggestions)
        // this.options.options = suggestions

        // const recommendedHabits = [...this.options];
        const recommendedHabits = suggestions;
        const uniqRecommendedHabits = lodash.uniqBy(recommendedHabits, "habit");
        this.userHabits = await this.habitTrackService.setHabits();

        console.log(
          "Habits options: ",
          recommendedHabits.map((h) => h.habit)
        );
        console.log("Habits recommended uniq: ", uniqRecommendedHabits);
        console.log(
          "Habits Users: ",
          this.userHabits.map((h) => h.name)
        );

        this.hideLoading();
        if (recommendedHabits.length > 0) {
          const onlyNewHabits = lodash.differenceBy(
            uniqRecommendedHabits,
            this.userHabits,
            "habit"
          );
          this.recommendedHabits = onlyNewHabits;
        }

        if (this.recommendedHabits.length === 0) {
          await this.submitData();
        } else {
          this.$nextTick(() => {
            const boxHeight = this.$refs.formContent.offsetHeight;
            const maxBoxHeight =
              window.innerHeight -
              (this.$refs.formContent.offsetTop + 133 + 20);

            if (maxBoxHeight < boxHeight) {
              this.$refs.formContent.style.height = maxBoxHeight + `px`;
            }
            this.$refs.formContent.style.overflowY = `auto`;
          });
        }
      } catch (error) {

        console.log(error);
        this.hideLoading();
        this.generalErrors = [error.message];

        await this.submitData();
      }
    },
    async submitData() {
      this.showLoading();

      // let newHabits = lodash.differenceBy(
      //   this.selectedHabits,
      //   this.previousHabits
      // );
      // const oldHabits = lodash.differenceBy(
      //   this.previousHabits,
      //   this.selectedHabits
      // );

      // console.log("Submit habits: ", {selected: this.selectedHabits, previous: this.previousHabits, newHabits, oldHabits});

      try {
        // if (newHabits.length) {
        //   const rHabitsIds = this.recommendedHabits.map((habit) => habit.id);
        //   newHabits = newHabits.sort(
        //     (x, y) => rHabitsIds.indexOf(x) - rHabitsIds.indexOf(y)
        //   );
        // }

        const selectedHabits = this.recommendedHabits.filter((habit) =>
          this.selectedHabits.includes(habit.habit)
        );

        if (selectedHabits.length !== this.selectedHabits.length)
          throw new Error("Invalid selected habits number");

        console.log("Selected: ", selectedHabits);

        // for (const habit of selectedHabits) {
        //   console.log("Accepting habit 1: ", habit.habit);
        //   // const rHabit = this.recommendedHabits.find((h) => h.habit === habit);
        //   await this.habitTrackService.acceptRecommendedHabit(habit)then.catch((e) => {
        //     console.error(e)
        //     Sentry.captureException(e);
        //     this.hideLoading();
        //   });
        // }

        for (const habit of selectedHabits) {
          console.log("Accepting habit: ", habit.habit);
          await this.habitTrackService
            .acceptRecommendedHabit(habit)
            .then(() => {
              // Trigger the HabitTracker.HabitCreated event for a suggestion
              this.logEvent("HabitTracker.HabitCreated", {
                type: "suggestion",
                context: "manage-habits",
              });
            })
            .catch((e) => {
              console.error(e);
              Sentry.captureException(e);
              this.hideLoading();
            });
        }
        this.$emit("next", { replace: false });
        // for (const habit of oldHabits) {
        //   const userHabit = this.userHabits.find((h) => h.name === habit);
        //   console.log(userHabit);
        //   await this.habitTrackService.deleteHabit(userHabit.id).catch((e) => {
        //     Sentry.captureException(e);
        //     this.hideLoading();
        //   });
        // }
      } catch (e) {
        Sentry.captureException(e);
        this.hideLoading();
        this.$emit("next", { replace: true });
      }
    },
    nextComponent() {
      const inputObj = {
        key: this.inputKey,
        value: this.selectedHabits,
      };
      this.$emit("submit", inputObj);
    },
  },
};
</script>
