import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import NativeAppMixin from '../../../NativeAppMixin'
import { checkNativeAppVersion, isMobileNativeApp } from '../../../../includes/NativeAppCommon'
import { isIOS } from 'mobile-device-detect'
import IAPService from '../../../../services/IAPService'
import AuthService from '../../../../services/AuthService'
import { env } from '../../../../../config/custom-config'
import jquery from 'jquery'
import NativeAppService from '../../../../services/NativeAppService'
import DashboardService from '../../../../services/DashboardService'
import LanguageService from '../../../../services/LanguageService'
import { flagValue } from '../../../../includes/TemplateSettings'
import { mapGetters } from 'vuex'
import { removeAllIndexedDBDatabases } from '@/services/index-db'

export default {
  extends: BaseComponent,
  mixins: [NativeAppMixin],
  data: function () {
    return {
      logoShow: true,
      iapEnabled: false,
      showPrivacyPolicyPopup: false,
      showTermsCondPopup: false,
      logoStyle: {
        visibility: 'hidden'
      },
      errorHTML: '',
      serverError: '',
      formData: {
        email: '',
        password: '',
        remember: true
      },
      logo: '',
      backgroundImage: ''
    }
  },
  computed: {
    ...mapGetters({
      'getLastVisitPage': 'getLastVisitPage'
    })
  },
  methods: {
    /**
     * show  Privacy Policy Popup
     */
    showPrivacyPolicy () {
      if (navigator.onLine) {
        this.showPrivacyPolicyPopup = true
      } else {
        this.$store.commit('showNoConnectionErrorPopup')
      }
    },
    /**
     * show Terms And Conditions Popup
     */
    showLegalDisclaimer () {
      if (navigator.onLine) {
        this.showTermsCondPopup = true
      } else {
        this.$store.commit('showNoConnectionErrorPopup')
      }
    },
    /**
     * check IAP is enabled for the trainer
     */
    setIAPConfig () {
      // if flag set to false, return
      if (
        templateConfig.instance_config &&
        'iap_enabled' in templateConfig.instance_config &&
        !templateConfig.instance_config.iap_enabled
      ) {
        return
      }

      if (checkNativeAppVersion() && isIOS) {
        this.showLoading()
        const iapService = new IAPService()
        iapService.setPurchaseInitStack()
        iapService.setPurchaseConfig()
          .then(() => {
            const purchaseConfig = iapService.getPurchaseConfig()
            this.iapEnabled = purchaseConfig.enabled || false
          }).finally(() => {
            this.hideLoading()
          })
      }
    },
    /**
     * go to IAP registration page
     */
    register () {
      this.$router.push({ path: '/iap/sign-up' })
    },
    /**
     * load background image
     */
    setImages () {
      // this.logo = this.imagesUrl+'generic/logo.png'
      this.logo = this.getStoreImage('login.logo')

      if (this.logo.match(/\.(jpeg|jpg|gif|png)$/) === null) {
        this.logoShow = false
      }
      this.backgroundImage = this.getStoreImage('login.background')
    },
    /**
     * submit the user credentials
     */
    signIn () {
      this.showLoading()
      this.validateFormData()
      const self = this

      if (this.formData.email === 'debug@macroactive.com') {
        window.location.href = '/debug'
      } else if (this.errors.email.length === 0 && this.errors.password.length === 0) {
        const service = new AuthService()
        localStorage.clear()
        service.signIn(this.formData)
          .then(() => {
            if (this.hasLastVisitPage()) {
              this.goToLastVisitedPage()
              return
            }
            this.handleSignInSuccess()
          })
          .catch(err => {
            if (err.response.status === 422) {
              this.$refs.form.setErrors(err.response.data.errors)
            } else {
              self.serverError = 'Something went wrong'
            }
            this.hideLoading()
          })
      } else {
        this.hideLoading()
      }
    },
    hasLastVisitPage () {
      return this.getLastVisitPage && this.getLastVisitPage.path
    },
    goToLastVisitedPage () {
      this.$router.replace({path: this.getLastVisitPage.path})
    },
    /**
     * logging call to legacy sign-in
     */
    handleSignInSuccess () {
      try {
        removeAllIndexedDBDatabases().then(() => {})
      } catch (e) {
        console.log(e)
      }
      if (env !== 'production') {
        this.$router.replace('dashboard')
        return
      }

      const formData = new FormData()
      formData.set('email', this.formData.email)
      formData.set('password', this.formData.password)
      formData.set('remember', 1)
      fetch('/login', {
        method: 'POST',
        redirect: 'manual',
        headers: {
          'X-CSRF-TOKEN': jquery('meta[name="csrf-token"]').attr('content'),
          'Access-Control-Allow-Origin': '*'
        },
        credentials: 'same-origin',
        body: formData

      }).catch(() => {
       // this.hideLoading()
      }).finally(() => {
        this.$router.replace('dashboard')
      })
    },
    /**
     * validate the user data
     */
    validateFormData () {
      this.errors = {
        email: [],
        password: []
      }
      const requiredText = this.$i18n.t('message["validation.required"]')
      if (!this.formData.email) {
        this.errors.email = [requiredText.replace(':attribute', this.$i18n.t('message[\'auth.email\']'))]
      }
      if (!this.formData.password) {
        this.errors.password = [requiredText.replace(':attribute', this.$i18n.t('message[\'auth.password\']'))]
      }
    },
    /**
     * hide Privacy Policy Popup
     */
    closePrivacyPolicyPopup () {
      this.showPrivacyPolicyPopup = false
    },
    /**
     * hide Terms And Conditions Popup
     */
    closeTermsAndConditionsPopup () {
      this.showTermsCondPopup = false
    },
    openForgotPasswordPage () {
      window.location.href = '/forgot-password'
    }
  },
  beforeRouteEnter (to, from, next) {
    let auth = new AuthService()
    if ((auth.hasAuthTokens() && isMobileNativeApp())) {
      next('/dashboard')
    } else if (auth.isCoach() || auth.isCreator()) {
      const lastPage = localStorage.getItem('lastSessionRoute')
      if (!lastPage) {
        next('/dashboard')
      } else {
        const pathObject = JSON.parse(lastPage)
        pathObject.hash = to.hash
        next(pathObject)
      }
    } else {
      next()
    }
  }

}
