<template>
  <div :class="componentClasses" v-on="$listeners">
    <svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4C0 1.79086 1.79086 0 4 0L16 0L16 32H4C1.79086 32 0 30.2091 0 28L0 4Z" fill="#434343"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33341 11.3333C6.33341 12.0697 5.73645 12.6667 5.00008 12.6667C4.26371 12.6667 3.66675 12.0697 3.66675 11.3333C3.66675 10.597 4.26371 10 5.00008 10C5.73645 10 6.33341 10.597 6.33341 11.3333ZM5.00008 17.3333C5.73645 17.3333 6.33341 16.7364 6.33341 16C6.33341 15.2637 5.73645 14.6667 5.00008 14.6667C4.26371 14.6667 3.66675 15.2637 3.66675 16C3.66675 16.7364 4.26371 17.3333 5.00008 17.3333ZM5.00008 22C5.73645 22 6.33341 21.403 6.33341 20.6667C6.33341 19.9303 5.73645 19.3333 5.00008 19.3333C4.26371 19.3333 3.66675 19.9303 3.66675 20.6667C3.66675 21.403 4.26371 22 5.00008 22Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3334 11.3333C12.3334 12.0697 11.7365 12.6667 11.0001 12.6667C10.2637 12.6667 9.66675 12.0697 9.66675 11.3333C9.66675 10.597 10.2637 10 11.0001 10C11.7365 10 12.3334 10.597 12.3334 11.3333ZM11.0001 17.3333C11.7365 17.3333 12.3334 16.7364 12.3334 16C12.3334 15.2637 11.7365 14.6667 11.0001 14.6667C10.2637 14.6667 9.66675 15.2637 9.66675 16C9.66675 16.7364 10.2637 17.3333 11.0001 17.3333ZM11.0001 22C11.7365 22 12.3334 21.403 12.3334 20.6667C12.3334 19.9303 11.7365 19.3333 11.0001 19.3333C10.2637 19.3333 9.66675 19.9303 9.66675 20.6667C9.66675 21.403 10.2637 22 11.0001 22Z" fill="white"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDrag',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-drag': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
