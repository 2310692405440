import Vue from 'vue'
import Vuex from 'vuex'
import lodah from 'lodash'
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    logFromData: new Map(),
    completedExercises: [],
    completedExercisesCount: [],
    incompleteExercises: [],
    incompleteExercisesCount: [],
    currentExercise: 0,
    currentExerciseCompleted: false,
    currentLog: { formData: [], note: '' }
  },
  mutations: {
    reset (state, payload) {
      state.logFromData = new Map()
      state.completedExercises = []
      state.completedExercisesCount = []
      state.incompleteExercises = []
      state.incompleteExercisesCount = []
      state.currentExercise = 0
      state.currentExerciseCompleted = false
      state.currentLog = { formData: [], note: '' }
    },
    /**
     * set workout log data
     * @param state
     * @param payload - {exerciseID:Int,log:Array}
     */
    setLogFormData (state, payload) {
      // get session from store index.js and object merge
      const log = {session: sessionStorage.getItem('session'), ...payload.log}
      state.logFromData.set(payload.id, log)
      const values = []

      state.logFromData.forEach((v,k)=>{
        values.push({
          ...v,
          id:k
        })
      })
      const str = JSON.stringify(values)

      localStorage.setItem('workoutLog', str)
      state.currentLog = state.logFromData.get(state.currentExercise) || { formData: [], note: '' }

    },
    setCurrentExerciseCompleted (state, payload) {
      state.currentExerciseCompleted = payload
    },
    /**
     * remove workout log data
     * @param state
     * @param payload - {exerciseID:Int,log:Array}
     */
    unsetLogFormData (state, payload) {
      state.logFromData.delete(payload)
      const values = []

      state.logFromData.forEach((v,k)=>{
        values.push({
          ...v,
          id:k
        })
      })
      const str = JSON.stringify(values)

      localStorage.setItem('workoutLog', str)
    },
    setCurrentExercise (state, payload) {
      state.currentExercise = payload
      if (state.logFromData.size === 0) {
        let data = localStorage.getItem('workoutLog') || '[]'

        data = JSON.parse(data)
        if(Array.isArray(data)){
          data = data.slice(Math.max(data.length - 20, 0))
          data.forEach(val=>{
            state.logFromData.set(val.id, val)
          })
        }

      }
      try{
        state.currentLog = state.logFromData.get(state.currentExercise) || { formData: [], note: '' }
        if(state.currentLog.session !== sessionStorage.getItem('session')){
          console.log(state.currentLog.lastTimerValue)
          delete state.currentLog.lastRestTimerValue
          delete state.currentLog.lastTimerValue
        }

      } catch (e){
        state.currentLog = { formData: [], note: '' }
      }

    }

  },
  actions: {
    setExerciseIncomplete ({ state, commit, dispatch }, payload) {
      payload = parseInt(payload)
      if (state.currentExercise === payload) {
        state.currentExerciseCompleted = false
      }
      state.incompleteExercises.push(payload)
      state.incompleteExercises = lodah.uniq(state.incompleteExercises)
      lodah.pull(state.completedExercises, payload)
      state.incompleteExercisesCount = state.incompleteExercises.length
      state.completedExercisesCount = state.completedExercises.length
    },
    setExerciseComplete ({ state, commit, dispatch }, payload) {
      payload = parseInt(payload)
      if (state.currentExercise === payload) {
        state.currentExerciseCompleted = true
      }
      state.completedExercises.push(payload)
      state.completedExercises = lodah.uniq(state.completedExercises)
      lodah.pull(state.incompleteExercises, payload)
      state.incompleteExercisesCount = state.incompleteExercises.length
      state.completedExercisesCount = state.completedExercises.length
    }
  },
  getters: {
    /**
     *  get log data by exercise ID
     * @param state
     * @returns {*}
     */
    getFormLogData (state) {
      return state.currentLog
    },
    getIncompleteExercises (state) {
      return state.incompleteExercises
    },
    getCompletedExercises (state) {
      return state.completedExercises
    },
    getIncompleteExercisesCount (state) {
      return state.incompleteExercisesCount
    },
    getCompletedExercisesCount (state) {
      return state.completedExercisesCount
    },
    getCurrentExerciseCompleted (state) {
      return state.currentExerciseCompleted
    }
  }
}
