<template>
  <div class="pc-page-content-skeleton">
    <page-secondary :header="headerOptions">
      <page-content-image-skeleton/>
      <page-container-skeleton/>
      <page-container-skeleton/>
    </page-secondary>
  </div>
</template>

<script>
import PageContainerSkeleton from '../../../global/pages/page-sub-components/PageContainerSkeleton.vue'
import PageContentImageSkeleton from '../../../global/pages/page-sub-components/PageContentImageSkeleton.vue'
import PageSecondary from "@/components/global/pages/PageSecondary.vue";
export default {
  name: 'PageContent',
  components: {
    PageSecondary,
    PageContainerSkeleton,
    PageContentImageSkeleton
  },
  data: function () {
    return {
      headerOptions: {
        show: true,
        left: "back",
        modifiers: ["with-small-title", "with-bg-color"],
        header: {
          mainTitle: "",
          subTitle: "",
        },
      },
    }
  }
}
</script>
<style scoped>

</style>
