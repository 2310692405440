import DashbardAPI from './api/dashboard/DashboardAPI'
import NavigationAPI from './api/common/NavigationAPI'
import MealPlanService from './MealPlanService'
import NotificationService from './NotificationService'
import WorkoutProgramsService from './WorkoutProgramsService'
import lodash from 'lodash'
import moment from 'moment'
import {flagValue} from '@/includes/TemplateSettings'
import {isMobileNativeApp} from '@/includes/NativeAppCommon'
import NativeAppService from './NativeAppService'
import * as Sentry from '@sentry/browser'
import NutritionService from './nutrition/NutritionService'
import creatorDashboardConfig from '../config/creator/dashboard-config'
import {checkIsMember} from '@/helpers/user'
import ActivityTracker from '@/services/ActivityTracker'
import store from "@/store";
import AuthService from "@/services/AuthService";

export default class DashBoardService {
  data = null
  productPages = null
  userData = null
  config = null
  navigation = {}
  videoPages = null

  constructor () {
    if (!DashBoardService.instance) {
      DashBoardService.instance = this
    }
    return DashBoardService.instance
  }

  resetData () {
    this.data = null
    this.productPages = null
    this.userData = null
    this.config = null
    this.navigation = {}
  }

  getPageData (force = false) {
    const service = new DashbardAPI()
    return new Promise((resolve, reject) => {
      if (!force && this.data !== null) {
        resolve(this.data)
      } else {
        if (!checkIsMember()) {
          this.data = creatorDashboardConfig
          this.setDashboardAPIResponseData(creatorDashboardConfig)

          resolve(this.data)
          return
        }
        service.getList().then(response => {
          this.data = response.data
          this.setDashboardAPIResponseData(response.data)
          resolve(this.data)
        }).catch((err) => {
          reject(err)
        })
      }
    })
  }

  setAmpData () {
    try {
      const activityTracker = new ActivityTracker()
      const metaField = document.querySelector('meta[name="ma-application"]')
      const tenant = metaField.getAttribute('content')
      const tenantParts = tenant.split('.')
      const platformVer = tenantParts [2]
      const tenantId = tenantParts [1]

      const account = this.userData
      if (account.id) {
        const cusId = `${account.reseller_id}-${account.trainer_id}-${account.id}`

        activityTracker.setUserIdentity(cusId)
      }

      activityTracker.setUserProperties('Tenant', window.location.host)
      activityTracker.setUserProperties('TenantId', tenantId)
      activityTracker.setUserProperties('PlatformVer', platformVer)
      activityTracker.setUserProperties('Device family', navigator.platform)

    } catch (e) {
      console.log(e)
    }
  }

  setDashboardAPIResponseData (responseData) {
    if (responseData.account) {
      this.userData = responseData.account || null
      sessionStorage.setItem('app-user-id', this.userData.id)
      this.sendNativeAppUser()
      this.setSentryUser(this.userData)
      this.setAmpData()
    }
    if (responseData.plan_pages) {
      this.productPages = responseData.plan_pages || null
    }

    if (responseData.meal_plans) {
      let mealPlanService = new MealPlanService()
      mealPlanService.setMealPlanList(responseData.meal_plans)
      let nutrition = new NutritionService()
      nutrition.mapMealPlans(responseData.meal_plans)
    }

    if (responseData.workouts) {
      let workoutService = new WorkoutProgramsService()
      workoutService.makeWorkoutProgramsByList(responseData.workouts)
    }
    if (responseData.notifications) {
      let notificationService = new NotificationService()
      notificationService.setNotifications(responseData.notifications)
    }
    if (responseData.navigation) {
      this.navigation['top'] = {links: responseData.navigation['top_links'] || []}
      this.navigation['bottom'] = {links: responseData.navigation['bottom_links'] || []}
    }
  }

  setSentryUser (user) {
    try {
      Sentry.setUser({
        email: user.email,
        resellerId: user.reseller_id,
        trainerId: user.trainer_id,
        id: user.id
      })
    } catch (e) {
      // ignore error
    }
  }

  sendNativeAppUser () {
    try {
      if (isMobileNativeApp()) {
        const auth = new AuthService()
        let nativeAppService = new NativeAppService()
        if(auth.isMember()){
          nativeAppService.sendIdentifyCustomerRequest(this.userData)
        } else {
          nativeAppService.sendIdentifyCreatorRequest(this.config)
        }

      }
    } catch (e) {
      // ignore error
    }
  }


  getSubscriptions(){
    return this.userData.subscriptions || []
  }

  /**
   * get registered date
   * @returns {moment.Moment}
   */
  getRegisteredDate () {
    if (this.userData) {
      let sortedSubscriptions = lodash.sortBy(this.userData.subscriptions, function (subscription) {
        return new Date(subscription.created_at)
      })
      if (sortedSubscriptions.length > 0) {
        // console.log("SUBSCRIBE DATE", {
        //     date: sortedSubscriptions[0].created_at,
        //     utc_moment: moment(sortedSubscriptions[0].created_at).local().format(),
        //     list: sortedSubscriptions
        //   })
        return moment(sortedSubscriptions[0].created_at)
      }
      return moment()
    }
    throw new Error('user data not loaded')
  }

  getDob () {
    return this.userData.dob
  }

  getProductPages () {
    let pages = []
    if (this.productPages) {
      pages = this.productPages.filter((productPage) => {
        return productPage.pricing_plan_type !== 'challenge'
      })
    }

    return lodash.uniqBy(pages, function (e) {
      return e.url
    })
  }

  getAllProductPages () {
    let pages = this.productPages || []
    return lodash.uniqBy(pages, function (e) {
      return e.url
    })
  }

  getChallengeProductPages () {
    let pages = []
    if (this.productPages) {
      pages = this.productPages.filter((productPage) => {
        return productPage.pricing_plan_type === 'challenge'
      })
    }

    return lodash.uniqBy(pages, function (e) {
      return e.url
    })
  }

  getNavigation (type = 'top') {
    return new Promise((resolve, reject) => {
      if (typeof this.navigation[type] !== 'undefined') {
        resolve(this.navigation[type])
      } else {
        const service = new NavigationAPI()
        service.get(type).then(response => {
          this.navigation[type] = response.data
          resolve(this.navigation[type])
        }).catch(() => {
          reject((this.navigation[type] || []))
        })
      }
    })
  }

  getUserData (force = false) {
    const service = new DashbardAPI()
    return new Promise((resolve, reject) => {
      if (this.userData !== null && !force) {
        resolve(this.userData)
      } else {
        service.getUserData().then(response => {
          this.userData = response.data
          sessionStorage.setItem('app-user-id', this.userData.id)
          resolve(this.userData)
        }).catch(err => {
          if (err.response.status === 500) {
            store.commit('showServerErrorPopup')
          }
          reject(err)
        })
      }
    })
  }

  getUserConfig (force = false) {
    let service = new DashbardAPI()
    return new Promise((resolve, reject) => {
      if (this.config !== null && !force) {
        resolve(this.config)
      } else {
        const config = (checkIsMember()) ? service.getConfig() : service.getTrainerConfig()
        config.then(response => {
          this.config = response.data
          if(!checkIsMember()){
            this.sendNativeAppUser()
          }
        }).catch(() => {
          this.config = {
            'feature_flags': []
          }
        }).finally(() => {
          resolve(this.config)
        })
      }
    })
  }

  isFitnessDiaryEnabled () {
    if (!flagValue('show_fitness_diary', true)) {
      return false
    }

    if (!this.navigation['top']) {
      return false
    }
    return this.navigation['top'].links.some(link => {
      return link.code === 'progress' || link.route === 'progress'
    })
  }

  hasActiveWorkoutPrograms () {
    if (this.userData === null) {
      throw new Error('user data not loaded')
    }
    return this.userData.subscriptions.some(subscription => {
      return subscription.has_workout_programs && (subscription.status !== 'pending-payment' && subscription.status !== 'expired' && subscription.status !== 'cancelled')
    })
  }

  getLatestSubscription () {
    const subscriptions = this.userData.subscriptions || []
    return lodash.last(subscriptions)
  }

  hasActiveMealPlans () {
    if (this.userData === null) {
      throw new Error('user data not loaded')
    }
    return this.userData.subscriptions.some(subscription => {
      return subscription.has_meal_plans && (subscription.status !== 'pending-payment' &&subscription.status !== 'expired' && subscription.status !== 'cancelled')
    })
  }

  hasWorkoutPrograms () {
    if (this.userData === null) {
      throw new Error('user data not loaded')
    }
    return this.userData.subscriptions.some(subscription => {
      return subscription.has_workout_programs
    })
  }

  hasMealPlans () {
    if (this.userData === null) {
      throw new Error('user data not loaded')
    }
    return this.userData.subscriptions.some(subscription => {
      return subscription.has_meal_plans
    })
  }

  hasPairedMealPlans () {
    if (this.userData === null) {
      throw new Error('user data not loaded')
    }
    return this.data.meal_plans.some(mealPlan => {
      return mealPlan.is_paired
    })
  }

  isInviteAFriendEnabled () {
    if (this.navigation.top) {
      return this.navigation.top.links.some(link => {
        return link.code === 'invitations' || link.route === 'invitations'
      })
    }
    return false
  }

  isFitnessDiaryEditable () {
    // return this.isEntriesEditable && this.isFitnessDiaryEnabled()
    return this.isFitnessDiaryEnabled()
  }
  /** Based on members active subscriptions */
  isEntriesEditable () {
    if (this.userData === null) {
      throw new Error('user data not loaded')
    }

    if (this.hasActivePlan()) {
      return true
    }

    if (this.hasExpiredPlan()) {
      return this.hasExpiredPlanWithVisibleProducts()
    }

    if (this.hasCancelledPlan()) {
      return this.hasCancelledPlanWithVisibleProducts()
    }


  }

  hasActivePlan () {
    if (this.userData && this.userData.subscriptions.length > 0) {
      return this.userData.subscriptions.some(subscription => {
        return subscription.status === 'active' || subscription.status === 'pending-cancellation'
      })
    }

    return false
  }

  hasExpiredPlan () {
    if (!this.userData) {
      return false
    }
    return this.userData.subscriptions.some(subscription => {
      return subscription.status === 'expired'
    })
  }

  hasCancelledPlan () {
    if (!this.userData) {
      return false
    }
    return this.userData.subscriptions.some(subscription => {
      return subscription.status === 'cancelled'
    })
  }

  hasExpiredPlanWithVisibleProducts () {
    if (this.hasExpiredPlan()) {
      return this.data.meal_plans.length > 0 || this.data.workouts.length > 0 || this.data.plan_pages.length > 0
    }
  }

  hasCancelledPlanWithVisibleProducts () {
    if (this.hasCancelledPlan()) {
      return this.data.meal_plans.length > 0 || this.data.workouts.length > 0 || this.data.plan_pages.length > 0
    }
  }

  hasChatEnabled () {
    if (checkIsMember()) {
      if(!this.userData){
        return false
      }
      return this.userData.subscriptions.some(subscription => {
        return !['expired', 'cancelled','pending-payment'].includes(subscription.status) && subscription.chat_enabled
      })
    }

    return this.config.capabilities.chat
  }

  hasHabitTrackerEnabled () {
    // if (checkIsMember()) {
    //   return this.isHabitTrackerEnabled() && this.userData.subscriptions.some(subscription => {
    //     return !['expired', 'cancelled'].includes(subscription.status)
    //   })
    // }
    //
    // return false

    return this.isHabitTrackerEnabled()
  }

  isExerciseSwapEnabled () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['exercise_swap'] || false
  }

  isWorkoutLogEnabled () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['workout_log'] || false
  }

  checkPlanNotExpired () {
    if (checkIsMember()) {
      return this.userData.subscriptions.some(subscription => {
        return !['expired', 'cancelled'].includes(subscription.status)
      })
    }
  }
  isStepTrackerEnabled () {
    if (this.checkPlanNotExpired()) {
      if (!this.config) {
        return false
      }
      return this.config['feature_flags']['step_tracker_enabled'] || false
    }
    return false
  }

  isShowFatQuestionEnabled () {
    if (!this.config) {
      return true
    }
    return this.config['feature_flags']['show_fat_question'] || false
  }

  isPreferredRecipesEnabled () {
    if (!this.config) {
      return false
    }

    return this.config['feature_flags']['preferred_recipes_enabled'] || false
  }

  isExerciseVideoMuted () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['exercise_video_mute'] || false
  }

  isCommunityEnabled () {
    // return false

    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['communities_enabled'] || false
  }

  isNetCarbsAsSeparateNutrient () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['net_carbs_as_separate_nutrient'] || false
  }

  getDefaultGender () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['default_gender'] || 'm'
  }

  getDefaultMeasurementSystem () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['default_measurement_system'] || 'metric'
  }

  canMeasurementSystemChangeable () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['measurement_system_changeable'] || false
  }

  isIngredientDislikesEnabled () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['enable_ingredient_dislikes'] || false
  }

  isInjuriesSelectionEnabled () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['enable_injuries_selection'] || false
  }

  isMealPlanPairEnabled () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['meal_plans_for_couples'] || false
  }

  isHabitTrackerEnabled () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['habit_tracker_enabled'] || false
  }

  getUserEmail () {
    if (this.userData !== null) {
      return this.userData.email
    }
  }

  getDashboardVideoPages () {
    const service = new DashbardAPI()
    return new Promise((resolve, reject) => {
      if(this.videoPages !== null){
        resolve(this.videoPages)
        return
      }
      service.getDashboardVideoConfigs().then(data=>data.data).then(response => {
        this.videoPages = response
        resolve(this.videoPages)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  isPeriodTrackEnabledByUser () {
    if (!this.config) {
      return false
    }
    return this.config['user_features']['period_tracker']
  }

  isPeriodTrackEnabledByFeatureFlag () {
    if (!this.config) {
      return false
    }
    return this.config['feature_flags']['period_tracker_enabled']
  }

  togglePeriodTrackerFeature (payload) {
    const service = new DashbardAPI()
    payload ={
      community_push_notifications: false,
      ...payload
    }
    return new Promise((resolve, reject) => {
        service.togglePeriodTracker(payload).then(response => {
          resolve(response.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }
}
