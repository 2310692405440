<template>
  <transition name="page-fade">
    <component
      v-bind:is="dynamicComponent"
      v-bind:key="'workout-page' + page + id"
    ></component>
  </transition>

</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import PageWorkout from './PageWorkoutProgram.vue'
import Weeks from './PageWorkout'
import Days from './PageWeek'
import ExercisesListWithCards from './PageExercisesListWithCards'
import ExerciseView from './PageExercise'
import PageWorkoutLogHistory from './PageWorkoutLogHistory'
import PageWorkoutLogHome from './PageWorkoutLogHome'
import MainPageMixin from '../../../../mixins/mainPageMixin'
import ExerciseViewMultipleSets from './PageExerciseGrouped'
import DashboardService from '../../../../services/DashboardService'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import PageWorkoutPrograms from './PageWorkoutPrograms'
import {newDashboardLayout, showBottomNavigationBar} from '../../../../includes/TemplateSettings'

export default {
  name: 'WorkoutMain',
  extends: BaseComponent,
  mixins: [MainPageMixin],
  components: {},
  data: function () {
    return {
      id: Math.random(),
      components: {
        'workout-list': {design1: PageWorkoutPrograms},
        'workout-program': {design1: PageWorkout},
        'workout-page': {design1: Weeks, design2: Weeks},
        'workout-day': {design1: Days},
        'workout-exercises-list': {design1: ExercisesListWithCards, design2: ExercisesListWithCards},
        'workout-exercise': {design1: ExerciseView},
        'workout-exercise-grouped': {design1: ExerciseViewMultipleSets},
        'workout-log-home': {design1: PageWorkoutLogHome},
        'workout-log-history': {design1: PageWorkoutLogHistory}
      }
    }
  },

  mounted () {
    this.setPageName(this.page)
  },
  computed: {
    bottomNavEnabled: function () {
      return showBottomNavigationBar() && !this.isDesktop
    },
    isNewDashboardEnabled: function () {
      if (localStorage.getItem('newDashboardTheme') === null) {
        return newDashboardLayout()
      } else if (localStorage.getItem('newDashboardTheme') === 'true') {
        return true
      } else {
        return false
      }
    },
    page: function () {
      const queryParams = this.$route.query
      if ('workout' in queryParams && 'week' in queryParams && 'day' in queryParams && 'exercise' in queryParams) {
        let isGrouped = this.$route.query.grouped || false
        isGrouped = (isGrouped === 'false') ? false : isGrouped
        return (isGrouped) ? 'workout-exercise-grouped' : 'workout-exercise'
      } else if ('workout' in queryParams && 'week' in queryParams && 'day' in queryParams) {
        return (this.bottomNavEnabled || this.isNewDashboardEnabled) ? 'workout-program' : 'workout-exercises-list'
      } else if ('workout' in queryParams && 'week' in queryParams) {
        return (this.bottomNavEnabled || this.isNewDashboardEnabled) ? 'workout-program' : 'workout-day'
      } else if ('workout' in queryParams) {
        return (this.bottomNavEnabled || this.isNewDashboardEnabled) ? 'workout-program' : 'workout-page'
      } else if ('workout-log' in queryParams) {
        return 'workout-log-home'
      } else if ('workout-log-history' in queryParams) {
        return 'workout-log-history'
      }
      return 'workout-list'
    }

  },
  beforeRouteEnter: function (to, from, next) {
    let initData = async () => {
      /**
       * load app config
       * load the all workout programs list
       */
      let service = new DashboardService()
      await service.getUserConfig()
      let workout = new WorkoutProgramsService()
      await workout.setWorkoutProgramList()

      /**
       * load the workout program data if user is inside a workout program
       */
      let currentWorkoutID = to.query.workout || 0
      currentWorkoutID = parseInt(currentWorkoutID)
      if (currentWorkoutID) {
        let workoutProgram = workout.getWorkoutProgram(currentWorkoutID)
        if (workoutProgram) {
          /**
           * load the workout program data
           */
          await workoutProgram.setProgramDetails()
        }
      }
    }
    initData().finally(() => {
      next()
    })
  },
  beforeRouteLeave (to, from, next) { //added to stop flickering when leaving page from browser nav buttons
    this.showLoading()
    let delay = 500
    setTimeout(() => {
      next()
    }, delay)
  },
  beforeRouteUpdate (to, from, next) { //added to stop flickering when leaving page from browser nav buttons
    this.showLoading()
    let delay = 500
    setTimeout(() => {
      next()
    }, delay)
  }
}
</script>
