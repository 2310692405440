<template>
  <div class="pc-container-skeleton">
    <div
      class="container gc-page__container pc-skeleton-flex-column"
      v-for="index in 3"
      :key="index"
    >
      <animated-placeholder width="100%" />
      <animated-placeholder width="100%" />
      <animated-placeholder width="100%" />
      <animated-placeholder width="30%" />
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";
export default {
  name: "PageContainer",
  components: { AnimatedPlaceholder },
};
</script>

<style scoped></style>
