<template>
  <div
    v-bind="$attrs"
    :class="componentClasses"
    v-on="$listeners" @click="clicked">
    <div class="gc-input-radio-card__image-wrapper">
        <grid-col v-for="image in images" :key="image" style="padding: 0">
          <img
            :src="image"
            @error="setNoImage()"
            class="gc-input-radio-card__image"
            alt="">
        </grid-col>

    </div>

    <div class="gc-input-radio-card__overlay"/>
    <div class="gc-input-radio-card__area-top-right">
      <input-radio-button-circular
        class="gc-input-radio-card__radio"
        :id="id"
        :value="inputVal"
      />
    </div>
    <div class="gc-input-radio-card__area-top-left">
      <badge-choice class="gc-input-radio-exercise__type">
        <text-body-regular
          weight="extra-bold"
          line-height="multi">{{ type }}</text-body-regular>
      </badge-choice>
      <text-body-regular
        weight="extra-bold"
        class="gc-input-radio-card__name"
        line-height="multi" >{{ name }}</text-body-regular>
    </div>
  </div>
</template>

<script>

import TextBodyRegular from '@/components/global/typography/TextBodyRegular.vue'
import InputRadioButtonCircular from '@/components/global/inputs/InputRadioButtonCircular.vue'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import BadgeImportant from '@/components/global/badges/BadgeImportant.vue'
import BadgeChoice from '@/components/global/badges/BadgeChoice.vue'
import GridCol from '@/components/global/grid/GridCol.vue'
import GridRow from '@/components/global/grid/GridRow.vue'

export default {
  name: 'InputRadioCard',
  components: {
    GridRow,
    GridCol,
    BadgeChoice,
    BadgeImportant,
    TextBodyExtraSmall, TextBodyRegular, InputRadioButtonCircular },
  props: {
    value: {
      default: ''
    },
    id: {
      required: true
    },
    type: {
      default: ''
    },
    name: {
      default: ''
    },
    images: {
      default: []
    }
  },
  data: function () {
    return {
      inputVal: '',
      hasImage: true
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-input-radio-card': true,
        'gc-input-radio-card--selected': this.value === this.id,
        'gc-input-radio-card--no-image': !this.hasImage
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        if (this.value !== this.inputValue) {
          this.inputVal = this.value
        }
      }
    }
  },
  methods: {
    setNoImage () {
      console.log('no image')
      this.hasImage = false
    },
    changeInput () {
      if (this.value !== this.id) {
        this.$emit('input', this.id)
      } else {
        this.$emit('input', null)
      }
    },

    clicked () {
      if (!this.disabled) {
        this.$emit('click', this.id)
        this.changeInput()
      }
    }
  }
}
</script>

<style scoped>

</style>
