<template>
  <div>
    Invalid Notification
    {{ message }}
    <button @click="dotoDahboard">Go To dashboard</button>
    <link rel="modulepreload" crossorigin :href="assertUrl+'index.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'preload-helper.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'__federation_fn_import.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'_sentry-release-injection-file.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'__federation_shared_react.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'jsx-runtime.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'SectionLoader.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'__federation_expose_Auth.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'__federation_expose_Routes.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'lodash.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'useActivityActions.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'useActivities.js'">


    <link rel="stylesheet" :href="assertUrl+'style.css'">
  </div>
</template>


<script>
import * as Sentry from '@sentry/browser'
import DashBoardService from "@/services/DashboardService";
import {checkNotAMember} from "@/helpers/user";
import DashboardService from "@/services/DashboardService";
export default {
  name: "PageNotificationRedirect",
  data: function () {
    return {
      message: '',
      assertUrl: this.$appConfig.communityAssetURL,
    }
  },
  beforeRouteEnter: function (to, from, next) {
    if (checkNotAMember()) {
      let initCreatorData = async () => {
        let service = new DashboardService()
        await service.getPageData()
        await service.getUserConfig()

      }

      initCreatorData().finally(() => {
        next()
      })
      return
    }

    let initData = async () => {
      let service = new DashboardService()
      await service.getPageData()
      await service.getUserData()
      await service.getUserConfig()
    }

    initData().finally(() => {
      next()
    })
  },
  mounted() {
    this.message = this.$route.fullPath


    const event = {
      name: (this.$route.query.name|| this.$route.query.route || ''),
    }
    const activityId = this.$route.query.activityId || ''
    const feedId = this.$route.query.feedId|| ''
    const commentId = this.$route.query.commentId|| ''
    const dashboardService = new DashBoardService()
    const userId = `{resellerId}-{creatorId}-{memberId}-member`
      .replace(/{resellerId}/g, dashboardService.userData.reseller_id|| '')
      .replace(/{creatorId}/g, dashboardService.userData.trainer_id|| '')
      .replace(/{memberId}/g, dashboardService.userData.id|| '')
    let route
    switch (event.name) {
      case 'community.activity':
        route = '/dashboard?community={feedId}#/communities/feeds/{feedId}/view-post/{activityId}'.replace(/{feedId}/g, feedId).replace(/{feedId}/g, feedId)
          .replace(/{activityId}/g, activityId);
        this.navigateTo(route)
        break
      case 'community.profile.posts.rejected':

        route =(`/dashboard?community={feedId}#/communities/feeds/{feedId}/profile/{userId}/posts/rejected`)
          .replace(/{feedId}/g, feedId)
          .replace(/{feedId}/g, feedId)
          .replace(/{userId}/g, userId)
          .replace(/{activityId}/g, activityId);

        this.navigateTo(route)

        break
      case 'community.activity.comment':
        route = '/dashboard?community={feedId}#/communities/feeds/{feedId}/view-post/{activityId}'.replace(/{feedId}/g, feedId).replace(/{feedId}/g, feedId)
          .replace(/{activityId}/g, activityId)
          .replace(/{commentId}/g, commentId)

        this.navigateTo(route)
        break
      default:
        this.hideLoading()
        Sentry.captureMessage('Unknown event type :'+event.name, 'error')

    }
  },
  methods: {
    async navigateTo(route) {
      let dashBoardService = new DashBoardService()
      await  dashBoardService.getPageData()
      await  dashBoardService.getUserConfig()
      this.$router.replace(route)
    },
    dotoDahboard() {
      this.$router.push('/dashboard')
    },
  }
}
</script>

