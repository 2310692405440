<template>
  <button-base
    :border-radius="'sm1'"
    :class="componentClasses"
    :type="'solid'"
    @click="clicked()"
  >
    <div class="gc-button-primary-forward__left-section">
      <div class="gc-button-primary-forward__text-section">
        <text-body-regular
          :class="textClass"
          :line-height="'multi'"
          :weight="'extra-bold'"
        >
          {{ text }}
        </text-body-regular>
        <text-body-extra-small
          v-if="hasSubText"
          :line-height="'multi'"
        >
          {{ subText }}
        </text-body-extra-small>
      </div>
      <div class="gc-button-primary-forward__left-section-chip">
        <slot />
      </div>
    </div>
    <div class="gc-button-primary-forward__right-section">
      <icon-forward />
    </div>
  </button-base>
</template>

<script>

import TextContent from '../../root/TextContent'
import ButtonBase from '../../root/ButtonBase'
import IconForward from '../../root/icons/IconForward'
import TextBodyRegular from '../typography/TextBodyRegular'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'

export default {
  name: 'ButtonPrimaryForwardChip',
  components: {
    ButtonBase,
    TextContent,
    IconForward,
    TextBodyRegular,
    TextBodyExtraSmall
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    hasIcon () {
      return !!this.$slots.default
    },
    textClass: function () {
      return {
        'gc-button-primary-forward__text': true,
        'gc-button-primary-forward__text--with-sub-text': this.hasSubText
      }
    },
    subTextClass: function () {
      return {
        'gc-button-primary-forward__sub-text': this.hasSubText
      }
    },
    componentClasses: function () {
      return {
        'gc-button-primary-forward': true,
        'gc-button-primary-forward--with-sub-text': this.hasSubText,
        'gc-button-primary-forward--disabled': this.disabled
      }
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
