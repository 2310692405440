<template>
  <div
    class="gc-calender-slider-v2__wrapper"
    v-show="show">

    <div class="splide" ref="splide">
      <div class="splide__track">
        <ul class="splide__list">

      <li
        class="gc-card-meal__values splide__slide"
      >
        <loading-buffer
          style="width: 73px"
          height="73"
          v-if="hasMore"/>
      </li>

      <li
        class="gc-card-meal__values splide__slide"
        v-for="(item, index) in dates"
        :key="index">
        <calender-slider-cal-day
            :month="item.month"
            :day="item.day"
            :is-selected="selected.day===item.day&&selected.month===item.month"
            :is-logged= "item.logged"
            :class="{ 'gc-calender-slider-cal-day--centered': dates.length<7 }"
            @click="setSelected(item,index)"
        />
      </li>

        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import Slick from 'vue-slick'
import CalenderSliderCalDay from '../sliders/calender-slider-sub-components/CalenderSliderCalDay'
import { slickMixin } from '@/mixins/slickMixin'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import moment from 'moment'
import Splide from '@splidejs/splide';
export default {
  name: 'CalendarSlider',
  mixins: [slickMixin],
  components: {
    LoadingBuffer,
    Slick,
    CalenderSliderCalDay
  },
  data () {
    return {
      show: true,
      selected: {
        id: null,
        date: null,
        day: null,
        month: null,
        index: 0
      },
      breakpointChanged: false,
      currentSlideIndex: 0,
      currentSlide: 0,
      previousCount: 0,
      scrollTo: 0
    }
  },
  props: {
    /**
     {
     * month: month name,
     * day: day ,
     * date: date,
     * id: unique id for day
     * logged: is day logged
     * }
     */
    maxSlides: {
      default: 0
    },
    lastSelectedDay: {
      type: Object
    },
    /**
      * [{
      * month: month name,
      * day: day ,
      * date: date,
      * id: unique id for day
      * logged: is day logged
      * }]
     */
    dates: {
      default: []
    },
    hasMore: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler: function (value, oldValue) {
        if  (oldValue) {
          if (value.length > oldValue.length) {
            this.$nextTick(() => {
              if (this.splideInstance) {
                this.splideInstance.destroy();
              }
            });
          }
        }
        this.$nextTick(() => {

          const currentLength = (value && Array.isArray(value)) ? value.length : 0;
          const previousLength = (oldValue && Array.isArray(oldValue)) ? oldValue.length : 0;

          if (!this.splideInstance || currentLength === previousLength) {
            return;
          }

          const currentIndex = this.currentSlide + (value.length - this.previousCount);
          this.initializeSplide();
          this.$nextTick(() => {
            if (this.splideInstance) {
              this.splideInstance.go(currentIndex);
            }

            this.previousCount = value.length;
          });
        });
      }

    }
  },
  beforeMount () {
  },
  mounted () {
    this.setSelectedDay()
    this.$nextTick(() => {
      this.initializeSplide();
      this.$nextTick(() => {
        if (this.scrollTo != 0) {
          this.splideInstance.go(this.scrollTo);
        } else {
          const lastIndex = this.dates.length - 1;
          this.splideInstance.go(lastIndex);
        }
      });
      window.addEventListener("resize", this.updatePerPage);
    });
    this.previousCount = this.dates.length
  },
  methods: {
    setSelectedDay() {
      if (!this.lastSelectedDay) {
        let today = moment().locale('en').format('YYYY-MM-DD')

        this.dates.some((day, index) => {
          const isToday = today === day.date
          if (isToday) {
            this.selected = day
            this.selected.index = index
            this.setSelected(day, index)
            this.scrollTo = index
          }

          return isToday
        })
      } else {
        const index = this.dates.findIndex(item =>
            item.date === this.lastSelectedDay.date &&
            item.day === this.lastSelectedDay.day &&
            item.id === this.lastSelectedDay.id
        );
        this.setSelected(this.lastSelectedDay, index)
        this.scrollTo = index
        this.selected = this.lastSelectedDay
      }
    },
    initializeSplide() {
      const perPage = this.calculatePerPage();
      this.splideInstance = new Splide(this.$refs.splide, {
        drag   : 'free',
        updateOnMove: true,
        snap   : true,
        arrows: false,
        pagination: false,
        speed: 10,
        gap: '15px',
        perPage: perPage
      }).mount();

      this.splideInstance.on('scrolled', () => {
        this.currentSlideIndex = this.splideInstance.index;
        if (this.currentSlideIndex === 0) {
          this.emitLoadMore()
        }
      });

    },
    updatePerPage() {
      if (this.splideInstance) {
        const perPage = this.calculatePerPage();
        this.splideInstance.options = { perPage };
      }
    },
    calculatePerPage() {
      const containerWidth = this.$refs.splide.offsetWidth;
      const slideMinWidth = 42;
      const perPage = Math.floor(containerWidth / (slideMinWidth + 20)) || 1;
      return perPage;
    },
    setSelected (item, index) {
      this.selected = {
        day: item.day,
        id: item.id,
        month: item.month,
        date: item.date,
        index: index
      }
      this.$emit('selected', this.selected)
    },
    emitLoadMore () {
      if (this.hasMore) {
        this.$emit('load-more')
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.updatePerPage);
    setTimeout(() => {
      if (this.splideInstance) {
        this.splideInstance.destroy();
      }
    }, 1000)
    this.show = false
  }
}
</script>

<style scoped>

</style>
