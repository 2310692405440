<template>
  <div class="pc-container-stripe-payment-element" style="margin-top: 20px">
    <div v-if="!success" id="paypal-button-container-nalf"></div>

    <div v-if="success" class="pc-container-stripe-payment__success"
         style="text-align: center;
         display: flex;
         flex-direction: column;
         flex-wrap: nowrap;
         justify-content: center;
         align-items: center; "
    >
      <text-body-regular weight="extra-bold">{{ successHeaderText }}</text-body-regular>
      <text-body-extra-small style="margin-top: 20px">{{ successText }}</text-body-extra-small>

    </div>

  </div>
</template>

<script>
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer.vue'
import AuthService from '@/services/AuthService'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import TextBodyRegular from '@/components/global/typography/TextBodyRegular.vue'

export default {
  name: 'ContainerPayPal',
  components: {
    TextBodyRegular,
    TextBodyExtraSmall,
    TextBodySmall,
    LoadingBuffer
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    email: {
      type: String,
      default: 'your@email'
    }
  },

  data: function () {
    return {
      loading: true,
      success: false,
      successHeaderText: '',
      successText: ''
    }
  },
  mounted () {
    this.addPayPalScript()
    this.loading = false

  },
  methods: {
    addPayPalScript () {
      let scriptExists = false

      // Get all script tags in the document
      const scripts = document.getElementsByTagName('script')

      // Loop through the script tags to see if the PayPal SDK is already present
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.includes('https://www.paypal.com/sdk/js?client-id=AZcQmv_eWKAXkedGJbnOYxUdMRP8MqfJJHN2C2_ZcUEuHIceMzb3Imc5aiNfhJNgC9KBB1dildPpNiqK')) {
          scriptExists = true
          break
        }
      }

      // If the script is not present, create and add it
      if (!scriptExists) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://www.paypal.com/sdk/js?client-id=AZcQmv_eWKAXkedGJbnOYxUdMRP8MqfJJHN2C2_ZcUEuHIceMzb3Imc5aiNfhJNgC9KBB1dildPpNiqK&components=buttons&vault=true&intent=subscription'
        script.onload = () => {
          console.log('PayPal SDK script loaded successfully')
          this.initPayPalButton()
        }

        document.getElementsByTagName('head')[0].appendChild(script)
      } else {
        this.initPayPalButton()
      }
    },
    initPayPalButton () {
      fetch(`https://paypal-poc.macroactivemvp.com/paypal/init`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': (new AuthService()).getToken()
        },
        cors: !0,
        body: JSON.stringify({
          uuid: window.location.href.match(/.*subscribe\/(.*)\/.*/)[1]
        })
      }).then((res) => res.json()).then((json) => {

        if (json && json.data && json.data !== 'dni') {
          paypal.Buttons({
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                'plan_id': json.data
              })
            },
            onApprove:  (data, actions)=> {
              const email = this.email
              fetch('https://paypal-poc.macroactivemvp.com/paypal/subbed', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                cors: !0,
                body: JSON.stringify({
                  subscriptionId: data.subscriptionID,
                  email: email
                })
              }).then((res) => res.json()).then((json) => {
                  this.$emit('success-payment', json.data)
                  const userEmail = this.email

                  this.$emit('success-payment', {})
                  this.success = true
                  this.successHeaderText = this.$i18n.t('message["sign-up.paypal-success-header"]',)
                  this.successText = this.$i18n.t('message["sign-up.paypal-success-message"]', { email:userEmail })

                  if (MAnalytics && typeof MAnalytics.sendEvents === 'function') {
                    MAnalytics.sendEvents('SignUp.Paypal.Subscribed', {
                      url: window.location.pathname,
                      paypal_plan_id: json.data
                    })
                  }
                  fetch('/logout')
                }
              )
            }
          }).render('#paypal-button-container-nalf')
        }

      })

    }

  },

}
</script>


<style scoped>

</style>
