<template>
  <div class="pc-card-nutrition-analysis">
    <div class="pc-card-nutrition-analysis__header">
      <text-body-regular
        class="pc-card-nutrition-analysis__header-title"
        :weight="'extra-bold'"
        :line-height="'lh-sm'"
      >
        {{ $tc('message[\'general.calories\']', 2) }}
      </text-body-regular>
      <text-body-regular
        class="pc-card-nutrition-analysis__header-calories"
        :weight="'extra-bold'"
        :line-height="'lh-sm'"
      >
        {{ calories + ' ' + $t('message[\'food-diary.unit-cal\']') }}
      </text-body-regular>
    </div>

    <div class="pc-card-nutrition-analysis__body">
      <div class="pc-card-nutrition-analysis__ingredients">
        <div
          v-for="(ingredient, index) in ingredients"
          :key="index"
          class="pc-card-nutrition-analysis__ingredients-item"
        >
          <text-body-small
            class="pc-card-nutrition-analysis__ingredients-item-name"
            :weight="'medium'"
            :line-height="'lh-sm'"
          >
            {{ ingredient.food }}
          </text-body-small>
          <div class="pc-card-nutrition-analysis__ingredients-quantity">
            <text-body-small
              class="pc-card-nutrition-analysis__ingredients-measure"
              :weight="'medium'"
              :line-height="'lh-sm'"
            >
              <div
                v-if="ingredient.measure === 'gram'"
                class="pc-card-nutrition-analysis__ingredients-measure-one"
              >
                <div>{{ ingredient.weight + $t('message[\'general.g\']') }}</div>
              </div>
              <div
                v-else
                class="pc-card-nutrition-analysis__ingredients-measure-two"
              >
                <div class="pc-card-nutrition-analysis__ingredients-measure-two-weight">
                  {{ ingredient.weight + $t('message[\'general.g\']') }}
                </div>
                <div class="pc-card-nutrition-analysis__ingredients-measure-two-quantity">
                  {{ ingredient.quantity }} {{ ingredient.measure }}
                </div>
              </div>
            </text-body-small>
            <text-body-small
              class="pc-card-nutrition-analysis__ingredients-calories"
              :weight="'medium'"
              :line-height="'lh-sm'"
            >
              {{ ingredient.calories + " " + $t('message[\'food-diary.unit-cal\']') }}
            </text-body-small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import TextBodySmall from '../../../../global/typography/TextBodySmall';

export default {
  name: 'CardNutritionAnalysis',
  components: { TextBodyRegular, TextBodySmall },
  props: {
    calories: {
      required: true,
      type: Number
    },
    ingredients: {
      required: true,
      type: Array
    },
  },
  data: function () {
    return {
      currentLog: false,
      title: '',
      images: []
    }
  },
  computed: {

  },
  watch: {

  },
  mounted () {

  },
  methods: {
  }
}
</script>
