<template>
  <div>
    <div :class="componentClasses">
      <text-body-extra-small>
        {{ $t('message["meal-plan.request-change.description"]') }}
      </text-body-extra-small>
      <text-body-extra-small class="pc-button-meal-change-request__button" @click="triggerPopup()"
                             :weight="'extra-bold'" :decoration="'underline'">
        {{ $t('message["meal-plan.request-change.button-text"]') }}
      </text-body-extra-small>
    </div>
    <popup-meal-plan-change
      :visible="showMealChangePopup"
      v-if="showMealChangePopup"
      @close="triggerPopup()"
      :mealId="mealId"
    />
  </div>
</template>

<script>
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import TextContent from '../../../../root/TextContent'
import GridCol from '../../../../global/grid/GridCol'
import IconPlayTriangle from '../../../../root/icons/IconPlayTriangle'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import PopupMealPlanChange from '@/components/layout/template-1/meal-plan/page-components/PopupMealPlanChange.vue'

export default {
  name: 'ButtonMealChangeRequest',
  components: {
    PopupMealPlanChange,
    TextBodyExtraSmall,
    IconPlayTriangle,
    GridCol,
    TextContent,
    TextBodySmall
  },
  props: {
    text: {
      default: ''
    },
    mealId: {
      default: 0
    }
  },
  data: function () {
    return {
      showMealChangePopup: false
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'pc-button-meal-change-request': true
      }
    }
  },
  methods: {
    triggerPopup () {
      this.showMealChangePopup = !this.showMealChangePopup
    }
  }
}
</script>
