<template>
  <div :class="componentClasses" @click="clicked()">
     <text-content :weight="'medium'" :lineHeight="'multi'" :size="'sm2'">
        <slot></slot>
    </text-content>
  </div>
</template>
<script>
import TextContent from '../../../root/TextContent'

export default {
  name: 'side-bar-button-primary',
  components: {
    TextContent
  },
  props: {
    active: {
      default: false
    },
    sub:{
      default: false
    }
  },
  methods: {
    clicked () {
      this.$emit('click')
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'gc-side-bar__button-primary': true,
        'gc-side-bar__button-primary__selected': this.active,
        'gc-side-bar__button-primary__sub': this.sub
      }
    }
  }
}
</script>
