<template>
  <div :class="componentClasses" >
    <div class="gc-header-simple__header-wrapper" :class="icon ? 'aaa' : 'gc-header-simple__header-wrapper-full-width'">
    <text-content  @click="clicked()" :weight="'extra-bold'" :line-height="'multi'" :size="'sm4'">
      {{ headerText }}
    </text-content>
    <icon-info :size="'sm3'" v-if="showInfoIcon && showIcon" @click="infoIconClicked()" />
    </div>
    <div class="gc-header-simple__icon-wrapper">
      <icon-paper-plane style="padding-left: 20px; padding-right: 20px" v-if="showSendIcon" @click="sendIconClicked()"></icon-paper-plane>
      <icon-info-bulb v-if="icon === 'bulb' && showIcon" @click="iconClicked()" />
      <icon-info-bulb :size="'sm3'" v-else-if="icon === 'bulb-small' && showIcon" @click="iconClicked()" />
      <icon-forward
              :size="'md1'"
              v-else-if="icon === 'forward' && showIcon"
              @click="clicked()"
      />
      <icon-info :size="'sm3'" v-if="icon === 'info-right' && showIcon" @click="infoIconClicked()" />
      <icon-forward :size="'sm4'"
                    v-else-if="icon === 'forward-small' && showIcon"
                    @click="clicked()"
      />
    </div>

  </div>
</template>

<script>
import TextContent from '../../root/TextContent'
import IconInfoBulb from '../../root/icons/IconInfoBulb'
import IconInfo from '../../root/icons/IconInfo'
import IconForward from '../../root/icons/IconForward'
import IconPaperPlane from '../../root/icons/IconPaperPlane'

export default {
  name: 'HeaderSimple',
  components: {
    IconPaperPlane,
    IconInfoBulb,
    IconForward,
    TextContent,
    IconInfo
  },
  props: {
    headerText: {
      default: ''
    },
    showSendIcon: {
      default: false
    },
    showIcon: {
      default: true
    },
    icon: {
      default: '' // 'bulb','forward'
    },
    showInfoIcon: {
      default: false
    }
  },
  computed: {

    componentClasses: function () {
      return {
        'gc-header-simple': true
      }
    }
  },
  methods: {
    sendIconClicked () {
      this.$emit('print-icon-click')
    },
    iconClicked () {
      this.$emit('icon-click')
    },
    infoIconClicked () {
      this.$emit('info-icon-click')
    },
    clicked () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped></style>
