import { render, staticRenderFns } from "./TextHeading4.vue?vue&type=template&id=6eac8770&scoped=true"
import script from "./TextHeading4.vue?vue&type=script&lang=js"
export * from "./TextHeading4.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eac8770",
  null
  
)

export default component.exports