import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'

export default class extends BaseAPI {
  _resource = 'meal-plans'
  _resourceMe = 'me'

  /**
   * Substitute the meal
   * @param mpID meal plan ID
   * @param mID meal ID
   * @returns {Promise<AxiosResponse<T>>}
   */
  substitute (mpID, mID, payload) {
    const url = this._baseURL
    const pathArr = [this._resource, mpID, 'substitute-meal', mID]
    return axios.post(url + this._getPath(pathArr), payload)
  }

  getUpdateData () {
    const url = this._baseURL
    const pathArr = [this._resource, 'update']
    return axios.get(url + this._getPath(pathArr))
  }

  updateData (payload) {
    const url = this._baseURL
    const pathArr = [this._resource, 'update']
    return axios.post(url + this._getPath(pathArr), payload)
  }

  sendRecipeEmail (payload) {
    const url = DefineInclude.shoppingListEmailAPIurl
    const headers = this._headers
    headers.Authorization = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    const pathArr = ['recipes', 'email']
    return axios.post(url + this._getPath(pathArr), payload, {headers: headers})
  }

  sendWeeklySummaryEmail (payload) {
    const url = DefineInclude.shoppingListEmailAPIurl
    const headers = this._headers
    headers.Authorization = 'Bearer ' + this._token
    headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
    const pathArr = ['meal-plans', 'email']
    return axios.post(url + this._getPath(pathArr), payload, {headers: headers})
  }

  getDislikes () {
    let pathArr = [this._resourceMe, 'meal-dislikes']
    return axios.get(this._baseURL + this._getPath(pathArr))
  }

  saveDislikes (payload) {
    let pathArr = [this._resourceMe, 'meal-dislikes']
    return axios.post(this._baseURL + this._getPath(pathArr), payload)
  }

  deleteDislikes (id) {
    let pathArr = [this._resourceMe, 'meal-dislikes', id]
    return axios.delete(this._baseURL + this._getPath(pathArr))
  }

  getPreferences () {
    let pathArr = [this._resourceMe, 'meal-preferences']
    return axios.get(this._baseURL + this._getPath(pathArr))
  }

  getDetailedPreferences () {
    let pathArr = [this._resourceMe, 'meal-preferences']
    return axios.get(this._baseURL + this._getPath(pathArr), {params: {withDetails: true}})
  }

  saveFavorites (payload) {
    let pathArr = [this._resourceMe, 'meal-favorites']
    return axios.post(this._baseURL + this._getPath(pathArr), payload)
  }

  deleteFavorites (id) {
    let pathArr = [this._resourceMe, 'meal-favorites', id]
    return axios.delete(this._baseURL + this._getPath(pathArr))
  }

  checkAvailabilityFavouriteMeal (payload) {

    let pathArr = [this._resourceMe, 'meal-favorites', 'check-availability']
    return axios.post(this._baseURL + this._getPath(pathArr), payload)
  }

  getSwapMealOptions (mealPlanId,mealToSwapId,payload={}) {

    let pathArr = ['meal-plans',mealPlanId,'swap',mealToSwapId]
    return axios.get(this._baseURL + this._getPath(pathArr), {
      params: payload
    })
  }
  swapWithSelectedOption (mealPlanId,mealToSwapId,swapWithMealId) {

    let pathArr = ['meal-plans',mealPlanId,'swap',mealToSwapId,swapWithMealId]
    return axios.post(this._baseURL + this._getPath(pathArr), )
  }
  requestChange (mealId, payload) {
    let pathArr = [this._resource, mealId, 'request-update']
    return axios.post(this._baseURL + this._getPath(pathArr), payload)
  }

}
