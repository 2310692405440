<template>
  <div>
    <div class="gc-card-content pc-dashboard__card-step-tracker">
      <div class="gc-header-simple">
        <AnimatedPlaceholder width="110px" height="27px" />
      </div>
      <div class="pc-dashboard__card--info">
        <AnimatedPlaceholder width="100%" height="14px" />
        <AnimatedPlaceholder width="100%" height="14px" />
      </div>
      <div class="pc-dashboard-button">
        <AnimatedPlaceholder width="100%" height="14px" />
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue"
export default {
  components: { AnimatedPlaceholder },
};
</script>

<style lang="scss" scoped>
.pc-dashboard__card--info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.pc-dashboard-button {
  width: 100px;
  text-align: center;
  display: block;
  margin: auto;
  margin-top: 8px;
}
</style>
