<template>
    <div :class="componentClasses">
        <div class="gc-card-memeber-progress__main-wrapper">

          <div class="gc-card-memeber-progress__photo-wrapper">
            <img loading="lazy" :src="progressDetails.processed_images.ig_post" />
          </div>
            <!-- <text-content class="gc-card-memeber-progress__text--gap-2">{{ progressDetails.transformation_time_human }}</text-content> -->
            <text-content class="gc-card-memeber-progress__text--gap-2">
              {{ transformationTimeHuman }}
            </text-content>
            <text-content class="gc-card-memeber-progress__text--gap-1" :size="'sm3'">{{ progressDetails.photos.oldest.date}} → {{ progressDetails.photos.latest.date}}</text-content>
            <loading-buffer v-if="showLoading" :height="30"/>
            <text-content v-if="!showCustomerData && !showLoading" class="gc-card-memeber-progress__text--gap-1 gc-card-memeber-progress__customer-info-button" :size="'sm3'" @click="showCustomerDetails">{{ $t("message['member-progress.show-customer-deatils']") }}</text-content>
            <text-content v-if="showCustomerData && !showLoading" class="gc-card-memeber-progress__text--gap-1 gc-card-memeber-progress__customer-info-button" :size="'sm3'" @click="showCustomerData=false">{{ $t("message['member-progress.hide-customer-deatils']") }}</text-content>
            <div v-if="showCustomerData">
              <text-content class="gc-card-memeber-progress__text--gap-2">{{ customerData.full_name}}</text-content>
              <text-content class="gc-card-memeber-progress__text--gap-1" :weight="'extra-bold'" :size="'sm3'" @click="copyEmail">{{ customerData.email}}</text-content>
            </div>
            <button-custom :text="$t(`message['member-progress.ig-post-text']`)" @click="openImageInNewTab" />
        </div>
    </div>
</template>
<script>
import TextContent from '../../root/TextContent'
import Image from '../../root/Image'
import ButtonCustom from '../buttons/ButtonCustom'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import { isMobileNativeApp } from '../../../includes/NativeAppCommon'
import MemberProgressService from '../../../services/api/member-progress/MemberProgressService'
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer'
import moment from 'moment'
export default {
  components: {
    Image,
    TextContent,
    ButtonCustom,
    LoadingBuffer
  },
  data () {
    return {
      showLoading: false,
      customerData: {},
      showCustomerData: false
    }
  },
  props: {
    progressDetails: {
      type: Object,
      required: true
    }
  },
  mixins: [NativeAppMixin],
  computed: {
    componentClasses: function () {
      return {
        'gc-card-member-progress': true
      }
    },
    transformationTimeHuman() {
      const d1 = this.progressDetails.photos.oldest.date;
      const d2 = this.progressDetails.photos.latest.date;

      if (d1 && d2) {
        const diffInYears = moment(d2).diff(moment(d1), 'years');
        const diffInMonths = moment(d2).diff(moment(d1), 'months');
        const diffInDays = moment(d2).diff(moment(d1), 'days');

        if (diffInYears > 0) {
      return `${this.$tc('message["evergreen-progress-image.transformation-time"]', diffInYears, { count: diffInYears })} ${this.$t('message["evergreen-progress-image.transformation-after"]')}`;
    } else if (diffInMonths > 0) {
      return `${this.$tc('message["evergreen-progress-image.transformation-time-months"]', diffInMonths, { count: diffInMonths })} ${this.$t('message["evergreen-progress-image.transformation-after"]')}`;
    } else if (diffInDays > 0) {
      return `${this.$tc('message["evergreen-progress-image.transformation-time-days"]', diffInDays, { count: diffInDays })} ${this.$t('message["evergreen-progress-image.transformation-after"]')}`;
    } else {
      return `0 ${this.$tc('message["evergreen-progress-image.transformation-time-days"]', 0, { count: 0 })} ${this.$t('message["evergreen-progress-image.transformation-after"]')}`;
    }
      } else {
        return '';
      }
    }
  },
  methods: {
    async showCustomerDetails () {
      this.showLoading = true
      let customerId = this.progressDetails.t + '-' + this.progressDetails.c

      const memberProgressService = new MemberProgressService()
      const response = await memberProgressService.getCustomerDetails(customerId)

      this.customerData = response.data
      this.showCustomerData = true
      this.showLoading = false
    },
    openImageInNewTab () {
      if (isMobileNativeApp()) {
        this.handleUrlNavigate(this.progressDetails.processed_images.ig_post)
      } else {
        const imageUrl = this.progressDetails.processed_images.ig_post
        const newWindow = window.open()
        newWindow.document.write(`<img src="${imageUrl}" alt="Image" style="max-width: 100%; height: auto; display: block; margin: 0 auto;">`);
        newWindow.document.close()
      }
    },
    copyEmail () {
      const email = this.progressDetails.customer.email
      const el = document.createElement('textarea')
      el.value = email
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
  }
}
</script>
