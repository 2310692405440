import {mapGetters, mapMutations} from 'vuex'
import DashboardService from '@/services/DashboardService'
import {checkNativeAppVersion, isMobileNativeApp} from '@/includes/NativeAppCommon'

import {isAndroid, isChrome, isIOS, isMobile, isSafari} from 'mobile-device-detect'

import ImageService from "@/services/ImageService";
import DefineInclude from "@/DefineInclude";

export default {
  props: {
    hasNotifications: {
      default: false
    },
    alwaysShowMenu: {
      default: false
    },
    pageHeader: {
      default: ''
    }
  },
  data: function () {
    return {
      rightIconActive: false,
      pwaPopup: {
        visible: false
      },
      hasTabImage: true,
      backgroundImage: null,
      backgroundTabImage: null,
      headerOptions: {
        show: false,
        left: 'menu',
        right: 'pwa',
        helpContent: '',
        rightDisabled: false,
        logoImage: '',
        modifiers: [],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      logoImage: '',
      isCommunityEnabled: false
    }
  },
  computed:
    {
      ...mapGetters({
        unreadCommunityNotificationsExist: 'communityStore/isUnreadNotificationsExist'
      }),
      showTabImage: function () {
        return (!this.isDesktop || this.hasTabImage)
      },
      featuredAreaClasses: function () {
        return {
          'pc-dashboard__main-featured-area--with-notification-bar': this
            .hasNotifications
        }
      },
      heroCardStyles: function () {
        if (this.isTablet) {
          return {'background-image': `url("${this.backgroundTabImage}")`}
        }

        return {'background-image': `url("${this.backgroundImage}")`}
      },
      heroCardStylesDesktop: function () {
        if (this.showTabImage) {
          return {'background-image': `url("${this.backgroundTabImage}")`}
        }

        return {}
      }
    },
  beforeMount() {
    this.backgroundImage = this.getStoreImage('dashboard.background')
    this.logoImage = this.getStoreImage('dashboard.header.logo')

    let imageService = new ImageService()
    const tabImage = imageService.getDashboardTabImage()
    let defaultTabImages = [
      'https://d3nepnh0ml2m2t.cloudfront.net/images/presets/generic/2x.dashborad.jpg',
      'https://test-macroapp-toning-images.s3-ap-southeast-2.amazonaws.com/images/presets/generic/2x.dashborad.jpg',
      'https://frontend-macroactive-testing.s3-us-west-1.amazonaws.com/images/presets/generic/2x.dashborad.jpg'
    ]
    if (this.backgroundImage !== tabImage && !defaultTabImages.includes(tabImage)) {
      this.backgroundTabImage = tabImage
      this.hasTabImage = true
    } else {
      this.backgroundTabImage = this.backgroundImage
      this.hasTabImage = false
    }

    let dashboardService = new DashboardService()
    dashboardService.getUserConfig().then(() => {
      this.isCommunityEnabled = dashboardService.isCommunityEnabled()
    })

    let auth = this.$route.meta.requiresAuth
    if (auth) {
      let dashboardService = new DashboardService()
      dashboardService.getUserConfig().then(() => {
        this.isCommunityEnabled = dashboardService.isCommunityEnabled()
      })
    }
  },
  methods: {
    isAndroidPWA() {
      return ['fullscreen', 'standalone', 'minimal-ui'].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      )
    },
    showPwaPopup() {
      if (checkNativeAppVersion()) {
        return false
      }
      // ios check
      const isIosDevice = isIOS && isMobile && isSafari
      // android PWA check
      const isAndroidDevice = isAndroid && isMobile && isChrome && !this.isAndroidPWA()

      // pwa check
      const isInStandaloneMode = () =>
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone ||
        document.referrer.includes('android-app://')
      const manifest = document.querySelector('link[rel=\'manifest\']')

      return manifest && !isInStandaloneMode() && (isIosDevice || isAndroidDevice)
    },


    goToNotificationPage() {
      this.delayedRouterPush({
        path: DefineInclude.appUrlList.dashboard,
        query: {page: 'notifications', tab: 'other'}
      })
    }
  }

}
