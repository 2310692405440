<template>

  <page-secondary class="pc-billing-info" :header="headerOptions">
    <page-container class="pc-billing-info-container">
      <grid-row>
        <grid-col v-if="plans.length===0" cols="12">
            <div class="pc-heading-plans-info--empty">
              <text-body-extra-small v-html="plansTextEmpty" line-height="lh-lg"></text-body-extra-small>
            </div>
        </grid-col>
        <grid-col md="6" v-else>
          <div class="pc-billing-info__plans">
            <div class="pc-heading-plans-info">
              <text-body-small v-html="plansText" line-height="lh-lg"></text-body-small>
            </div>

            <card-recurring-subscription v-for="plan in plans" :plan="plan" :key="'plan-'+plan.getID()"/>
          </div>
        </grid-col>
        <grid-col md="6"  >
          <div class="pc-billing-info__payment-method" v-if="plans.length>0 && (hasStripeCardPaymentGateway||hasApplePaymentGateway) ">
            <div class="pc-heading-plans-info">
              <text-body-small line-height="lh-lg">
                {{ $tc('message[\'billing-info.payment-method\']', paymentMethodsCount) }}
              </text-body-small>
            </div>
            <card-apple-in-app v-if="hasApplePaymentGateway"/>
            <card-debit-card v-if="hasStripeCardPaymentGateway"
                             :last-digits="creditCardLastDigits"
                             @click="update()"
            />
          </div>
        </grid-col>

      </grid-row>
    </page-container>
    <popup-payment-error
      v-if="errorPopup.show"
      :error="errorPopup.errorDescription"
      :title="errorPopup.title"
      :visible="errorPopup.show"
      @close="errorPopup.show = false"
    />
  </page-secondary>

</template>

<script>
import BaseComponent from '../../global/base/BaseComponent'
import ProfileService from '../../../../../services/ProfileService'
import PopupPaymentError from './widgets/PopupPaymentError'
import ImageBase from '../../../../root/Image'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PageSecondary from '../../../../global/pages/PageSecondary'
import PageContainer from '../../../../global/pages/page-sub-components/PageContainer'
import PageFooter from '../../../../global/pages/page-sub-components/PageFormFooter'
import BillingService from '../../../../../services/plans/BillingService'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import CardRecurringSubscription from './page-components/CardRecurringSubscription'
import CardAppleInApp from './page-components/CardAppleInApp'
import CardDebitCard from './page-components/CardDebitCard'
import headerEventBus from '../../../../../event-buses/headerEventBus'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'
import GridRow from '../../../../global/grid/GridRow'
import GridCol from '../../../../global/grid/GridCol'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import { isMobileNativeApp } from '@/includes/NativeAppCommon'

export default {
  name: 'PageBillingInfo',
  components: {
    GridCol,
    GridRow,
    TextBodyExtraSmall,
    CardDebitCard,
    CardAppleInApp,
    CardRecurringSubscription,
    TextBodySmall,
    PageFooter,
    PopupPaymentError,
    ImageBase,
    ButtonPrimary,
    PageSecondary,
    PageContainer
  },
  extends: BaseComponent,
  data: function () {
    return {
      errorPopup: {
        visibility: false,
        title: '',
        errorDescription: '',
        show: false
      },
      plans: [],
      creditCardLastDigits: [],
      hasStripeCardPaymentGateway: false,
      hasApplePaymentGateway: false,
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: '',
        rightBlink: false,
        modifiers: ['with-bg-color', 'small-title'],
        mainTitle: this.$t('message["billing-info.page-header"]'),
        subTitle: ''
      }
    }
  },
  mounted () {
    this.setPageData()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
  },
  computed: {
    paymentMethodsCount () {
      let count = 0
      if (this.hasApplePaymentGateway) {
        count += 1
      }
      if (this.hasStripeCardPaymentGateway) {
        count += 1
      }

      return count
    },
    plansText () {
      return this.$i18n.tc('message[\'billing-info.my-plans\']', this.plans.length)
    },
    plansTextEmpty () {
      return this.$i18n.t('message[\'billing-info.empty\']')
    }
  },
  created () {
    this.setPageTitle(this.$t('message["billing-info.page-header"]'))
  },
  methods: {
    navigate () {
      const query = {
        page: 'payment-settings',
        section: 'update',
        stk: btoa(this.stripeKey)
      }
      this.$router.push({
        path: this.$route.path,
        query: query
      })
    },
    update () {
      if(!isMobileNativeApp()) {
        this.showLoading()
        const profileService = new ProfileService()
        profileService
          .createStripeCreditCardUpdateSession()
          .then((data) => {
            profileService
              .redirectToStripeCheckout(data.session)
              .then(() => {
                /**
                 * doing nothing stripe redirection
                 */
              })
              .catch((redirectionError) => {
                this.errorPopup.title = this.$i18n.t(
                  'message["profile.payment-settings.update"]'
                )
                this.errorPopup.errorDescription = redirectionError
                this.errorPopup.visibility = true
              })
          })
          .catch((err) => {
            this.errorPopup.title = this.$i18n.t(
              'message["profile.payment-settings.update"]'
            )
            if (err.type === 'form' && err.errors.message) {
              this.errorPopup.errorDescription = err.errors.message
            } else {
              this.errorPopup.errorDescription = this.$i18n.t(
                'message["profile.payment-settings.update-error"]'
              )
            }

            setTimeout(() => {
              this.errorPopup.show = true
            }, 2000)
          }).finally(() => {
          this.hideLoading()
        })
      }
    },

    setPageData () {
      const billingService = new BillingService()
      billingService.getInfoFormAPI().then((res) => {
        this.stripeKey = billingService.getStripeKey()
        this.plans = billingService.getCustomSortedPlans()
        this.creditCardLastDigits = billingService.getCreditCardLastDigits()
        this.hasStripeCardPaymentGateway = billingService.hasSubscriptionPlansWithStripePaymentGateway()
        this.hasApplePaymentGateway = billingService.hasApplePaymentGatewayPlan()
        this.hideLoading()
        pageReadyEvent()
      }).catch(err => console.log(err))
    },
    navigatePrevious () {
      this.$router.push({
        path: this.$route.path,
        query: {loading: '0'}
      })
    }
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  }
}
</script>
