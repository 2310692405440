<template>
    <div>
        <card-navigation-skeleton style="margin-bottom: 20px" />
        <card-navigation-skeleton  style="margin-bottom: 20px"/>
    </div>
</template>
<script>
import CardNavigationSkeleton from '../../../../global/cards/CardNavigationSkeleton'

export default {
    components: {
        CardNavigationSkeleton
    }
}
</script>
