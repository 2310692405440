<template>
  <page-secondary
    :header="headerOptions"
    :desktop-side-bar="false"
    class="pc-sign-up-register"
  >
    <header-subscription
      :steps="stepsData"
      :route="'/sign-up'"
      class="pc-sign-up-register__header-subscription"
    />
    <page-container-fluid>
      <grid-row :no-gutters="true">
        <grid-col
          md="12"
          lg="4"
        >
          <card-subscription-cover-image
            :logo="logoImage"
            :image="titleImage"
            class="pc-sign-up-register__subscription-cover-image"
          />
        </grid-col>
        <grid-col
          md="12"
          lg="8"
          class="pc-sign-up-register__content-col"
        >
          <page-container class="pc-sign-up-register__container container-sm">
            <div class="pc-sign-up-register__intro" v-if="plan.description">
              <text-body-small
                :line-height="'multi'"
                v-html="plan.description"
              />
            </div>
            <div class="pc-sign-up-register__plan">
              <text-body-regular
                :line-height="'multi'"
                :weight="'extra-bold'"
                class="pc-sign-up-register__plan-title"
              >
                {{ $t('message["sign-up.selected-plan"]') }}
              </text-body-regular>
              <card-subscription-plan
                :type="plan.type"
                :title="plan.title"
                :period="plan.period"
                :price="plan.price"
                :currency="plan.currency"
                :status="plan.status"
                :trial-period="plan.trialPeriod"
                :discounted-price="plan.price"
              />
            </div>

            <hr class="pc-sign-up-register__seperator">
            <validation-observer
              v-slot="{handleSubmit, invalid }"
              ref="form"
              tag="div"
            >
              <div class="pc-sign-up-register__registration-form">
                <text-body-regular
                  :line-height="'multi'"
                  :weight="'extra-bold'"
                  class="pc-sign-up-register__registration-form-title"
                >
                  {{ $t('message["sign-up.register.form-title"]') }}
                </text-body-regular>


                <form
                  id="signUpForm"
                  @submit.prevent="handleSubmit(submit)"
                >
                  <div class="pc-sign-up-register__registration-form-inputs">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('message[\'sign-up.form.first-name\']')"
                      tag="div"
                      class="pc-sign-up-register__registration-form-input"
                      vid="first_name"
                      rules="required"
                    >
                      <text-body-small
                        class="pc-sign-up-register__registration-form-label"
                      >
                        {{ $t('message[\'sign-up.form.first-name\']') }}
                      </text-body-small>
                      <text-input
                        v-model="formData.first_name"
                        :type="'text'"
                        :placeholder="$t('message[\'sign-up.form.first-name\']')"
                        class="pc-sign-up-register__registration__first-name"
                        :error-message="errors[0]||''"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('message[\'sign-up.form.last-name\']')"
                      tag="div"
                      class="pc-sign-up-register__registration-form-input"
                      vid="last_name"
                      rules="required"
                    >
                      <text-body-small
                        class="pc-sign-up-register__registration-form-label"
                      >
                        {{ $t('message[\'sign-up.form.last-name\']') }}
                      </text-body-small>
                      <text-input
                        v-model="formData.last_name"
                        :type="'text'"
                        class="pc-sign-up-register__registration__last-name"
                        :placeholder="$t('message[\'sign-up.form.last-name\']')"
                        :error-message="errors[0]||''"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('message[\'sign-up.form.email\']')"
                      mode="eager"
                      rules="required|email"
                      vid="email"
                      tag="div"
                      class="pc-sign-up-register__registration-form-input"
                    >
                      <text-body-small
                        class="pc-sign-up-register__registration-form-label"
                      >
                        {{ $t('message[\'sign-up.form.email\']') }}
                      </text-body-small>
                      <text-input
                        v-model="formData.email"
                        :type="'email'"
                        class="pc-sign-up-register__registration__email"
                        :placeholder="$t('message[\'sign-up.form.email\']')"
                      />
                      <text-error
                        v-if="errors && errors[0]"
                        @click="emailErrorClick"
                        v-html="errors[0]"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('message[\'sign-up.form.country\']')"
                      rules="required"
                      tag="div"
                      class="pc-sign-up-register__registration-form-input"
                      vid="country"
                    >
                      <div class="pc-sign-up-payment__registration-form-input">
                        <text-body-small
                          :line-height="'multi'"
                          class="pc-sign-up-payment__registration-form-label"
                        >
                          {{ $t('message[\'sign-up.form.country\']') }}
                        </text-body-small>
                        <input-select
                          v-if="countries.length>0"
                          v-model="formData.country"
                          :items="countries"
                          :error-message="errors[0]||''"
                          :placeholder="$t('message[\'sign-up.form.country\']')"
                          class="pc-sign-up-payment__registration-form-input-country"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('message[\'sign-up.form.region\']')"
                      rules="required"
                      tag="div"
                      class="pc-sign-up-register__registration-form-input"
                      vid="region_name"
                    >
                      <div class="pc-sign-up-payment__registration-form-input">
                        <text-body-small
                          :line-height="'multi'"
                          class="pc-sign-up-payment__registration-form-label"
                        >
                          {{ $t('message[\'sign-up.form.region\']') }}
                        </text-body-small>
                        <input-select
                          v-if="regions.length>0"
                          v-model="formData.region_name"
                          :items="regions"
                          :error-message="errors[0]||''"
                          :placeholder="$t('message[\'general.select\']')"
                          class="pc-sign-up-payment__registration-form-input-region"
                        />
                        <text-input
                          v-else
                          v-model="formData.region_name"
                          :error-message="errors[0]||''"
                          :placeholder="$t('message[\'sign-up.form.region\']')"
                          class="pc-sign-up-payment__registration-form-input-region"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-if="hasExtraField"
                      v-slot="{ errors }"
                      :name="extraFieldTitle"
                      rules="required"
                      vid="extra_field"
                      tag="div"
                      class="pc-sign-up-register__registration-form-input"
                    >
                      <text-body-small
                        class="pc-sign-up-register__registration-form-label"
                      >
                        {{ extraFieldTitle }}
                      </text-body-small>
                      <text-input
                        v-model="formData.extra_field"
                        :type="'text'"
                        :error-message="errors[0]||''"
                      />
                    </validation-provider>
                    <validation-provider
                      v-if="hasActivationField"
                      v-slot="{ errors }"
                      :name="$t('message[\'sign-up.form.activation-code-field\']')"
                      rules="required"
                      vid="activation_code"
                      tag="div"
                      class="pc-sign-up-register__registration-form-input"
                    >
                      <text-body-small
                        class="pc-sign-up-register__registration-form-label"
                      >
                        {{ $t("message['sign-up.form.activation-code-field']") }}
                      </text-body-small>
                      <text-input
                        v-model="formData.activation_code"
                        :type="'text'"
                        :placeholder="$t('message[\'sign-up.form.activation-code-field-placeholder\']')"
                        :error-message="errors[0]||''"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('message[\'sign-up.form.password\']')"
                      mode="eager"
                      rules="required|min:6"
                      tag="div"
                      class="pc-sign-up-register__registration-form-input"
                      vid="password"
                    >
                      <text-body-small class="pc-sign-up-register__registration-form-label">
                        {{ $t('message[\'sign-up.form.password\']') }}
                      </text-body-small>
                      <input-password
                        v-model="formData.password"
                        @strong="passwordIsStrong"
                        @weak="passwordIsWeak"
                        class="pc-sign-up-register__registration__password"
                      />
                      <text-error
                        v-if="errors && errors[0]"
                        v-html="errors[0]"
                      />
                    </validation-provider>
                  </div>
                </form>
                <div class="pc-sign-up-register__registration-form-privacy">
                  <input-check-box-squared
                    :id="1"
                    v-model="terms"
                    class="pc-sign-up-register__registration-form-privacy-icon"
                    @click="checkTerms"
                  />
                  <text-body-extra-small
                    :line-height="'multi'"
                    class="pc-sign-up-register__registration-form-privacy-text"
                    @click="statementTextClicked"
                    v-html="statementText"
                  />
                </div>
                <text-error v-if="showStatementTextError">
                  {{ $t('message["sign-up.form.validation.terms-error"]') }}
                </text-error>
                <div class="pc-sign-up-register__enroll-btn">
                  <button-primary
                    :disabled="invalid || terms.length===0 || passwordStrength === 'weak'"
                    :text="submitButtonText"
                    form="signUpForm"
                  >
                    <icon-loading-buffer
                      v-if="state!== 'init'"
                      size="md1"
                    />
                  </button-primary>
                </div>
              </div>
            </validation-observer>
            <content-page-popup
              v-if="showPrivacyPolicyPopup"
              :page="'privacy-policy'"
              :visible="showPrivacyPolicyPopup"
              @close="closePrivacyPolicyPopup()"
            />
            <content-page-popup
              v-if="showLegalDisclaimerPopup"
              :page="'legal-disclaimer'"
              :visible="showLegalDisclaimerPopup"
              @close="closeLegalDisclaimerPopup()"
            />
          </page-container>
        </grid-col>
      </grid-row>
    </page-container-fluid>
  </page-secondary>
</template>

<script>
import ButtonLink from '../../../global/buttons/ButtonLink'
import ButtonPrimary from '../../../global/buttons/ButtonPrimary'
import CardSubscriptionCoverImage
  from '../../../global/cards/card-subscription-sub-components/CardSubscriptionCoverImage'
import CardSubscriptionPlan from '../../../global/cards/card-subscription-sub-components/CardSubscriptionPlan'
import CardContent from '../../../global/cards/CardContent'
import HeaderSubscription from '../../../global/headers/HeaderSubscription'
import InputCheckBoxSquared from '../../../global/inputs/InputCheckBoxSquared'
import InputPassword from '../../../global/inputs/InputPassword'
import TextInput from '../../../global/inputs/TextInput'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../global/pages/PageSecondary'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import TextBodyRegular from '../../../global/typography/TextBodyRegular'
import TextBodySmall from '../../../global/typography/TextBodySmall'
import { ValidationObserver, ValidationProvider,setInteractionMode  } from 'vee-validate'
import AuthService from '../../../../services/AuthService'
import ContentPagePopup from '../../../global/popups/PopupContentPage'
import globalSignUpMixin from '../../../../mixins/component-mixins/page/sign-up/globalSignUpMixin'
import InputSelect from '../../../global/inputs/InputSelect'
import TextError from './page-components/TextError'
import IconLoadingBuffer from '../../../root/icons/IconLoadingBuffer'
import pageSignUpRegisterMixin from '../../../../mixins/component-mixins/page/sign-up/pageSignUpRegisterMixin'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import { registrationPageLoadedEvent } from '@/helpers/dom/events/customEvents'
import { mapMutations } from 'vuex'

setInteractionMode('eager');
export default {
  name: 'PageRegister',
  components: {
    PageContainerFluid,
    GridCol,
    GridRow,
    IconLoadingBuffer,
    InputSelect,
    TextError,
    ContentPagePopup,
    ValidationObserver,
    ValidationProvider,
    PageSecondary,
    HeaderSubscription,
    CardSubscriptionCoverImage,
    PageContainer,
    TextBodyRegular,
    TextBodySmall,
    CardSubscriptionPlan,
    TextBodyExtraSmall,
    ButtonLink,
    CardContent,
    TextInput,
    InputPassword,
    InputCheckBoxSquared,
    ButtonPrimary

  },
  mixins: [globalSignUpMixin, pageSignUpRegisterMixin],

  created () {
    this.showLoading()
    this.setImages()
  },
  async beforeMount () {
    this.initService()
    this.setRouteTransition({ name: 'none', mode: 'out-in' })
    const data = await this.service.getPlanDetails()
    if (data.activation_code_required) {
      this.hasActivationField = true
    }

    if (data.extra_field && data.extra_field.position === 'first_step') {
      this.extraFieldTitle = data.extra_field.name
      this.formData.extra_field = 'None'
      this.hasExtraField = true
    }
    this.setPlanData()
    this.setCountries()
    this.hideLoading()
    registrationPageLoadedEvent()
    let auth = new AuthService()
    if (auth.isLoggedIn()) {
      this.goToPaymentPage()
    }
  },
  methods:{
    ...mapMutations({
      setRouteTransition: 'setRouteTransition'
    }),
  }

}
</script>
