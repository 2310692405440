<template>
  <div :class="componentClasses" v-on="$listeners" @click="clicked()">
    <icon-forward-arrow-rounded :size="'sm2'" class="gc-meal__action-icon" />
    <text-body-small
      v-if="showText"
      class="gc-meal__action-text"
      :lineHeight="'multi'"
    >
      {{ text }}
    </text-body-small>
  </div>
</template>

<script>
import IconForwardArrowRounded from "../../root/icons/IconForwardArrowRounded";
import TextBodySmall from "../typography/TextBodySmall";

export default {
  name: "ActionMealViewMeal",
  components: {
    TextBodySmall,
    IconForwardArrowRounded,
  },
  props: {
    state: {
      type: String,
      default: "",
    },
    showText: {
      type: Boolean,
      default: true,
    },
    limitText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentClasses: function () {
      return {
        "gc-meal__action": true,
        "gc-meal__action-view-meal": true,
        "gc-meal__action-disabled": this.state === "disabled",
        "gc-meal__action-onTap": this.state === "onTap",
      };
    },
    text: function () {
      let text = this.$i18n.t('message["meal-plan.view-meal"]');
      if (this.limitText) {
        text = text.substring(0, 2) + "...";
      }
      return text;
    },
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>
<style scoped></style>
