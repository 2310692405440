<template>
  <div>
    <div
      class="pc-page-workout-single-program-skeleton pc-page-workout-single-program-skeleton--exercise-log"
    >
      <div class="pc-page-workout-skeleton-exercise-log">
        <div class="pc-page-workout-skeleton-exercise-log__top-section">
          <AnimatedPlaceholder width="50%" height="16px" />
        </div>
        <div class="container">
          <div
            class="pc-navigation-tabs-secondary pc-container-single-exercise__navigation-tabs"
          >
            <AnimatedPlaceholder width="60px" height="27px" />
            <AnimatedPlaceholder width="60px" height="27px" />
            <AnimatedPlaceholder width="60px" height="27px" />
          </div>
          <div class="d-flex text-center">
            <AnimatedPlaceholder
              width="100%"
              height="63px"
              border-radius="10px"
            />
          </div>

          <div class="pc-timer__action-button">
            <AnimatedPlaceholder width="70px" />
          </div>

          <div class="card-list">
            <div class="pc-skeleton-input-reps" v-for="index in 3" :key="index">
              <div><AnimatedPlaceholder width="15px" /></div>
              <div><AnimatedPlaceholder width="70px" height="50px" /></div>
              <div><AnimatedPlaceholder width="70px" height="50px" /></div>
            </div>
          </div>
          <div class="pc-skeleton-button">
            <AnimatedPlaceholder
              width="100%"
              height="50px"
              border-radius="50px"
            />
          </div>
          <div class="pc-skeleton-button">
            <AnimatedPlaceholder
              width="100%"
              height="50px"
              border-radius="50px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";

// import AnimatedPlaceHolder from "@/components/global/animators/AnimatedPlaceHolder";
export default {
  components: { AnimatedPlaceholder },
};
</script>

<style lang="scss" scoped></style>
