<template>
  <div  ref="slickWrapper" class="gc-input-meal-category">
    <!-- Wrapping InputMealCategoryCard with Slick slider -->

      <component
        :is="componentName"
        align-h="center"
        :class="componentClasses"
        :options="slick.slickOptions"
        @afterChange="handleAfterChange"
        @beforeChange="handleBeforeChange"
        @breakpoint="handleBreakpoint"
        @destroy="handleDestroy"
        @edge="handleEdge"
        @init="handleInit"
        @lazyLoadError="handleLazeLoadError"
        @lazyLoaded="handleLazyLoaded"
        @reInit="handleReInit"
        @setPosition="handleSetPosition"
        @swipe="handleSwipe"
        ref="slick">
        <component
          :is="subComponentName"
          :class="subComponentClasses"
          v-for="(option) in options"
          cols="4"
          :key="'input-meal-category-card-'+option.id">
          <input-meal-category-card
            v-model="input"
            :id="option.id"
            :text="option.name"
            :image="option.image"
          />
      </component>
    </component>
  </div>
</template>

<script>
import InputMealCategoryCard from '../inputs/input-meal-category-sub-components/InputMealCategoryCard'
import Slick from 'vue-slick'
import { slickMixin } from '../../../mixins/slickMixin'
import GridRow from '../grid/GridRow'
import GridCol from '../grid/GridCol'

export default {
  name: 'InputMealCategory',
  components: {
    GridCol,
    GridRow,
    InputMealCategoryCard,
    Slick
  },
  mixins: [slickMixin],
  props: {
    value: {
      default: 0
    },
    options: {
      type: Array,
      required: true
    }
  },
  watch: {
    value: {
      handler: function () {
        this.input = this.value
      },
      immediate: true
    },
    input: function () {
      this.$emit('input', this.input)
    }
  },
  data: function () {
    return {
      input: 0,
      showSlick: true,
      slick: {
        slickOptions: {
          centerMode: true,
          centerPadding: '110px',
          slidesToShow: 1,
          autoplay: false,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          infinite: false,
          variableWidth: true,
          rtl: false,
          focusOnSelect: true
        },
        data: []
      }
    }
  },
  computed: {
    componentName: function () {
      if (this.isTablet || this.isDesktop) {
        return 'grid-row'
      } else {
        return 'slick'
      }
    },
    subComponentName: function () {
      if (this.isTablet || this.isDesktop) {
        return 'grid-col'
      } else {
        return 'div'
      }
    },
    componentClasses: function () {
      return {
        'gc-input-meal-category__grid-row': this.isTablet || this.isDesktop
      }
    },
    subComponentClasses: function () {
      return {
        'gc-input-meal-category__grid-col': this.isTablet || this.isDesktop
      }
    }
  },
  created () {
    this.checkDirection()
  },
  mounted () {
    if (!this.isDesktop) {
      this.$nextTick(() => {
        let optionIndex = this.options.findIndex(option => {
          return this.input === option.id
        })
        this.slickGoTo(optionIndex)
      })
    }
  },
  beforeDestroy() {

    this.$refs.slickWrapper.style.visibility='hidden'
    if(this.$refs.slick){
      this.$refs.slick.$destroy()
    }

  },
  methods: {
    slickGoTo (slideIndex) {
      this.$refs.slick.goTo(slideIndex)
    },
    checkDirection () {
      this.slick.slickOptions.rtl = this.isRTL()
    }
  }
}
</script>
