import moment from 'moment'
import headerEventBus from '../../../../event-buses/headerEventBus'
import LanguageService from '../../../../services/LanguageService'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import DashBoardService from '../../../../services/DashboardService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
export default {
  data () {
    return {
      firstRecord: {},
      history: [],
      prediction: {},
      selectedDates: [],
      defaultSettingsPopup: {
        visible: false
      },
      headerOptions: {
        show: true,
        left: 'previous',
        right: 'add',
        rightDisabled: false,
        isFixed: true,
        modifiers: ['with-bg-color', 'small-title'],
        header: {
          mainTitle: this.$i18n.t('message["period-tracker.title"]'),
          subTitle: ''
        },
        mainTitle: this.$i18n.t('message["period-tracker.title"]')
      },
      pageClass: ['pc-period-tracker'],
      viewHeaderOptions: {
        show: true,
        left: 'previous-emit',
        right: 'add',
        rightDisabled: false,
        helpContent: '',
        modifiers: ['with-bg-color', 'fixed-top'],
        formData: {
          image: ''
        },
        header: {
          mainTitle: this.$t('message[\'period-tracker.title\']'),
          subTitle: ''
        }
      },
      editHeaderOptions: {
        show: true,
        leftDisabled: true,
        right: 'close',
        rightDisabled: false,
        helpContent: '',
        modifiers: ['with-bg-color', 'fixed-top'],
        formData: {
          image: ''
        },
        header: {
          mainTitle: this.$t('message[\'period-tracker.edit.title\']'),
          subTitle: ''
        }
      },
      scrollHeight: null,
      inputValue: [],
      calendarEdit: false,
      editValidated: false,
      editInfoAlert: true,
      calendar: {
        visible: false,
        toPage: {
          year: 2021,
          month: 2
        },
        locale: 'en',
        slides: 0,
        cols: 1,
        masks: {
          title: 'MMM YYYY',
          weekdays: 'WW'
        },
        selectedDates: [],
        predictedDates: [],
        disabledDates: {
          start: moment().add(1, 'days').toDate()
        },
        attributes: [
          {
            key: 'today',
            dot: {
              class: 'calendar-today'
            },
            dates: new Date(),
            order: 0
          },
          {
            key: 'history',
            highlight: {
              class: 'calendar-history',
              start: {
                contentClass: 'calendar-history-start-wrapper',
                fillMode: 'none'
              },
              base: {
                fillMode: 'light',
                contentClass: 'calendar-range-wrapper'
              },
              end: {
                contentClass: 'calendar-history-end-wrapper',
                fillMode: 'none'
              }
            },
            dates: []
          },
          {
            key: 'predicted',
            highlight: {
              class: 'calendar-prediction',
              fillMode: 'none',
              contentClass: 'calendar-prediction-wraper'
            },
            dates: [],
            order: 0
          }
        ]
      },
      observeElement: null,
      resizeObserver: null,
      width: document.documentElement.clientWidth
    }
  },
  computed: {
    // ...mapGetters({
    //   headerEditButtonClick: 'pageStore/getEditButtonClick',
    //   headerBackButtonClick: 'pageStore/getBackButtonClick',
    //   headerCloseButtonClick: 'pageStore/getCloseButtonClick'
    // }),
    calendarSelections () {
      return this.calendar.selectedDates
    },
    windowWidth () {
      return this.width
    }
  },
  watch: {
    windowWidth: {
      handler () {
        let colCount = 1
        if (this.width > 800) {
          colCount = 2
        }
        this.calendar.cols = colCount
      },
      immediate: true
    },
    // header back on calendar view
    headerBackButtonClick () {
      this.delayedRouterPush({path: this.$appConfig.appUrlList.dashboard})
    },
    firstRecord: {
      handler () {
        // automatically show new user popup if no logs found
        if (!this.firstRecord.getId()) {
          this.showDefaultSettingsPopup()
        }
      },
      deep: true
    },
    history () {
      this.setSelectedDates()
    },
    prediction () {
      this.setPredictedDates()
    },
    calendarSelections (values) {
      let existedDates = this.selectedDates.map(x => x.toISOString())
      let selectedDates = values.map(x => x.toISOString())
      let intersection = existedDates.filter(x => selectedDates.includes(x))
      if (
        this.calendarSelections.length === 0 ||
          (intersection.length === this.selectedDates.length && intersection.length === values.length)
      ) {
        this.editValidated = false
      } else {
        this.editValidated = true
      }
    }
  },
  mounted () {
    if (this.$route.query.edit) {
      this.calendarEdit = true
    }
    // this.setPageClass()
    this.pageInit().then(() => {
      pageReadyEvent()
    })

    headerEventBus.$on('add-button-click', this.headerEditButtonClick)
    headerEventBus.$on('close-button-click', this.headerCloseButtonClick)
    this.logEvent('PeriodTracker.ViewedTimeline', { }, false)
    window.addEventListener('resize', this.getDimensions)
  },
  methods: {
    ...mapGetters({
      service: 'periodTrackerStore/getService'
    }),
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    ...mapActions({
      initPage: 'periodTrackerStore/pageInit'
    }),
    // header close on calendar edit
    headerCloseButtonClick () {
      this.setCurrentScrollPosition()
      this.showViewCalendar()
    },
    // header edit on calendar view
    headerEditButtonClick () {
      this.setCurrentScrollPosition()
      // if no records have added show new user popup
      let service = new DashBoardService()
      if (!service.isEntriesEditable()) {
        this.showSubscriptionExpiredPopup('')
      } else if (!this.firstRecord.getId()) {
        this.showDefaultSettingsPopup()
      } else {
        this.showEditCalendar()
      }
    },
    headerSubTitleClick () {
      this.$router.push({path: '/page/periodtracker'})
    },
    async pageInit () {
      this.showLoading()
      this.calendarHide()

      // set locale
      let langCode = (new LanguageService()).getAppLang()
      if (langCode) {
        this.calendar.locale = langCode
      }

      // loading details
      await this.initPage().then(() => {
        this.firstRecord = this.service().getFirstRecord()
        this.history = this.service().getHistory()
        this.prediction = this.service().getPrediction()
      }).catch({

      })

      // DO NOT CHANGE THE ORDER
      // set selected dates to calendar
      await this.setSelectedDates()
      // set predicted days
      await this.setPredictedDates()
      // set calendar months
      await this.setMonths()
      // enable and display active calendar (view/edit)
      this.showActiveCalendar()
    },
    // calendar type methods
    showViewCalendar () {
      this.calendarHide()
      this.changeHeader('add')
      this.calendarEdit = false
      // set existing period ranges to calendar
      this.calendar.selectedDates = []
      let ranges = []
      if (this.history.length > 0) {
        this.history.forEach((record) => {
          let actualDate = moment(record.getActualDate())
          ranges.push({
            start: actualDate.toDate(),
            end: actualDate.add((record.getPeriodLength() - 1), 'days').toDate()
          })
        })
      }
      this.calendar.attributes[this.getAttributeIndexByKey('history')].dates = ranges
      this.calendar.attributes[this.getAttributeIndexByKey('predicted')].dates = this.calendar.predictedDates
      this.calendarReady()
    },
    showEditCalendar () {
      this.calendarHide()
      this.changeHeader('close')
      this.calendarEdit = true
      this.calendar.attributes[this.getAttributeIndexByKey('history')].dates = []
      this.calendar.selectedDates = this.selectedDates
      // assigning to calendar payload
      this.calendar.attributes[this.getAttributeIndexByKey('predicted')].dates = []
      this.calendarReady()
    },
    changeHeader (type) {
      this.headerOptions.right = type
      if (type === 'close') {
        this.headerOptions.mainTitle = this.$i18n.t('message["period-tracker.logTitle"]')
      } else if (type === 'add') {
        this.headerOptions.mainTitle = this.$i18n.t('message["period-tracker.title"]')
      }
    },
    showActiveCalendar () {
      if (this.calendarEdit) {
        this.showEditCalendar()
      } else {
        this.showViewCalendar()
      }
    },
    calendarHide () {
      this.calendar.visible = false
    },
    calendarReady () {
      this.calendar.visible = true
      this.hideLoading()
      this.setPageScrollPosition()
    },
    // calendar common methods
    setMonths () {
      return new Promise((resolve) => {
        if (this.history.length > 0) {
          let maxHistoryAt = moment().subtract(5, 'months').startOf('day')
          let firstRecordAt = moment(this.history[0].getActualDate()).startOf('day')

          let startAt = null
          if (firstRecordAt.diff(maxHistoryAt, 'days') > 0) {
            startAt = moment(this.history[0].getActualDate()).startOf('day')
          } else {
            startAt = moment().subtract(5, 'months').startOf('day')
          }

          let endAt = moment(this.calendar.predictedDates[this.calendar.predictedDates.length - 1])

          // no of months for calendar
          const slides = moment(endAt.toDate()).startOf('month')
            .diff(moment(startAt.toDate()).startOf('month'), 'months') + 1
          this.calendar.slides = Math.ceil(slides / this.calendar.cols)
          // // calendar last month set
          let endMonthAt = moment(endAt.toDate())
          this.calendar.toPage.month = parseInt(endMonthAt.format('M'))
          this.calendar.toPage.year = parseInt(endMonthAt.format('YYYY'))
        }
        resolve()
      })
    },
    setSelectedDates () {
      return new Promise((resolve) => {
        this.selectedDates = []
        if (this.history.length > 0) {
          this.history.forEach((record) => {
            let actualDate = moment(record.getActualDate()).startOf('day')
            this.selectedDates.push(actualDate.toDate())
            for (let i = 0; i < (record.getPeriodLength() - 1); i++) {
              this.selectedDates.push(actualDate.add(1, 'days').toDate())
            }
          })
        }
        resolve()
      })
    },
    setPredictedDates () {
      return new Promise((resolve) => {
        let nextPeriodDate = this.prediction.getNextPeriod()
        if (nextPeriodDate) {
          this.calendar.predictedDates = []
          let periodDuration = this.firstRecord.getPeriodLength()
          let cycleDuration = this.prediction.getCycleLength()

          // set last record prediction
          if (this.history.length > 0) {
            let lastRecord = this.history[this.history.length - 1]
            let lastRecordStart = moment(lastRecord.getActualDate()).startOf('day')
            let lastRecordEnd = moment(lastRecord.getActualDate())
              .add(lastRecord.getPeriodLength(), 'days')
              .startOf('day')
            let lastRecordExpectedEnd = moment(lastRecordStart.toDate()).add(periodDuration, 'days')

            // checking current date is within last record prediction range
            if (
              moment()
                .startOf('day')
                .diff(
                  moment(lastRecordExpectedEnd.toDate())
                    .subtract(1, 'days'), 'days'
                ) <= 0
            ) {
              let lastRecordPredictionCount = lastRecordExpectedEnd.diff(lastRecordEnd, 'days')
              if (lastRecordPredictionCount > 0) {
                this.calendar.predictedDates.push(lastRecordEnd.toDate())
                for (let i = 0; i < (lastRecordPredictionCount - 1); i++) {
                  this.calendar.predictedDates.push(lastRecordEnd.add(1, 'days').toDate())
                }
              }
            }
          }

          let futurePredictions = 0
          let loopPredictionAt = moment(nextPeriodDate)
          while (futurePredictions < 2) {
            if (
              moment(loopPredictionAt.toDate())
                .startOf('day')
                .diff(moment().startOf('day'), 'days') > 0) {
              futurePredictions++
            }
            for (let i = 0; i < periodDuration; i++) {
              this.calendar.predictedDates.push(moment(loopPredictionAt.toDate()).add(i, 'days').toDate())
            }
            loopPredictionAt.add(cycleDuration, 'days')
          }

          // assigning to calendar payload
          this.calendar.attributes[this.getAttributeIndexByKey('predicted')].dates = this.calendar.predictedDates
        }
        resolve()
      })
    },
    getAttributeIndexByKey (key) {
      let attributeIndex = ''
      this.calendar.attributes.forEach((attribute, index) => {
        if (attribute.key === key) {
          attributeIndex = index
        }
      })
      return attributeIndex
    },
    async updateHistory () {
      this.setCurrentScrollPosition()
      this.showLoading()
      this.calendarHide()
      let dates = []
      let durations = []
      let completedDates = []
      this.calendar.selectedDates.forEach(date => {
        let loopDate = moment(date).format('YYYY-MM-DD')
        if (!completedDates.includes(loopDate)) {
          if (dates.length === 0) {
            dates.push(loopDate)
            durations[loopDate] = 1
          } else {
            let lastAddedDate = dates[(dates.length - 1)]
            let expectingDate = moment(lastAddedDate).add(durations[lastAddedDate], 'days').format('YYYY-MM-DD')

            if (expectingDate === loopDate) {
              durations[lastAddedDate]++
            } else {
              dates.push(loopDate)
              durations[loopDate] = 1
            }
          }
        }
        completedDates.push(loopDate)
      })

      let payload = []
      dates.forEach((date) => {
        payload.push({actual_date: date, period_length: durations[date]})
      })
      this.logEvent('PeriodTracker.LoggedPeriod', { }, false)
      this.service().updateCycles(payload)
        .finally(() => {
          this.calendarEdit = false
          this.pageInit()
        })
    },
    setCurrentScrollPosition () {
      this.scrollHeight = document.documentElement.scrollTop
    },
    setPageScrollPosition () {
      if (this.scrollHeight) {
        this.setScrollToCurrentHeight()
      } else {
        this.setScrollToCurrentMonth()
      }
    },
    setScrollToCurrentHeight () {
      this.$nextTick(() => {
        // scrolling to current height
        let elements = document.getElementsByClassName('is-today')
        let elementsCount = elements.length

        // checking whether dom is loaded
        if (elementsCount > 0) {
          window.scrollTo(0, this.scrollHeight)
        }
      })
    },
    setScrollToCurrentMonth () {
      this.$nextTick(() => {
        // scrolling to current month
        let elements = document.getElementsByClassName('is-today')
        let elementsCount = elements.length

        // checking whether dom is loaded
        if (elementsCount > 0) {
          elements[elementsCount - 1].offsetParent.parentElement.parentElement.scrollIntoView()

          // scrolling back up to prevent covering from top fixed content (please change this height if top content height changes)
          window.scrollBy(0, -121)
        }
      })
    },
    // popups
    showDefaultSettingsPopup () {
      let service = new DashBoardService()
      if (!service.isEntriesEditable()) {
        this.showSubscriptionExpiredPopup('')
      } else {
        this.defaultSettingsPopup.visible = true
      }
    },
    closeDefaultSettingsPopup () {
      this.defaultSettingsPopup.visible = false
    },
    defaultSettingsProcessed () {
      this.setCurrentScrollPosition()
      this.calendarEdit = false
      this.pageInit()
    },
    getDimensions () {
      this.width = document.documentElement.clientWidth
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getDimensions)
  }
}
