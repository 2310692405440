<template>
  <div :class="['pc-header-dashboard-v3', 'pc-dashboard__main-featured-area', isDesktop ? 'pc-dashboard__main-featured-area--desktop' : 'pc-dashboard__main-featured-area--mobile']">
    <nav-bar-header :options="headerOptions">
      <template v-slot:left>
        <div class="pc-dashboard__main-featured-area__logo" v-if="logoImage&&bottomBarState|| logoImage&&isDesktop" >
          <image-base :src="logoImage"/>
        </div>

      </template>
      <template v-slot:center>
        <div class="pc-dashboard__main-featured-area__logo"  v-if="logoImage&&!bottomBarState&&!isDesktop">
          <image-base :src="logoImage"/>
        </div>
      </template>

      <template v-slot:right>
        <div class="app-nav__btn-pwa-wrap">
          <icon-create-shortcut class="app-nav__btn-pwa" v-if="showPwaPopup()" @click="pwaPopup.visible = true" size="md1" />
          <popup-p-w-a-instructions
            :visible="pwaPopup.visible"
            v-bind:key="'pwa-popup'"
            v-if="pwaPopup.visible"
            v-on:close="pwaPopup.visible = false"
          ></popup-p-w-a-instructions>
        </div>
        <div class="pc-dashboard__nav-bar-header-bell-icon"
             :class="{ 'pc-dashboard__nav-bar-header-bell-icon--new-notification': unreadCommunityNotificationsExist }"
             @click="goToNotificationPage"
             v-if="isCommunityEnabled">
          <icon-bell-filled v-if="unreadCommunityNotificationsExist" />
          <icon-bell v-else />
        </div>
      </template>
    </nav-bar-header>
    <page-container class="pc-dashboard__container-featured-image">
      <text-heading-5 class="pc-dashboard__main-featured-area__logo-text"  weight="extra-bold" line-height="lh-lg"      >{{ pageHeader }}</text-heading-5>
    </page-container>
  </div>
</template>

<script>
import NavBarHeader from '../NavBarHeader.vue'
import TextHeading3 from '../../../../template-2/global/typography/TextHeading3.vue'
import ImageBase from '../../../../template-2/root/Image.vue'
import IconBell from '../../../../../root/icons/IconBell.vue'
import IconBellFilled from '../../../../../root/icons/IconBellFilled.vue'
import HeaderDashboardMixin from "@/mixins/page-mixins/dashboard/headerDashboardMixin";
import TextHeading5 from "@/components/global/typography/TextHeading5.vue";
import PopupPWAInstructions from "@/components/global/popups/PopupPWAInstructions.vue";
import IconCreateShortcut from "@/components/root/icons/IconCreateShortcut.vue";
import PageContainer from "@/components/global/pages/page-sub-components/PageContainer.vue";
import {showBottomNavigationBar} from "@/includes/TemplateSettings";

export default {
  name: 'HeaderDashboardStyle3',
  components: {
    PageContainer, IconCreateShortcut, PopupPWAInstructions, TextHeading5,
    ImageBase,
    TextHeading3,
    NavBarHeader,
    IconBell,
    IconBellFilled
  },
  mixins:[HeaderDashboardMixin],
  computed:{
    bottomBarState : function (){
      return showBottomNavigationBar()
    }
  },
  beforeMount() {
    document.querySelector('body').classList.add('header-dashboard-v3');
  },
  beforeDestroy() {
    document.querySelector('body').classList.remove('header-dashboard-v3');
  },
}
</script>
