<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup gc-popup-food-diary-reminder"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <popup-text-heading >{{$i18n.t("message['food-diary.info-popup.header']")}}</popup-text-heading>
        </popup-head>
        <popup-text-body >
          <text-content :size="'sm2'" :lineHeight="'multi'">{{$i18n.t("message['food-diary.info-popup.description']")}}</text-content>
        </popup-text-body>
        <div>
        </div>
      </popup-content>
    </popup-body>

  </popup>
</template>

<script>

import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import ButtonPrimary from '../../global/buttons/ButtonPrimary'
import PopupFooter from '../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextHeading from '../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../global/popups/Popup'
import TextContent from '../../root/TextContent'
import ButtonSecondary from '../../global/buttons/ButtonSecondary'

export default {
  name: 'FoodDiaryReminderPopup',
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent,
    ButtonSecondary
  },
  data: function () {
    return {
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.hideLoading()
      }
    }
  },
  computed: {
    visible () {
      return this.$store.getters.getFoodDiaryInfoPopupStatus
    }
  },
  methods: {
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$store.commit('hideFoodDiaryInfoPopup')
      })
    }
  }
}
</script>

<style scoped>
</style>
