<template>
  <page-secondary :header="headerOptions" :class="componentClasses" style="transform: translate3d(0)">
    <page-container class="chart-container">
      <div v-if="statData.latestValue">
        <div>
          <div class="pc-chart__header-wrapper">
            <!-- Graph -->
            <div class="pc-chart__head-section">
              <!-- navbar -->
              <text-content
                v-if="statData.latestValue"
                class="pc-chart__title"
                :weight="'extra-bold'"
                :size="'lg1'"
                :lineHeight="'multi'"
              >
                {{ showLatestValue() }}
                <span>{{ measurementUnit }}</span>
              </text-content>
              <text-content
                v-else
                class="pc-chart__title"
                :weight="'extra-bold'"
                :size="'lg1'"
                :lineHeight="'multi'"
              >{{ $i18n.t('message[\'general.no-data\']') }}
              </text-content>
              <text-content
                class="pc-chart__sub-title"
                :size="'sm1'"
                :lineHeight="'multi'">{{ latestDateLabel }}
              </text-content>
            </div>
          </div>
          <my-journey-chart v-if="datacollection" :graphData="datacollection" :graphWidth="chartWidth" :isRtl="isRTL()"
                            :tooltips="chartTooltip" :unit="measurementUnit" :axisLables="labelsList"/>
        </div>
        <div class="card-measure--first-time">
          <p class="card-measure__info">
            {{ $i18n.t('message["fitness-diary.chart-note"]', [typeLabel]) }}
          </p>
        </div>
      </div>
      <div class="pc-chart__card-measure--first-time p-4" v-else>
        <text-content :size="'sm1'" :lineHeight="'multi'">
          {{ $i18n.t('message["fitness-diary.empty-measurement"]', [typeLabel]) }}
        </text-content>
      </div>
      <PopUpUpdateFitnessData
        :fitnessData="updatePopup.data"
        :type="updatePopup.type" :visible="updatePopup.show" v-if="updatePopup.show"
        v-on:updated="dataUpdated()"
        v-on:close="updatePopup.show=false"></PopUpUpdateFitnessData>
      <popup-feed-back
        :flag="feedback.eventName"
        :text-place-holder="feedback.textPlaceHolder"
        :title="feedback.title"
        :visible="feedback.popupShow"
        v-if="feedback.popupShow"
        v-on:close="hideFeedbackPopup ()"
        v-on:success="hideFeedbackPopup ()"/>
    </page-container>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import '../../../../helpers/validation'
import LineChart from './LineChart'
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'
import PopUpUpdateFitnessData from '../../../global/popups/PopUpUpdateFitnessData'
import FitnessDiaryAPI from '../../../../services/api/progress-check-in/FitnessDiaryAPI'
import fitnessDairyPageMixin from '../../../../mixins/fitnessDairyPageMixin'
import MyJourneyChart from '../../../global/charts/MyJourneyChart'
import TextContent from '../../../root/TextContent'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import headerEventBus from '../../../../event-buses/headerEventBus'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  name: 'Chart',
  extends: BaseComponent,
  mixins: [fitnessDairyPageMixin],
  components: {LineChart, PopUpUpdateFitnessData, MyJourneyChart, TextContent, PageSecondary, PageContainer},
  data: function () {
    return {
      graphData: null,
      labelsList: [],
      firstLoad: false,
      type: '',
      measurementUnit: '',
      latestDateLabel: '',
      chartWidth: 0,
      pageClass: ['macroapp--page-common'],
      headerOptions: {
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous-emit',
        right: 'add',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      },
      datacollection: null,
      chartData: {
        measurementUnit: ''
      },
      chartTooltip: {
        labels: []
      },
      chartReinit: false,
      fitnessData: [],
      updatePopup: {
        show: false,
        type: '',
        data: 0
      },
      dataOptions: {
        layout: {
          padding: {
            top: 10,
            right: 0,
            left: -10
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        legend: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              align: 'start',
              display: true,
              gridLines: {
                tickMarkLength: false,
                drawBorder: false,
                display: true,
                lineWidth: 1,
                zeroLineColor: templateConfig.style_variables['$text-area-bg-outline'],
                color: templateConfig.style_variables['$text-area-bg-outline']
              },
              ticks: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 600,
                padding: 10,
                fontColor: templateConfig.style_variables['$text-secondary-on-bg'],
                lineHeight: 1.5,
                maxRotation: 0,
                minRotation: 0
                // maxTicksLimit: 100
              },
              scaleLabel: {
                display: true
                // labelString: "Week", // hide more details
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                drawBorder: false,
                // tickMarkLength: false,
                display: true,
                lineWidth: 1,
                zeroLineColor: templateConfig.style_variables['$text-area-bg-outline'],
                color: templateConfig.style_variables['$text-area-bg-outline']
              },
              position: 'left',
              ticks: {
                maxTicksLimit: 4,
                display: false,
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 600,
                fontColor: templateConfig.style_variables['$text-secondary-on-bg'],
                beginAtZero: false
                // stepSize: 1
              },
              scaleLabel: {
                display: true
                // labelString: "Data",// hide more details
              }
            }
          ]
        }
      },
      staticAxis: {
        ticks: [],
        margins: {}
      },
      statData: {
        startDate: '',
        endDate: '',
        latestValue: '',
        history: {}
      }
    }
  },
  beforeMount () {
    // let dashBoardService = new DashBoardService()
    // if (!dashBoardService.isFitnessDiaryEditable()) {
    //   this.headerOptions.right = ''
    // }
  },
  mounted () {
    // this.setHeader(this.headerOptions)
    this.showLoading()
    this.setPageData()
    this.setPageClass()
    headerEventBus.$on('back-button-click', this.navigatePrevious)
    pageReadyEvent()
  },
  computed: {
    ...mapGetters({
      getAddButtonClick: 'pageStore/getAddButtonClick',
      headerBackButtonClick: 'pageStore/getBackButtonClick',
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    }),
    yTickGap () {
      return this.staticAxis.ticks.length === 4 ? 74 : 121
    },
    typeLabel () {
      const currentData = this.fitnessData.find(data => {
        return data.getType() === this.type
      })
      if (!currentData) {
        return ''
      }
      return (currentData) ? currentData.getName().toLowerCase() : ''
    },
    orientation () {
      return this.$store.getters.getOrientation
    },
    componentClasses: function () {
      return {
        'pc-chart': true,
        'pc-chart__tablet-view': this.isTablet,
        'pc-chart__desktop-view': this.isDesktop
      }
    }
  },
  watch: {
    getAddButtonClick: function (newVal, oldVal) {
      this.updatePopup.show = true
    },
    chartReinit: function () {
      if (this.chartReinit) {
        this.chartReinit = false
      }
    },
    orientation: function () {
      this.showLoading()
      this.setPageData()
    }
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    navigatePrevious () {
      this.$router.push({
        path: this.$appConfig.appUrlList.progress
      })
    },
    showLatestValue () {
      return Number.parseFloat(this.statData.latestValue).toFixed(1)
    },
    setAxisData (data) {
      this.staticAxis = data.y
      this.hideLoading()
      if (!this.firstLoad) {
        this.setHorizontalScroll()
        this.firstLoad = true
      }
    },
    setHorizontalScroll () {
      const element = this.$refs['graph-scroll-sec']
      element.scrollRight = element.offsetWidth
    },
    dataUpdated () {
      this.setPageData()
      setTimeout(() => {
        this.updatePopup.show = false
        this.fitnessData = []
        this.firstLoad = false
      }, 2000)
    },
    requestUpdate (type) {
      if (type) {
        const currentData = this.fitnessData.find(data => {
          return data.getType() === type
        })
        if (currentData) {
          this.updatePopup.type = currentData.getType()
          this.updatePopup.data = currentData
        }
      }
    },
    setPageData () {
      this.type = this.$route.query.type
      // if type is empty
      if (!this.type) {
        this.goBack()
      }
      this.datacollection = false

      this.service().getDataFromAPI().then(() => {
        const typeStatData = this.service().getData()[this.type]

        this.fitnessData = Object.values(this.service().getData())

        if (typeStatData) {
          this.headerOptions.mainTitle = typeStatData.getName()
          this.statData.endDate = typeStatData.getLatestData().date
          this.statData.latestValue = typeStatData.getLatestData().value
          this.statData.startDate = moment(this.statData.endDate)
            .subtract(30, 'days').format('YYYY-MM-DD')

          if (moment().isSame(this.statData.endDate, 'day')) {
            this.latestDateLabel = this.$i18n.t('message[\'general.today\']')
          } else {
            this.latestDateLabel = moment(this.statData.endDate)
              .format('dddd ll')
          }

          this.measurementUnit = typeStatData.getMeasurementUnit()
          this.initDatesChart()
          this.requestUpdate(this.type)
        } else {
          this.goBack()
        }
      }).finally(() => {
        pageReadyEvent()
        if (!this.datacollection) {
          this.hideLoading(0)
        }
      })
    },
    goBack () {
      this.$router.push({
        path: this.$route.path
      })
    },
    // temp fix till date range API integration
    async initDatesChart () {
      const startDate = moment(this.statData.startDate)
      const endDate = moment(this.statData.endDate)

      let labels = []
      const tooltipLabelNames = []
      const chartData = []

      const historyData = []
      const fitnessDiaryApi = new FitnessDiaryAPI()
      await fitnessDiaryApi.getEntriesHistory({
        page: 1,
        per_page: 30,
        field: this.type,
        type: 'day'
      })
        .then(response => {
          const history = response.data.data

          history.forEach(rec => {
            let value = rec.value
            const zeroFixedTypes = ['weight', 'height']
            if (zeroFixedTypes.includes(this.type) && this.service().measurementSystem === 'metric') {
              value = Number.parseFloat(value).toFixed(1)
            }
            if (zeroFixedTypes.includes(this.type) && this.service().measurementSystem === 'imperial') {
              value = Number.parseFloat(value).toFixed(1)
            } else if (this.type === 'body_fat') { // "body_fat" values comes as a percentage
              value *= 100
              value = Math.round(value)
            }
            historyData[String(moment(rec.date).format('YYYY-MM-DD'))] = value
          })
        })

      let loopMonth = ''
      let lastDisplayedMonth = ''
      let monthLabel = ''
      const yearLabel = ''
      for (
        let loopDate = moment(startDate.format('YYYY-MM-DD'));
        endDate.diff(loopDate, 'days') >= 0;
        loopDate.add(1, 'days')
      ) {
        loopMonth = loopDate.format('MMM')

        if (loopMonth !== lastDisplayedMonth) {
          monthLabel = '\n' + loopMonth
          lastDisplayedMonth = loopMonth
        } else {
          monthLabel = ''
        }

        tooltipLabelNames.push(loopDate.format('ll'))

        const labelName = loopDate.format('DD') + monthLabel + yearLabel
        labels.push(labelName)

        chartData.push(historyData[loopDate.format('YYYY-MM-DD')] || null)
        this.graphData = chartData
      }
      this.labelsList = labels
      this.setChartWidth(labels.length)
      this.chartTooltip.labels = tooltipLabelNames

      if (this.isRTL()) {
        labels.reverse()
        chartData.reverse()
        this.chartTooltip.labels = tooltipLabelNames.reverse()
      }
      this.datacollection = {
        labels: labels,
        datasets: [
          {
            label: '',
            fill: false,
            backgroundColor: '#2A25FF',
            borderColor: templateConfig.style_variables['$selected-state'],
            borderDash: [6, 6],
            borderWidth: 1,
            pointBorderWidth: 5,
            pointHitRadius: 5,
            options: {
              // spanGaps: true,
              responsive: false, // Instruct chart js to respond nicely.
              maintainAspectRatio: false // Add to prevent default behaviour of full-width/height
            },
            data: chartData
          }
        ]
      }
    },
    setChartWidth (noOfLabels) {
      let chartWidth = window.innerWidth
      const requiredWidth = noOfLabels * 20

      if (requiredWidth > chartWidth) {
        chartWidth = requiredWidth
      }

      this.chartWidth = chartWidth
    }
  },
  beforeDestroy () {
    headerEventBus.$off('back-button-click', this.navigatePrevious)
  }
}
</script>

<style scoped>

</style>
