<template>
  <div class="gc-period-tracker-progress">
    <icon-flower />
    <svg
      class="gc-period-tracker-progress__wrapper"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      style="position: absolute; width: 100px; height: 100px"
    >
      <circle
        class="gc-period-tracker-progress__circle-chart--background"
        stroke="#efefef"
        stroke-opacity="50%"
        stroke-width="6"
        fill="none"
        cx="50"
        cy="50"
        r="32"
      />
      <circle
        class="gc-period-tracker-progress__progress-circle"
        cx="50"
        cy="50"
        stroke="black"
        r="31.5"
        fill="transparent"
        stroke-width="7"
        stroke-linecap="round"
      />
    </svg>
    <!-- <svg width="68.6" height="74.6"  -->
    <svg
      width="74.6"
      height="74.6"
      class="gc-period-tracker-progress__progressbar-marker"
    >
      <circle
        class="gc-period-tracker-progress__date-progress"
        r="5"
        cx="31.3"
        cy="6"
        stroke-width="2"
        fill="#FFFFFF"
      />
    </svg>
  </div>
</template>
<script>
import moment from "moment";
import IconFlower from "../../root/icons/IconFlower";
export default {
  name: "period-tracker-progress",
  components: {
    IconFlower,
  },
  props: {
    periodCycle: {
      type: Number,
      default: 28,
    },

    periodDaysCount: {
      type: Number,
      required: true,
    },

    periodStartDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      periodDaysPercentage: 0,
      daysDiffPercentage: 0,
    };
  },
  mounted() {
    this.setPrecentage();
  },

  methods: {
    setPrecentage() {
      const mianCircle = document.querySelector(
        ".gc-period-tracker-progress__circle-chart--background"
      );
      const circle = document.querySelector(
        ".gc-period-tracker-progress__progress-circle"
      );
      const dateCircle = document.querySelector(
        ".gc-period-tracker-progress__progressbar-marker"
      );
      const dateProgress = document.querySelector(
        ".gc-period-tracker-progress__date-progress"
      );
      const circumference = circle.getTotalLength();
      mianCircle.setAttribute(
        "stroke",
        templateConfig.style_variables["$text-secondary-on-bg"]
      );
      circle.setAttribute(
        "stroke",
        templateConfig.style_variables["$text-secondary-on-bg"]
      );
      dateProgress.style.stroke =
        templateConfig.style_variables["$text-secondary-on-bg"];
      dateProgress.style.fill =
        templateConfig.style_variables["$text-area-bg-fill"];
      this.daysDiffPercentage = this.setDate();
      this.periodDaysPercentage = this.getPrecentage();
      circle.style.strokeDashoffset =
        circumference - (this.periodDaysPercentage / 100) * circumference;
      dateCircle.style.transform = `rotate(${this.daysDiffPercentage}deg)`;
    },

    getPrecentage() {
      return (100 / this.periodCycle) * this.periodDaysCount;
    },

    setDate() {
      const startDate = moment(this.periodStartDate).startOf("day");
      const today = moment(new Date());
      let daysDiff = today.diff(startDate, "days");
      if (daysDiff < 0) {
        return (360 / this.periodCycle) * this.periodCycle;
      } else {
        return (360 / this.periodCycle) * daysDiff;
      }
    },
  },
};
</script>
