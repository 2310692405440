<template>
  <page-secondary class="pc-dashboard-creator-editor pc-dashboard" :header="headerOptions">
    <page-container-fluid breakpoint="lg" :reverse="true">
      <grid-row no-gutters>
        <grid-col lg="12">
          <card-dashboard-layout-info/>
        </grid-col>
        <grid-col lg="12">
            <container-style-editor
              v-model="selectedHeaderVariationId"
              :variations="headerVariations"
              :mode="editor.state"
              :selected="selectedWidget === 'header'"
              @click="selectedWidget ='header'"
              :header="true"
            >

              <transition :name="editor.state==='edit'?'element-fade':''">
                <component :is="selectedHeaderVariation.component"

                           style="pointer-events: none"
                           :has-notifications="notificationBarShow"
                           :page-header="pageHeader"
                           options=""/>
              </transition>
            </container-style-editor>
        </grid-col>
        <button-custom
          @click="editorButtonClick"
          class="pc-dashboard-edit__button"
          style=""
          :text="editorStateText">
          <template v-slot:left>
            <icon-edit v-if="editor.state==='preview'" size="sm2"></icon-edit>
            <icon-publish v-if="editor.state==='edit'" size="sm2"></icon-publish>
          </template>
        </button-custom>
        <page-container class="pc-dashboard__main-content-area" :class="editor.state==='edit' ? 'pc-dashboard__main-content-area__edit-mode' : ''">

          <container-style-editor
            :editable="descriptionSelectionVariationEditable"
            :selected="selectedWidget === 'description'"
            v-model="selectedDescriptionSelectionVariationId"
            :variations="descriptionSelectionVariations"
            :mode="editor.state"
            @click="selectedWidget ='description'"
          >
            <transition :name="editor.state==='edit'?'element-fade':''">
              <component :is="selectedDescriptionSelectionVariation.component"
                         style="pointer-events: none"
                         :elements="pageContentMock"
                         options=""/>
            </transition>
          </container-style-editor>
          <draggable v-model="widgets" v-if="showWidgets" draggable=".item">
            <div v-for="componentConfig in layout" :key="componentConfig.id"
                 style="position: relative"

                 @click="setSelectedWidget(componentConfig.id)"
            >
              <div :key="componentConfig.id" style="pointer-events: none">

                <component
                  v-if="componentConfig.show&&componentConfig.html"
                  :is="componentConfig.component"
                  v-bind="componentConfig.props"
                  v-html="componentConfig.html"
                   :class="componentConfig.class"

                />
                <component
                  v-else-if="componentConfig.show"
                  :is="componentConfig.component"
                  v-bind="componentConfig.props"
                  :class="componentConfig.class"
                />
              </div>


            </div>

          </draggable>
        </page-container>
      </grid-row>
    </page-container-fluid>

  </page-secondary>
</template>

<script>
import CardDashboardEvergreenProgressImage from './page-components/CardDashboardEvergreenProgressImage'
import CardMoodTracker from './page-components/CardDashboardMoodTracker'
import CardHabitsTracker from './page-components/CardDashboardHabitsTracker'
import BaseComponent from '../global/base/BaseComponent'
import CardMyJourneyWithInputs from './page-components/CardMyJourneyWithInputs'
import CardListMealPlans from './page-components/CardListMealPlans'
import CardListWorkouts from './page-components/CardListWorkouts'
import CardListProductPages from './page-components/CardListProductPages'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import NotificationPopupHandler from '../notifications/Handler'
import CardPeriodTracker from '../period-tracker/page-components/CardPeriodTracker'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import CardListChallengePlans from './page-components/CardListChallengePlans'
import ImageFeatured from './page-components/headers/HeaderDashboardStyle3.vue'
import CardFoodDiary from './page-components/CardFoodDiary'
import ContainerChat from '../../../global/containers/ContainerChat.vue'
import dashboardMixin from '@/mixins/page-mixins/dashboard/dashboardMixin'
import ListProgressNotifications from '@/components/layout/template-1/dashboard/ListProgressNotifications.vue'
import ListProgressNotificationsV2 from '@/components/layout/template-1/dashboard/ListProgressNotificationsV2.vue'
import ButtonPrimary from '@/components/global/buttons/ButtonPrimary.vue'
import draggable from 'vuedraggable'

import IconSettings from '@/components/root/icons/IconSettings.vue'
import ImageFeaturedNoImage from "@/components/layout/template-1/dashboard/page-components/ImageFeaturedNoImage.vue";
import ImageFeaturedMini from "@/components/layout/template-1/dashboard/page-components/ImageFeaturedMini.vue";
import CardListProductPagesGrid
  from "@/components/layout/template-1/dashboard/page-components/CardListProductPagesGrid.vue";
import CardListProductPagesAccordion
  from "@/components/layout/template-1/dashboard/page-components/CardListProductPagesAccordion.vue";
import ContainerStyleEditor from "@/components/layout/template-1/dashboard/page-components/ContainerStyleEditor.vue";
import dashboardEditMixin from "@/mixins/page-mixins/dashboard/dashboardEditMixin";
import ButtonCustom from "@/components/global/buttons/ButtonCustom.vue";
import IconEdit from "@/components/root/icons/IconEdit.vue";
import IconPublish from "@/components/root/icons/IconPublish.vue";
import {mapGetters} from "vuex";
import CardDashboardLayoutInfo
  from "@/components/layout/template-1/dashboard/page-components/CardDashboardLayoutInfo.vue";
import IconDrag from "@/components/root/icons/IconDrag.vue";
import DashBoardService from "@/services/DashboardService";
import {enableDashboardVariationsDescription} from "@/includes/TemplateSettings";


export default {
  name: 'PageDashboardEditor',
  extends: BaseComponent,
  mixins: [dashboardMixin, dashboardEditMixin],
  components: {
    IconDrag,
    CardDashboardLayoutInfo,
    IconPublish,
    IconEdit,
    ButtonCustom,
    ContainerStyleEditor,
    IconSettings,
    ButtonPrimary,
    ListProgressNotifications,
    CardFoodDiary,
    ImageFeatured,
    ImageFeaturedMini,
    ImageFeaturedNoImage,
    GridCol,
    GridRow,
    PageContainerFluid,
    CardListChallengePlans,
    NotificationPopupHandler,
    CardListWorkouts,
    CardListMealPlans,
    CardListProductPages,
    CardListProductPagesGrid,
    CardListProductPagesAccordion,
    CardMyJourneyWithInputs,
    CardMoodTracker,
    CardHabitsTracker,
    PageSecondary,
    PageContainer,
    TextBodyExtraSmall,
    CardPeriodTracker,
    ContainerChat,
    CardDashboardEvergreenProgressImage,
    ListProgressNotificationsV2,
    draggable
  },
  computed: {
    ...mapGetters({
      storeChallengeMealPlans: 'layoutDashboardStore/challengeMealPlans',
      storeMealPlanNotAChallenge: 'layoutDashboardStore/mealPlanNotAChallenge',
      storeWorkouts: 'layoutDashboardStore/workouts',
    }),
    editorStateText: function () {
      return (this.editor.state === 'preview') ? this.$i18n.t('message["creator.layout-editor.edit-dashboard"]') : this.$i18n.t('message["creator.layout-editor.publish"]')
    },
    habitsTrackerEnabled() {
      return  this.habitsTrackerEnabledByCreator
    },
  },
  created() {
    this.headerOptions.right =''
    this.headerOptions.right =''
    this.layoutService.getDashboardData().then(data => {
      this.toningConfig = data.component_data.find(c => c.field === 'config')
      if (this.toningConfig.data && this.toningConfig.data.value) {
        this.layoutConfig = this.toningConfig.data.value
        console.log(this.layoutConfig)
        this.selectedHeaderVariationId = this.layoutConfig.header.variation
        if(Array.isArray(this.layoutConfig.widgets)&&enableDashboardVariationsDescription()){

          this.selectedDescriptionSelectionVariationId = this.layoutConfig.widgets.find(w => w.code === 'descriptionSelection').variation || 'style1'
        } else {
          this.layoutConfig.widgets = [
            {
              code : 'descriptionSelection',
              variation: 'style1'
            }
          ]
        }

      }

    })
    this.headerOptions.header.mainTitle = this.$i18n.t('message["creator.layout-editor.dashboard"]')
    this.headerOptions.header.subTitle = this.$i18n.t('message["creator.layout-editor.look-and-feel"]')
    this.pageContent = 'Dashboard page description comes here';

    this.pageHeader = 'Hey Creator,';
    this.setHeader(this.headerOptions);
    this.setPageClass();


    this.setNotification();
    this.hideLoading()
  },
  mounted() {
    this.setPageTitle('Dashboard')
    this.$store.dispatch("layoutDashboardStore/loadWorkoutPlans");
    this.$store.dispatch("layoutDashboardStore/loadMealPlans");
    this.$store.dispatch("layoutDashboardStore/loadProductPlans");
    this.pageContent = 'page description comes here';

    this.pageHeader = 'Hey Creator,';
    this.setHeader(this.headerOptions);
    this.setPageClass();
    this.setNotification();
    Promise.all([this.setWidgetData()])
        .finally(() => {
          this.hideLoading()
        });
    const service = new DashBoardService()
    service.getUserData().then((data) => {
      this.habitsTrackerEnabledByCreator = service.isHabitTrackerEnabled()
    })
    this.hideLoading()
  },
  watch: {
    storeWorkouts: {
      handler: function (val) {
        const index = this.layout.findIndex(l => l.id === 'workouts')
        this.$set(this.layout[index], 'show', true);
        const props = {
          workouts: val,
        }
        this.$set(this.layout[index], 'props', props);
      },
    },
    pageContent: {
      handler: function (val) {
        const index = this.layout.findIndex(l => l.id === 'description')
        this.$set(this.layout[index], 'show', true);
        this.$set(this.layout[index], 'html', val);
      },
    },
    habitsTrackerEnabledByCreator: function () {
      const index = this.layout.findIndex(l => l.id === 'habits-tracker')
      this.$set(this.layout[index], 'show', this.habitsTrackerEnabledByCreator);
    },
    storeMealPlanNotAChallenge: {
      handler: function () {
        const index = this.layout.findIndex(l => l.id === 'meal-plans')
        const props = {
          noneChallengeMealPlans: this.storeMealPlanNotAChallenge,
          challengeMealPlans: this.storeChallengeMealPlans
        }
        const show = this.storeMealPlanNotAChallenge.length > 0
        this.layout[index] = {...this.layout[index], show, props}
        this.$set(this.layout[index], 'show', show);
        this.$set(this.layout[index], 'props', props);
        this.setComponentToVisibility('food-diary', true)
      },
      deep: true

    },
    storeChallengeMealPlans: {
      handler(val) {
        const index = this.layout.findIndex(l => l.id === 'challenge-plans');
        const show = val.length > 0;
        this.$set(this.layout[index], 'show', show);
      },
      deep: true
    },
    notifications: {
      handler() {
        const index = this.layout.findIndex(l => l.id === 'progress-notifications');
        this.$set(this.layout[index], 'props', {
          notifications: this.notifications,
          showNotification: this.showNotification,
          actionButtonsClasses: {'pc-dashboard__action-buttons': true}
        });
        this.$set(this.layout[index], 'show', true);
      },
      deep: true
    }
  },
  data: function () {
    return {
      descriptionSelectionVariationEditable: enableDashboardVariationsDescription(),
      pageContentMock: '<p>Dashboard page description comes here,<br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel egestas dolor, nec dignissim metus. Donec augue elit, rhoncus ac sodales id, porttitor vitae est. Donec laoreet rutrum libero sed pharetra.\n' +
        '\n' +
        ' Donec vel egestas dolor, nec dignissim metus. Donec augue elit, rhoncus ac sodales id, porttitor vitae est. Donec laoreet rutrum libero sed pharetra. Duis a arcu convallis, gravida purus eget, mollis diam.</p>',
      layout: [
         {
          id: 'progress-notifications',
          component: 'ListProgressNotifications',
          props: {},
          show: false,
          type: 'component'
        },
        {
          id: 'evergreen-progress',
          component: 'CardDashboardEvergreenProgressImage',
          props: {},
          show: false,
          type: 'component'
        },

        {
          id: 'workouts',
          component: 'CardListWorkouts',
          props: {
            headerText: 'Workout Programs',
          },
          show: false,
          type: 'component',
        },
        {
          id: 'meal-plans',
          component: 'CardListMealPlans',
          class:'pc-dashboard__card-list-meal-plans',
          props: {
            headerText: 'Meal Plans',
          },
          show: false,
          type: 'component',
        },
        {
          id: 'food-diary',
          component: 'CardFoodDiary',
          props: {},
          show: false,
          type: 'component',
        },
        {
          id: 'my-journey',
          component: 'CardMyJourneyWithInputs',
          props: {},
          show: true,
          type: 'component',
        },
        {
          id: 'mood-tracker',
          component: 'CardMoodTracker',
          props: {},
          show: true,
          type: 'component',
        },
        {
          id: 'habits-tracker',
          component: 'CardHabitsTracker',
          props: {},
          show: false,
          type: 'component',
        },
        {
          id: 'period-tracker',
          component: 'CardPeriodTracker',
          props: {},
          show: false,
          type: 'component',
        },
     {
          id: 'product-pages',
          variations: ['CardListProductPagesAccordion', 'CardListProductPages', 'CardListProductPagesGrid'],
          component: 'CardListProductPages',
          props: {
            headerText: 'Other Programs',
            pages: [
              {
                "image": "//s3-us-west-1.amazonaws.com/s3b-usw-1-alpha/117/2/media-page-95ae74ab96ea448097fd077bbcf36909.jpg",
                "is_default": false,
                "name": "Exercise & Fitness",
                "url": "/page/exercise-fitness"
              }
            ],
          },
          show: true,
          type: 'component',
        },


      ],
      showWidgets: true,
      pageClass: ["macroapp--page-dashboard--with-card", 'pc-dashboard-creator-editor'],
      selectedWidgetArea: {
        id: null,
        show: false
      },
      widgets: [
        {
          id: 'image-featured',
        },
        {
          id: 'description',
        },
        {
          id: 'progress-notifications',
        },
        {
          id: 'evergreen-progress',
        },
        {
          id: 'my-journey',
        },
        {
          id: 'mood-tracker',
        },
        {
          id: 'habits-tracker',
        },
        {
          id: 'period-tracker',
        },
        {
          id: 'food-diary',
        },
        {
          id: 'challenge-plans',
        },
        {
          id: 'meal-plans',
        },
        {
          id: 'workouts',
        },
        {
          id: 'product-pages',
        }
      ],
      toggle: {
        progressNotifications: true
      }
    }
  },
  methods: {
    navigateBack () {
      if (this.editor.state === 'edit') {
        this.delayedRouterPush({
          path: '/dashboard?page=edit&edit=1'
        })
      } else {
        this.delayedRouterPush({
          path: '/dashboard'
        })
      }
    },
    setWidgetData() {
      return this.layoutService.getDashboardData().then(data => {
        this.toningConfig = data.component_data.find(c => c.field === 'config');
        if (this.toningConfig?.data?.value) {
          this.layoutConfig = this.toningConfig.data.value;
          this.selectedHeaderVariationId = this.layoutConfig.header.variation;
          if (Array.isArray(this.layoutConfig.widgets)) {
            this.selectedDescriptionSelectionVariationId =
                this.layoutConfig.widgets.find(w => w.code === 'descriptionSelection')?.variation || 'style1';
          } else {
            this.layoutConfig.widgets = [
              {
                code: 'descriptionSelection',
                variation: 'style1'
              }
            ];
          }
        }
      });
    },
    setComponentToVisibility(id, show = true) {
      const index = this.layout.findIndex(l => l.id === id)
      this.$set(this.layout[index], 'show', show);
    },
    editorButtonClick() {
      if (this.editor.state === 'edit') {
        this.disabled = true
        this.showLoading()
        this.layoutConfig

        this.updateConfigInToning().then(() => {
          this.logs.header()
          this.logs.intro()

          this.disabled = false
          this.hideLoading()
          this.editor.state = (this.editor.state === 'preview') ? 'edit' : 'preview'
        }).catch(err=>{
          console.log(err)
        })
      } else {
        this.editor.state = (this.editor.state === 'preview') ? 'edit' : 'preview'
      }

    },
    async updateConfigInToning() {
      await this.layoutService.setDashboardData({
        data: this.layoutConfig,
        widgetOrder: []
        , field: 'config'
      })
    },
    setNotification() {
      this.notifications = [
        {
          "message": this.$i18n.t(
            'message["notification-workout-update-progress"]'
          ),
          "type": "info",
          "code": "workout-program-update-required",
          "action": {
            "url": "/workout-programs/update",
            "text": "Update Now"
          }
        }, {
          "message": this.$i18n.t(
            'message["notification-meal-plan-update-progress"]'
          ),
          "type": "info",
          "code": "meal-plan-update-required",
          "action": {
            "url": "/meal-plans/update",
            "text": "Update Now"
          }
        }
      ]
      this.showNotification = true
      this.loaded.notification = true;
      this.$store.commit("setNotificationCount", 3);
      this.$store.commit("setNotificationsList", [
        {
          "message": "You have not completed sign up - We cannot prepare you plans until this is complete.",
          "type": "danger",
          "code": "subscription-incomplete",
          "action": {
            "url": "https:\/\/nz-alpha-customer.alpha-macro.com\/subscriptions\/7880\/settings",
            "text": "Complete Now"
          }
        }
      ]);

    },

  }

}
</script>
