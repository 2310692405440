<template>
  <div>
    <div class="pc-page-workout-program-skeleton">
      <div class="pc-workout-program__container__top">
        <div class="gc-card-image-cover pc-workout-program__cover-image">
          <AnimatedPlaceholder width="320px" height="27px" />
        </div>
        <div class="pc-workout-program__sliders pc-skeleton-flex-row">
          <AnimatedPlaceholder width="110px" height="27px" />
          <AnimatedPlaceholder width="110px" height="27px" />
        </div>
        <div
          class="pc-skeleton-flex-row gc-slider-navigation pc-workout-program__slider-navigation-days"
        >
          <AnimatedPlaceholder
            width="76px"
            height="27px"
            border-radius="200px"
          />
          <AnimatedPlaceholder
            width="76px"
            height="27px"
            border-radius="200px"
          />
          <AnimatedPlaceholder
            width="76px"
            height="27px"
            border-radius="200px"
          />
        </div>
      </div>

      <div class="pc-workout-program__container">
        <div class="container">
          <div class="pc-workout-program__header-day">
            <AnimatedPlaceholder width="70px" />
          </div>

          <div class="card-list">
            <div class="card-list-grid-left">
              <!-- Repeat gc-card-skeleton 4 times -->
              <div
                class="gc-card-skeleton gc-card-exercise-list-group__card-wrapper gc-card-exercise-overview"
                v-for="index in 4"
                :key="index"
              >
                <!-- <div
                class="gc-card-skeleton gc-card-exercise-list-group__card-wrapper gc-card-exercise-overview"
              > -->
                <div class="gc-card-skeleton-info">
                  <AnimatedPlaceholder width="50px" />
                </div>
                <div class="gc-card-exercise-overview__card-container">
                  <div class="gc-card-skeleton-info">
                    <AnimatedPlaceholder width="270px" height="27px" />
                  </div>
                  <div class="gc-card-skeleton-info">
                    <AnimatedPlaceholder width="50px" height="12px" />
                  </div>
                  <div class="gc-card-skeleton-info"></div>
                  <div class="gc-card-skeleton-info">
                    <AnimatedPlaceholder width="60px" height="12px" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-list-grid-right">
              <!-- Repeat gc-card-skeleton 4 times -->
              <div
                class="gc-card-skeleton gc-card-exercise-list-group__card-wrapper gc-card-exercise-overview"
                v-for="index in 4"
                :key="index"
              >
                <!-- <div
                class="gc-card-skeleton gc-card-exercise-list-group__card-wrapper gc-card-exercise-overview"
              > -->
                <div class="gc-card-skeleton-info">
                  <AnimatedPlaceholder width="50px" />
                </div>
                <div class="gc-card-exercise-overview__card-container">
                  <div class="gc-card-skeleton-info">
                    <AnimatedPlaceholder width="270px" height="27px" />
                  </div>
                  <div class="gc-card-skeleton-info">
                    <AnimatedPlaceholder width="50px" height="12px" />
                  </div>
                  <div class="gc-card-skeleton-info"></div>
                  <div class="gc-card-skeleton-info">
                    <AnimatedPlaceholder width="60px" height="12px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "@/components/global/animators/AnimatedPlaceholder.vue";

// import AnimatedPlaceHolder from "@/components/global/animators/AnimatedPlaceHolder";
export default {
  components: { AnimatedPlaceholder },
};
</script>

<style lang="scss" scoped></style>
