import DefineInclude from '../../../DefineInclude'
import Slideout from 'vue-slideout'
import MealPlanService from '../../../services/MealPlanService'
import DashboardService from '../../../services/DashboardService'
import AuthService from '../../../services/AuthService'
import ContentPagePopup from '../../../components/global/popups/PopupContentPage'
import {checkNativeAppVersion, isMobileNativeApp} from '../../../includes/NativeAppCommon'
import NativeAppMixin from '../../NativeAppMixin'
import {
  enableDashboardVariations,
  flagValue,
  isMyJourneyEnabled,
  showBottomNavigationBar
} from '../../../includes/TemplateSettings'
import WorkoutProgramsService from '../../../services/WorkoutProgramsService'
import UserEventsService from '../../../services/UserEventsService'
import moment from 'moment'
import CommunityService from '../../../services/community/CommunityService'
import {checkIsMember} from '../../../helpers/user'

export default {
  mixins: [NativeAppMixin],
  components: {
    Slideout,
    ContentPagePopup
  },
  created() {
    this.nonse=Math.random().toString(36).substring(7)
  },
  data: function () {
    return {
      nonse:'333',
      logButtonActive: false,
      features: {
        hasNew: false,
        isInviteAFriendVisited: true,
        isCommunityVisited: true
      },
      hasInviteAFriend: false,
      charLimitedUserName: '',
      contentPageTypes: ['custom-page-text', 'custom-page-list', 'custom-page-video', 'custom-page-external'],
      showPrivacyPolicyPopup: false,
      showTermsCondPopup: false,
      year: '',
      appVersion: DefineInclude.app.version,
      clicked: false,
      showingMenu: false,
      closingMenu: false,
      userName: '',
      fitnessDiaryEnabled: false,
      profileImage: DefineInclude.imagesUrl + 'default-profile.png',
      imagesUrl: DefineInclude.imagesUrl,
      mealPlans: [],
      workoutPlans: [],
      showParent: true,
      subMenuType: '',
      navLinksList: [],
      scrollPosition: 0,
      showWorkoutLogLink: false,
      curruntRoute: '',
      communitiesLinks: []
    }
  },
  watch: {
    dashboardService: {
      handler: function () {
        this.showWorkoutLog()
        this.loadCommunities()
      },
      deep: true
    }
  },
  computed: {

    isMember: function () {
      return checkIsMember()
    },
    customLinksList: function () {
      return this.navLinksList.filter(element => this.contentPageTypes.includes(element.type))
    },
    systemLinksList: function () {
      let links = this.navLinksList.filter(element => element.type === 'system-page')
      let newLinks = []
      links.forEach(link => {
        link = Object.assign(link, {isNew: false})
        if (link.code === 'progress') {
          newLinks.push(link)
          // hide the progress if need to hide it
        } else if (link.code === 'meal-plans') {
          this.mealPlans.forEach(mealplan => {
            newLinks.push({
              label: mealplan.name,
              url: '/meal-plans/' + mealplan.id,
              code: 'meal-plans-' + mealplan.id,
              type: 'system-page'
            })
          })
        } else if (link.code === 'progress' && !(flagValue('show_fitness_diary') === null || flagValue('show_fitness_diary'))) {
          // hide fitness diary
        } else if (link.code === 'workout-programs') {
          if (this.workoutPlans.length > 0) {
            newLinks.push(link)
          }
        } else if (link.code === 'invitations') {
          link.isNew = !this.features.isInviteAFriendVisited
          this.hasInviteAFriend = true
          newLinks.push(link)
        } else {
          newLinks.push(link)
        }
      })
      return newLinks
    },
    navLinks: function () {
      let links = []
      let dashBoardService = new DashboardService()
      const challengeProductPages = dashBoardService.getChallengeProductPages()
      this.navLinksList.forEach((link) => {
        link = Object.assign(link, {isNew: false})
        if (link.url === '/dashboard') {
          links.push(link)
          if (this.isDesktop || !showBottomNavigationBar() || dashBoardService.hasChatEnabled() ||!(dashBoardService.hasActiveMealPlans() || dashBoardService.hasActiveWorkoutPrograms() )  ) {
            this.communitiesLinks.forEach(community => {
                links.push({
                  label: community.name,
                  url: '/dashboard?community=' + community.id,
                  code: community.id,
                  type: 'system-page',
                  isNew: !this.features.isCommunityVisited
                })
              }
            )
          }
        } else if (link.code === 'progress') {
          links.push(link)
          // hide the progress if need to hide it
        } else if (link.code === 'meal-plans') {
          this.mealPlans.forEach(mealplan => {
            links.push({
              label: mealplan.name,
              url: '/meal-plans/' + mealplan.id,
              code: 'meal-plans-' + mealplan.id,
              type: 'system-page'
            })
          })
        } else if (link.code === 'progress' && (!(flagValue('show_fitness_diary') === null || flagValue('show_fitness_diary')) || !isMyJourneyEnabled())) {
          // hide fitness diary
        } else if (link.code === 'workout-programs') {
          if (this.workoutPlans.length > 0) {
            links.push(link)
          }
        } else if (link.code === 'invitations') {
          if (dashBoardService.hasActivePlan()) {
            link.isNew = !this.features.isInviteAFriendVisited
            this.hasInviteAFriend = true
            links.push(link)
          }
        } else if (this.contentPageTypes.includes(link.type)) {
          const isInactive = challengeProductPages.some(page => {
            return (page.pricing_plan_type === 'challenge' &&
              link.url === page.url &&
              moment.unix(page.activate_at).isAfter(moment()))
          })
          if (!isInactive) {
            links.push(link)
          }
        } else {
          links.push(link)
        }
      })
      return links
    }
  },
  beforeMount () {
    this.showDashboardEditorLink = enableDashboardVariations()
    let date = new Date()
    this.year = date.getFullYear()

    let service = new DashboardService()
    service.getNavigation().then(data => {
      this.navLinksList = data.links
    }).catch(e=>console.log(e))
    this.showWorkoutLog()
  },
  mounted () {
    if (this.$children[0] && this.$children[0].slideout) {
      this.$children[0].slideout.close()
    }
    if (checkIsMember()) {
      let mealPlanService = new MealPlanService()
      mealPlanService.getActiveMealPlans().then(data => {
        this.mealPlans = data.meal_plans
      })
      let workoutService = new WorkoutProgramsService()
      workoutService.setWorkoutProgramList().then(data => {
        this.workoutPlans = workoutService.getAllWorkoutPrograms()
      })
      this.checkNewFeatures()
      const service = new DashboardService()
      service.getUserConfig().then(() => {
        this.showWorkoutLog()
        this.loadCommunities()
      })
    }
  },
  updated () {
    this.getUserData()
  },
  methods: {
    loadCommunities () {
      const dashBoardService = new DashboardService()
      if (!dashBoardService.isCommunityEnabled()) {
        if (checkIsMember()) {
          UserEventsService.trackRegisterBeforeCommunities()
        }
        return
      }
      let community = new CommunityService()
      community.getCommunities().then(async () => {
        this.communitiesLinks = await community.getActiveCommunities()
        if (checkIsMember() && await community.getActiveCommunities() === 0) {
          await UserEventsService.trackRegisterBeforeCommunities()
        } else if (checkIsMember() && dashBoardService.hasActivePlan()) {
          UserEventsService.checkRegisterBeforeCommunities().then(registered => {
            if (registered || moment('2023-11-14').diff(dashBoardService.getRegisteredDate(), 'days') > 0) {
              const allCommunity = community.getActiveCommunities()[0]
              if (!allCommunity || !allCommunity.id) {
                return
              }
              UserEventsService.communityFeaturePopupView('/dashboard?community=' + allCommunity.id)
              UserEventsService.checkCommunityPageVisit(allCommunity.id).then(visit => {
                this.features.hasNew = this.features.hasNew || !visit
                this.features.isCommunityVisited = visit
              })
            }
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkNewFeatures () {
      if (!checkIsMember()) {
        return
      }
      UserEventsService.checkInviteAFriendPageVisit().then(visit => {
        let dashBoardService = new DashboardService()
        if (dashBoardService.isInviteAFriendEnabled() && moment('2022-08-02').diff(dashBoardService.getRegisteredDate(), 'days') > 0) {
          this.features.hasNew = this.features.hasNew || !visit
          this.features.isInviteAFriendVisited = visit
        }
      }).catch(() => {
        // ignore
      })
    },
    onMenuOpens () {
      this.getUserData()
      this.showingMenu = true
      this.settingCurruntRoute()
      this.setUserNameCharLimit()
    },
    getUserData () {
      let service = new DashboardService()
      service.getUserData().then(data => {
        this.userName = data.first_name || ''
        this.profileImage = data.image || DefineInclude.imagesUrl + 'default-profile.png'
        this.fitnessDiaryEnabled = data.subscriptions.some(subscription => {
          return subscription.status === 'active' || subscription.status === 'pending-cancellation'
        })
        this.setUserNameCharLimit()
      })
    },
    setUserNameCharLimit () {
      let username = this.userName
      if (username.length > 8) {
        username = username.substring(0, 8)
        this.charLimitedUserName = username + '...!'
      } else {
        this.charLimitedUserName = username + '!'
      }
    },
    settingCurruntRoute () {
      this.curruntRoute = this.$route.path
    },
    setScroll () {
      this.scrollPosition = document.getElementById('menu').scrollTop
    },
    navigate (path) {
      if (path !== this.$router.currentRoute.fullPath) {
        if (path.includes('/progress')) {
          this.logEvent('Journey.ViewedHome', {context: 'side_menu'}, false)
        }
        // holding router so slick dom breaking is not visible before fade effect
        this.$router.push({
          path: path
        })
      }

      this.logButtonActive = false
    },
    showLinkText (text) {
      if (text === 'Progress') {
        return this.$i18n.t('message["fitness-diary.home-title"]')
      }
      return text
    },
    /**
     * navigate link by object
     * @param link
     */
    navigateLink (link) {
      if (link.type === 'custom-page-external') {
        this.handleUrlNavigate(link.url)
      } else {
        this.navigate(link.url)
      }
    },
    navigateToPage (link) {
      if (link.includes('/page/')) {
        this.navigate(link)
      } else {
        this.navigate('/page' + link)
      }
    },
    navigateToExternalPage (link) {
      window.open(link)
    },
    openExternalURL (url) {
      if (isMobileNativeApp() && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage({
          name: 'openInAppBrowserUrl',
          args: {
            url: url
          }
        })
      } else {
        window.open(url)
      }
    },
    showSub (type) {
      this.subMenuType = type
      this.showParent = false
    },
    imageLoadOnError () {
      this.profileImage = DefineInclude.imagesUrl + 'default-profile.png'
    },
    logout () {
      this.showLoading()
      let auth = new AuthService()
      auth.logOut().then(() => {
        if (checkNativeAppVersion()) {
          auth.mobileLogout()
            .finally(res => {
              try {
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    command: {
                      name: 'logout'
                    }
                  })
                )
              } catch (e) {
              }
              window.location.href = '/login'
            })
        }
      }).catch(() => {
        window.location.href = '/logout'
      })
    },
    closeMenu () {
      if (this.$children[0].slideout) {
        this.$children[0].slideout.close()
      }
      document.querySelectorAll('.gc-sidebar__bg').forEach((element) => {
        element.style.display = 'none'
      })
    },
    closedMenu () {
      this.showParent = true
      this.showingMenu = false
      this.closingMenu = true
      setTimeout(() => {
        this.closingMenu = false
      }, 500)
    },
    closePrivacyPolicyPopup () {
      this.showPrivacyPolicyPopup = false
    },
    closeTermsAndConditionsPopup () {
      this.showTermsCondPopup = false
    },
    getTrainerIDInfo () {
      return this.$trainer
    },
    openPrivacyPolicyPopup () {
      this.showPrivacyPolicyPopup = true
    },
    openTermsAndConditionsPopup () {
      this.showTermsCondPopup = true
    },
    showWorkoutLog () {
      let dashBoardService = new DashboardService()
      this.showWorkoutLogLink = dashBoardService.isWorkoutLogEnabled()

    }
  },
  beforeDestroy () {
    if (this.$children.length > 0 && this.$children[0].slideout) {
      this.$children[0].slideout.destroy()
    }
  }
}
