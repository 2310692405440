<template>
  <div :class="{'pc-container-style-editor':editable && mode==='edit'}" v-on="$listeners"
       :style="{
        position: (selected)?'relative':'inherit'
  }">

    <slot></slot>
    <div class="pc-container-style-editor-actions" v-if="editable && mode==='edit' && selected" >
      <icon-up-arrow-filled
        class="pc-container-style-editor-actions__back"
        :class="{
        'pc-container-style-editor-actions__back--disabled': selectedVariationIndex === 0
      }"
                            @click="back"></icon-up-arrow-filled>
      <text-body-small
        weight="extra-bold"
        class="pc-container-style-editor-actions__title"
      >{{ selectedVariation.text }}
      </text-body-small>
      <icon-up-arrow-filled
        class="pc-container-style-editor-actions__next"
        :class="{
        'pc-container-style-editor-actions__next--disabled': selectedVariationIndex === variations.length - 1
        }"
        @click="next"></icon-up-arrow-filled>

    </div>
    <icon-drag v-if="editable && mode === 'edit' && !selected" :class="header ? 'pc-container-style-editor__header-drag-icon' : 'pc-container-style-editor__drag-icon'"/>
    <div class="pc-container-style-editor__section-cover"  v-if="false">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33333 9.66668C4.0697 9.66668 4.66667 10.2636 4.66667 11C4.66667 11.7364 4.0697 12.3333 3.33333 12.3333C2.59695 12.3333 2 11.7364 2 11C2 10.2636 2.59695 9.66668 3.33333 9.66668ZM9.33333 11C9.33333 10.2636 8.7364 9.66668 8 9.66668C7.26367 9.66668 6.66667 10.2636 6.66667 11C6.66667 11.7364 7.26367 12.3333 8 12.3333C8.7364 12.3333 9.33333 11.7364 9.33333 11ZM14 11C14 10.2636 13.403 9.66668 12.6667 9.66668C11.9303 9.66668 11.3333 10.2636 11.3333 11C11.3333 11.7364 11.9303 12.3333 12.6667 12.3333C13.403 12.3333 14 11.7364 14 11Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33333 3.66668C4.0697 3.66668 4.66667 4.26361 4.66667 5.00001C4.66667 5.73641 4.0697 6.33334 3.33333 6.33334C2.59695 6.33334 2 5.73641 2 5.00001C2 4.26361 2.59695 3.66668 3.33333 3.66668ZM9.33333 5.00001C9.33333 4.26361 8.7364 3.66668 8 3.66668C7.26367 3.66668 6.66667 4.26361 6.66667 5.00001C6.66667 5.73641 7.26367 6.33334 8 6.33334C8.7364 6.33334 9.33333 5.73641 9.33333 5.00001ZM14 5.00001C14 4.26361 13.403 3.66668 12.6667 3.66668C11.9303 3.66668 11.3333 4.26361 11.3333 5.00001C11.3333 5.73641 11.9303 6.33334 12.6667 6.33334C13.403 6.33334 14 5.73641 14 5.00001Z" fill="white"/>
      </svg>

    </div>
  </div>

</template>

<script>
import IconUpArrowFilled from "@/components/root/icons/IconUpArrowFilled.vue";
import TextBodySmall from "@/components/global/typography/TextBodySmall.vue";
import IconDrag from "@/components/root/icons/IconDrag.vue";

export default {
  name: "ContainerStyleEditor",
  components: {IconDrag, TextBodySmall, IconUpArrowFilled},
  props: {
    mode:{
      default: 'preview',
      type: String
    },
    selected:{
      default: false
    },
    editable:{
      default: true
    },
    variations: {
      default: [],
      type: Array
    },
    value: {
      default: null,
      type: String
    },
    header: {
      default: false
    }
  },

  data: function () {
    return {
      selectedVariationIndex : 0,
    }
  },
  computed:{
    selectedVariation(){
      return this.variations[this.selectedVariationIndex]
    }
  },
  watch:{
    value:{
      immediate:true,
      handler(){
        this.selectedVariationIndex = this.variations.findIndex(obj=>obj.value === this.value)
      }
    },
    selectedVariationIndex(){
      this.$emit('input', this.selectedVariation.value)
    }
  },
  methods:{
    back(){
      if(this.selectedVariationIndex > 0){
        this.selectedVariationIndex--
      }
    },
    next(){
      if(this.selectedVariationIndex < this.variations.length - 1){
        this.selectedVariationIndex++
      }
    }
  }
}
</script>


<style scoped>

</style>
