import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '@/DefineInclude'

export default class extends BaseAPI {
  _resource ='dashboard'

  getUserData () {
    return axios.get(this._baseURL + 'me')
  }

  getConfig () {
    return axios.get(this._baseURL + 'me/config')
  }

  getTrainerConfig () {
    return axios.get(this._baseURL + 'trainer/me')
  }

  getBillingInformation () {
    return axios.get(this._baseURL + 'me/billing-information')
  }

  getInvitationPageData () {
    return axios.get(this._baseURL + 'me/invitations')
  }

  getInvitations () {
    return axios.get(this._baseURL + 'me/invitations/users')
  }

  getAnnounceConfig () {
    return axios.get(DefineInclude.announceKit + 'integration-data?location=creator-app')
  }
}
