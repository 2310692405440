<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_29448_16940)">
        <path
          d="M15.7449 12.3555L9.62933 1.76295C9.28927 1.17395 8.68018 0.822266 7.99999 0.822266C7.31984 0.822266 6.71071 1.17395 6.37065 1.76295L0.25507 12.3555C-0.0850233 12.9445 -0.0850233 13.6478 0.25507 14.2369C0.595163 14.8259 1.20422 15.1776 1.88441 15.1776H14.1156C14.7957 15.1776 15.4048 14.8259 15.7449 14.2369C16.085 13.6478 16.085 12.9445 15.7449 12.3555ZM14.9331 13.7682C14.7625 14.0638 14.4569 14.2402 14.1156 14.2402H1.88441C1.5431 14.2402 1.23747 14.0638 1.06685 13.7682C0.896225 13.4726 0.896225 13.1198 1.06685 12.8242L7.18249 2.23167C7.35312 1.93611 7.65874 1.75967 8.00002 1.75967C8.34127 1.75967 8.64693 1.93611 8.81755 2.23167L14.9332 12.8242C15.1038 13.1198 15.1038 13.4726 14.9331 13.7682Z"
          fill="#9645E8"/>
        <path d="M8.46862 5.5H7.53125V10.1869H8.46862V5.5Z" fill="#9645E8"/>
        <path
          d="M7.99994 11.125C7.65534 11.125 7.375 11.4053 7.375 11.7499C7.375 12.0945 7.65534 12.3749 7.99994 12.3749C8.3445 12.3749 8.62487 12.0945 8.62487 11.7499C8.62487 11.4053 8.34453 11.125 7.99994 11.125Z"
          fill="#9645E8"/>
      </g>
      <defs>
        <clipPath id="clip0_29448_16940">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>


  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconWarning',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-warning': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
