<template>
  <div :class="componentClasses" :style="componentStyles" @click="emitClick()">
    <header-simple :header-text="headerText" :icon="'forward'" />
    <!-- Background Blur Div -->
    <div v-if="image" class="gc-image-background-blur hidden-mobile">
      <!-- <basicImage></basicImage> -->
      <ImageBase
        v-if="image"
        :src="image"
        alt="Feature Image"
        class="gc-first-image"
      />
    </div>
    <div v-if="image" class="gc-image-background-solid hidden-mobile">
      <ImageBase
        v-if="image"
        :src="image"
        alt="Feature Image"
        class="gc-second-image"
      />
    </div>
  </div>
</template>

<script>
import TextContent from "../../root/TextContent";
import HeaderSimple from "../headers/HeaderSimple";
import ImageBase from "@/components/root/Image";

export default {
  name: "CardNavigation",
  components: {
    HeaderSimple,
    TextContent,
    ImageBase,
  },
  props: {
    headerText: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    componentClasses: function () {
      return {
        "gc-card-navigation": true,
      };
    },
    componentStyles: function () {
      return {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("${this.image}"), #000000`,
      };
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped></style>
