<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
  extends: Line,
  mixins: [reactiveProp],
  props: [ 'tooltip', 'uom' ],
  data: function () {
    return {
      options: {
        layout: {
          padding: {
            top: 10,
            right: 0,
            left: -10
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        legend: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              align: 'end',
              display: true,
              gridLines: {
                tickMarkLength: false,
                drawBorder: false,
                display: true,
                lineWidth: 1,
                zeroLineColor: templateConfig.style_variables['$text-area-bg-outline'],
                color: templateConfig.style_variables['$text-area-bg-outline']
              },
              ticks: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 700,
                padding: 10,
                fontColor: templateConfig.style_variables['$text-secondary-on-bg'],
                lineHeight: 1.5,
                maxRotation: 0,
                minRotation: 0
                // maxTicksLimit: 100
              },
              scaleLabel: {
                display: true
                // labelString: "Week", // hide more details
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                drawBorder: false,
                // tickMarkLength: false,
                display: true,
                lineWidth: 1,
                zeroLineColor: templateConfig.style_variables['$text-area-bg-outline'],
                color: templateConfig.style_variables['$text-area-bg-outline']
              },
              position: 'left',
              ticks: {
                maxTicksLimit: 4,
                display: false,
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 700,
                fontColor: templateConfig.style_variables['$text-secondary-on-bg'],
                beginAtZero: false
                // stepSize: 1
              },
              scaleLabel: {
                display: true
                // labelString: "Data",// hide more details
              }
            }
          ]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, Object.assign(this.options, {
      tooltips: {
        enabled: false,
        mode: 'index',
        position: 'nearest',
        custom: this.customTooltips
      },
      animation: {
        onComplete: (e) => {
          this.$emit('axisDataRendered', {
            y: e.chart.scales['y-axis-0']
          })
        }
      }
    }))
  },
  beforeDestroy () {
    this.$data._chart.destroy()
    let tooltipEl = document.getElementById('chartjs-tooltip')
    if (tooltipEl) {
      tooltipEl.remove()
    }
  },
  methods: {
    customTooltips (tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip')

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        tooltipEl.innerHTML = '<table></table>'
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      // Set Text
      if (tooltipModel.body) {
        const dataPoints = tooltipModel.dataPoints || []

        let innerHtml = '<thead>'

        if (this.isRTL()) {
          dataPoints.forEach((dataPoint) => {
            innerHtml += '<tr>' +
            '<th>' +
            '<div class="card-graph__head-section">' +
            '<p class="card-graph__title"><span>' + this.uom + '</span>' + Number.parseFloat(dataPoint.value).toFixed(1) + '</p>' +
            '<p class="card-graph__sub-title">' + this.tooltip.labels[dataPoint.index] + '</p></div>' +
            '</th>' +
            '</tr>'
          })
        } else {
          dataPoints.forEach((dataPoint) => {
            innerHtml += '<tr>' +
            '<th>' +
            '<div class="card-graph__head-section">' +
            '<p class="card-graph__title">' + Number.parseFloat(dataPoint.value).toFixed(1) + '<span>' + this.uom + '</span></p>' +
            '<p class="card-graph__sub-title">' + this.tooltip.labels[dataPoint.index] + '</p></div>' +
            '</th>' +
            '</tr>'
          })
        }

        const tableRoot = tooltipEl.querySelector('table')
        tableRoot.innerHTML = innerHtml
      }

      // `this` will be the overall tooltip
      const position = this.$data._chart.canvas.getBoundingClientRect()

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1
      tooltipEl.style.left = position.left + tooltipModel.caretX + 'px'
      tooltipEl.style.top = position.top + tooltipModel.caretY + 'px'
      tooltipEl.style.fontFamily = tooltipModel._fontFamily
      tooltipEl.style.fontSize = tooltipModel.fontSize
      tooltipEl.style.fontStyle = tooltipModel._fontStyle
      tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
    }
  }
}
</script>
