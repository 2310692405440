var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-secondary',{staticClass:"pc-dashboard pc-dashboard--new-layout",attrs:{"header":_vm.headerOptions}},[_c('page-container-fluid',{attrs:{"breakpoint":"lg","reverse":true}},[_c('grid-row',{attrs:{"no-gutters":""}},[_c('grid-col',{attrs:{"lg":"12"}},[_c('image-featured',{attrs:{"has-notifications":_vm.notificationBarShow,"page-header":_vm.pageHeader}})],1),_c('page-container',{staticClass:"pc-dashboard__main-content-area"},[(_vm.pageContent)?_c('div',{staticClass:"pc-dashboard__text"},[_c('text-body-extra-small',{staticClass:"pc-dashboard__text-description",attrs:{"line-height":'multi'},domProps:{"innerHTML":_vm._s(_vm.pageContent)}})],1):_vm._e(),_c('grid-row',{class:_vm.actionButtonsClasses},_vm._l((_vm.notifications),function(notification,id){return (
              _vm.showNotification &&
              !notification.action.url.includes(
                'api/notifications/subscription/'
              )
            )?_c('grid-col',{key:'dashboard-notification' + id,attrs:{"lg":_vm.notifications.length === 1 ? 12 : 6}},[_c('button-progress-update',{attrs:{"text":notification.message,"action":notification.action}})],1):_vm._e()}),1),(
            !_vm.showBottomBar &&
            (_vm.hasSubscriptionAndOneOffWorkoutPrograms || _vm.workouts.length > 0)
          )?_c('card-list-workouts',{staticClass:"pc-dashboard__workouts-list",attrs:{"widget-type":'card',"workouts":_vm.workouts}}):_vm._e(),_c('card-list-challenge-plans',{attrs:{"workoutWidgetType":'card',"workouts":_vm.challengeWorkoutPlans,"meal-plans":_vm.challengeMealPlans,"plan-pages":_vm.challengeProductPages}}),(
            !_vm.showBottomBar &&
            (_vm.hasSubscriptionAndOneOffMealPlans ||
              _vm.mealPlanNotAChallenge.length > 0)
          )?_c('card-list-meal-plans',{staticClass:"pc-dashboard__nutrition-card-list",attrs:{"none-challenge-meal-plans":_vm.mealPlanNotAChallenge,"challenge-meal-plans":_vm.challengeMealPlans}}):_vm._e(),(
            _vm.showBottomBar ||
            (_vm.mealPlanNotAChallenge.length === 0 &&
              _vm.challengeMealPlans.length > 0)
          )?_c('card-food-diary'):_vm._e(),_c('grid-row',[_c('grid-col',{attrs:{"cols":12}},[(
                _vm.evergreenConfig.available &&
                !_vm.isEvergreenVisited
              )?_c('card-dashboard-evergreen-progress-image',{attrs:{"configs":_vm.evergreenConfig},on:{"dismissCard":function($event){_vm.isEvergreenVisited = true}}}):_vm._e()],1)],1),(_vm.fitnessDiaryEnabled && _vm.isMyJourneyWidgetEnabled)?_c('card-my-journey-with-inputs'):_vm._e(),_c('grid-row',[(_vm.moodTrackerEnabled)?_c('grid-col',{attrs:{"cols":12}},[(_vm.moodTrackerEnabled)?_c('card-mood-tracker'):_vm._e()],1):_vm._e(),(_vm.habitsTrackerEnabled)?_c('grid-col',{attrs:{"cols":12}},[(_vm.habitsTrackerEnabled)?_c('card-habits-tracker'):_vm._e()],1):_vm._e(),(_vm.periodTrackerEnabledByFeatureFlag && _vm.periodTrackerEnabled)?_c('grid-col',{attrs:{"cols":12}},[(_vm.periodTrackerEnabledByFeatureFlag && _vm.periodTrackerEnabled)?_c('card-period-tracker'):_vm._e()],1):_vm._e()],1),(_vm.productPlans.pages.length > 0)?_c('card-list-product-pages',{staticClass:"pc-dashboard__product-pages-list",attrs:{"header-text":_vm.productPlans.header,"pages":_vm.productPlans.pages}}):_vm._e()],1)],1)],1),_c('notification-popup-handler'),(_vm.showChatWidget)?_c('container-chat',{staticClass:"pc-dashboard__container-chat"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }