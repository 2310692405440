<template>
  <div>
    <transition name="fade" mode="out-in">
      <component class="pc-progress"
                 v-bind:is="dynamicComponent"
                 v-bind="{...componentData,'pageConfig': getPageConfigByName(this.page) }"
                 v-on:submit="formSubmit($event)"
                 v-on:back="delayedGoBack"></component>
    </transition>
  </div>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import ThankYou from './PageThankYou'
import Start from './PageProgressStart'
import PageBodyMeasurements from './PageBodyMeasurements'
import MealPlanMain from './MealPlanMain'
import WorkoutMain from './WorkoutMain'
import BodyFat from '../../../global/pages/PageBodyFat'
import ProgressCheckInService from '../../../../services/ui/forms/ProgressCheckInService'
import Weight from '../../../global/pages/PageWeight'
import {mapGetters, mapMutations, mapState} from 'vuex'
import FitnessDiaryStore from '../../../../store/modules/FitnessDiaryStore'
import AddPhotos from '../../../global/pages/PageAddPhotos'
import lodash from 'lodash'
import moment from 'moment'
import ComparePhotos from '../../../global/forms/FormComparePhotos'
import ProcessData from './ProcessData'
import {
  flagValue,
  isPhotoUploadEnabled,
  isMeasurementsEnabled,
  enableSmartCheckingForActivityLvl
} from '../../../../includes/TemplateSettings'
import ProgressFeedbackService from '../../../../services/ProgressFeedbackService'
import DashBoardService from '../../../../services/DashboardService'
import PageConfirmation from '../../../global/pages/PageConfirmation'

export default {
  name: 'Main',
  extends: BaseComponent,
  components: {},
  data: function () {
    return {
      components: {
        'progress-start': {
          component: {design1: Start},
          querySection: 'start',
          options: {},
          hideHeader: true,
          init: function (service) {
            return new Promise(resolve => {
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            if (data.progressType === 'fitness') {
              resolve({component: WorkoutMain, query: {page: 'fitness'}})
            }
            resolve({component: Weight, query: {page: 'weight'}})
          }
        },
        'progress-thank-you': {
          component: {design1: ThankYou},
          querySection: 'thank-you',
          options: {},
          hideHeader: true,
          init: function (service) {
            return new Promise(resolve => {
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, data) {
            resolve({component: Start, query: {page: 'start'}})
          }
        },
        'progress-measurements': {
          component: {design1: PageBodyMeasurements},
          querySection: 'measurements',
          options: {},
          init: function (service) {
            return new Promise((resolve, reject) => {
              if (isMeasurementsEnabled()) {
                setTimeout(() => {
                  resolve()
                }, 100)
              } else {
                reject(new Error('measurements disabled'))
              }
            })
          },
          getNextComponent: (resolve, reject, data) => {
            resolve({component: AddPhotos, query: {page: 'add-photos'}})
          }
        },
        'process-data': {
          component: {design1: ProcessData},
          querySection: 'process',
          options: {},
          init: function (service) {
            return new Promise((resolve) => {
              resolve()
            })
          },
          getNextComponent: (resolve, reject, data) => {
            resolve({component: ThankYou, query: {page: 'thank-you'}})
          }
        },
        'progress-add-photos': {
          component: {design1: AddPhotos},
          querySection: 'add-photos',
          options: {},
          init: (service) => {
            return new Promise((resolve, reject) => {
              if (!isPhotoUploadEnabled()) {
                reject(new Error('photo upload disabled'))
                return
              }
              let dashBoardService = new DashBoardService()
              let imageDates = this.getDiaryImageDates(true)
              let navigationLinks = dashBoardService.getNavigation()
              let userData = dashBoardService.getUserData()
              Promise.all([imageDates, navigationLinks, userData]).then((values) => {
                const dates = values[0]
                const progressEnabled = dashBoardService.isFitnessDiaryEditable()

                if (!progressEnabled) {
                  reject(Error('Progress not enabled'))
                }
                this.logEvent('ProgressUpdate.addPhotos.imageDatesResponse', dates, false)
                let latestDate = dates.latestDate
                // no photos uploaded yet
                if (!latestDate) {
                  this.logEvent('ProgressUpdate.addPhotos.Show', {
                    message: 'No photos uploaded yet',
                    payload: dates
                  }, false)
                  resolve()
                }
                // calculations done in utc
                let latestDateObj = moment(latestDate)
                let currentUtcObj = moment(moment.utc().format('YYYY-MM-DD'))

                if (latestDateObj.isSame(currentUtcObj, 'day')) {
                  reject(Error('Photo already uploaded for today'))
                } else {
                  this.logEvent('ProgressUpdate.addPhotos.Show', {
                    message: 'Range check match',
                    payload: dates
                  }, false)
                  resolve()
                }
              }).catch((error) => {
                reject(error)
              })
            })
          },
          getNextComponent: (resolve, reject, data) => {
            resolve({component: ComparePhotos, query: {page: 'compare-photos'}})
          }
        },
        'progress-compare-photos': {
          component: {design1: ComparePhotos},
          querySection: 'compare-photos',
          options: {},
          init: (service) => {
            return new Promise((resolve, reject) => {
              if (!flagValue('enable_progress_photo_diary_feedback', false)) {
                reject(Error('No enabled'))
                return
              }
              this.getDiaryImageDates(true)
                .then((dates) => {
                  this.logEvent('ProgressUpdate.comparePhotos.imageDatesResponse', dates, false)
                  let firstDate = dates.firstDate
                  let latestDate = dates.latestDate
                  if (!(firstDate && latestDate)) {
                    reject(Error('No enough photos to compare'))
                  }
                  // calculations done in utc
                  let firstDateObj = moment(firstDate)
                  let latestDateObj = moment(latestDate)
                  let currentUtcObj = moment(moment.utc().format('YYYY-MM-DD'))
                  if (
                    latestDateObj.isSame(currentUtcObj, 'day') &&
                    firstDateObj.isSameOrBefore(currentUtcObj.subtract(30, 'days'), 'day')
                  ) {
                    this.showLoading()
                    // checking last feedback
                    let progressFeedbackService = new ProgressFeedbackService()
                    progressFeedbackService.getLatestFeedbackAt()
                      .then((response) => {
                        this.logEvent('ProgressUpdate.comparePhotos.latestFeedbackResponse', response.data, false)
                        let lastFeedbackAt = response.data.latest_feedback_at
                        let lastFeedbackAtObj = null
                        if (lastFeedbackAt) {
                          lastFeedbackAtObj = moment(lastFeedbackAt)
                        }
                        if (lastFeedbackAtObj && currentUtcObj
                          .startOf('day')
                          .isSameOrBefore(
                            lastFeedbackAtObj.startOf('day').add(30, 'days')
                          )) {
                          reject(Error('Latest feedback is less that 30 days old'))
                        } else {
                          // photo uploaded today and first photo is older than 30 days
                          let weight = ''
                          if (service.bodyStat.weight.preference === 'imperial') {
                            weight = service.bodyStat.weight.lbs
                          } else {
                            weight = service.bodyStat.weight.kg
                          }
                          this.options = {
                            latestWeight: weight
                          }
                          this.logEvent('ProgressUpdate.comparePhotos.Show', {
                            message: 'Range check and last feedback check match',
                            payload: {
                              feedbackAt: lastFeedbackAt,
                              dates: dates
                            }
                          }, false)
                          resolve()
                        }
                      })
                      .catch((error) => {
                        reject(error)
                      }).finally(() => {
                      this.hideLoading()
                    })
                  } else {
                    reject(Error('Initial and current photo range is not enough'))
                  }
                })
                .catch((error) => {
                  reject(error)
                })
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            if (data.progressType === 'diet') {
              resolve({component: PageConfirmation, query: {page: 'results'}})
            } else {
              resolve({component: ProcessData, query: {page: 'process'}})
            }
          }
        },
        'progress-meal-plan-main': {
          component: {design1: MealPlanMain},
          querySection: 'diet',
          options: {},
          init: function (service) {
            return new Promise(resolve => {
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, data) {
            resolve({component: PageBodyMeasurements, query: {page: 'measurements'}})
          }
        },
        'progress-workout-main': {
          component: {design1: WorkoutMain},
          querySection: 'fitness',
          options: {},
          init: function (service) {
            return new Promise(resolve => {
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, data) {
            resolve({component: PageBodyMeasurements, query: {page: 'measurements'}})
          }
        },
        'progress-weight': {
          component: {design1: Weight},
          querySection: 'weight',
          options: {},
          init: function (service) {
            return new Promise(resolve => {
              this.options = service.getWeightFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({component: BodyFat, query: {page: 'body-fat'}})
          }
        },
        'progress-body-fat': {
          component: {design1: BodyFat},
          querySection: 'body-fat',
          options: {},
          init: function (service) {
            return new Promise((resolve, reject) => {
              let dashBoardService = new DashBoardService()
              if (!dashBoardService.isShowFatQuestionEnabled() || service.getStatisticsOptions('body_fats').length === 0) {
                reject(new Error('body fat disabled'))
                return
              }
              this.options = service.getBodyFatFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            if (data.progressType === 'diet') {
              resolve({component: MealPlanMain, query: {page: 'diet'}})
            } else if (data.progressType === 'fitness') {
              resolve({component: WorkoutMain, query: {page: 'fitness'}})
            } else {
              throw new Error('Invalid progress type :' + (data.progressType || ''))
            }
          }

        },

        'progress-check-results': {
          component: {design1: PageConfirmation},
          querySection: 'results',
          options: {},
          init: function (service) {
            return new Promise((resolve, reject) => {
              if (service.isSelectedGoalMultiplierOne() || !enableSmartCheckingForActivityLvl()) {
                reject(Error('smart checkin not enabled'))
              }
              window.history.pushState(null, '', window.location.href)
              window.onpopstate = function () {
                window.history.pushState(null, '', window.location.href)
              }
              this.options = service.getCheckResultFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({component: PageConfirmation, query: {page: 'follow'}})
          }
        },
        'progress-check-plan-follow': {
          component: {design1: PageConfirmation},
          querySection: 'follow',
          options: {},
          init: function (service) {
            return new Promise((resolve, reject) => {
              if (service.isSelectedGoalMultiplierOne() || !enableSmartCheckingForActivityLvl()) {
                reject(Error('smart checkin not enabled'))
              }
              if (service.plan.result === 'yes') {
                reject(Error('skip  follow plan step'))
              }
              window.history.pushState(null, '', window.location.href)
              window.onpopstate = function () {
                window.history.pushState(null, '', window.location.href)
              }
              this.options = service.getCheckFollowPlanFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve) {
            resolve({component: ProcessData, query: {page: 'process'}})
          }
        }
      },
      dynamicComponent: '',
      componentData: []
    }
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state.fitnessDiaryStore)) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  mounted () {
    if (this.getService() === null) {
      const progressService = new ProgressCheckInService()

      const payload = {type: (this.$route.name === 'MealPlanUpdateMain') ? 'diet' : 'fitness'}
      progressService.getDataFromAPI(payload).then(() => {
        this.setComponentByType(this.$route.query.page)

        if (progressService.progressData &&
          progressService.progressData.contents &&
          progressService.progressData.contents['page-title']) {
          this.setPageTitle(progressService.progressData.contents['page-title'])
        }
        this.setService(progressService)
      }).catch(() => {
        this.showServerErrorPopup()
        this.hideLoading()
        setTimeout(() => {
          this.$router.replace({
            path: this.$appConfig.appUrlList.dashboard
          })
          this.hideServerErrorPopup()
        }, 5000)
      })
    } else {
      this.setComponentByType(this.$route.query.page)
    }
    this.routerChangeEvent()
  },
  computed: {
    ...mapState({
      formStatus: 'formWizardStore/formStatus'

    }),
    page () {
      for (const [key, value] of Object.entries(this.components)) {
        if (value.querySection === this.$route.query.page) {
          return key
        }
      }
      return 'progress-start'
    },
    appClassObject: function () {
      return {
        'macroapp--page-progress-measurements': this.dynamicComponent === PageBodyMeasurements,
        'macroapp--page-progress-checking macroapp--external-page': this.dynamicComponent === Start,
        'macroapp--page-progress-thank-you macroapp--external-page': this.dynamicComponent === ThankYou
      }
    }
  },
  updated () {
    const type = (this.$route.name === 'MealPlanUpdateMain') ? 'diet' : 'fitness'
    this.setPageName(type + '-' + this.page)
  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getProgressFormService',
      fitnessDiaryService: 'fitnessDiaryStore/getService'
    }),
    ...mapMutations({
      setService: 'formWizardStore/setProgressFormService',
      resetService: 'formWizardStore/reset',
      setPageName: 'pageStore/setPageName',
      unsetPageName: 'pageStore/unsetPageName',
      showServerErrorPopup: 'showServerErrorPopup',
      hideServerErrorPopup: 'hideServerErrorPopup',
      setFormStatus: 'formWizardStore/setFormStatus'

    }),
    getDiaryImageDates (force = false) {
      return new Promise((resolve, reject) => {
        this.fitnessDiaryService()
          .getDataFromAPI(force)
          .finally(() => {
            let images = this.fitnessDiaryService().images

            let dates = Object.values(images).map(image => {
              return new Date(image.firstTime.date)
            })
            let newDates = Object.values(images).map(image => {
              return new Date(image.latestDate)
            })

            resolve({
              firstDate: lodash.min(dates),
              latestDate: lodash.max(newDates)
            })
          })
      })
    },
    formSubmit (data) {
      if (data !== null) {
        this.setKeyData(data.key, data.value)
      }
      this.nextComponent(this.$route.query.page)
    },
    setKeyData (key, value) {
      const service = this.getService()
      if (!key) {
        return
      }
      if (['result', 'follow'].includes(key)) {
        service.plan[key] = value
      } else if (key === 'body_fat') {
        value = parseFloat(value)
        let isValueChanged = this.checkValueChange(service.bodyStat[key], value, key)
        this.logEvent('ProgressUpdate.StepComplete', {
          type: this.$route.path.split('/')[1],
          step: key,
          value_changed: isValueChanged
        }, false)
        service.bodyStat[key] = value
      } else if (key !== 'measurements') {
        let isValueChanged = this.checkValueChange(service.bodyStat[key], value, key)
        this.logEvent('ProgressUpdate.StepComplete', {
          type: this.$route.path.split('/')[1],
          step: key,
          value_changed: isValueChanged
        }, false)
        service.bodyStat[key] = value
      }
    },

    checkValueChange (oldValue, newValue, key) {
      if (key === 'weight') {
        newValue = newValue.kg
      } else if (key === 'body_fat') {
        newValue = parseFloat(newValue) / 100
      }

      return newValue - oldValue !== 0
    },

    nextComponent () {
      const next = new Promise((resolve, reject) => {
        this.getComponentBySection().getNextComponent(resolve, reject, this.getService(), {progressType: ((this.$route.name === 'MealPlanUpdateMain') ? 'diet' : 'fitness')})
      })
      next.then(next => {
        this.showLoading()
        let self = this
        setTimeout(function () {
          self.$router.push({
            path: self.$route.path,
            query: next.query
          })
        }, 500)
      })
    },
    getComponentBySection () {
      return this.components[this.page]
    },
    setComponentByType (type = '') {
      const service = this.getService()
      this.getComponentBySection().init(service).then(() => {
        this.componentData = this.getComponentBySection().options
        this.dynamicComponent = this.getComponentByConfig()
      }).catch((error) => {
        this.logEvent('ProgressUpdate.ComponentInit.Skip', {
          component: this.getComponentBySection().querySection,
          error: error
        }, false)
        this.nextComponent()
      })
    },
    getComponentByConfig () {
      const pageConfig = this.getPageConfigByName(this.page)
      const pageComponents = this.getComponentBySection().component
      if (pageConfig && pageConfig.variation) {
        return pageComponents[pageConfig.variation] || pageComponents.design1
      } else {
        return pageComponents.design1
      }
    },
    routerChangeEvent () {
      const route = this.$route
      const path = route.path
      const queryParams = {}
      const detail = {path: path, query_params: queryParams, query: {}, date_time: moment().format()}
      localStorage.setItem('lastSessionRoute', JSON.stringify(detail))
    }
  },
  beforeRouteLeave (to, from, next) {
    this.fitnessDiaryService().getDataFromAPI(true)
    this.resetService()
    this.showLoading()
    let delay = 500
    setTimeout(() => {
      next()
    }, delay)
  },
  beforeRouteUpdate (to, from, next) {
    this.showLoading()
    let delay = 500
    setTimeout(() => {
      next()
    }, delay)
  }
}
</script>
