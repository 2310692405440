<template>
  <page-secondary :header="headerOptions" class="pc-exercise" :desktop-side-bar="true">
    <container-single-exercise v-if="loaded" @header-text="setHeaderText($event)"/>
    <ContainerSingleExerciseSkeleton v-else></ContainerSingleExerciseSkeleton>
  </page-secondary>
</template>

<script>

import ContainerSingleExercise from './page-components/ContainerSingleExercise'
import pageMixin from '../../../../mixins/pageMixin'
import exerciseMixin from '../../../../mixins/page-mixins/workout/exerciseMixin'
import PageSecondary from '../../../global/pages/PageSecondary'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import ContainerSingleExerciseSkeleton
  from "@/components/layout/template-1/workout/page-components/ContainerSingleExerciseSkeleton.vue";
export default {
  name: 'ExerciseView',
  mixins: [pageMixin, exerciseMixin],
  components: {
    ContainerSingleExerciseSkeleton,
    PageSecondary,
    ContainerSingleExercise
  },
  data: function () {
    return {
      loaded: false,
      headerOptions: {
        right: 'help'
      }
    }
  },
  mounted () {
    let workoutProgramID = parseInt(this.workoutData.workout)
    let workoutProgramsService = new WorkoutProgramsService()
    workoutProgramsService.setWorkoutProgramList().then(() => {
      let program = workoutProgramsService.getWorkoutProgram(workoutProgramID)
      if(!program){
        this.$router.push({path: this.$appConfig.routes.dashboard})
      }
      this.setPageTitle(program.getName())
    })
    this.headerOptions.modifiers = ['small-title']
    this.setPageClass()
    this.setStoreData({ workoutId: this.workoutData.workout, exerciseID: this.workoutData.exercise })
    this.setStyles()
    this.loaded = true
    this.setNotificationBarSettings({ show: false })
  },
  watch: {
    getExerciseDataStatus: function (newVal, oldVal) {
      if (this.getWorkoutDataStatus === 'loaded' && this.getExerciseDataStatus === 'loaded') {
        this.logExerciseViewedEvent()
        pageReadyEvent()
      }
    },
    getWorkoutDataStatus: function (newVal, oldVal) {
      if (newVal === 'loaded') {
        this.getNextExercise()
      }
      if (this.getWorkoutDataStatus === 'loaded' && this.getExerciseDataStatus === 'loaded') {
        this.logExerciseViewedEvent()
      }
    }
  },
  methods: {
    setHeaderText (header) {
      this.headerOptions.header = header
      this.headerOptions.mainTitle = header.mainTitle
      this.headerOptions.subTitle = header.subTitle
    },
    navigateNextWorkout () {
      if (!this.nextExercise.exercise) {
        this.navigateExerciseList()
      } else {
        this.goToExerciseByQuery(this.nextExercise)
      }
    }
  }
}
</script>
